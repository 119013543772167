import styled from 'styled-components';

const EventItemContainer = styled.div`
  height: 100%;
  border-width: 0rem 0rem 0rem 2rem;
  border-style: solid;
  border-radius: 1rem 0rem 0rem 1rem;
  border-color: ${({ theme }) => theme.customTheme.primary};
  padding: 1rem;
  background: ${({ theme }) => theme.core.pureSecondary};
  & * {
    color: ${({ theme }) => theme.contrast.primary};
  }
`;
export const LoadingContainer = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 80%;
  &.off-screen {
    display: none;
  }
`;

const DemoAppContainer = styled.div`
  display: flex;
  position: relative;
  /* min-height: 100%; */
  font-size: 14px;
  height: calc(100% - 11rem); // header height: 7rem, 4rem vertical margin
  background: ${({ theme }) => theme.core.secondary};
  /* background: yellow; */
  margin: 2rem;
  &.isListView {
    height: auto;
  }
  & .svg-inline--fa {
    margin-left: 5px;
  }
  & .fc-v-event,
  & .fc-h-event {
    background: transparent;
    border: 0px;
  }
  & .fc-daygrid-day.fc-day-today,
  & .fc-timegrid-col.fc-day-today {
    background: ${({ theme }) => theme.aux.secondary};
    & ${EventItemContainer} {
      background: ${({ theme }) => theme.core.secondary};
    }
  }
  & .fc .fc-button-primary {
    background-color: ${({ theme }) => theme.customTheme.primary};
    border: 1rem;
    text-transform: capitalize;
  }
  & .fc-toolbar {
    position: relative;
    z-index: 1000;
  }
  & .view-selection-container {
    margin-left: 2rem;
    align-items: center;
    display: inline-block;
  }
  & .view-selection-container > div > div {
    margin-top: 10px;
    right: -10px;
    text-transform: capitalize;
  }
  & .view-selection-container p:hover {
    background: ${({ theme }) => theme.core.secondary};
  }
  & .date-picker-container,
  & .search-bar {
    display: inline-block;
  }
  &.isListView .fc-view-harness {
    display: none;
    /* display: ${({ isListView }) => (isListView ? 'none' : '')}; */
  }
`;
const DemoAppMain = styled.div`
  flex-grow: 1;
  & .fc-col-header-cell {
    padding: 5px 0rem;
    background: white;
  }
`;

const EventsSidePanel = styled.div`
  width: 25%;
  background: red;
  padding: 1rem;
`;

export { DemoAppContainer, DemoAppMain, EventItemContainer, EventsSidePanel };
