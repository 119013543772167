/* eslint-disable no-console */
/* eslint-disable max-len */

import { put } from 'redux-saga/effects';
import { Creators } from './action';
import { WebService } from '../../../../services/axios/webServices';
import { apiEndPoints } from '../../../../services/axios/endPoints';
// import  { menuData } from '../../../constants/sampleData';

export function* getAssetListSaga({ payload }) {
  // console.log('payload', payload);
  const { pageNo, pageSize, filtersPayload, WFTid, searchParams } = payload;
  try {
    yield put(Creators.getAssetListStart());

    const fullURL = yield `${apiEndPoints.getAssetsList}${WFTid}/${pageNo}/${pageSize}`;
    const response = yield WebService.post(fullURL, { ...filtersPayload, SearchFilters: { ...searchParams } });
    // console.log('response===form=>', JSON.parse(response.data[0].formDefinition));
    if (response.status >= 200 && response.status < 300) {
      const { meta, data: rawData } = response.data;
      let data;
      if (typeof rawData === 'string') {
        data = JSON.parse(rawData);
      } else {
        data = rawData;
      }
      yield put(Creators.getAssetListSuccess({ assetListMeta: meta, assetListData: data }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getAssetListFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getAssetListFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.getAssetListFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getAssetListFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getAssetListFailure(payload));
    }
  }
}
