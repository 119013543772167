/* eslint-disable max-len */
import React, { useRef, useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import Calendar from '../../component/Common/Calendar/Calendar';
import ViewTypeSelection from '../../component/Common/Calendar/ViewTypeSelection/ViewTypeSelection';
import HeaderComp from '../../component/Common/Header/Header';
import Toast from '../../component/Common/Toast';
// import Pagination from '../../component/Common/Pagination/Pagination.view';
import PlanListView from '../../component/Plan/PlanList/PlanList.view';
import { useActions } from '../../store/hooks/useActions';
import { PlanCreators } from './store';
import { fetchUserAndLocation } from '../../component/Sidenav/fetchPageStatistics';
// const rowOptions = [
//   { id: 1, label: 'test1', value: 'test1' },
//   { id: 1, label: 'test2', value: 'test2' },
//   { id: 1, label: 'test3', value: 'test3' }
// ];

const Plan = () => {
  // const { loading, error, searchQueries, pageSize, activityList, activityListMeta } = useSelector(
  //   (state) => state.activityData
  // );
  const error = useSelector((state) => state.plansData.error);
  const isListView = useSelector((state) => state.plansData.isListView);

  const dispatch = useDispatch();
  const history = useHistory();
  const [calendarRef] = useState(useRef());
  // const calendarRef = useRef();

  const handleDetails = (details) => {
    // console.log('plan details', details);
    const { scheduleId, planId } = details;
    history.push({ pathname: `/home/plan/details/${planId}` });
  };

  const clearError = () => {
    dispatch(
      PlanCreators.planValueSetter({
        key: 'error',
        value: ''
      })
    );
  };

  const location = useLocation();
  const { userData } = useSelector((state) => state.loginData);
  const { finalMenu } = useSelector((state) => state.home);
  useEffect(() => {
    const name = finalMenu.find((ele) => ele.path === location.pathname);
    fetchUserAndLocation(location.pathname, userData, {
      pageType: name?.name
    });
  }, []);

  return (
    <>
      <CalendarContainer>
        <HeaderComp style={{ zIndex: 1000 }} hasBack={false} header='Plan'>
          <ViewTypeSelection calendarRef={calendarRef} />
        </HeaderComp>
        <Calendar handleDetails={handleDetails} calendarRef={calendarRef} />
        {isListView && (
          <PlanListView
            handleDetails={handleDetails}
            calendarRef={calendarRef}
          />
        )}
      </CalendarContainer>
      {error && (
        <Toast
          message={error}
          className='right'
          header='Error'
          fn={clearError}
        />
      )}
    </>
  );
};

const CalendarContainer = styled.div`
  /* padding: 2rem 3rem; */
  /* background: red; */
  height: 100%;
  & * {
    font-size: 1.6rem;
  }
`;

export default Plan;
