/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable prefer-template */
/* eslint-disable operator-assignment */
/* eslint-disable max-len */
/* eslint-disable no-trailing-spaces */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-curly-brace-presence */
import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FlexContainer } from '../../../globalStyles';
import { BtnAddActivity } from '../../Activity/AddActivity/AddActivity.style';
import HeaderComp from '../../Common/Header/Header';
import { Input } from '../../Common/Input/Input';
import { InputAndButtonContainer } from '../../Common/DropDownModal/DropDownModal.style';
import DropDownModal from '../../Common/DropDownModal/DropDownModal';
import { ContentText, Span } from '../../Common/KeyValueLayout/KeyValueLayout.style';
import { Divider } from '../ScheduleTypeDetails/ScheduleTypeDetails.style';
import { BodyContainer } from '../../Activity/ActivityDetails/ActivtyDetails.style';
import { DropBtn, DropdownCon } from '../../../views/WorkOrders/WorkOrders.styles';
import { ReactComponent as Arrow } from '../../../assets/icons/layout/dropdownarrow.svg';
import { InputCounter } from '../../Common/Inputupdown/InputAddMinus';
import Radiobutton from '../../Common/RadioButton/RadioButton';
import { Div } from './ScheduleTypeForm.style';
import { dropDownOptions } from '../../../views/ScheduleType/sampleData';
import { SearchResultText, SearchTextContainer } from '../../GlobalSearch/GlobalSearchContainer.style';
import useClickOutside from '../../../shared/hooks/useClickOutside';
import { SchedultTypesCreators } from '../../../views/ScheduleType/store/action';
import KeyInputLayoutView from '../../Common/KeyValueLayout/KeyInputLayout.view';
import { WebService } from '../../../services/axios/webServices';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';
import InputDropdown from '../../Common/DropDownModal/InputDropdown';
import { INPUT_TYPES, scheduleRecurenceUnit } from '../../../constants';
import ScheduleTypeFrequency from './ScheduleTypeFrequency';
import ScheduleTypeWithinDayFrequency from './ScheduleTypeWithinDayFrequency';
import { isEmpty } from '../../../shared/utility/isEmpty';
import Loader from '../../Common/Loader/Loader';
import { shouldSubmissionEnable } from '../utility';

const ScheduleTypeForm = (props) => {
  const { data, isEdit = false, handleSave, submissionLoader } = props;
  const [isSubmissionEnabled, setIsSubmissionEnabled] = useState(false);
  const [input, setInput] = useState(
    data || {
      scheduleName: '',
      allowBufferHours: 0,
      allowBufferMinutes: 0,

      // frequency
      // 1. day option
      day: 1,
      dayofEvery: 1,
      // option 2
      recurring: 1,
      firstLastDayName: '',
      firstLastName: '',
      // firstLastDayName: '', // repeat
      firstLastDayEvery: 1,

      // withinday frequency
      occurs: '',
      occursEvery: 1,
      startTime: '',
      endTime: ''
    }
  );
  // console.log('input', input);
  const [showDropdownOf, setShowDropdownOf] = useState('');
  const history = useHistory();
  const handleCancel = () => {
    history.push('/home/schedule-type');
  };
  // console.log('input', input);
  // fetching dropdown data
  const [dropdownDataLoading, setDropdownDataLoading] = useState(false);
  const [dropdownData, setDropdownData] = useState({});
  const [frequencyDropdownData, setFrequencyDropdownData] = useState({});
  const [submitLoader, setSubmitLoader] = useState(false);

  const fetchDropdownData = async () => {
    setDropdownDataLoading(true);
    const response = await WebService.get(apiEndPoints.MDScheduleType);
    try {
      if (response.status >= 200 && response.status < 300) {
        const { data } = response;
        const dpData = data.data;
        setDropdownData(dpData);

        // setting the first values in a dropdown by default
        setInput((inp) => {
          const defaultObj = {
            masterScheduleFrequencyName: inp?.masterScheduleFrequencyName?.masterScheduleFrequencyName
              ? inp?.masterScheduleFrequencyName
              : dpData?.masterScheduleFrequency?.[0]?.[0],
            masterScheduleTypeName: inp?.masterScheduleTypeName?.masterScheduleTypeName
              ? inp?.masterScheduleTypeName
              : dpData?.masterScheduleTypes?.[0]?.[0],
            firstLastDayName: inp?.firstLastDayName?.firstLastDayName
              ? inp?.firstLastDayName
              : dpData?.Frequency_FirstLastDays?.[0]?.[0],
            firstLastName: inp?.firstLastName?.firstLastName
              ? inp?.firstLastName
              : dpData?.Frequency_FirstLast?.[0]?.[0]
          };
          return { ...inp, ...defaultObj };
        });
        setDropdownDataLoading(false);
      } else {
        throw response;
      }
    } catch (e) {
      setDropdownDataLoading(false);
      const payload = sagaCatchBlockHandling(e);
    }
  };

  const fetchFrequencyDropdown = async (scheduleTypeId) => {
    setDropdownDataLoading(true);
    const response = await WebService.get(`${apiEndPoints.frequencyType}/${scheduleTypeId}`);
    try {
      if (response.status >= 200 && response.status < 300) {
        const { data } = response;
        setFrequencyDropdownData(data);
        setDropdownDataLoading(false);
      } else {
        throw response;
      }
    } catch (e) {
      setDropdownDataLoading(false);
      const payload = sagaCatchBlockHandling(e);
    }
  };
  // check for empty fields
  useEffect(() => {
    setIsSubmissionEnabled(shouldSubmissionEnable(input, dropdownData));
  }, [input]);

  useEffect(() => {
    fetchDropdownData();
  }, []);

  const masterScheduleTypeName =
    input?.masterScheduleTypeName?.masterScheduleTypeName ||
    dropdownData?.masterScheduleTypes?.[0]?.[0]?.masterScheduleTypeName;

  /**
     * Schedule Edit has to check for MasterScedule Id on basis of masterScheduleTypeName
     */
  let masterScheduleTypeId;
  if (isEdit) {
    if (input?.masterScheduleTypeName?.masterScheduleTypeName && !input?.masterScheduleTypeName?.masterScheduleTypeId) {
      masterScheduleTypeId = dropdownData?.masterScheduleTypes && dropdownData?.masterScheduleTypes[0]?.filter((schedule) => schedule.masterScheduleTypeName === input?.masterScheduleTypeName?.masterScheduleTypeName)[0]?.masterScheduleTypeId;
    } else {
      masterScheduleTypeId =
        input?.masterScheduleTypeName?.masterScheduleTypeId ||
        dropdownData?.masterScheduleTypes?.[0]?.[0]?.masterScheduleTypeId;
    }
  } else {
    masterScheduleTypeId =
      input?.masterScheduleTypeName?.masterScheduleTypeId ||
      dropdownData?.masterScheduleTypes?.[0]?.[0]?.masterScheduleTypeId;
  }

  useEffect(() => {
    if (masterScheduleTypeId) {
      fetchFrequencyDropdown(masterScheduleTypeId);
    }
  }, [masterScheduleTypeId]);

  const setOptions = (payload) => {
    // console.log('options', input);
    const checkFrom = payload || input;
    // console.log('checking options....', checkFrom.firstLastName);
    let freqOptionValue = 'day';
    // undefinged firstLastDayEvery (month=0)
    if (checkFrom.firstLastDayEvery < 1 || !checkFrom.firstLastDayEvery) {
      freqOptionValue = 'date';
    }
    return {
      frequencyOption: freqOptionValue, // day // if more than week
      frequencyWithDayOption: (payload || input).occurs ? 'once' : 'repeats' // repeats
    };
  };
  // console.log('additionalInputPayload', additionalInputPayload);
  // setInput({ ...input, ...additionalInputPayload });
  // derivedfrom props
  useEffect(() => {
    const additionalInputPayload = setOptions(data);
    setInput({ ...data, ...additionalInputPayload });
  }, [data]);

  // setting options of frequency and within
  useEffect(() => {
    const additionalInputPayload = setOptions();
    setInput({ ...input, ...additionalInputPayload });
  }, [
    masterScheduleTypeName
    // TODO: test after removal
    // input.firstLastName,
    // input.occurs
  ]);

  const handleInputChange = (inputType, e) => {
    if (inputType === INPUT_TYPES.TEXT) {
      const { name, value } = e.target;
      setInput({ ...input, [name]: value });
    }
  };
  const handleInputCounter = ({ label, value }) => {
    setInput({ ...input, [label]: value });
  };

  const handleDropdownSelection = (item, extraInfo) => {
    const { label } = extraInfo;
    if (label === 'Name') {
      setInput({ ...input, masterScheduleFrequencyName: item });
    } else {
      setInput({ ...input, [label]: item });
    }
  };

  const handleDropdownVisibility = (key) => {
    setShowDropdownOf(showDropdownOf === key ? '' : key);
  };

  const handleSubmit = () => {
    handleSave(input, dropdownData, setSubmitLoader);
  };
  // console.log('inppppp-1', input, dropdownData);
  return (
    <>
      <HeaderComp className='fixed-height' header={'Schedule Type'} hasBack>
        <FlexContainer className='jc_center'>
          <BtnAddActivity className='cancel_btn' onClick={handleCancel}>
            Cancel
          </BtnAddActivity>
          <BtnAddActivity className={isSubmissionEnabled ? '' : 'disable'} onClick={handleSubmit}>
            Save
          </BtnAddActivity>
        </FlexContainer>
      </HeaderComp>
      <BodyContainer style={{ zIndex: '1', position: 'relative' }}>
        {(dropdownDataLoading || submitLoader) && <Loader />}
        {/* Schedule Name */}
        <KeyInputLayoutView className='required' label='Schedule Name'>
          <Input
            width='51rem'
            placeholder='Schedule Name'
            value={input.scheduleName}
            name='scheduleName'
            onChange={(e) => {
              handleInputChange(INPUT_TYPES.TEXT, e);
            }}
          />
        </KeyInputLayoutView>
        <Divider style={{ marginBottom: '3rem', marginTop: '3rem' }} />
        {/* Schedule Type */}
        <KeyInputLayoutView className='required' label='Schedule Type'>
          <DropDownModal
            // data={dropDownOptions?.recursType}
            data={dropdownData?.masterScheduleTypes?.[0]}
            isActive={showDropdownOf === 'masterScheduleTypeName'}
            handleClick={handleDropdownSelection}
            setModalIsVisibal={() => handleDropdownVisibility('masterScheduleTypeName')}
            label='masterScheduleTypeName'
            singleSelection
            width='51rem'
          >
            <InputDropdown
              keyName='masterScheduleTypeName'
              value={masterScheduleTypeName}
              handleDropdownVisibility={handleDropdownVisibility}
              isEdit={isEdit}
            />
          </DropDownModal>
        </KeyInputLayoutView>
        {/* Allow BufferTime */}
        <KeyInputLayoutView label='Allow BufferTime'>
          <FlexContainer style={{ alignItems: 'center' }}>
            <Span>HH</Span>
            <InputCounter
              inputFor='hours'
              label='allowBufferHours'
              value={input.allowBufferHours}
              valueSetter={handleInputCounter}
            />
            <Span style={{ marginLeft: '5rem' }}>MM</Span>
            <InputCounter
              inputFor='minutes'
              label='allowBufferMinutes'
              value={input.allowBufferMinutes}
              valueSetter={handleInputCounter}
            />
          </FlexContainer>
        </KeyInputLayoutView>
        {!isEmpty(dropdownData) && masterScheduleTypeName !== 'One Time' && (
          <>
            <Divider style={{ marginBottom: '3rem', marginTop: '3rem' }} />
            {/* Frequency */}
            <ScheduleTypeFrequency
              input={input}
              setInput={setInput}
              dropdownData={dropdownData}
              frequencyDropdownData={frequencyDropdownData}
              showDropdownOf={showDropdownOf}
              handleDropdownSelection={handleDropdownSelection}
              handleInputCounter={handleInputCounter}
              handleDropdownVisibility={handleDropdownVisibility}
            />
          </>
        )}
        {/* {masterScheduleTypeName !== 'Relative Recurring' && ( */}
        <>
          <Divider style={{ marginBottom: '3rem', marginTop: '3rem' }} />
          {/* frequency with the day details */}
          <ScheduleTypeWithinDayFrequency
            input={input}
            setInput={setInput}
            handleInputChange={handleInputChange}
            handleInputCounter={handleInputCounter}
          />
        </>
        {/* )} */}
      </BodyContainer>
    </>
  );
};

export default ScheduleTypeForm;
