import { tableContentTypes } from '../../constants';

export const columnsDetails = {
  columns: [
    {
      key: 'name',
      imageKey: 'profileImage',
      title: 'Name',
      type: tableContentTypes.IMAGE_DETAILS,
      align: 'left',
      sortBy: true,
      searchable: true,
      visible: true,
      options: []
    },
    // {
    //   key: 'Category',
    //   name: 'Category',
    //   isSortable: true
    // },
    {
      key: 'employeeId',
      title: 'Employee Id',
      searchable: true,
      visible: true,
      options: []
    },
    {
      key: 'emailId',
      title: 'Email Id',
      sortable: true,
      searchable: true,
      visible: true,
      options: []
    },
    {
      key: 'roles',
      title: 'Roles',
      visible: true,
      options: [],
      sortBy: true
      // searchable: true
    },
    {
      key: 'location',
      title: 'Location',
      // sortable: true,
      visible: true,
      options: [],
      sortBy: true
    }
  ],
  count: 6
};

export const userData = [
  {
    name: 'Arun A',
    emailId: 'adamtest@mail.com',
    department: 'Production',
    profileImage: 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png',
    employeeId: 39402,
    location: 'India',
    supervisorName: 'Mr. Gere',
    roles: 'Supervisor'
  },
  {
    name: 'Adam',
    emailId: '2adamtest@mail.com',
    department: 'Production',
    profileImage: 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png',
    employeeId: 33490,
    location: 'Dubai',
    supervisorName: 'Mr. Gere',
    roles: '2Sde'
  },
  {
    name: 'Abdul',
    emailId: '3adamtest@mail.com',
    department: 'Production',
    profileImage: 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png',
    employeeId: 32334,
    location: 'Zamaica',
    supervisorName: 'Mr. Gere',
    roles: 'Sde'
  },
  {
    name: '4Adam',
    emailId: '4adamtest@mail.com',
    department: 'Production',
    profileImage: 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png',
    employeeId: 23789,
    location: 'New York',
    supervisorName: 'Mr. Gere',
    roles: 'Sde'
  },
  {
    name: 'Ram',
    emailId: 'ram.test@mail.com',
    department: 'Production',
    profileImage: 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png',
    employeeId: 23866,
    location: 'Africa',
    supervisorName: 'Mr. Gere',
    roles: 'operator'
  },
  {
    name: 'Aujla',
    emailId: 'aujlatest@mail.com',
    department: 'Production',
    profileImage: 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png',
    employeeId: 23876,
    location: 'India',
    supervisorName: 'Mr. Gere',
    roles: 'viewer'
  }
];

export const userformStructure = [
  {
    header: 'Basic User Details'
  },
  {
    label: 'Full Name',
    inputType: 'text',
    key: 'name',
    fieldWidth: 'LARGE',
    isRequired: true
  },
  {
    label: 'Employee ID',
    inputType: 'text',
    key: 'employeeId',
    placeholder: '',
    fieldWidth: 'LARGE',
    isRequired: true, // mandatory field
    isReadOnly: true, // read only
    allowEmpty: false
  },
  {
    label: 'Email ID',
    inputType: 'email', // email validation
    key: 'ema ilId',
    placeholder: '',
    fieldWidth: 'LARGE'
  },
  {
    label: 'Password',
    inputType: 'password', // password type input
    key: 'pwd',
    placeholder: '',
    fieldWidth: 'MEDIUM'
  },
  {
    label: 'Confirm Password',
    inputType: 'password', // password type input
    key: 'cfrmpwd',
    placeholder: '',
    fieldWidth: 'MEDIUM',
    validation: {
      value: 'equals',
      key: 'pwd'
    }
  },

  {
    label: 'Active Directory User',
    inputType: 'toggle', // toggle button
    key: 'activeDirectoryUser',
    placeholder: '',
    fieldWidth: 'LARGE'
  },
  {
    label: 'Location ',
    inputType: 'text',
    key: 'location',
    placeholder: '',
    fieldWidth: 'LARGE'
  },
  {
    label: 'Department',
    inputType: 'text',
    key: 'department',
    placeholder: '',
    fieldWidth: 'LARGE'
  },
  {
    label: 'Profile Image',
    inputType: 'imageUpload',
    placeholder: ''
  },

  {
    label: 'Supervisor Name',
    inputType: 'text',
    key: 'supervisorName',
    placeholder: '',
    isRequired: true
  },
  {
    header: 'Assign Roles'
  },
  {
    label: 'Assign Role',
    inputType: 'dropdown',
    key: 'roles',
    placeholder: '',
    isReadOnly: true,
    dataSource: {
      dataKey: 'data',
      data: [
        { label: 'Designer', value: 'designer' },
        { label: 'Technisian', value: 'technisian' },
        { label: 'Supervisor', value: 'supervisor' },
        { label: 'Operator', value: 'operator' },
        { label: 'Foremen', value: 'foremen' }
      ]
    }
  }
  // {
  //   label: 'Show Equipment Effectiveness',
  //   key: 'equipmentEffectiveness',
  //   inputType: 'radios',
  //   isRequired: true,
  //   dataSource: {
  //     dataKey: 'data',
  //     data: [
  //       {
  //         label: 'Overall Equipment Effectiveness',
  //         value: 'OEE'
  //       },
  //       {
  //         label: 'Availability Effectivness',
  //         value: 'AE'
  //       },
  //       {
  //         label: 'Performance Effectivness',
  //         value: 'PE'
  //       },
  //       {
  //         label: 'Quality Effectivness',
  //         value: 'QE'
  //       }
  //     ],
  //     selectAll: {
  //       showOption: true,
  //       defaultValue: true
  //     }
  //   }
  // }
];

export const columnsDetailsXl = {
  columns: [
    {
      key: 'name',
      imageKey: 'profileImage',
      title: 'Name',
      type: tableContentTypes.IMAGE_DETAILS,
      align: 'left',
      visible: true,
      options: []
    },
    // {
    //   key: 'Category',
    //   name: 'Category',
    //   isSortable: true
    // },
    {
      key: 'employeeId',
      title: 'Employee Id',
      visible: true,
      options: []
    },
    {
      key: 'emailId',
      title: 'Email Id',
      visible: true,
      options: []
    },
    {
      key: 'roles',
      title: 'Roles',
      visible: true,
      options: []
      // searchable: true
    },
    {
      key: 'location',
      title: 'Location',
      // sortable: true,
      visible: true,
      options: []
    }
  ],
  count: 6
};

export const userDataFromXl = [
  {
    name: 'Abdul',
    emailId: '3adamtest@mail.com',
    department: 'Production',
    profileImage: 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png',
    employeeId: 32334,
    location: 'Zamaica',
    supervisorName: 'Mr. Gere',
    roles: 'Sde4',
    excelEdit: true
  },
  {
    name: 'Aujla k',
    emailId: 'aujlatest@mail.com',
    department: 'Production',
    profileImage: 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png',
    employeeId: 23876,
    location: 'India',
    supervisorName: 'Mr. Gere',
    roles: 'viewer',
    excelEdit: true
  },
  {
    name: 'Ram',
    emailId: 'rama.test@mail.com',
    department: 'Production',
    profileImage: 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png',
    employeeId: 23866,
    location: 'Africa',
    supervisorName: 'Mr. Gere',
    roles: 'operator',
    excelEdit: true
  },
  {
    name: 'Adam',
    emailId: '2adamtest@mail.com',
    department: 'Production',
    profileImage: 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png',
    employeeId: 33490,
    location: 'Dubaiii',
    supervisorName: 'Mr. Gere',
    roles: '2Sde',
    excelEdit: true
  }
];
