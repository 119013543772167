import React from 'react';
import { useHistory } from 'react-router-dom';

import { Div, FlexContainer } from '../../../globalStyles';
import DropArrow from '../../../assets/icons/layout/dropdownarrow.svg';

import DynamicSVGIcon from '../DynamicSVGIcon';
import { Header, HeaderText } from './PageHeader.styles';

const PageHeader = ({ title, children, hasBack = true, handleBack }) => {
  const history = useHistory();
  const onBack = () => history.goBack();
  return (
    // header component having only header buttons passed as props
    <Header className='fixed jc_spacebetween'>
      <FlexContainer className='ai_center'>
        {hasBack && (
          <Div className='pointer' onClick={handleBack || onBack} style={{ paddingRight: '1rem' }}>
            <DynamicSVGIcon iconUrl={DropArrow} width='2.2rem' rotate='180' />
          </Div>
        )}
        <HeaderText>{title}</HeaderText>
      </FlexContainer>
      <Div>{children}</Div>
    </Header>
  );
};

export default PageHeader;
