import { vistrianLogo } from '../assets/icons/index';

export { colors } from './colors';
export { fontFamily, fontWeight, fontSize } from './font';
export { device, minDevice } from './device';

export const PAGE_TYPES = {
  DIRECT_IFRAME: 'directIframe'
};

export const CHART_TYPES = {
  DATA_CHART: 'dataChart',
  TRANSITION_CHART: 'transitionChart'
};

export const LANGUAGES = {
  ENGLISH: 'en',
  TEST: 'Test'
};

export const THEMES = {
  DARK: 'dark',
  LIGHT: 'light'
};

export const FILTER_TERMS = {
  SINGLE_SELECT: 'singleselect'
};

export const STATUSES = {
  TO_BE_ASSIGNED: 'To be assigned',
  TO_BE_ACKNOWLEGED: 'To be acknowleged',
  TO_BE_STARTED: 'To be sarted',
  SPARE_REQUEST: 'Spare request'
};

export const WO_TYPES = {
  PREVENTIVE_MAINTENANCE: 'Preventive Maintenance',
  SPARE_REQUEST: 'Spare Request',
  BREAKDOWN: 'Breakdown',
  HV_PARTS: 'HV Parts'
};

export const WO_TYPES_SHORTHAND = {
  PM: 'Preventive Maintenance',
  Spare: 'Spare Request',
  Breakdown: 'Breakdown',
  'HV PARTS': 'HV Parts'
};

export const INPUT_TYPES = {
  TEXT: 'text',
  DROPDOWN: 'dropdown',
  NUM_COUNT: 'numberCount',
  RADIO: 'radios',
  CHECKBOX: 'checkboxes',
  FILTER_SEARCH: 'filterSearch',
  FILTER_SEARCH_TREE: 'filterSearchTree',
  EQUIPMENT_SEARCH: 'equipmentSearch',
  PASSWORD: 'password',
  EMAIL: 'email',
  TOGGLE: 'toggle',
  MULTI_DROPDOWN: 'multiDropdown',
  DATE_TIME_LOCAL: 'dateTimeLocal',
  COUNTER: 'counter',
};

export const SCHEDULE_FREQUENCY_TYPES = {
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  BI_WEEKLY: 'Bi-Weekly',
  MONTHLY: 'Monthly',
  BI_MONTHLY: 'Bi-Monthly',
  QUARTERLY: 'Quarterly',
  HALF_QUARTERLY: 'Half Quarterly',
  YEARLY: 'Yearly'
};

export const scheduleRecurenceUnit = {
  Daily: 'Day(s)',
  Weekly: 'Week(s)',
  'Bi-Weekly': 'Week(s)',
  Monthly: 'Month(s)',
  'Bi-Monthly': 'Month(s)',
  Quarterly: 'Quarter(s)',
  'Half Quarterly': 'Quarter(s)',
  Yearly: 'Year(s)'
};

export const SUCCESS = 'Success';

export const POSITION_MAPPING = {
  First: 1,
  Second: 2,
  Third: 3,
  Fourth: 4
};

export const WO_CREATION_FROM = {
  WORK_ORDERS_LIST: 'workOrdersList',
  PLAN_DETAILS: 'planDetails'
};

export const WO_PANEL_DETAILS = {
  ATTACHMENT: 'attchment',
  CHECKLIST: 'checklist'
};

export const SCHEDULE = {
  ONE_TIME: 'One Time'
};

export const WO_BUTTON_LABLE = {
  ADD_PARTS: 'Add Parts',
  EXCHANGE_PARTS: 'Exchange Parts'
};

export const tableItemFlags = {
  NEWLY_ADDED: 'newlyAdded',
  CHANGED_KEYS: 'changedKeys',
  SHOULD_DELETE: 'shouldDelete'
};

export const tableContentTypes = {
  IMAGE_DETAILS: 'imageDetails'
};

export const COLUMN_WIDTHS = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE',
  EXTRA_LARGE: 'EXTRA_LARGE'
};

export const RESOLVED_FIELD_CLASS_NAME = {
  [COLUMN_WIDTHS.SMALL]: 'col_sm',
  [COLUMN_WIDTHS.MEDIUM]: 'col_md',
  [COLUMN_WIDTHS.LARGE]: 'col_lg',
  [COLUMN_WIDTHS.EXTRA_LARGE]: 'col_xl'
};

export const FILTER_TERMINOLOGY = {
  LEAF_NODE: 'LEAF_NODE',
  TREE: 'TREE'
};
