/* eslint-disable react/jsx-indent-props */
/* eslint-disable array-callback-return */
/* eslint-disable max-len */
/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import HeaderComp from '../../Common/Header/Header';
import { FlexContainer } from '../../../globalStyles';
import { BtnAddActivity } from '../../Activity/AddActivity/AddActivity.style';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { WebService } from '../../../services/axios/webServices';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';
import Loader from '../../Loader/Loader';
import { isEmpty } from '../../../shared/utility/isEmpty';
import { BodyContainer } from '../../Activity/ActivityDetails/ActivtyDetails.style';
import GenericForm from '../../Common/GenericForm/GenericForm';
import { getFieldDependents } from '../../Common/GenericForm/utils';
import {
  getFilterSelectionInp,
  populateFilterDashboard
} from '../../Filter/utility/populateFilterDashboard';
import { Creators as FilterCreators } from '../../../views/Filters/store';
import { useActions } from '../../../store/hooks/useActions';
import Toast from '../../Common/Toast';

const AddEditFormDynamic = (props) => {
  const { data, handleSave, isEdit = false } = props;

  const [loading, setLoading] = useState(false);
  const [formInput, setFormInput] = useState({});
  const [showToast, setShowToast] = useState(false);
  const [formStructure, setFormStructure] = useState([]);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [isFormFilled, setIsFormFilled] = useState(false);

  const filterData = useSelector((state) => state.filterData.filterData);

  const scheduleDetailsFormRef = useRef();

  const history = useHistory();
  const dispatch = useDispatch();
  const { setToastError } = useActions();

  const backButton = () => {
    history.push('/home/schedule');
  };

  useEffect(() => {
    if (!isEmpty(data)) {
      setFormInput(data);
    }
  }, [data]);

  useEffect(() => {
    setLoading(true);
    WebService.get(`${apiEndPoints.scheduleFormStructure}/create`)
      .then((res) => {
        const { status, data } = res;
        if (status >= 200 && status < 300) {
          setFormStructure(data);
        } else {
          throw res.data;
        }
      })
      .catch((e) => {
        const { error } = sagaCatchBlockHandling(e);
        setToastError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onSubmit = () => {
    const localFormInput = scheduleDetailsFormRef.current.getFormInput();
    if (
      new Date(localFormInput.startDate) <= new Date(localFormInput.endDate)
    ) {
      handleSave({ ...localFormInput }, setSubmitLoader);
    } else {
      setShowToast(true);
    }
  };

  useEffect(() => {
    if (isEdit) {
      if (!isEmpty(data) && !isEmpty(filterData)) {
        const filterTree = filterData.reduce((acc, cur) => {
          const { keyName } = cur;
          return {
            ...acc,
            [keyName]: [cur]
          };
        }, {});
        const check = {
          data: {
            tree: data?.asset[0]?.tree && [data?.asset[0]?.tree[0]]
          }
        };
        if (data?.asset[0]?.tree) {
          delete check?.data?.tree[0]?.id;
        }
        const { cumTags, cumSelectedObj } = populateFilterDashboard(
          check,
          filterTree
        );
        const payload = getFilterSelectionInp(cumTags, cumSelectedObj);
        dispatch(FilterCreators.populateFilterData(payload));
      }
    }
  }, [data, filterData]);

  if (loading) {
    return <Loader />;
  }
  const clearToastMessage = () => {
    setShowToast(false);
  };

  return (
    <div>
      <HeaderComp
        isActivity={true}
        className='fixed-height'
        header='Schedule'
        hasBack
      >
        <FlexContainer className='jc_center'>
          <BtnAddActivity className='cancel_btn' onClick={backButton}>
            Cancel
          </BtnAddActivity>
          <BtnAddActivity onClick={onSubmit} disabled={!isFormFilled}>
            {submitLoader ? <Loader className='submitBtn' /> : 'Save'}
          </BtnAddActivity>
        </FlexContainer>
      </HeaderComp>
      <BodyContainer>
        <GenericForm
          isFormEditable={true}
          formStructure={formStructure}
          ref={scheduleDetailsFormRef}
          formInput={formInput}
          setFormInput={setFormInput}
          showHeader
          setIsFormFilled={setIsFormFilled}
          formStructureFieldDepenents={getFieldDependents(formStructure)}
          allowHorizontal={true}
          alignColumn={true}
          isUser={false}
        />
        {showToast && (
          <Toast
            header='Error'
            message='End date Should be greater then Start date'
            style={{ right: '20px', bottom: '30px' }}
            fn={clearToastMessage}
          />
        )}
      </BodyContainer>
    </div>
  );
};

export default AddEditFormDynamic;
