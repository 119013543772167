/* eslint-disable max-len */
import { createActions } from 'reduxsauce';

export const { Types, Creators: SchedultTypesCreators } = createActions({
  setSearchQueries: ['payload'],
  setScheduleType: ['payload'],
  editScheduleType: ['payload'],
  getScheduleType: ['payload'],
  getScheduleTypeStart: ['payload'],
  getScheduleTypeSuccess: ['payload'],
  getScheduleTypeFailure: ['payload'],
  getScheduleTypeDetails: ['payload'],
  getScheduleTypeDetailsStart: ['payload'],
  getScheduleTypeDetailsSuccess: ['payload'],
  getScheduleTypeDetailsFailure: ['payload']
});
