/* eslint-disable react/jsx-curly-newline */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-danger */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-template */
/* eslint-disable function-paren-newline */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useState, useEffect, useRef, Suspense } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import {
  AddToCart,
  CancelBlack,
  RemoveFromCart,
  searchBlack,
  rightArrow
} from '../../../assets/icons';
import {
  Container,
  ContentContainer,
  HeaderContainer,
  MachineHeaderText,
  Row,
  TableContainer,
  Cell,
  CancelIcon,
  SearchIcon,
  SearchInput,
  ActionIcon
} from './TabsTable.style';
import Loader from '../../Loader/Loader';
import DynamicSVGIcon from '../DynamicSVGIcon';
import Pagination from '../Pagination/Pagination.view';
import { Table, Tr, Td, Th } from '../Table/SpacedRows.styles';
import { FlexContainer } from '../../../globalStyles';
import Toast from '../Toast';
import DropDownModal from '../DropDownModal/DropDownModal';
import { fontSize } from '../../constants';
import PopUpModal from '../PopUpModal/Modal.view';
import DateRangePicker from '../../RangeSelectors/DateRangePicker/DateRangePicker';
import ButtonComp from '../Button/Button.view';
import { isEmpty } from '../../../shared/utility/isEmpty';
import Groupby from '../GroupBy/GroupBy';
import TableContent from './TableContent';
import { curryIsOptionsVisible, OPTIONS_TYPES } from './utility';
import PaginationWithPage from '../Pagination/PaginationWithPage.view';

// import { assetMetaCol } from '../../../views/WorkOrders/data';

const TabTable = styled(Table)`
  background-color: ${({ theme }) => theme.core.secondary};
  border-radius: 10px;
  padding: 1rem;
  &.spareTable {
    background: none;
    padding: 0 1rem;
    border-spacing: 0rem 0.2rem;
  }
`;

const TabsTable = ({
  // activeTab = 'a',
  // setActiveTab,
  // tabs = [
  //   { id: 1, name: 'a' },
  //   { id: 2, name: 'b' },
  //   { id: 3, name: 'c' }
  // ],
  // removeTab,
  // handleTabSelect,
  metaDetails,
  dataDetails,
  pageSize,
  setPageSize,
  handlePageChange,
  assetShortlist,
  handleShortList,
  assetListLoading,
  onItemSelect,
  showPagiation = true,
  // search query setter
  saveSearchQueries,
  // entered search queries
  // if not defined it makes a local search
  savedSearchQueries,
  // show shortlist selection
  showShortlistSelection = true,
  // show options
  rowOptions,
  handleRowOptionsItemClick: handleRowOptionsItemClickHandler,
  resetSearchQueries = () => {},
  error,
  className,
  additionalColumns,
  id, // unique id assigned for each table
  styleType, // type of style needed to be applied to the table
  noItemsFoundMessage,
  onHyperLinkSelect = () => {},
  onLogsClick = () => {}
}) => {
  // const metaDetails = assetMetaCol;
  // console.log('metaDetails', metaDetails);
  const { localeStrings } = useSelector((state) => state.localeStrings);
  const [showSearchInp, setShowSearchInp] = useState({});
  const [showDropdown, setShowDropdown] = useState({});
  const [searchQuery, setSearchQuery] = useState(savedSearchQueries || {});
  const [filteredResultData, setFilteredResultData] = useState(null);
  const [groupByList, setGroupByList] = useState([]);
  const [groupBy, setGroupBy] = useState();
  const searchRef = useRef();
  // const metaColumns = metaDetails?.columns || metaDetails?.fields;

  // console.log('savedSearchQueries', savedSearchQueries);
  const isLocalSearch = !savedSearchQueries && true;

  const getDataDetailsByGroup = (dataDetails, groupBy) => {
    try {
      if (!groupBy) {
        return {
          noGrouping: dataDetails
        };
      }
      if (!dataDetails) {
        return {
          [groupBy]: []
        };
      }
      return dataDetails.reduce((result, dataItem) => {
        const groupValue = dataItem[groupBy];
        if (!result[groupValue]) {
          return { ...result, [groupValue]: [dataItem] };
        }
        result[groupValue].push(dataItem);
        return result;
      }, {});
    } catch (e) {
      console.log('error in getDataDetailsByGroup ', e);
    }
    return {};
  };
  useEffect(() => {
    // set groupBylist & groupBy
    if (!isEmpty(metaDetails?.grouping)) {
      const grouping = metaDetails.grouping?.map((groupName) => ({
        key: groupName,
        title: metaDetails.columns?.find((col) => col.key === groupName)?.title
      }));
      setGroupByList(grouping);
      const defaultGroup = grouping[0].key;
      setGroupBy(defaultGroup);
    }
    // compute inputs
    if (!isLocalSearch) {
      const showFeildInputs = Object.keys(savedSearchQueries).reduce(
        (acc, key) => ({ ...acc, [key]: savedSearchQueries[key] && true }),
        {}
      );
      setShowSearchInp(showFeildInputs);
    }

    if (id !== 'UserID') {
      return () => {
        resetSearchQueries();
      };
    }
  }, []);

  // format the normal/fitered data based on groups
  const [rowsDetails, setRowsDetails] = useState({});
  useEffect(() => {
    const rowsDetailsArrRes =
      Object.keys(showSearchInp).some((e) => showSearchInp[e]) &&
      filteredResultData
        ? filteredResultData
        : dataDetails;
    const rowsDetailsRes = rowsDetailsArrRes?.length
      ? getDataDetailsByGroup(rowsDetailsArrRes, groupBy)
      : {};
    setRowsDetails(rowsDetailsRes);
  }, [filteredResultData, dataDetails, groupBy]);

  // useEffect(() => {
  //   // searchable feilds computation
  //   const metaColumns = metaDetails?.columns || metaDetails?.fields;
  //   const searchableFields = metaColumns?.filter((field) => field.searchable);
  //   const tempShowSearchInp = {};
  //   searchableFields?.forEach((e) => {
  //     console.log('saa', e.key, savedSearchQueries[e.key]?.length);
  //     tempShowSearchInp[e.key] = savedSearchQueries[e.key]?.length > 0;
  //   });
  //   setShowSearchInp({ ...tempShowSearchInp });
  // }, []);

  const fetchData = (searchQueryParam) => {
    // console.log('searchQuery-ssubmit', key, searchQuery);
    saveSearchQueries(searchQueryParam || searchQuery);
    handlePageChange(1, { searchParams: searchQueryParam || searchQuery });
  };

  const [showToast, setShowToast] = useState(false);
  const handleSearchQuery = (field, e, isDirectValue = false) => {
    let locSearchQuery = e?.target?.value || '';
    if (isDirectValue) {
      locSearchQuery = e;
    }
    if (!isLocalSearch && !isDirectValue) {
      // toast handling
      if (!showToast && locSearchQuery.length > 0) {
        setShowToast(true);
      } else if (locSearchQuery.length < 1) {
        setShowToast(false);
      }
    }

    const updatedSearchQueries = { ...searchQuery, [field]: locSearchQuery };
    setSearchQuery(updatedSearchQueries);
    if (isLocalSearch) {
      let tempFilteredKpvGroupDet = { ...dataDetails };

      // eslint-disable-next-line arrow-body-style
      tempFilteredKpvGroupDet = dataDetails.filter((item) => {
        return Object.keys(searchQuery).every((query) => {
          if (query === field) {
            return String(item[query])
              .toLowerCase()
              .includes(locSearchQuery.toLowerCase());
          }
          // all other cumulative search queries are checked
          return String(item[query])
            .toLowerCase()
            .includes(searchQuery[query].toLowerCase());
        });
      });

      setFilteredResultData(tempFilteredKpvGroupDet);
    } else {
      const savedSearchQuery = savedSearchQueries[field] || '';
      // fetch data if complete data is present(isDirectValue===true)
      if (isDirectValue && savedSearchQuery !== locSearchQuery) {
        fetchData({ ...searchQuery, [field]: locSearchQuery });
      }
    }
  };

  const _handleKeyPress = (e) => {
    e.preventDefault();
    if (e.key === 'Enter' && !isLocalSearch) {
      setShowToast(false);
      fetchData();
    }
  };

  const handleUICompVisiblity = ({ field, index }, uiComp = 'input') => {
    if (uiComp === 'dropdown') {
      setShowDropdown({ ...showDropdown, [field]: !showDropdown[field] });
    } else {
      // closing the searchinput(click cancel icon)
      if (showSearchInp[field]) {
        handleSearchQuery(field, '', true);
      }
      setShowSearchInp({ ...showSearchInp, [field]: !showSearchInp[field] });
    }
  };

  const handleDropdownItem = (field, item) => {
    if (searchQuery[field] === (item.value || item.label)) {
      handleSearchQuery(field, '', true);
    } else {
      handleSearchQuery(field, item.value || item.label, true);
    }
    setShowDropdown({ ...showDropdown, [field]: !showDropdown[field] });
  };

  const closeDropdown = (field) => {
    // console.log('closeDropdown', field);
    const updatedSearchQueries = {
      ...searchQuery,
      [field]: savedSearchQueries?.[field]
    };
    setSearchQuery(updatedSearchQueries);
    setShowDropdown({ ...showDropdown, [field]: false });
  };

  // console.log('kpvGroupDetails', kpvGroupDetails);
  // tabs
  // <HeaderContainer>
  //   {tabs.map((tab) => (
  //     <MachineHeaderText
  //       onClick={() => handleTabSelect(tab)}
  //       className={activeTab === tab.name ? 'active' : ''}
  //       key={tab.id}
  //     >
  //       {tab.name}
  //       {/* <CancelIcon onClick={() => handleRemoveMachine(selectedMachines[machine])}>
  //             <DynamicSVGIcon iconUrl={CancelBlack} width='1.5rem' />

  //           </CancelIcon> */}
  //     </MachineHeaderText>
  //   ))}
  // </HeaderContainer>;

  const handleDateSelection = (value, rest) => {
    const { label } = rest;
    handleSearchQuery(label, { target: { value: value.rawValue } });
    // old datetime handling
    // setShowToast(true);
    // toastMsg.current = 'Please close the dropdown to search';
  };

  const closeDateModal = (field) => {
    setShowDropdown({ ...showDropdown, [field]: !showDropdown[field] });
  };

  const DateRangeModal = (col) => (
    <PopUpModal
      style={{ width: 'fit-content' }}
      closeModalHandler={() => {
        closeDateModal(col.key);
      }}
    >
      <DateRangePicker
        initValue={searchQuery[col.key]}
        handleSelection={(e) => {
          handleDateSelection(e, { label: col.key });
        }}
      />
      <FlexContainer style={{ marginTop: '2rem', justifyContent: 'flex-end' }}>
        <ButtonComp
          style={{ marginRight: '2rem' }}
          onClick={() => {
            closeDateModal(col.key);
          }}
        >
          Discard
        </ButtonComp>
        <ButtonComp
          dark
          onClick={() => {
            closeDateModal(col.key);
            fetchData();
          }}
        >
          Apply
        </ButtonComp>
      </FlexContainer>
    </PopUpModal>
  );
  const displayDate = (col) =>
    `= ${moment(searchQuery[col.key]?.startDate)?.format(
      'DD-MM-YYYY'
    )} => ${moment(searchQuery[col.key]?.endDate)?.format('DD-MM-YYYY')}`;

  const handleGroupBy = (item) => {
    setGroupBy(item);
  };

  const visibleColumnsCount = metaDetails?.columns?.reduce((count, obj) => {
    if (obj.visible) {
      return count + 1;
    }
    return count;
  }, 0);

  /* dropdown/cancel icon based on value */
  const DropdownOrCancelIcon = ({ col, index }) => (
    <SearchIcon>
      {!searchQuery[col.key] ? (
        <div
          style={{
            transform: 'rotate(90deg)'
          }}
          onClick={() =>
            handleUICompVisiblity({ field: col.key, index }, 'dropdown')
          }
        >
          <DynamicSVGIcon iconUrl={rightArrow} width='3.5rem' />
        </div>
      ) : (
        <FlexContainer style={{ width: '100%' }} className='jc_center'>
          <div
            style={{
              transform: 'rotate(270deg)'
            }}
            onClick={() => handleSearchQuery(col.key, '', true)}
          >
            <DynamicSVGIcon width='1.6rem' iconUrl={CancelBlack} />
          </div>
        </FlexContainer>
      )}
    </SearchIcon>
  );

  return (
    <Container className={styleType}>
      <ContentContainer className={`${styleType || ''} ${className}`}>
        <TabTable className={styleType}>
          <Tr className={`${className} ${styleType} header`}>
            {metaDetails?.columns?.map((col, index) => {
              const { searchable, sortable, options, type } = col;
              const columnOptionsConfig = {
                searchable,
                sortable,
                options,
                type
              };
              const isOptionVisible =
                curryIsOptionsVisible(columnOptionsConfig);
              return (
                col?.visible && (
                  <Th
                    border
                    key={col.key}
                    width={col.width}
                    // border={col.name !== 'Action'}
                    // eslint-disable-next-line prefer-template
                    className={('colHeading ' + col.key, styleType)}
                    style={{ padding: '0 1rem' }}
                  >
                    <FlexContainer className='jc_center'>
                      {showSearchInp[col.key] &&
                      isOptionVisible(OPTIONS_TYPES.SEARCHABLE) ? (
                        <SearchInput
                          className='show'
                          ref={searchRef}
                          onKeyUp={(e) => _handleKeyPress(e)}
                          type='text'
                          placeholder={'Search ' + col.title}
                          onChange={(e) => handleSearchQuery(col.key, e)}
                          value={searchQuery[col.key]}
                          // onKeyUp={(e) => _handleKeyPress(e)}
                        />
                      ) : (
                        <FlexContainer
                          className='col ai_center'
                          style={{ position: 'relative' }}
                        >
                          {/* show title for all and dropdown value if present */}
                          <FlexContainer
                            style={{ fontSize: '1.8rem' }}
                            className='title ai_center'
                          >
                            {(localeStrings[col.title] || col.title) +
                              (col.options?.length && searchQuery[col.key]
                                ? ' = ' + searchQuery[col.key]
                                : ' ')}
                            {/* only normal dropdown icon & dropdown */}
                            {isOptionVisible(OPTIONS_TYPES.HAS_OPTIONS) && (
                              <DropDownModal
                                isActive={showDropdown[col.key]}
                                ContainerStyle={{ top: '0px' }}
                                version={2}
                                data={col.options}
                                handleClick={handleDropdownItem}
                                setModalIsVisibal={
                                  showDropdown[col.key]
                                    ? closeDropdown
                                    : () => {}
                                }
                                field={col.key}
                              >
                                <DropdownOrCancelIcon col={col} index={index} />
                              </DropDownModal>
                            )}
                            {/* show daterange icon & value */}
                            {col.type === 'DateTime' && (
                              <DropdownOrCancelIcon col={col} index={index} />
                            )}
                            <p style={{ fontSize: '1.8rem' }}>
                              {col.type === 'DateTime' && searchQuery[col.key]
                                ? displayDate(col)
                                : ''}
                            </p>
                          </FlexContainer>
                        </FlexContainer>
                      )}
                      {/* search/cancel icon only for input type */}
                      {isOptionVisible(OPTIONS_TYPES.SEARCHABLE) && (
                        <SearchIcon
                          onClick={() =>
                            handleUICompVisiblity({ field: col.key, index })
                          }
                        >
                          {!showSearchInp[col.key] ? (
                            <DynamicSVGIcon
                              width='1.6rem'
                              iconUrl={searchBlack}
                            />
                          ) : (
                            <DynamicSVGIcon
                              width='1.6rem'
                              iconUrl={CancelBlack}
                            />
                          )}
                        </SearchIcon>
                      )}

                      {/* show group by for first column & if groupList exists */}
                      {index === 0 && !isEmpty(groupByList) && (
                        <div style={{ marginLeft: 'auto' }}>
                          <Groupby
                            selected={groupBy}
                            onClick={handleGroupBy}
                            list={groupByList}
                          />
                        </div>
                      )}
                      {/* {col.key === 'KPV' && (
                      <div style={{ marginLeft: 'auto' }}>
                        <Groupby selected={groupBy} onClick={handleGroupBy} list={groupByList} />
                      </div>
                    )} */}
                    </FlexContainer>
                    {/* date dropdown ui */}
                    <div
                      style={{
                        width: '200%',
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      {col.type === 'DateTime' && showDropdown[col.key] && (
                        <>{DateRangeModal(col)}</>
                      )}
                    </div>
                  </Th>
                )
              );
            })}
            {additionalColumns?.map((col) => (
              <Th flex={0.5}>{col.label}</Th>
            ))}
            {showShortlistSelection && <Th flex={0.5}>action</Th>}
            {rowOptions && <Th />}
          </Tr>
          {/* {Object.keys(showSearchInp).some((e) => showSearchInp[e]) && filteredResultData ? (
            Object.keys(filteredResultData)?.map((groupName) => (
              <KPVGroup
                // key={groupName.id}
                groupItems={filteredResultData[groupName]}
                groupTitle={groupName}
                machineId={activeTab}
                machineName={selectedMachines[activeTab]?.name}
                activeTab={activeTab}
              />
            ))
          ) :  */}
          {assetListLoading ? (
            <Loader className='insideTable' />
          ) : (
            Object.keys(rowsDetails).map((type) => (
              <TableContent
                styleType={styleType}
                id={id}
                type={type}
                rowsDetails={rowsDetails}
                metaDetails={metaDetails}
                assetShortlist={assetShortlist}
                visibleColumnsCount={visibleColumnsCount}
                onItemSelect={onItemSelect}
                onHyperLinkSelect={onHyperLinkSelect}
                onLogsClick={onLogsClick}
                showShortlistSelection={showShortlistSelection}
                handleShortList={handleShortList}
                rowOptions={rowOptions}
                handleRowOptionsItemClickHandler={
                  handleRowOptionsItemClickHandler
                }
                additionalColumns={additionalColumns}
                className={styleType}
              />
            ))
          )}
        </TabTable>
      </ContentContainer>
      {!assetListLoading &&
        !error &&
        metaDetails?.columns &&
        !Object.values(rowsDetails).some((values) => values.length > 0) && (
          <FlexContainer
            className='ai_center jc_center'
            style={{ height: '100%' }}
          >
            <span style={{ fontSize: fontSize.text, fontWeight: 'bold' }}>
              {noItemsFoundMessage || 'No Items'}
            </span>
          </FlexContainer>
        )}
      {showPagiation && !assetListLoading && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
            height: '50px'
          }}
        >
          <PaginationWithPage
            ItemsPerPage={pageSize}
            setPageSize={setPageSize}
            total={metaDetails?.count}
            handlePageChange={handlePageChange}
            searchParams={savedSearchQueries}
            buttonStyle={{ backgroundColor: '#EEEFF5' }}
          />
        </div>
      )}
      {showToast && (
        <Toast
          message='Please press enter to search'
          style={{ right: '20px', bottom: '30px' }}
          header='note'
          shouldFade={false}
        />
      )}
    </Container>
  );
};

export default TabsTable;
