/* eslint-disable function-paren-newline */
/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */

const actualId = (rawId) => (rawId[0] === '_' ? rawId.slice(1, rawId.length) : rawId);

let connectedNodes;
export const linkNodes = (nodes, level) => {
  const root = {};
  let parent = root;
  Object.keys(nodes).forEach((e) => {
    if (e >= level) {
      // console.log('nodes[e]', nodes[e], e, Array.isArray(nodes[e]));
      if (!Array.isArray(nodes[e])) {
        const id = actualId(nodes[e].id);
        parent.values = [{ ...nodes[e], id }];
      } else {
        parent.values = [];
        nodes[e].forEach((ele, index) => {
          parent.values.push({ ...nodes[e][index], id: actualId(nodes[e][index].id) });
        });
      }
      parent = parent.values[0];
    }
  });
  // console.log('final', root);
  return root;
};

// isMerge for no merging the selection paths
const mergePathOfGroup = (group, tags, isMerge = true) => {
  const topMostCheck = connectedNodes[group]?.values.find((ele) => ele.id === actualId(tags[0].id));
  if (!topMostCheck || !isMerge) {
    connectedNodes[group].values = [...connectedNodes[group].values, ...linkNodes(tags, 0).values];
  } else {
    let parent = connectedNodes[group].values;
    // console.log('for path tags', group, tags, parent);
    const tagsKeys = Object.keys(tags);

    tagsKeys.every((tagKey) => {
      // console.log('parent', parent);
      // ?.values
      const check = parent.find((ele) => ele.id === actualId(tags[tagKey].id));
      // console.log('check', check, tags[tagKey].id);
      if (!check) {
        parent.push(linkNodes(tags, tagKey).values[0]);
        return false;
      }
      parent = check;
      parent = parent.values;
      return true;
    });
  }
};

let obj;
export const loopLevels = (results, groups, level, final) => {
  // console.log('results', results, groups, level, final);
  if (level !== groups.length) {
    const { groupTitle, attribute } = results[groups[level]];
    results[groups[level]].values.forEach((selectionPath) => {
      obj[groups[level]] = { groupTitle, attribute, values: [{ ...selectionPath }] };
      loopLevels(results, groups, level + 1, final);
    });
  } else {
    final.push({ ...obj });
  }
};

export const getResolvedBreadCrumb = (item) =>
  // console.log('item', item);
  Object.keys(item).map((groupName) => {
    const group = item[groupName];
    let value = group.groupTitle;
    let pointer = group.values && group.values[0];
    while (pointer?.values?.length) {
      value += ` > ${pointer.name}`;
      // eslint-disable-next-line prefer-destructuring
      pointer = pointer.values[0];
    }
    if (pointer?.name) {
      value += ` > ${pointer.name}`;
    }
    return value;
  });

export const getBreadCrumbs = (results) => {
  const groups = Object.keys(results);
  const level = 0;
  const final = [];
  obj = {};

  loopLevels(results, groups, level, final);
  return final.map((item) => ({ value: item, key: getResolvedBreadCrumb(item) }));
};

export const generateSelectedFilter = (cumulativeTags, filterData, isMerge) => {
  connectedNodes = {};
  const levelsPresent = filterData.map((level) => level.groupTitle);
  Object.keys(cumulativeTags).forEach((group) => {
    if (levelsPresent.includes(group)) {
      // grouptitle as key except plant
      connectedNodes[group === 'Equipment Type' ? 'tree' : group] = { groupTitle: group, attribute: group, values: [] };
      const pathNos = Object.keys(cumulativeTags[group]);
      pathNos.forEach((pathNo) =>
        mergePathOfGroup(group === 'Equipment Type' ? 'tree' : group, cumulativeTags[group][pathNo], isMerge)
      );
      // if keyName is needed as key
      // const keyName = filterData?.filter((e) => e.groupTitle === group)[0]?.keyName;
      // connectedNodes[keyName] = { groupTitle: group, attribute: group, values: [] };
      // const pathNos = Object.keys(cumulativeTags[group]);
      // pathNos.forEach((pathNo) => mergePathOfGroup(keyName, cumulativeTags[group][pathNo], isMerge));
    }
  });
  // Object.keys(cumulativeTags).forEach((group) => {
  //   connectedNodes[group] = { groupTitle: group, attribute: group, values: [] };
  //   const pathNos = Object.keys(cumulativeTags[group]);
  //   pathNos.forEach((pathNo) => mergePathOfGroup(group, cumulativeTags[group][pathNo]));
  // });

  // if (!isMerge && connectedNodes) getBreadCrumbs(connectedNodes);
  return { ...connectedNodes };
};
