/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
/* eslint no-else-return: "error" */
/* eslint-disable no-nested-ternary */

import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import FilterTagArea from '../../component/Filter/FilterTagArea/FilterTagArea.view';
import FilterSearchComponent from '../../component/Filter/FilterSearchComponent/FilterSearchComponent';
import Toast from '../../component/Common/Toast';
import DropDownModal from '../../component/Common/DropDownModal/DropDownModal';
import ShowAllTheFilter from '../../component/ResultView/ShowAllTheFilter';
import {
  FilterViewContainer,
  FilterHeadingContainer,
  FilterHeadingText,
  FilterTabContainer,
  Divider,
  ActiveDivider,
  FilterHeadingBox,
  MobileFilterButton,
  KababIcon,
  FilterSelectImage,
  FilterSelectText,
  FilterNoDataContainer,
  FilterDataContainer,
  KababIconContainer,
  HeaderText,
  WrapperContainer,
  LeftArrow
} from './Filter.style';
import {
  Image,
  ImageFilter
} from '../../component/Filter/FilterSearchComponent/FilterSearchComponent.style';
import kababSvg from '../../assets/icons/Filter/kabab.svg';
import FilterSelectPng from '../../assets/Images/Filter/Use Filter @3x.png';
import FilterContainer from '../../component/Filter/FilterContainer/FilterContainer';
import FilterMobileIcon from '../../assets/icons/Filter/Filters white.svg';
import { filterMenuData } from '../../constants/sampleData';
import { Creators as FilterCreators } from './store';
// import { localString } from '../../../localization/localString';
// import { applyTypeMapping } from '../../../constants/mapping';
import Loader from '../../component/Loader/Loader';
import DynamicSVGIcon from '../../component/Common/DynamicSVGIcon';
import { usePrevious } from '../../shared/hooks/usePrevious';
import DropArrow from '../../assets/icons/layout/dropdownarrow.svg';
import { apiEndPoints } from '../../services/axios/endPoints';
import BreadCrumbComp from '../../component/Filter/BreadCrumb/BreadCrumb.view';
import { WebService } from '../../services/axios/webServices';
import { SUCCESS } from '../../constants';
import { sagaCatchBlockHandling } from '../../store/utility/sagaErrorHandling';
import { fetchUserAndLocation } from '../../component/Sidenav/fetchPageStatistics';
// import { FlexContainer } from '../../../globalStyles';

// export const headingTitle = ['Summary', 'Trend', 'Comparison', 'Details'];

// subHeightBy is used when filter component is used in other like spares
function FilterView({
  menuId,
  filterFor,
  setResultTypeAs,
  subHeightBy,
  filterMenuOptions,
  setUploadBulk,
  setBulkUpdateDetails
}) {
  // const [showFilter, setShowFilter] = useState(false);
  const location = useLocation();
  const [activeFilterCard, setActiveFilterCard] = useState(0);
  const [modalIsVisibal, setModalIsVisibal] = useState(false);
  // const [formatTags, setFormatTags] = useState('');

  const { userData } = useSelector((state) => state.loginData);
  const { finalMenu } = useSelector((state) => state.home);
  useEffect(() => {
    const name = finalMenu.find((ele) => ele.path === location.pathname);
    fetchUserAndLocation(location.pathname, userData, {
      pageType: name?.name
    });
  }, []);

  const history = useHistory();
  const {
    selectedParent,
    filterData,
    resultType,
    showFilterResult,
    error,
    tags,
    showFilter,
    loading,
    menuIdofFilter
  } = useSelector((state) => state.filterData);
  // const { resultType } = useSelector((state) => state.filterResultData);
  const { subMenuOptions } = useSelector((state) => state.home);
  const prevResultType = usePrevious(resultType);
  const dispatch = useDispatch();
  // const string = localString.English;
  const { localeStrings } = useSelector((state) => state.localeStrings);
  const findPathObject = (path) =>
    !!subMenuOptions.find((item) => item.path === path);
  const menuName = useRef();

  const getTabNames = (path = location.pathname) => {
    const data = subMenuOptions.find((item) => item.path === path);
    return data?.subMenuOptions;
  };

  const fetchFilters = (activeTab = '') => {
    dispatch(FilterCreators.handleFilterChange());
    dispatch(
      FilterCreators.universalFilterSetter({
        key: 'breadCrumbs',
        value: []
      })
    );

    // in FACTORYLook
    // dispatch(FilterCreators.getFilterData({ type: 'tab', menuId, tab: activeTab }));

    if (filterFor === 'spares') {
      dispatch(
        FilterCreators.getFilterData({
          URLparam: apiEndPoints.getSparesFilters,
          menuId,
          setResultTypeAs
        })
      );
    } else {
      dispatch(
        FilterCreators.getFilterData({
          URLparam: apiEndPoints.getFilterCategory2,
          menuId,
          tab: activeTab
        })
      );
    }
  };

  const setActiveTab = (value) => {
    dispatch(
      FilterCreators.universalFilterSetter({ key: 'selectedParent', value })
    );
  };
  const setShowFilter = (value) => {
    dispatch(
      FilterCreators.universalFilterSetter({ key: 'showFilter', value })
    );
  };

  const handleTabChange = (item) => {
    // if (selectedParent !== item) {
    // dispatch(FilterCreators.resetFilterState());
    setShowFilter(false);
    setActiveTab(item);
    fetchFilters(item);
    // dispatch(FilterCreators.getFilterData(item));
    // }
  };

  // only for menu change
  const tabsAvailable = getTabNames(location.pathname);
  useEffect(() => {
    // dispatch(FilterCreators.setMenuIdOfFilter(menuId));
    // console.log('menuId changes', menuId, menuIdofFilter);
    if (menuIdofFilter !== menuId && !getTabNames()?.length) {
      fetchFilters();
      // console.log('1.menu changed, no tabs');
    } else if (menuIdofFilter !== menuId) {
      // console.log('change tab', selectedParent || tabsAvailable[0]);
      dispatch(FilterCreators.handleFilterChange());
      handleTabChange(selectedParent || tabsAvailable[0]);
      // console.log('2.menu changed, with tabs so filterChange handle');
    }
  }, [menuIdofFilter]);

  useEffect(() => {
    dispatch(FilterCreators.setMenuIdOfFilter(menuId));
    const locArr = location.pathname.split('/');
    const homeIndex = locArr.findIndex((e) => e === 'home');
    menuName.current =
      homeIndex + 1 < locArr.length ? locArr[homeIndex + 1] : 'dashboard';
  }, []);

  const clearFilterToastMessage = () => {
    dispatch(
      FilterCreators.universalFilterSetter({ key: 'error', value: null })
    );
  };

  const kababIconRef = useRef();

  const handleSpareOptions = async (id) => {
    try {
      const fullUrl = `${apiEndPoints.getSparesOptionsIndividual}/${id}`;
      // eslint-disable-next-line prefer-const, object-shorthand
      let res = await WebService.get(fullUrl);
      if (res.status >= 200 && res.status < 300) {
        setBulkUpdateDetails(res?.data);
        setUploadBulk(true);
      } else {
        console.log(error);
      }
    } catch (err) {
      const payload = sagaCatchBlockHandling(err);
      // setError(payload?.error);
    }
  };

  const hanldeFilterMenuItemClick = ({ role, disable, ...rest }) => {
    if (!disable) {
      if (role === 'clear') {
        setShowFilter(false);
        dispatch(FilterCreators.clearSelectedFilter());
      }
      if (
        role === 'Add Spares' ||
        role === 'Update Spares' ||
        role === 'Update Quantity' ||
        role === 'Delete Spares'
      ) {
        handleSpareOptions(rest?.Id);
      }
    }
    setModalIsVisibal(false);
  };

  // covered above
  // useEffect(() => {
  //   if (prevResultType !== undefined && resultType !== prevResultType) {
  //     // console.log("don't show result", prevResultType, resultType);
  //     setShowFilter(false);
  //     dispatch(
  //       FilterCreators.universalFilterSetter({
  //         key: 'showFilterResult',
  //         value: false
  //       })
  //     );
  //   }
  // }, [resultType]);

  // old version
  // useEffect(() => {
  //   if (selectedParent && getTabNames()?.length) fetchFilters(selectedParent);
  // }, [selectedParent]);

  // useEffect(() => {
  //   setFormatTags(JSON.stringify(tags));
  // }, [tags]);

  // useEffect(() => {
  //   const singleSelectgrpNames = [];
  //   filterData?.forEach((grp) => {
  //     if (grp.selectionType === 'singleselect') {
  //       singleSelectgrpNames.push(grp.groupTitle);
  //     }
  //   });
  //   dispatch(FilterCreators.setSingleSelectionGroups(singleSelectgrpNames));
  // }, [filterData]);

  return (
    <>
      <FilterViewContainer
        subHeightBy={subHeightBy}
        minusHeight={Object.keys(tags).length > 0 ? 34 : 60}
      >
        {subMenuOptions && findPathObject(location.pathname) && (
          <FilterTabContainer showFilter={showFilter}>
            <FilterHeadingContainer>
              {subMenuOptions &&
                tabsAvailable.map((item, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <FilterHeadingBox
                    className='pointer'
                    key={i}
                    onClick={() => handleTabChange(item)}
                  >
                    <FilterHeadingText active={selectedParent === item}>
                      {item}
                    </FilterHeadingText>
                    {selectedParent === item && <ActiveDivider />}
                  </FilterHeadingBox>
                ))}
            </FilterHeadingContainer>
            {/* old placement of menu moved to down */}
            {/* <div>
              <KababIconContainer ref={kababIconRef} onClick={() => setModalIsVisibal(!modalIsVisibal)}>
                <KababIcon>
                  <DynamicSVGIcon iconUrl={kababSvg} width='3rem' />
                </KababIcon>
              </KababIconContainer>

              {modalIsVisibal && (
                <DropDownModal
                  kababIconRef={kababIconRef}
                  setModalIsVisibal={setModalIsVisibal}
                  data={filterMenuData}
                  ContainerStyle={{ top: 45, right: 0 }}
                  handleClick={hanldeFilterMenuItemClick}
                />
              )}
            </div> */}
            <Divider />
          </FilterTabContainer>
        )}
        {loading && <Loader />}
        {!loading && filterData && (
          <>
            <div
              style={{ display: 'flex', width: '97%', alignItems: 'center' }}
            >
              <FilterSearchComponent
                showFilter={showFilter}
                setShowFilter={setShowFilter}
                setActiveTab={setActiveFilterCard}
              />
              {showFilter && showFilterResult ? (
                <WrapperContainer style={{ marginTop: '1.5rem', flex: 1 }}>
                  <BreadCrumbComp />
                </WrapperContainer>
              ) : (
                <div
                  style={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'flex-end'
                  }}
                >
                  <div style={{ position: 'relative' }}>
                    <KababIconContainer
                      ref={kababIconRef}
                      onClick={() => setModalIsVisibal(!modalIsVisibal)}
                    >
                      <KababIcon>
                        <DynamicSVGIcon iconUrl={kababSvg} width='3rem' />
                      </KababIcon>
                    </KababIconContainer>

                    <DropDownModal
                      kababIconRef={kababIconRef}
                      isActive={modalIsVisibal}
                      setModalIsVisibal={setModalIsVisibal}
                      data={
                        filterMenuOptions || filterMenuData[menuName.current]
                      }
                      ContainerStyle={{ top: 45, right: 0 }}
                      handleClick={hanldeFilterMenuItemClick}
                    />
                  </div>
                </div>
              )}
            </div>
            {showFilter ? (
              showFilterResult ? (
                <ShowAllTheFilter
                  type={resultType}
                  location={location.pathname}
                />
              ) : (
                <FilterDataContainer>
                  <FilterTagArea
                    setShowFilter={setShowFilter}
                    location={location}
                  />
                  <FilterContainer
                    activeFilterCard={activeFilterCard}
                    setActiveFilterCard={setActiveFilterCard}
                  />
                </FilterDataContainer>
              )
            ) : (
              <FilterNoDataContainer>
                <FilterSelectImage src={FilterSelectPng} />
                <FilterSelectText>
                  {`Choose From Filters To See ${filterFor || 'dashboard'}`}
                </FilterSelectText>
              </FilterNoDataContainer>
            )}

            {!showFilter && (
              <MobileFilterButton onClick={() => setShowFilter(true)}>
                <ImageFilter src={FilterMobileIcon} width={24} height={24} />
              </MobileFilterButton>
            )}
          </>
        )}
      </FilterViewContainer>
      {error && (
        <Toast
          style={{ right: 20 }}
          fn={clearFilterToastMessage}
          header='Error'
          message={error}
        />
      )}
    </>
  );
}

export default FilterView;
