import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  getSpareList: ['payload'],
  getSpareListStart: ['payload'],
  getSpareListSuccess: ['payload'],
  getSpareListFailure: ['payload'],
  setSparesSearchQueries: ['payload'],
  resetSparesSearchQueries: ['payload'],

  setSpareDetails: ['payload'],
  getSpareDetails: ['payload'],
  setSpareDetailsStart: ['payload'],
  setSpareDetailsSuccess: ['payload'],
  setSpareDetailsFailure: ['payload'],

  getSpareListMenu: ['payload'],
  getSpareListMenuStart: ['payload'],
  getSpareListMenuSuccess: ['payload'],
  getSpareListMenuFailure: ['payload'],

  getBulkDocumentList: ['payload'],
  getBulkDocumentListStart: ['payload'],
  getBulkDocumentListSuccess: ['payload'],
  getBulkDocumentListFailure: ['payload']
});
