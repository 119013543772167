import styled from 'styled-components';
import { fontFamily } from '../../../constants';

export const ItemPadding = styled.div`
  margin-left: calc(1.5rem + 20px);
  display: flex;
  align-items: center;
  &.jc_spacebetween {
    justify-content: space-between;
  }
`;

export const ItemContainer = styled(ItemPadding)`
  //icon and margin
  font-size: 1.6rem;
  color: ${({ theme }) => theme.contrast.primary};
  font-family: ${fontFamily.circularMedium};
  margin-bottom: 1.6rem;

  &.disable > div {
    pointer-events: none;
    opacity: 0.4;
  }
`;

export const Label = styled.text`
  font-size: 1.8rem;
`;

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckBox = styled(FlexCenter)`
  width: 2.5rem;
  min-width: 2.5rem;
  height: 2.5rem;
  min-height: 2.5rem;
  border-radius: 30%;

  border: ${({ theme }) => `1px solid ${theme.contrast.secondary}`};
  justify-content: center;
  margin-right: 1.6rem;
  &.active {
    background-color: ${({ theme }) => 'black'};
    & > svg {
      fill: ${({ theme }) => 'white'};
    }
  }

  & > svg {
    width: 1.5rem;
    fill: ${({ theme }) => theme.aux.darkSecondary};
  }
`;
