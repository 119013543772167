import styled from 'styled-components';
import { FlexContainer } from '../../../globalStyles';
import { ContentTextTemp, DetailsContainerTemp, DetailSectionTemp } from '../../Common/CommonStyles';

export const DetailsContainer = styled(DetailsContainerTemp)``;

export const DetailSection = styled(DetailSectionTemp)``;

export const ContentText = styled(ContentTextTemp)``;

export const Header = styled(FlexContainer)`
  padding: 2rem;
  border-bottom: 1px solid #70707080;
  margin-bottom: 2rem;
`;
