import styled from 'styled-components';
import { fontFamily, fontSize } from '../../constants';
import { shadow } from '../../constants/colors';
import { device } from '../constants';

export const AlignedDiv = styled.div`
  display: flex;
  align-items: ${({ al }) => al || 'center'};
  justify-content: ${({ jc }) => jc || 'center'};
`;

export const CircularDiv = styled(AlignedDiv)`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  color: #ffffff;
`;

export const DropdownBar = styled.div`
  width: 100%;
  height: 4rem;
  font-size: ${fontSize.text};
  border: 1px solid;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  outline: none;
  border: 0px;
  border-radius: 1rem;
  color: ${({ theme }) => theme.contrast.darkSecondary};
  font-family: ${fontFamily.circularMedium};
  background-color: ${({ theme }) => theme.core.secondary};
  z-index: 2;
  box-shadow: inset -3px -3px 5px ${({ theme }) => theme.shadowContrast},
    inset 3px 3px 10px ${({ theme }) => theme.shadow};

  &.active {
    /* box-shadow: none; */
    box-shadow: inset 3px 3px 10px ${({ theme }) => theme.shadow};
  }
`;

export const HeaderContainer = styled.div`
  width: calc(100% - 4rem);
  margin: 0rem 2rem;
  padding: 2.5rem 2rem 1.2rem 0rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px dotted #707070;

  & .dynamicSVGIcon {
    /* transform: rotate(180deg) !important; */

    & * {
      fill: ${({ theme }) => theme.contrast.primary} !important;
    }
  }
  &.fixed-height {
    height: 8rem;
  }
  @media ${device.tablet} {
   overflow-x:  ${({ isActivity }) => (!isActivity && 'auto')};
  
    
  }
`;

export const Header = styled.div`
  color: '#4A4A4A';
  font: 2.2rem ${fontFamily.circularMedium};
  margin-bottom: ${({ mrgBtm }) => mrgBtm || '0rem'};
`;

export const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 0rem 2rem;

  &.wrap {
    flex-wrap: wrap;
  }
`;

export const MainBodyContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
  align-items: center;
`;

export const NormalContent = styled.div`
  color: #474747;
  font: 2rem ${fontFamily.circularBook};
`;

// table like details structure
export const DetailsContainerTemp = styled.div`
  display: flex;
  width: ${({ width }) => width && width};
  &.ai {
    align-items: center;
  }
  &.detail {
    margin-bottom: 2.5rem;
    max-width: 50%;
    justify-content: space-between;
    @media ${device.tablet} {
      max-width: none;
    
  }

    /* background-color: yellow; */
  }

  &.keyValuedetail {
    max-width: 98%;
  }
  &.top {
    & > div + div {
      /* margin-left: 10rem; */
    }
  }
`;

export const DetailSectionTemp = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const ContentTextTemp = styled.div`
  color: #000000;
  font: 1.6rem ${fontFamily.circularMedium};
  &.ml {
    padding-left: 3rem;
  }
  &.label {
    flex: 1;
  }
  &.heading {
    /* flex: 0.7; */
    min-width: 17rem;
    color: #717171;
    text-transform: capitalize;
  }
`;

export const CommentInputContainerTemp = styled.textarea`
  width: 100%;
  font: 1.8rem ${fontFamily.circularBook};
  border: none;
  background-color: transparent;
  box-shadow: ${shadow.inputInset};
  border-radius: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  color: ${({ theme }) => theme.black};
  &:focus {
    outline: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.placeHolderText};
    font-size: 1.8rem;
  }
`;

export const Span = styled.span`
  font-size: 2rem;
`;

export const Hr = styled.div`
  border: 0.5px dashed #707070;
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
`;

export const H2 = styled.h2`
  width: 100%;
`;
