/* eslint-disable max-len */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { SUCCESS } from '../../../constants';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { WebService } from '../../../services/axios/webServices';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';
import Toast from '../../Common/Toast';
import ScheduleTypeForm from '../ScheduleTypeForm/ScheduleTypeForm';
import { formatPayload } from '../utility';

const AddScheduleTyoe = () => {
  const { scheduleListMeta } = useSelector((state) => state.scheduleTypesData);
  const [error, setError] = useState('');
  const history = useHistory();
  const handleSave = async (payload, dropdowndata, setSubmitLoader) => {
    setSubmitLoader(true);

    try {
      const input = formatPayload(payload);
      // console.log('submit', input);
      const res = await WebService.post(apiEndPoints.CreateScheduleType, {
        data: [input]
      });
      input.scheduleTypeId = res?.data.id;
      if (res.data?.message === SUCCESS) {
        history.push({
          pathname: '/home/schedule-type/details',
          state: { details: input }
        });
      } else {
        setError(res.data?.message);
      }
    } catch (err) {
      console.log('error facing location:', err);
      const payload = sagaCatchBlockHandling(err);
      setError(payload?.error);
    } finally {
      setSubmitLoader(false);
    }
  };

  return (
    <>
      <ScheduleTypeForm isEdit={false} handleSave={handleSave} />
      {error && <Toast message={error} header='Error' style={{ right: 30, zIndex: 20 }} fn={() => setError('')} />}
    </>
  );
};

export default AddScheduleTyoe;
