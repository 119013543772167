/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { PopUpModal } from '../../../component';
import {
  BoldSpan,
  BulkUpdateHeader,
  CloseContainer,
  FileControl,
  Header,
  Instructions,
  ProgressBarContainer,
  ProgressText,
  UpdateBody,
  UpdateInstruction,
  UploadButton,
  UploadingText
} from './SpareBulkUpdate.styles';
import DynamicSVGIcon from '../../../component/Common/DynamicSVGIcon';
import { CancelBlack } from '../../../assets/icons';
import ButtonComp from '../../../component/Common/Button/Button.view';
import {
  WebService,
  WebServiceMultipart
} from '../../../services/axios/webServices';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';

const SpareBulkUpdateView = (props) => {
  const { handleCloseUpdate, label, bulkUpdateDetails, setError, setSuccess } =
    props;

  const { userData } = useSelector((state) => state.loginData);

  const [downloading, setDownloading] = useState();
  const [showProgress, setShowProgress] = useState(false);
  const [uploadingStatus, setUpLoadingStatus] = useState('Uploading...');

  const inputRef = useRef();
  const history = useHistory();

  /**
   * Description - Function which reads file and converts it into json  using xlxs
   * @param {any} event
   * @returns {any}
   */
  const fileSelectedHandler = async (event) => {
    const maxFileSize = bulkUpdateDetails?.MaxFileSize * 1024 * 1024;
    const fileSize = event.target.files[0].size;
    if (fileSize <= maxFileSize) {
      setShowProgress(true);
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('File', file);
      formData.append('userId', userData.userId);

      try {
        const res = await WebServiceMultipart.post(
          bulkUpdateDetails?.UploadPostURL,
          formData
        );
        if (res.status >= 200 && res.status < 300) {
          setShowProgress(false);
          history.push('/home/Stores/Bulk-Documents');
          setSuccess('Success');
        } else {
          throw res;
        }
      } catch (err) {
        const payload = sagaCatchBlockHandling(err);
        setError(payload.error);
      } finally {
        setShowProgress(false);
        handleCloseUpdate();
      }
    } else {
      setError(
        `Maximum File Size Allowed is ${bulkUpdateDetails?.MaxFileSize}`
      );
    }
  };

  /**
   * Function which receives the download url, Makes call to the api and returns the blob of response for download
   * @param {*} url
   * @returns object to be downloaded
   */
  function toDataURL(url) {
    return WebService.get(url, { responseType: 'blob' })
      .then((response) => {
        const blob = new Blob([response.data], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            'application/vnd.ms-excel'
        });
        // response.blob();
        return blob;
      })
      .then((blob) => URL.createObjectURL(blob));
  }

  /**
   * Function Which is called on  click of Template name for downloading
   */
  const downloadFile = async () => {
    setDownloading(true);
    try {
      const fullUrl = `${bulkUpdateDetails?.TemplateDownloadlink}`;
      // eslint-disable-next-line prefer-const, object-shorthand
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = await toDataURL(fullUrl);
      a.download = bulkUpdateDetails?.TemplateName.replace(/[/?:><*|]/g, '');
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setDownloading(false);
    } catch (err) {
      const payload = sagaCatchBlockHandling(err);
      setError(payload?.error);
      setDownloading(false);
    }
  };

  return (
    <div>
      <PopUpModal
        width='45%'
        style={{ padding: '4rem', width: !(window.innerWidth > 769) && '100%' }}
        closeModalHandler={() => {}}
      >
        <Header>
          <BulkUpdateHeader>{label}</BulkUpdateHeader>
          <div style={{ cursor: 'pointer' }} onClick={handleCloseUpdate}>
            <DynamicSVGIcon iconUrl={CancelBlack} />
          </div>
        </Header>
        <UpdateBody>
          <UpdateInstruction>
            {`Please follow the following steps to ${bulkUpdateDetails?.TemplateName}`}
          </UpdateInstruction>
          <Instructions>
            <div>1. {bulkUpdateDetails?.DisplayMessage}</div>
            <div>
              2. Download{' '}
              <BoldSpan
                disabled={downloading}
                onClick={() => {
                  if (!downloading) {
                    downloadFile();
                  }
                }}
              >
                {bulkUpdateDetails?.TemplateName}
              </BoldSpan>
            </div>
          </Instructions>
          <UploadButton>
            <input
              hidden
              type='file'
              accept={bulkUpdateDetails?.AllowedFileTypes}
              ref={inputRef}
              onChange={(e) => fileSelectedHandler(e)}
              onClick={(e) => {
                e.target.value = null;
              }}
            />
            {!showProgress && (
              <ButtonComp
                dark
                disabled={downloading}
                onClick={() => {
                  inputRef.current.click();
                }}
              >
                Upload
              </ButtonComp>
            )}
            {showProgress && (
              <ProgressBarContainer>
                <UploadingText>{uploadingStatus}</UploadingText>
                {uploadingStatus !== 'Upload completed' && (
                  <FileControl>
                    <ProgressText>
                      {bulkUpdateDetails?.TemplateName}
                    </ProgressText>
                    <div>
                      <CloseContainer
                        onClick={() => {
                          setShowProgress(false);
                          setUpLoadingStatus('Uploading...');
                        }}
                      >
                        <DynamicSVGIcon width='7px' iconUrl={CancelBlack} />
                      </CloseContainer>
                    </div>
                  </FileControl>
                )}
              </ProgressBarContainer>
            )}
          </UploadButton>
        </UpdateBody>
      </PopUpModal>
    </div>
  );
};

export default SpareBulkUpdateView;
