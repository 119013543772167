/* eslint-disable indent */
/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { StateStoring } from 'devextreme-react/data-grid';
import { createReducer } from 'reduxsauce';
import { userData } from '../../../component/Users/data';
import { tableItemFlags } from '../../../constants';
import { Types } from './action';

const INITIAL_STATE = {
  usersData: [],
  usersMeta: {},
  userCount: null,
  usersUploadedData: [],
  searchQueries: {},
  valueForOutline: [],
  userPermissions: {},
  loading: false,
  error: null
};

const userReducerValueSetter = (state = INITIAL_STATE, action) => {
  const { key, value } = action.payload;
  return {
    ...state,
    [key]: value
  };
};

const updateUsersData = (state = INITIAL_STATE, action) => {
  // this updates the list by adding newlyAdded flag into items
  const { freshRecords, resolvedRecords } = action.payload;
  const updatedUsers = state.usersData?.map((e) => {
    const { employeeId } = e;
    if (resolvedRecords?.[employeeId]) {
      return { ...resolvedRecords[employeeId], [tableItemFlags.NEWLY_ADDED]: true };
    }
    return e;
  });
  return {
    ...state,
    usersData: [
      ...((freshRecords || []).map((e) => ({ ...e, [tableItemFlags.NEWLY_ADDED]: true })) || []),
      ...updatedUsers
    ]
  };
};

const resetUserDataFlags = (state = INITIAL_STATE, action) => ({
  ...state,
  userData: state.usersData.map((e) => {
    delete e[tableItemFlags.NEWLY_ADDED];
    return e;
  })
});

const getUserTableData = (state = INITIAL_STATE, action) =>
//   console.log(action.payload, 'getusertabledata');
({
  ...state,
  usersData: [...action.payload]
});
const setUserTableData = (state = INITIAL_STATE, action) =>
//   console.log(action.payload, 'getusertabledata');
({
  ...state,
  usersData: [action.payload.formDetails, ...state.usersData]
});

const updateUserTableData = (state = INITIAL_STATE, action) => {
  console.log('action==>', action.payload);
  const { id, formDetails } = action.payload;
  const newData = state.usersData.map((ele) => {
    if (ele.employeeId === id) {
      return formDetails;
    }
    return ele;
  });
  return {
    ...state,
    usersData: newData
  };
};

const setSearchQueries = (state = INITIAL_STATE, action) => ({
  ...state,
  searchQueries: { ...action.payload }
});

const latestSavedValue = (state = INITIAL_STATE, action) =>
// console.log(action.payload, 'latlatestSavedValue');
({
  ...state,
  valueForOutline: [action.payload]
});
const getUsersListStart = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: true
});
const getUsersListSuccess = (state = INITIAL_STATE, action) => {
  const { data, meta, Count, userPermissions } = action.payload;
  return {
    ...state,
    usersData: data,
    usersMeta: meta,
    userCount: Count,
    userPermissions,
    loading: false
  };
};
const getUsersListFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    error,
    loading: false
  };
};
const HANDLERS = {
  [Types.USER_REDUCER_VALUE_SETTER]: userReducerValueSetter,
  [Types.UPDATE_USERS_DATA]: updateUsersData,
  [Types.RESET_USER_DATA_FLAGS]: resetUserDataFlags,
  [Types.GET_USER_TABLE_DATA]: getUserTableData,
  [Types.SET_USER_TABLE_DATA]: setUserTableData,
  [Types.UPDATE_USER_TABLE_DATA]: updateUserTableData,
  [Types.SET_SEARCH_QUERIES]: setSearchQueries,
  [Types.LATEST_SAVED_VALUE]: latestSavedValue,
  [Types.GET_USERS_LIST_START]: getUsersListStart,
  [Types.GET_USERS_LIST_SUCCESS]: getUsersListSuccess,
  [Types.GET_USERS_LIST_FAILURE]: getUsersListFailure
};

export const UsersReducer = createReducer(INITIAL_STATE, HANDLERS);
