import { Types as ConfigTypes, Creators as ConfigCreators } from '../config/action';
import { Types as LoginTypes, Creators as LoginCreators } from '../views/auth/store/action';
import { Types as localStringsTypes, Creators as localStringsCreators } from '../localeStrings';
import { Types as FilterTypes, Creators as FilterCreators } from '../views/Filters/store';
import { Types as HomeTypes, Creators as HomeCreators } from '../views/Layout/store';
import { PlanCreators, PlanTypes } from '../views/Plan/store';
import {
  Types as UpsertWorkOrderTypes,
  Creators as UpsertWorkOrderCreators
} from '../component/WorkOrder/CreateWorkOrder/store';
import { Types as SpareDetailsTypes, Creators as SpareDetailsCreators } from '../views/Stores/Spares/store';
import { Types as WorkOrderTypes, WorkOrderCreators } from '../views/WorkOrders/store';
import { Types as ActivityTypes, ActivityCreators } from '../views/Activity/store';
import { ScheduleCreators, ScheduleTypes } from '../views/Schedule/store/action';
import { SchedultTypesCreators, Types as SchedultTypesType } from '../views/ScheduleType/store/action';
import { UsersCreators, UsersTypes } from '../views/Users/store';

const Types = {
  ...ConfigTypes,
  ...LoginTypes,
  ...FilterTypes,
  ...HomeTypes,
  ...localStringsTypes,
  ...UpsertWorkOrderTypes,
  ...SpareDetailsTypes,
  ...WorkOrderTypes,
  ...ActivityTypes,
  ...ScheduleTypes,
  ...SchedultTypesType,
  ...PlanTypes,
  ...UsersTypes
};

const Creators = {
  ...ConfigCreators,
  ...LoginCreators,
  ...FilterCreators,
  ...HomeCreators,
  ...localStringsCreators,
  ...UpsertWorkOrderCreators,
  ...SpareDetailsCreators,
  ...WorkOrderCreators,
  ...ActivityCreators,
  ...ScheduleCreators,
  ...SchedultTypesCreators,
  ...PlanCreators,
  ...UsersCreators
};

export { Types, Creators };
