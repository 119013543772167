export const filterMenuData = [
  { label: 'Clear Filter', id: 1, role: 'clear' },
  { label: 'Save Dashboard', id: 2, role: 'save' },
  { label: 'Print Dashboard', id: 3, role: 'print' },
  { label: 'Share Dashboard', id: 4, role: 'share' }
];

export const profileMenuData = [
  { label: 'Filter', id: 1, role: 'filter' },
  { label: 'Logout', id: 2, role: 'logout' }
];

export const spares = [
  {
    GRN_id: '032810A03',
    spare_details: {
      imageUrl: '',
      name: 'THAS002030001'
    },
    quantity: 4,
    store: 'Tristan',
    category: 'category 1',
    added: '10-10-2020',
    last_updated: '24-02-2021'
  },
  {
    GRN_id: '05980AD3',
    spare_details: {
      imageUrl: '',
      name: 'THAS002150002'
    },
    quantity: 8,
    store: 'Tristan',

    category: 'category 1',
    added: '31-08-2021',
    last_updated: '08-02-2021'
  },
  {
    GRN_id: '05980A03',
    spare_details: {
      imageUrl: '',
      name: 'TS1L006170001'
    },
    quantity: 8,
    store: 'Tristan',
    category: 'category 1',
    added: '10-10-2021',
    last_updated: '24-02-2021'
  },
  {
    GRN_id: '05980AD3',
    spare_details: {
      imageUrl: '',
      name: 'TS1L006170002'
    },
    quantity: 8,
    store: 'Tristan',
    category: 'category 1',
    added: '31-08-2020',
    last_updated: '08-02-2021'
  }
];

export const spareDetails = {
  id: 'sparePartId',
  spare_part_image_URLs: [],
  attachments: [
    {
      fileLink: 'spare_invoice.pdf',
      uploadedBy: 'McNamaraV',
      uploadedOn: '5-09-2021'
    },
    {
      fileLink: 'file.jpg',
      uploadedBy: 'Lupe Berney',
      uploadedOn: '10-09-2021'
    }
  ],
  issue_history: [
    {
      work_order: '05678',
      quantity: 2,
      issued_date: '24-04-2021',
      issued_by: 'McNamaraV'
    },
    {
      work_order: '08978',
      quantity: 1,
      issued_date: '3-02-2021',
      issued_by: 'McNamaraV'
    }
  ]
};

export const sparesListMeta = [
  {
    key: 'partName',
    title: 'Part Name',
    searchable: true,
    visible: true
  },
  {
    key: 'mainPartNumber',
    title: 'Main Part Number',
    searchable: true,
    visible: true
  },
  {
    key: 'inventPartnumber',
    title: 'Invent Part Number',
    searchable: true,
    visible: true
  },
  {
    key: 'location',
    title: 'Location',
    searchable: true,
    visible: true
  },
  {
    key: 'qtyAvailable',
    title: 'Quantity Available',
    searchable: true,
    visible: true
  },
  {
    key: 'category',
    title: 'Category',
    searchable: true,
    visible: true
  },
  {
    key: 'equipmenttype',
    title: 'Equipment Type',
    searchable: true,
    visible: true
  },
  {
    key: 'priceIn',
    title: 'Price',
    searchable: true,
    visible: true
  },
  {
    key: 'imageUrls',
    title: 'Image(s)',
    searchable: false,
    visible: true
  }
];

export const sparesListData = [
  {
    mainPartNumber: 'TC1R006070001',
    inventPartnumber: 'TC1R006070001-1',
    partName: 'SMC-HAND VALVE MODEL: VHS400-02',
    location: 'SF Inventory B2L1',
    qtyAvailable: 1,
    imageUrls:
      '[{"url":"http://35.161.23.251/CMMS/images/TGCM000140001.jpg","description":"Image Description"},{"url":"http://35.161.23.251/CMMS/images/TGCM000140001.jpg","description":"Image Description"}]',
    equipmenttype: 'Auto-Dryer',
    priceIn: 88.04
  },
  {
    mainPartNumber: 'TC1R006070003',
    inventPartnumber: 'TC1R006070003-1',
    partName: 'PARKER 1/2 NPT ADSORBER HSG CW FILTER ELEMENT AU10-025 MODEL: P QN2N-AUD',
    location: 'SF Inventory B2L1',
    qtyAvailable: 4,
    imageUrls:
      '[{"url":"http://35.161.23.251/CMMS/images/TGCM000140001.jpg","description":"Image Description"},{"url":"http://35.161.23.251/CMMS/images/TGCM000140001.jpg","description":"Image Description"}]',
    equipmenttype: 'Auto-Dryer',
    priceIn: 455.52
  },
  {
    mainPartNumber: 'TC1R006070004',
    inventPartnumber: 'TC1R006070004-1',
    partName: 'PARKER 1/2 NPT COALESCING HSG CW FILTER ELEMENT 6C10-025 MODEL: P QN2N-6CD',
    location: 'SF Inventory B2L1',
    qtyAvailable: 5,
    imageUrls:
      '[{"url":"http://35.161.23.251/CMMS/images/TGCM000140001.jpg","description":"Image Description"},{"url":"http://35.161.23.251/CMMS/images/TGCM000140001.jpg","description":"Image Description"}]',
    equipmenttype: 'Auto-Dryer',
    priceIn: 865.25
  }
];

export const assetListMeta = {
  count: 16,
  page: 1,
  pagesize: 10,
  filteredBy: [
    {
      Site: 'THO',
      BU: ['SF'],
      Process: null,
      MC: null,
      Category: ['00']
    }
  ],
  columns: [
    {
      key: 'partName',
      title: 'Part Name',
      searchable: true,
      sortable: false,
      visible: true,
      type: 'string'
    },
    {
      key: 'mainPartNumber',
      title: 'Main Part Number',
      searchable: true,
      sortable: false,
      visible: true,
      type: 'string'
    },
    {
      key: 'inventPartnumber',
      title: 'Invent Part Number',
      searchable: true,
      sortable: false,
      visible: true,
      type: 'string'
    },
    {
      key: 'location',
      title: 'Location',
      searchable: true,
      sortable: false,
      visible: true,
      type: 'string'
    },
    {
      key: 'qtyAvailable',
      title: 'Quantity Available',
      searchable: true,
      sortable: false,
      visible: true,
      type: 'string'
    },
    {
      key: 'equipmenttype',
      title: 'Equipment Type',
      searchable: true,
      sortable: false,
      visible: true,
      type: 'string'
    },
    {
      key: 'priceIn',
      title: 'Price',
      searchable: true,
      sortable: false,
      visible: true,
      type: 'string'
    },
    {
      key: 'imageUrl',
      title: 'Image',
      searchable: false,
      sortable: false,
      visible: true,
      type: 'Image'
    }
  ]
};

export const assetListData = [
  {
    mainPartNumber: 'TSCM000000001',
    inventPartnumber: 'TSCM000000001-1',
    partName: 'TOHO-TEMPERATURE CONTROLLERTTM-004 MODEL: TTM-004',
    location: 'SF Inventory B2L1',
    qtyAvailable: 1,
    imageUrl: 'http://35.161.23.251/CMMS/api/WF/getImages/thumbnail/TSCM000000001',
    equipmenttype: 'Common SF',
    priceIn: 90.14
  },
  {
    mainPartNumber: 'TSCM000000001',
    inventPartnumber: 'TSCM000000001-2',
    partName: 'TOHO-TEMPERATURE CONTROLLERTTM-004 MODEL: TTM-004',
    location: 'SF Inventory B2L1',
    qtyAvailable: 2,
    imageUrl: 'http://35.161.23.251/CMMS/api/WF/getImages/thumbnail/TSCM000000001',
    equipmenttype: 'Common SF',
    priceIn: 90.14
  },
  {
    mainPartNumber: 'TSCM000000003',
    inventPartnumber: 'TSCM000000003-1',
    partName: 'INFINITY-CONTROLLER LIMIT TEMPERATURE FORM C RELAY OUT 1X16 DIN MODEL: UT150L-RN',
    location: 'SF Inventory B1L2',
    qtyAvailable: 1,
    imageUrl: 'http://35.161.23.251/CMMS/api/WF/getImages/thumbnail/TSCM000000003',
    equipmenttype: 'Common SF',
    priceIn: 306.85
  },
  {
    mainPartNumber: 'TSCM000000003',
    inventPartnumber: 'TSCM000000003-1',
    partName: 'INFINITY-CONTROLLER LIMIT TEMPERATURE FORM C RELAY OUT 1X16 DIN MODEL: UT150L-RN',
    location: 'SF Inventory B2L1',
    qtyAvailable: 1,
    imageUrl: 'http://35.161.23.251/CMMS/api/WF/getImages/thumbnail/TSCM000000003',
    equipmenttype: 'Common SF',
    priceIn: 306.85
  },
  {
    mainPartNumber: 'TSCM000000004',
    inventPartnumber: 'TSCM000000004-1',
    partName: 'ALLEN BRADLEY-PLC I/O MODULE 32 INPUTS MODEL: 1769-IQ32',
    location: 'SF Inventory B2L1',
    qtyAvailable: 2,
    imageUrl: 'http://35.161.23.251/CMMS/api/WF/getImages/thumbnail/TSCM000000004',
    equipmenttype: 'Common SF',
    priceIn: 1004.71
  },
  {
    mainPartNumber: 'TSCM000000006',
    inventPartnumber: 'TSCM000000006-1',
    partName: 'PANASONIC-AC SERVO DRIVER MODEL: MCDHT3520',
    location: 'SF Inventory B1L2',
    qtyAvailable: 13,
    imageUrl: 'http://35.161.23.251/CMMS/api/WF/getImages/thumbnail/TSCM000000006',
    equipmenttype: 'Common SF',
    priceIn: 912.94
  },
  {
    mainPartNumber: 'TSCM000000006',
    inventPartnumber: 'TSCM000000006-0',
    partName: 'PANASONIC-AC SERVO DRIVER MODEL: MCDHT3520',
    location: 'SF Inventory B2L1',
    qtyAvailable: 2,
    imageUrl: 'http://35.161.23.251/CMMS/api/WF/getImages/thumbnail/TSCM000000006',
    equipmenttype: 'Common SF',
    priceIn: 912.94
  },
  {
    mainPartNumber: 'TSCM000000009',
    inventPartnumber: 'TSCM000000009-0',
    partName: 'VEECO-MFC 0-50 D-NET GM50A (N2) PN: 189041431 MODEL: GM50A013501R6M010',
    location: 'SF Inventory B2L1',
    qtyAvailable: 2,
    imageUrl: 'http://35.161.23.251/CMMS/api/WF/getImages/thumbnail/TSCM000000009',
    equipmenttype: 'Common SF',
    priceIn: 1966.64
  },
  {
    mainPartNumber: 'TSCM000000009',
    inventPartnumber: 'TSCM000000009-1',
    partName: 'VEECO-MFC 0-50 D-NET GM50A (N2) PN: 189041431 MODEL: GM50A013501R6M010',
    location: 'SF Inventory B2L1',
    qtyAvailable: 3,
    imageUrl: 'http://35.161.23.251/CMMS/api/WF/getImages/thumbnail/TSCM000000009',
    equipmenttype: 'Common SF',
    priceIn: 1966.64
  },
  {
    mainPartNumber: 'TSCM000000011',
    inventPartnumber: 'TSCM000000011-1',
    partName: 'ORIENTAL-POWER CONTROLLER MODEL: TMP-1',
    location: 'SF Inventory B2L1',
    qtyAvailable: 2,
    imageUrl: 'http://35.161.23.251/CMMS/api/WF/getImages/thumbnail/TSCM000000011',
    equipmenttype: 'Common SF',
    priceIn: 130
  }
];
export const userProfileDetails = {
  profileName: 'Mohammad Akhil',
  empId: 39403,
  userId: 'MdAkhil',
  role: 'Engineer',
  mail: 'md.akhil@panorbit.in',
  reportingManager: 'Louie Corbyn',
  phone: '0788- 536 7542'
};

export const dummyFilter = {
  data: {
    tree: [
      {
        id: 1,
        groupTitle: 'Site',
        selectionType: 'singleselect',
        required: 'true',
        attribute: 'Site',
        values: [
          {
            id: 'S_P',
            name: 'PHO',
            type: 'object',
            selectionType: 'multiselect',
            required: 'false',
            attribute: 'BU',
            values: [
              {
                id: 'BU_S',
                name: 'SF',
                type: 'object',
                selectionType: 'multiselect',
                required: 'false',
                attribute: 'Process',
                values: [
                  {
                    id: 'P_1M',
                    name: 'FAB1 Maching',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_1L',
                    name: 'FAB1 Lapping',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_1O',
                    name: 'FAB1 Other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HD',
                    name: 'Hamony DLC/Coating',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HP',
                    name: 'Hamony Photo&Etching',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HO',
                    name: 'Hamony other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2H',
                    name: 'FAB2 Head part',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2N',
                    name: 'FAB2 Inspection',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2S',
                    name: 'FAB2 Sorting',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2O',
                    name: 'FAB2 Other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TQ',
                    name: 'TEST QST',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TS',
                    name: 'TEST SDET',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TH',
                    name: 'TEST HGA',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SO',
                    name: 'TEST Sorting',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TO',
                    name: 'TEST OTHER',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_AS',
                    name: "Ass'y ASM",
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_AC',
                    name: "Ass'y Compact line",
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_BE',
                    name: 'Backend',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_CM',
                    name: 'COMMON',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_OT',
                    name: 'OTHER',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SP',
                    name: 'Specialty',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_UC',
                    name: 'Under coat',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_ID',
                    name: 'IDM PO',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_EN',
                    name: 'IDM Eng PO (new part)',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HG',
                    name: 'All HGA',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SF',
                    name: 'All SF',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TE',
                    name: 'All TEST',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  }
                ],
                lockable: true
              },
              {
                id: 'BU_T',
                name: 'TEST',
                type: 'object',
                selectionType: 'multiselect',
                required: 'false',
                attribute: 'Process',
                values: [
                  {
                    id: 'P_1M',
                    name: 'FAB1 Maching',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_1L',
                    name: 'FAB1 Lapping',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_1O',
                    name: 'FAB1 Other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HD',
                    name: 'Hamony DLC/Coating',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HP',
                    name: 'Hamony Photo&Etching',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HO',
                    name: 'Hamony other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2H',
                    name: 'FAB2 Head part',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2N',
                    name: 'FAB2 Inspection',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2S',
                    name: 'FAB2 Sorting',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2O',
                    name: 'FAB2 Other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TQ',
                    name: 'TEST QST',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TS',
                    name: 'TEST SDET',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TH',
                    name: 'TEST HGA',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SO',
                    name: 'TEST Sorting',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TO',
                    name: 'TEST OTHER',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_AS',
                    name: "Ass'y ASM",
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_AC',
                    name: "Ass'y Compact line",
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_BE',
                    name: 'Backend',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_CM',
                    name: 'COMMON',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_OT',
                    name: 'OTHER',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SP',
                    name: 'Specialty',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_UC',
                    name: 'Under coat',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_ID',
                    name: 'IDM PO',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_EN',
                    name: 'IDM Eng PO (new part)',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HG',
                    name: 'All HGA',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SF',
                    name: 'All SF',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TE',
                    name: 'All TEST',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  }
                ],
                lockable: true
              },
              {
                id: 'BU_H',
                name: 'HGA',
                type: 'object',
                selectionType: 'multiselect',
                required: 'false',
                attribute: 'Process',
                values: [
                  {
                    id: 'P_1M',
                    name: 'FAB1 Maching',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_1L',
                    name: 'FAB1 Lapping',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_1O',
                    name: 'FAB1 Other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HD',
                    name: 'Hamony DLC/Coating',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HP',
                    name: 'Hamony Photo&Etching',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HO',
                    name: 'Hamony other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2H',
                    name: 'FAB2 Head part',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2N',
                    name: 'FAB2 Inspection',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2S',
                    name: 'FAB2 Sorting',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2O',
                    name: 'FAB2 Other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TQ',
                    name: 'TEST QST',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TS',
                    name: 'TEST SDET',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TH',
                    name: 'TEST HGA',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SO',
                    name: 'TEST Sorting',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TO',
                    name: 'TEST OTHER',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_AS',
                    name: "Ass'y ASM",
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_AC',
                    name: "Ass'y Compact line",
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_BE',
                    name: 'Backend',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_CM',
                    name: 'COMMON',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_OT',
                    name: 'OTHER',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SP',
                    name: 'Specialty',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_UC',
                    name: 'Under coat',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_ID',
                    name: 'IDM PO',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_EN',
                    name: 'IDM Eng PO (new part)',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HG',
                    name: 'All HGA',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SF',
                    name: 'All SF',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TE',
                    name: 'All TEST',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  }
                ],
                lockable: true
              },
              {
                id: 'BU_C',
                name: 'CL',
                type: 'object',
                selectionType: 'multiselect',
                required: 'false',
                attribute: 'Process',
                values: [
                  {
                    id: 'P_1M',
                    name: 'FAB1 Maching',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_1L',
                    name: 'FAB1 Lapping',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_1O',
                    name: 'FAB1 Other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HD',
                    name: 'Hamony DLC/Coating',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HP',
                    name: 'Hamony Photo&Etching',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HO',
                    name: 'Hamony other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2H',
                    name: 'FAB2 Head part',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2N',
                    name: 'FAB2 Inspection',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2S',
                    name: 'FAB2 Sorting',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2O',
                    name: 'FAB2 Other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TQ',
                    name: 'TEST QST',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TS',
                    name: 'TEST SDET',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TH',
                    name: 'TEST HGA',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SO',
                    name: 'TEST Sorting',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TO',
                    name: 'TEST OTHER',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_AS',
                    name: "Ass'y ASM",
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_AC',
                    name: "Ass'y Compact line",
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_BE',
                    name: 'Backend',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_CM',
                    name: 'COMMON',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_OT',
                    name: 'OTHER',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SP',
                    name: 'Specialty',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_UC',
                    name: 'Under coat',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_ID',
                    name: 'IDM PO',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_EN',
                    name: 'IDM Eng PO (new part)',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HG',
                    name: 'All HGA',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SF',
                    name: 'All SF',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TE',
                    name: 'All TEST',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  }
                ],
                lockable: true
              },
              {
                id: 'BU_E',
                name: 'ESM',
                type: 'object',
                selectionType: 'multiselect',
                required: 'false',
                attribute: 'Process',
                values: [
                  {
                    id: 'P_1M',
                    name: 'FAB1 Maching',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_1L',
                    name: 'FAB1 Lapping',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_1O',
                    name: 'FAB1 Other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HD',
                    name: 'Hamony DLC/Coating',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HP',
                    name: 'Hamony Photo&Etching',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HO',
                    name: 'Hamony other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2H',
                    name: 'FAB2 Head part',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2N',
                    name: 'FAB2 Inspection',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2S',
                    name: 'FAB2 Sorting',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2O',
                    name: 'FAB2 Other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TQ',
                    name: 'TEST QST',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TS',
                    name: 'TEST SDET',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TH',
                    name: 'TEST HGA',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SO',
                    name: 'TEST Sorting',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TO',
                    name: 'TEST OTHER',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_AS',
                    name: "Ass'y ASM",
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_AC',
                    name: "Ass'y Compact line",
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_BE',
                    name: 'Backend',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_CM',
                    name: 'COMMON',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_OT',
                    name: 'OTHER',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SP',
                    name: 'Specialty',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_UC',
                    name: 'Under coat',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_ID',
                    name: 'IDM PO',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_EN',
                    name: 'IDM Eng PO (new part)',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HG',
                    name: 'All HGA',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SF',
                    name: 'All SF',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TE',
                    name: 'All TEST',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  }
                ],
                lockable: true
              },
              {
                id: 'BU_O',
                name: 'ME other',
                type: 'object',
                selectionType: 'multiselect',
                required: 'false',
                attribute: 'Process',
                values: [
                  {
                    id: 'P_1M',
                    name: 'FAB1 Maching',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_1L',
                    name: 'FAB1 Lapping',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_1O',
                    name: 'FAB1 Other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HD',
                    name: 'Hamony DLC/Coating',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HP',
                    name: 'Hamony Photo&Etching',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HO',
                    name: 'Hamony other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2H',
                    name: 'FAB2 Head part',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2N',
                    name: 'FAB2 Inspection',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2S',
                    name: 'FAB2 Sorting',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2O',
                    name: 'FAB2 Other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TQ',
                    name: 'TEST QST',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TS',
                    name: 'TEST SDET',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TH',
                    name: 'TEST HGA',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SO',
                    name: 'TEST Sorting',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TO',
                    name: 'TEST OTHER',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_AS',
                    name: "Ass'y ASM",
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_AC',
                    name: "Ass'y Compact line",
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_BE',
                    name: 'Backend',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_CM',
                    name: 'COMMON',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_OT',
                    name: 'OTHER',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SP',
                    name: 'Specialty',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_UC',
                    name: 'Under coat',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_ID',
                    name: 'IDM PO',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_EN',
                    name: 'IDM Eng PO (new part)',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HG',
                    name: 'All HGA',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SF',
                    name: 'All SF',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TE',
                    name: 'All TEST',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  }
                ],
                lockable: true
              },
              {
                id: 'BU_N',
                name: 'Non ME',
                type: 'object',
                selectionType: 'multiselect',
                required: 'false',
                attribute: 'Process',
                values: [
                  {
                    id: 'P_1M',
                    name: 'FAB1 Maching',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_1L',
                    name: 'FAB1 Lapping',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_1O',
                    name: 'FAB1 Other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HD',
                    name: 'Hamony DLC/Coating',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HP',
                    name: 'Hamony Photo&Etching',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HO',
                    name: 'Hamony other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2H',
                    name: 'FAB2 Head part',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2N',
                    name: 'FAB2 Inspection',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2S',
                    name: 'FAB2 Sorting',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2O',
                    name: 'FAB2 Other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TQ',
                    name: 'TEST QST',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TS',
                    name: 'TEST SDET',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TH',
                    name: 'TEST HGA',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SO',
                    name: 'TEST Sorting',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TO',
                    name: 'TEST OTHER',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_AS',
                    name: "Ass'y ASM",
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_AC',
                    name: "Ass'y Compact line",
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_BE',
                    name: 'Backend',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_CM',
                    name: 'COMMON',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_OT',
                    name: 'OTHER',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SP',
                    name: 'Specialty',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_UC',
                    name: 'Under coat',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_ID',
                    name: 'IDM PO',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_EN',
                    name: 'IDM Eng PO (new part)',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HG',
                    name: 'All HGA',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SF',
                    name: 'All SF',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TE',
                    name: 'All TEST',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  }
                ],
                lockable: true
              },
              {
                id: 'BU_P',
                name: 'Production',
                type: 'object',
                selectionType: 'multiselect',
                required: 'false',
                attribute: 'Process',
                values: [
                  {
                    id: 'P_1M',
                    name: 'FAB1 Maching',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_1L',
                    name: 'FAB1 Lapping',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_1O',
                    name: 'FAB1 Other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HD',
                    name: 'Hamony DLC/Coating',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HP',
                    name: 'Hamony Photo&Etching',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HO',
                    name: 'Hamony other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2H',
                    name: 'FAB2 Head part',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2N',
                    name: 'FAB2 Inspection',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2S',
                    name: 'FAB2 Sorting',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2O',
                    name: 'FAB2 Other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TQ',
                    name: 'TEST QST',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TS',
                    name: 'TEST SDET',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TH',
                    name: 'TEST HGA',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SO',
                    name: 'TEST Sorting',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TO',
                    name: 'TEST OTHER',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_AS',
                    name: "Ass'y ASM",
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_AC',
                    name: "Ass'y Compact line",
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_BE',
                    name: 'Backend',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_CM',
                    name: 'COMMON',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_OT',
                    name: 'OTHER',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SP',
                    name: 'Specialty',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_UC',
                    name: 'Under coat',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_ID',
                    name: 'IDM PO',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_EN',
                    name: 'IDM Eng PO (new part)',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HG',
                    name: 'All HGA',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SF',
                    name: 'All SF',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TE',
                    name: 'All TEST',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  }
                ],
                lockable: true
              },
              {
                id: 'BU_G',
                name: 'General',
                type: 'object',
                selectionType: 'multiselect',
                required: 'false',
                attribute: 'Process',
                values: [
                  {
                    id: 'P_1M',
                    name: 'FAB1 Maching',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_1L',
                    name: 'FAB1 Lapping',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_1O',
                    name: 'FAB1 Other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HD',
                    name: 'Hamony DLC/Coating',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HP',
                    name: 'Hamony Photo&Etching',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HO',
                    name: 'Hamony other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2H',
                    name: 'FAB2 Head part',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2N',
                    name: 'FAB2 Inspection',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2S',
                    name: 'FAB2 Sorting',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2O',
                    name: 'FAB2 Other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TQ',
                    name: 'TEST QST',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TS',
                    name: 'TEST SDET',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TH',
                    name: 'TEST HGA',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SO',
                    name: 'TEST Sorting',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TO',
                    name: 'TEST OTHER',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_AS',
                    name: "Ass'y ASM",
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_AC',
                    name: "Ass'y Compact line",
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_BE',
                    name: 'Backend',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_CM',
                    name: 'COMMON',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_OT',
                    name: 'OTHER',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SP',
                    name: 'Specialty',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_UC',
                    name: 'Under coat',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_ID',
                    name: 'IDM PO',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_EN',
                    name: 'IDM Eng PO (new part)',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HG',
                    name: 'All HGA',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SF',
                    name: 'All SF',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TE',
                    name: 'All TEST',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  }
                ],
                lockable: true
              }
            ],
            lockable: false
          },
          {
            id: 'S_T',
            name: 'THO',
            type: 'object',
            selectionType: 'multiselect',
            required: 'false',
            attribute: 'BU',
            values: [
              {
                id: 'BU_S',
                name: 'SF',
                type: 'object',
                selectionType: 'multiselect',
                required: 'false',
                attribute: 'Process',
                values: [
                  {
                    id: 'P_1M',
                    name: 'FAB1 Maching',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_1L',
                    name: 'FAB1 Lapping',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_1O',
                    name: 'FAB1 Other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HD',
                    name: 'Hamony DLC/Coating',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HP',
                    name: 'Hamony Photo&Etching',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HO',
                    name: 'Hamony other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2H',
                    name: 'FAB2 Head part',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2N',
                    name: 'FAB2 Inspection',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2S',
                    name: 'FAB2 Sorting',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2O',
                    name: 'FAB2 Other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TQ',
                    name: 'TEST QST',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TS',
                    name: 'TEST SDET',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TH',
                    name: 'TEST HGA',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SO',
                    name: 'TEST Sorting',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TO',
                    name: 'TEST OTHER',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_AS',
                    name: "Ass'y ASM",
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_AC',
                    name: "Ass'y Compact line",
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_BE',
                    name: 'Backend',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_CM',
                    name: 'COMMON',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_OT',
                    name: 'OTHER',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SP',
                    name: 'Specialty',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_UC',
                    name: 'Under coat',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_ID',
                    name: 'IDM PO',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_EN',
                    name: 'IDM Eng PO (new part)',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HG',
                    name: 'All HGA',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SF',
                    name: 'All SF',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TE',
                    name: 'All TEST',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  }
                ],
                lockable: true
              },
              {
                id: 'BU_T',
                name: 'TEST',
                type: 'object',
                selectionType: 'multiselect',
                required: 'false',
                attribute: 'Process',
                values: [
                  {
                    id: 'P_1M',
                    name: 'FAB1 Maching',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_1L',
                    name: 'FAB1 Lapping',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_1O',
                    name: 'FAB1 Other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HD',
                    name: 'Hamony DLC/Coating',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HP',
                    name: 'Hamony Photo&Etching',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HO',
                    name: 'Hamony other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2H',
                    name: 'FAB2 Head part',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2N',
                    name: 'FAB2 Inspection',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2S',
                    name: 'FAB2 Sorting',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2O',
                    name: 'FAB2 Other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TQ',
                    name: 'TEST QST',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TS',
                    name: 'TEST SDET',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TH',
                    name: 'TEST HGA',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SO',
                    name: 'TEST Sorting',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TO',
                    name: 'TEST OTHER',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_AS',
                    name: "Ass'y ASM",
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_AC',
                    name: "Ass'y Compact line",
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_BE',
                    name: 'Backend',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_CM',
                    name: 'COMMON',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_OT',
                    name: 'OTHER',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SP',
                    name: 'Specialty',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_UC',
                    name: 'Under coat',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_ID',
                    name: 'IDM PO',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_EN',
                    name: 'IDM Eng PO (new part)',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HG',
                    name: 'All HGA',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SF',
                    name: 'All SF',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TE',
                    name: 'All TEST',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  }
                ],
                lockable: true
              },
              {
                id: 'BU_H',
                name: 'HGA',
                type: 'object',
                selectionType: 'multiselect',
                required: 'false',
                attribute: 'Process',
                values: [
                  {
                    id: 'P_1M',
                    name: 'FAB1 Maching',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_1L',
                    name: 'FAB1 Lapping',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_1O',
                    name: 'FAB1 Other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HD',
                    name: 'Hamony DLC/Coating',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HP',
                    name: 'Hamony Photo&Etching',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HO',
                    name: 'Hamony other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2H',
                    name: 'FAB2 Head part',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2N',
                    name: 'FAB2 Inspection',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2S',
                    name: 'FAB2 Sorting',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2O',
                    name: 'FAB2 Other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TQ',
                    name: 'TEST QST',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TS',
                    name: 'TEST SDET',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TH',
                    name: 'TEST HGA',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SO',
                    name: 'TEST Sorting',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TO',
                    name: 'TEST OTHER',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_AS',
                    name: "Ass'y ASM",
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_AC',
                    name: "Ass'y Compact line",
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_BE',
                    name: 'Backend',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_CM',
                    name: 'COMMON',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_OT',
                    name: 'OTHER',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SP',
                    name: 'Specialty',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_UC',
                    name: 'Under coat',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_ID',
                    name: 'IDM PO',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_EN',
                    name: 'IDM Eng PO (new part)',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HG',
                    name: 'All HGA',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SF',
                    name: 'All SF',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TE',
                    name: 'All TEST',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  }
                ],
                lockable: true
              },
              {
                id: 'BU_C',
                name: 'CL',
                type: 'object',
                selectionType: 'multiselect',
                required: 'false',
                attribute: 'Process',
                values: [
                  {
                    id: 'P_1M',
                    name: 'FAB1 Maching',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_1L',
                    name: 'FAB1 Lapping',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_1O',
                    name: 'FAB1 Other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HD',
                    name: 'Hamony DLC/Coating',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HP',
                    name: 'Hamony Photo&Etching',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HO',
                    name: 'Hamony other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2H',
                    name: 'FAB2 Head part',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2N',
                    name: 'FAB2 Inspection',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2S',
                    name: 'FAB2 Sorting',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2O',
                    name: 'FAB2 Other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TQ',
                    name: 'TEST QST',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TS',
                    name: 'TEST SDET',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TH',
                    name: 'TEST HGA',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SO',
                    name: 'TEST Sorting',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TO',
                    name: 'TEST OTHER',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_AS',
                    name: "Ass'y ASM",
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_AC',
                    name: "Ass'y Compact line",
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_BE',
                    name: 'Backend',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_CM',
                    name: 'COMMON',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_OT',
                    name: 'OTHER',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SP',
                    name: 'Specialty',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_UC',
                    name: 'Under coat',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_ID',
                    name: 'IDM PO',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_EN',
                    name: 'IDM Eng PO (new part)',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HG',
                    name: 'All HGA',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SF',
                    name: 'All SF',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TE',
                    name: 'All TEST',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  }
                ],
                lockable: true
              },
              {
                id: 'BU_E',
                name: 'ESM',
                type: 'object',
                selectionType: 'multiselect',
                required: 'false',
                attribute: 'Process',
                values: [
                  {
                    id: 'P_1M',
                    name: 'FAB1 Maching',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_1L',
                    name: 'FAB1 Lapping',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_1O',
                    name: 'FAB1 Other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HD',
                    name: 'Hamony DLC/Coating',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HP',
                    name: 'Hamony Photo&Etching',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HO',
                    name: 'Hamony other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2H',
                    name: 'FAB2 Head part',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2N',
                    name: 'FAB2 Inspection',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2S',
                    name: 'FAB2 Sorting',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2O',
                    name: 'FAB2 Other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TQ',
                    name: 'TEST QST',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TS',
                    name: 'TEST SDET',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TH',
                    name: 'TEST HGA',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SO',
                    name: 'TEST Sorting',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TO',
                    name: 'TEST OTHER',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_AS',
                    name: "Ass'y ASM",
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_AC',
                    name: "Ass'y Compact line",
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_BE',
                    name: 'Backend',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_CM',
                    name: 'COMMON',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_OT',
                    name: 'OTHER',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SP',
                    name: 'Specialty',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_UC',
                    name: 'Under coat',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_ID',
                    name: 'IDM PO',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_EN',
                    name: 'IDM Eng PO (new part)',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HG',
                    name: 'All HGA',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SF',
                    name: 'All SF',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TE',
                    name: 'All TEST',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  }
                ],
                lockable: true
              },
              {
                id: 'BU_O',
                name: 'ME other',
                type: 'object',
                selectionType: 'multiselect',
                required: 'false',
                attribute: 'Process',
                values: [
                  {
                    id: 'P_1M',
                    name: 'FAB1 Maching',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_1L',
                    name: 'FAB1 Lapping',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_1O',
                    name: 'FAB1 Other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HD',
                    name: 'Hamony DLC/Coating',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HP',
                    name: 'Hamony Photo&Etching',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HO',
                    name: 'Hamony other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2H',
                    name: 'FAB2 Head part',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2N',
                    name: 'FAB2 Inspection',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2S',
                    name: 'FAB2 Sorting',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2O',
                    name: 'FAB2 Other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TQ',
                    name: 'TEST QST',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TS',
                    name: 'TEST SDET',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TH',
                    name: 'TEST HGA',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SO',
                    name: 'TEST Sorting',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TO',
                    name: 'TEST OTHER',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_AS',
                    name: "Ass'y ASM",
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_AC',
                    name: "Ass'y Compact line",
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_BE',
                    name: 'Backend',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_CM',
                    name: 'COMMON',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_OT',
                    name: 'OTHER',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SP',
                    name: 'Specialty',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_UC',
                    name: 'Under coat',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_ID',
                    name: 'IDM PO',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_EN',
                    name: 'IDM Eng PO (new part)',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HG',
                    name: 'All HGA',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SF',
                    name: 'All SF',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TE',
                    name: 'All TEST',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  }
                ],
                lockable: true
              },
              {
                id: 'BU_N',
                name: 'Non ME',
                type: 'object',
                selectionType: 'multiselect',
                required: 'false',
                attribute: 'Process',
                values: [
                  {
                    id: 'P_1M',
                    name: 'FAB1 Maching',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_1L',
                    name: 'FAB1 Lapping',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_1O',
                    name: 'FAB1 Other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HD',
                    name: 'Hamony DLC/Coating',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HP',
                    name: 'Hamony Photo&Etching',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HO',
                    name: 'Hamony other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2H',
                    name: 'FAB2 Head part',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2N',
                    name: 'FAB2 Inspection',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2S',
                    name: 'FAB2 Sorting',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2O',
                    name: 'FAB2 Other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TQ',
                    name: 'TEST QST',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TS',
                    name: 'TEST SDET',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TH',
                    name: 'TEST HGA',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SO',
                    name: 'TEST Sorting',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TO',
                    name: 'TEST OTHER',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_AS',
                    name: "Ass'y ASM",
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_AC',
                    name: "Ass'y Compact line",
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_BE',
                    name: 'Backend',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_CM',
                    name: 'COMMON',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_OT',
                    name: 'OTHER',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SP',
                    name: 'Specialty',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_UC',
                    name: 'Under coat',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_ID',
                    name: 'IDM PO',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_EN',
                    name: 'IDM Eng PO (new part)',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HG',
                    name: 'All HGA',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SF',
                    name: 'All SF',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TE',
                    name: 'All TEST',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  }
                ],
                lockable: true
              },
              {
                id: 'BU_P',
                name: 'Production',
                type: 'object',
                selectionType: 'multiselect',
                required: 'false',
                attribute: 'Process',
                values: [
                  {
                    id: 'P_1M',
                    name: 'FAB1 Maching',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_1L',
                    name: 'FAB1 Lapping',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_1O',
                    name: 'FAB1 Other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HD',
                    name: 'Hamony DLC/Coating',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HP',
                    name: 'Hamony Photo&Etching',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HO',
                    name: 'Hamony other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2H',
                    name: 'FAB2 Head part',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2N',
                    name: 'FAB2 Inspection',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2S',
                    name: 'FAB2 Sorting',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2O',
                    name: 'FAB2 Other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TQ',
                    name: 'TEST QST',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TS',
                    name: 'TEST SDET',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TH',
                    name: 'TEST HGA',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SO',
                    name: 'TEST Sorting',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TO',
                    name: 'TEST OTHER',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_AS',
                    name: "Ass'y ASM",
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_AC',
                    name: "Ass'y Compact line",
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_BE',
                    name: 'Backend',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_CM',
                    name: 'COMMON',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_OT',
                    name: 'OTHER',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SP',
                    name: 'Specialty',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_UC',
                    name: 'Under coat',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_ID',
                    name: 'IDM PO',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_EN',
                    name: 'IDM Eng PO (new part)',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HG',
                    name: 'All HGA',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SF',
                    name: 'All SF',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TE',
                    name: 'All TEST',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  }
                ],
                lockable: true
              },
              {
                id: 'BU_G',
                name: 'General',
                type: 'object',
                selectionType: 'multiselect',
                required: 'false',
                attribute: 'Process',
                values: [
                  {
                    id: 'P_1M',
                    name: 'FAB1 Maching',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_1L',
                    name: 'FAB1 Lapping',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_1O',
                    name: 'FAB1 Other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HD',
                    name: 'Hamony DLC/Coating',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HP',
                    name: 'Hamony Photo&Etching',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HO',
                    name: 'Hamony other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2H',
                    name: 'FAB2 Head part',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2N',
                    name: 'FAB2 Inspection',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2S',
                    name: 'FAB2 Sorting',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_2O',
                    name: 'FAB2 Other',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TQ',
                    name: 'TEST QST',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TS',
                    name: 'TEST SDET',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TH',
                    name: 'TEST HGA',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SO',
                    name: 'TEST Sorting',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TO',
                    name: 'TEST OTHER',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_AS',
                    name: "Ass'y ASM",
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_AC',
                    name: "Ass'y Compact line",
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_BE',
                    name: 'Backend',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_CM',
                    name: 'COMMON',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_OT',
                    name: 'OTHER',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SP',
                    name: 'Specialty',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_UC',
                    name: 'Under coat',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_ID',
                    name: 'IDM PO',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_EN',
                    name: 'IDM Eng PO (new part)',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_HG',
                    name: 'All HGA',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_SF',
                    name: 'All SF',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  },
                  {
                    id: 'P_TE',
                    name: 'All TEST',
                    type: 'object',
                    selectionType: 'multiselect',
                    required: 'false',
                    attribute: 'MC',
                    values: [
                      {
                        id: 'MC_Other MC',
                        name: 'Other MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SF MC',
                        name: 'SF MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_General',
                        name: 'General',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_HGA MC',
                        name: 'HGA MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      },
                      {
                        id: 'MC_SDET MC',
                        name: 'SDET MC',
                        type: 'object',
                        selectionType: 'multiselect',
                        required: 'false',
                        attribute: 'Category',
                        lockable: 'true',
                        values: '[]'
                      }
                    ],
                    lockable: true
                  }
                ],
                lockable: true
              }
            ],
            lockable: false
          }
        ]
      }
    ],
    Category: [
      {
        id: 2,
        groupTitle: 'Category',
        selectionType: 'multiselect',
        required: 'false',
        attribute: 'Category',
        values: [
          {
            id: 'C_00',
            name: 'MSP-Controller',
            type: 'object',
            selectionType: 'multiselect',
            required: 'false',
            attribute: '',
            values: '[]'
          },
          {
            id: 'C_01',
            name: 'MSP-Measurement/Instrument',
            type: 'object',
            selectionType: 'multiselect',
            required: 'false',
            attribute: '',
            values: '[]'
          },
          {
            id: 'C_02',
            name: 'MSP-Conversion kit',
            type: 'object',
            selectionType: 'multiselect',
            required: 'false',
            attribute: '',
            values: '[]'
          },
          {
            id: 'C_03',
            name: 'MSP-PCB/board',
            type: 'object',
            selectionType: 'multiselect',
            required: 'false',
            attribute: '',
            values: '[]'
          },
          {
            id: 'C_04',
            name: 'MSP-Power Supply',
            type: 'object',
            selectionType: 'multiselect',
            required: 'false',
            attribute: '',
            values: '[]'
          },
          {
            id: 'C_05',
            name: 'JnF-Tray',
            type: 'object',
            selectionType: 'multiselect',
            required: 'false',
            attribute: '',
            values: '[]'
          },
          {
            id: 'C_06',
            name: 'MSP-Screw/Nut/Spring/Washer/Standoff ',
            type: 'object',
            selectionType: 'multiselect',
            required: 'false',
            attribute: '',
            values: '[]'
          },
          {
            id: 'C_07',
            name: 'MSP-Pneumatic part',
            type: 'object',
            selectionType: 'multiselect',
            required: 'false',
            attribute: '',
            values: '[]'
          },
          {
            id: 'C_08',
            name: 'MSP-Standard Component',
            type: 'object',
            selectionType: 'multiselect',
            required: 'false',
            attribute: '',
            values: '[]'
          },
          {
            id: 'C_09',
            name: 'MSP-Machine Component',
            type: 'object',
            selectionType: 'multiselect',
            required: 'false',
            attribute: '',
            values: '[]'
          },
          {
            id: 'C_10',
            name: 'MSP-Linear Motion',
            type: 'object',
            selectionType: 'multiselect',
            required: 'false',
            attribute: '',
            values: '[]'
          },
          {
            id: 'C_11',
            name: 'JnF-DWG TEST',
            type: 'object',
            selectionType: 'multiselect',
            required: 'false',
            attribute: '',
            values: '[]'
          },
          {
            id: 'C_12',
            name: 'JnF-DWG SF',
            type: 'object',
            selectionType: 'multiselect',
            required: 'false',
            attribute: '',
            values: '[]'
          },
          {
            id: 'C_13',
            name: 'JnF-DWG HGA',
            type: 'object',
            selectionType: 'multiselect',
            required: 'false',
            attribute: '',
            values: '[]'
          },
          {
            id: 'C_14',
            name: 'JnF-JIG And Fixture DWG vendor',
            type: 'object',
            selectionType: 'multiselect',
            required: 'false',
            attribute: '',
            values: '[]'
          },
          {
            id: 'C_15',
            name: 'MSP-Motor,Pump and accessories',
            type: 'object',
            selectionType: 'multiselect',
            required: 'false',
            attribute: '',
            values: '[]'
          },
          {
            id: 'C_16',
            name: 'MSP-Cable',
            type: 'object',
            selectionType: 'multiselect',
            required: 'false',
            attribute: '',
            values: '[]'
          },
          {
            id: 'C_17',
            name: 'MSP-Hand Tool',
            type: 'object',
            selectionType: 'multiselect',
            required: 'false',
            attribute: '',
            values: '[]'
          },
          {
            id: 'C_18',
            name: 'IDM-Chemical/Gas',
            type: 'object',
            selectionType: 'multiselect',
            required: 'false',
            attribute: '',
            values: '[]'
          },
          {
            id: 'C_19',
            name: 'MSP-Machine Filter IDM',
            type: 'object',
            selectionType: 'multiselect',
            required: 'false',
            attribute: '',
            values: '[]'
          },
          {
            id: 'C_20',
            name: 'MSP-IT',
            type: 'object',
            selectionType: 'multiselect',
            required: 'false',
            attribute: '',
            values: '[]'
          },
          {
            id: 'C_21',
            name: 'IDM-Indirect mat',
            type: 'object',
            selectionType: 'multiselect',
            required: 'false',
            attribute: '',
            values: '[]'
          },
          {
            id: 'C_22',
            name: 'MSP-ESD',
            type: 'object',
            selectionType: 'multiselect',
            required: 'false',
            attribute: '',
            values: '[]'
          },
          {
            id: 'C_23',
            name: 'MSP-Safety',
            type: 'object',
            selectionType: 'multiselect',
            required: 'false',
            attribute: '',
            values: '[]'
          },
          {
            id: 'C_24',
            name: 'MSP-General',
            type: 'object',
            selectionType: 'multiselect',
            required: 'false',
            attribute: '',
            values: '[]'
          },
          {
            id: 'C_25',
            name: 'OTH-Stationary',
            type: 'object',
            selectionType: 'multiselect',
            required: 'false',
            attribute: '',
            values: '[]'
          },
          {
            id: 'C_26',
            name: 'MSP-Scope',
            type: 'object',
            selectionType: 'multiselect',
            required: 'false',
            attribute: '',
            values: '[]'
          },
          {
            id: 'C_27',
            name: 'MSP-Consign part ',
            type: 'object',
            selectionType: 'multiselect',
            required: 'false',
            attribute: '',
            values: '[]'
          }
        ]
      }
    ],
    Location: [
      {
        id: 3,
        groupTitle: 'Location',
        selectionType: 'singleselect',
        required: 'true',
        attribute: 'Location',
        values: [
          {
            location: 'TEST Inventory B2L2',
            values: '[]',
            id: '1',
            name: 'TEST Inventory B2L2',
            selectionType: 'singleselect',
            required: true,
            attribute: 'none'
          },
          {
            location: 'TEST Inventory B2L1',
            values: '[]',
            id: '2',
            name: 'TEST Inventory B2L1',
            selectionType: 'singleselect',
            required: true,
            attribute: 'none'
          },
          {
            location: 'SF Inventory B2L1',
            values: '[]',
            id: '3',
            name: 'SF Inventory B2L1',
            selectionType: 'singleselect',
            required: true,
            attribute: 'none'
          },
          {
            location: 'SF Inventory B1L2',
            values: '[]',
            id: '4',
            name: 'SF Inventory B1L2',
            selectionType: 'singleselect',
            required: true,
            attribute: 'none'
          },
          {
            location: 'ME Inventory R15',
            values: '[]',
            id: '5',
            name: 'ME Inventory R15',
            selectionType: 'singleselect',
            required: true,
            attribute: 'none'
          },
          {
            location: 'ME Inventory B1L1',
            values: '[]',
            id: '6',
            name: 'ME Inventory B1L1',
            selectionType: 'singleselect',
            required: true,
            attribute: 'none'
          },
          {
            location: 'HGA Inventory B2L2-2',
            values: '[]',
            id: '7',
            name: 'HGA Inventory B2L2-2',
            selectionType: 'singleselect',
            required: true,
            attribute: 'none'
          },
          {
            location: 'HGA Inventory B2L2',
            values: '[]',
            id: '8',
            name: 'HGA Inventory B2L2',
            selectionType: 'singleselect',
            required: true,
            attribute: 'none'
          }
        ]
      }
    ],
    partName: [
      {
        id: 'input_1',
        groupTitle: 'Part Name',
        required: 'false',
        attribute: 'Part Name',
        type: 'input',
        values: [
          {
            id: 'input_1',
            type: 'input',
            required: 'false',
            attribute: '',
            placeholder: 'enter part name',
            values: '[]'
          }
        ]
      }
    ],
    location: [
      {
        id: 'input_2',
        groupTitle: 'Location',
        required: 'false',
        attribute: 'Location',
        type: 'input',
        values: [
          {
            id: 'input_2',
            type: 'input',
            required: 'false',
            attribute: '',
            placeholder: 'enter location name',
            values: '[]'
          }
        ]
      }
    ]
  },
  resultType: 'drilldown',
  assetType: 'Spares',
  message: 'success'
};
