/* eslint-disable max-len */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WorkOrderCreators } from '../../../../views/WorkOrders/store';
import { Creators as UpserWorkOrderCreators } from '../store';

const useGetWorkOrderTypes = () => {
  // const { workOrderTypes } = useSelector((state) => state.workOrders);
  const dispatch = useDispatch();
  useEffect(() => {
    // if (workOrderTypes?.length === 0)
    dispatch(WorkOrderCreators.getWorkOrderTypes());
  }, []);
};

export default useGetWorkOrderTypes;
