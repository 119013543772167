/* eslint-disable react/jsx-indent */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-danger */
/* eslint-disable no-unsafe-negation */
/* eslint-disable function-paren-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable eqeqeq */
/* eslint-disable prefer-template */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable indent */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { AddToCart, RemoveFromCart } from '../../../assets/icons';
import CollapseIcon from '../../../assets/icons/CollapseIcon';
import { FlexContainer } from '../../../globalStyles';
import DynamicSVGIcon from '../DynamicSVGIcon';
import { Td, ToogleGroupICon, Tr } from '../Table/SpacedRows.styles';
import { ActionIcon, InputCell, StatusBackground } from './TabsTable.style';
import kababIcon from '../../../assets/icons/Filter/kabab.svg';
import DropDownModal from '../DropDownModal/DropDownModal';
import { format } from '../../../config/index';
import { validate, VALIDATION_TYPES } from '../../../constants/regex';
import { useActions } from '../../../store/hooks/useActions';
import { isRowMatched } from './utility';
import { BoldSpan } from '../../../views/Stores/Spares/SpareBulkUpdate.styles';

const TableContent = ({
  type, // groupby value
  rowsDetails, // object: data is formed from rowsDetails[type]
  metaDetails, // object containing columns
  assetShortlist,
  visibleColumnsCount,
  onItemSelect,
  onHyperLinkSelect,
  onLogsClick,
  showShortlistSelection,
  handleShortList,
  rowOptions,
  handleRowOptionsItemClickHandler,
  additionalColumns,
  styleType,
  id = 'id' // unique id for each row
}) => {
  const [showDetails, setShowDetails] = useState(true);
  const { setAssetShortlist } = useActions();
  const handleToggle = () => {
    setShowDetails((prev) => !prev);
  };
  // show rowoptions
  const [activeRowItem, setActiveRowItem] = useState({});
  const handleOptionsClick = (item) => {
    setActiveRowItem(item);
  };

  // close handler
  const closeDropdownOptions = () => {
    setActiveRowItem({});
  };

  const handleRowOptionsItemClick = (...spred) => {
    handleRowOptionsItemClickHandler(...spred);
    closeDropdownOptions();
  };

  const addtionalColHandler = (
    e, // event details
    entry // value row entry
  ) => {
    const { name, value } = e.target;
    const editVal = assetShortlist?.map((ele) =>
      // TODO: pass unique key(change partname)
      ele.partName === entry.partName ? { ...entry, [name]: value } : ele
    );
    setAssetShortlist(editVal);
  };

  useEffect(() => {
    // init with default values
    const editVal = assetShortlist?.map((ele) => {
      const newEle = { ...ele };
      additionalColumns?.forEach((addColEntry) => {
        const { defaultValue, key } = addColEntry;
        newEle[key] = defaultValue;
      });
      return newEle;
    });
    // const editVal = assetShortlist;
    setAssetShortlist(editVal);
  }, []);

  return (
    <>
      {/* hide row if there is grouping specified */}
      {type !== 'noGrouping' && (
        <Tr className={styleType}>
          <Td
            className={styleType}
            style={{ cursor: 'pointer' }}
            colSpan={visibleColumnsCount}
          >
            <FlexContainer className='ai_center'>
              <ToogleGroupICon onClick={handleToggle}>
                <CollapseIcon />
              </ToogleGroupICon>
              <p>{type}</p>
            </FlexContainer>
          </Td>
        </Tr>
      )}
      {showDetails &&
        rowsDetails?.[type]?.map((entry, index) => (
          <Tr key={index} className={styleType}>
            {metaDetails?.columns?.map((col, key) => {
              let isImg = false;
              let isHtmlValue = false;
              let value = entry[col.key] || '-';
              if (typeof entry[col.key] === 'string') {
                isImg = entry[col.key]?.startsWith('http');
                isHtmlValue = entry[col.key]?.startsWith('<');
                if (
                  typeof entry[col.key] === 'string' &&
                  entry[col.key].trim() === 'to'
                ) {
                  value = '--:--:-- to --:--:--';
                }
              }
              if (validate(entry[col.key], VALIDATION_TYPES.ISO_DATE_STRING)) {
                value = moment(entry[col.key]).format(format);
              }
              let content = isImg ? (
                <img height='40px' src={entry[col.key]} alt='spare' />
              ) : (
                <>{value}</>
              );
              if (isHtmlValue) {
                content = (
                  <p
                    style={{ fontSize: '1.6rem' }}
                    dangerouslySetInnerHTML={{ __html: entry[col.key] || '-' }}
                  />
                );
              }
              if (styleType === 'spareTable' && col.key === 'status') {
                const statusColor =
                  typeof col.statusColor === 'string'
                    ? JSON.parse(col.statusColor)
                    : col.statusColor;
                value = entry[col.key];
                content = (
                  <FlexContainer className='jc_center ai_center'>
                    <StatusBackground bgColor={statusColor[value]}>
                      <p>{value}</p>
                    </StatusBackground>
                  </FlexContainer>
                );
              }
              if (col.type === 'hyperlink') {
                value = entry[col.key];
                content = (
                  <BoldSpan
                    onClick={() => {
                      onHyperLinkSelect(entry);
                    }}
                  >
                    {value}
                  </BoldSpan>
                );
              }
              if (col.type === 'logsDetails') {
                value = entry[col.key];
                content = (
                  <BoldSpan
                    onClick={() => {
                      onLogsClick(entry);
                    }}
                  >
                    {entry?.filename}
                  </BoldSpan>
                );
              }
              return (
                col.visible && (
                  <Td
                    border
                    key={key}
                    style={{
                      background: metaDetails?.fieldsToColor?.includes(col.key)
                        ? entry?.[metaDetails?.colorField?.[0]]
                        : ''
                    }}
                    // width={col.width}
                    // order={col.order}
                    // align={col.textAlign}
                    className={
                      assetShortlist?.some(
                        (ele) =>
                          ele.mainPartNumber === entry.mainPartNumber &&
                          ele.inventPartnumber === entry.inventPartnumber
                      )
                        ? 'active pointer'
                        : 'pointer'
                    }
                    removeVerticalPadding={isImg}
                    onClick={() => onItemSelect(entry)}
                  >
                    {/* {col.label !== 'Action' ? ( */}
                    {content}
                  </Td>
                )
              );
            })}
            {additionalColumns?.map((addColEntry) => {
              const { type, key, placeholder } = addColEntry;
              return (
                <Td className='className'>
                  <InputCell
                    type={type}
                    name={key}
                    placeholder={placeholder}
                    value={entry[key]}
                    onChange={(e) => addtionalColHandler(e, entry)}
                  />
                </Td>
              );
            })}
            {showShortlistSelection && (
              <Td
                className={
                  assetShortlist?.some(
                    (ele) =>
                      ele.mainPartNumber === entry.mainPartNumber &&
                      ele.inventPartnumber === entry.inventPartnumber
                  )
                    ? 'active'
                    : ''
                }
                flex={0.5}
              >
                <ActionIcon
                  // className={!entry[metaDetails.chartable[0]] && 'disable'}
                  onClick={() => handleShortList(entry)}
                >
                  {assetShortlist?.some((ele) => isRowMatched(ele, entry)) ? (
                    <DynamicSVGIcon iconUrl={RemoveFromCart} width='2.2rem' />
                  ) : (
                    <DynamicSVGIcon iconUrl={AddToCart} width='2.2rem' />
                  )}
                </ActionIcon>
              </Td>
            )}
            {rowOptions && (
              <Td id={index}>
                <DropDownModal
                  data={rowOptions}
                  isActive={activeRowItem[id] === entry[id]}
                  setModalIsVisibal={closeDropdownOptions}
                  handleClick={handleRowOptionsItemClick}
                  extraParms={{ activeRowItem }}
                  ContainerStyle={{ top: 0, right: 100 }}
                  // boxRef={boxRef}
                  // boxRefProp={activeRowItem.id === entry.id ? boxRef : null}
                >
                  <div
                    // style={{ position: 'relative', background: 'red' }}
                    className='pointer'
                    onClick={() => handleOptionsClick(entry)}
                  >
                    <DynamicSVGIcon iconUrl={kababIcon} width='2.2rem' />
                  </div>
                </DropDownModal>
              </Td>
            )}
          </Tr>
        ))}
    </>
  );
};

export default TableContent;
