/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PointedDropdownModal from '../../Common/DropDownModal/PointedDropdownModal';
import { Creators as LayoutCreators } from '../../../views/Layout/store';
import ToggleOn from '../../../assets/icons/Toggle on.svg';
import ToggleOff from '../../../assets/icons/toggle off.svg';
import { colors, THEMES } from '../../../constants';
import { Creators as configCreators } from '../../../config/action';
import { arrowRightBlack, mode, language, settingsBlack } from '../../../assets/icons';

const SettingsDropdown = () => {
  const [toggleIcon, setToggleIcon] = useState(ToggleOff);
  const themeMode = useSelector((state) => state.configData.theme);
  const settingsDropdown = useSelector((state) => state.home.settingsDropdown);

  const settingData = [
    { label: 'Dark Mode', value: 'darkMode', disable: false, icon: toggleIcon, labelIcon: mode },
    // { label: 'Language', value: 'language', icon: arrowRightBlack, labelIcon: language },
    { label: 'User Settings', value: 'userSettings', labelIcon: settingsBlack }
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    if (themeMode === 'dark') {
      setToggleIcon(ToggleOn);
    } else {
      setToggleIcon(ToggleOff);
    }
  }, [themeMode]);

  const closeModal = () => {
    if (settingsDropdown) {
      dispatch(LayoutCreators.toggleHeaderOption('settingsDropdown'));
    }
  };

  const handleClick = (value) => {
    let newTheme;
    switch (value) {
      case 'darkMode':
        newTheme = themeMode === THEMES.LIGHT ? THEMES.DARK : THEMES.LIGHT;
        if (newTheme === THEMES.LIGHT) {
          setToggleIcon(ToggleOff);
        } else {
          setToggleIcon(ToggleOn);
        }
        dispatch(configCreators.setThemeColors({ themeMode: newTheme }));
        dispatch(configCreators.setTheme(newTheme));
        break;

      case 'userSettings':
        dispatch(LayoutCreators.toggleHeaderOption('settingsDropdown'));
        dispatch(LayoutCreators.settingsActiveMenu('General'));
        break;

      default:
        break;
    }
  };

  return <PointedDropdownModal data={settingData} closeModal={() => null} handleClick={handleClick} />;
};

export default SettingsDropdown;
