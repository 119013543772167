import styled from 'styled-components';
import { fontSize } from '../../../constants';
import { FlexContainer } from '../../../globalStyles';

export const Header = styled(FlexContainer)`
  padding: 2rem;
  border-bottom: 1px solid #70707080;
  width: 100%;
  & + .formContainer {
    padding: 2rem;
    overflow-y: auto;
  }
`;

export const HeaderContainer = styled.div`
  position: relative;
  width: 100%;
  & ${Header}.fixed {
    height: 8rem;
    position: fixed;
    z-index: 150;
    padding-right: 3rem;
    width: ${({ showSideNav }) => (showSideNav ? 'calc(100% - 300px)' : 'calc(100% - 75px)')};
    top: 70px;
    background: ${({ theme }) => theme.core.secondary};
  }
  // pushing the adjacent div by 8rem
  &.fixed + div {
    margin-top: 8rem;
  }
`;

export const HeaderText = styled.span`
  position: relative;
  display: flex;
  justify-content: center;
  /* font-size: ${fontSize.title}; */
  font-size: 2.2rem;
  &.inner {
    cursor: pointer;
  }
  /* &.active {
    background-color: red;
  } */
`;
