/* eslint-disable no-confusing-arrow */
/* eslint-disable indent */
/* eslint-disable max-len */
import styled from 'styled-components';
import { fontFamily, fontSize } from '../../../constants';

export const Tr = styled.tr`
  box-shadow: ${({ theme }) => theme.shadowout};
  border-radius: 1rem;

  &.spareTable {
    box-shadow: none;
    background: #f2f3f8 0% 0% no-repeat padding-box;
    & > td:first-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background-color: #eaebf2;
      color: #202020;
    }
    & > td:last-child {
      border-top-right-radius: 0rem;
      border-bottom-right-radius: 0rem;
    }
    & > td {
      background-color: #eaebf2;
      color: #202020;
    }
  }
  &.header {
    box-shadow: none;
    border-radius: 0;
  }

  &.child {
    padding-left: 3rem;
  }

  & > td:first-child {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  & > th:last-child {
    border-right: 0;
  }

  & > td:last-child {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    border: 0;

    ::after {
      border: 0;
    }
    ::before {
      border: 0;
    }
  }
  position: relative;
  &.nested {
    display: none;
  }

  &.shortlistedAssetsTable {
    display: none;
  }
`;

export const Th = styled.th`
  font-size: ${fontSize.text};
  font-family: ${fontFamily.circularBook};
  color: ${({ theme }) => theme.text.gray};
  padding-bottom: 1rem;
  /* border-right: ${({ theme, border }) =>
    border ? `1px dashed ${theme.contrast.lightSecondary}` : 0}; */
  border-right: ${({ theme, noBorder }) =>
    noBorder ? 0 : `1px dashed ${theme.contrast.lightSecondary}`};
  border-bottom: 1px solid ${({ theme }) => theme.contrast.lightSecondary};
  text-transform: capitalize;

  & > * {
    font-size: ${fontSize.text};
  }
  & p {
    font-size: 1.8rem;
  }
  &.spareTable {
    height: 6rem;
    background: #f2f3f8 0% 0% no-repeat padding-box;
    border-bottom: 1px dashed #cbcbcf;
    color: #717171;
  }
`;

export const Td = styled.td`
  /* max-height: 8rem; */
  background-color: ${({ theme }) => theme.cardBg};
  font-size: ${fontSize.text};
  color: ${({ theme }) => theme.text.primary};
  /* padding: 0rem 2rem; */
  padding: ${({ removeVerticalPadding }) =>
    removeVerticalPadding ? '0.5rem 1rem' : '2rem 1rem'};
  /* border-right: ${({ theme, border }) =>
    border ? `1px dashed ${theme.contrast.lightSecondary}` : 0}; */
  /* border-right: ${({ theme, noBorder }) =>
    noBorder ? 0 : `1px dashed ${theme.contrast.lightSecondary}`}; */
  border-right: ${({ theme, noBorder }) =>
    noBorder
      ? '0px solid black'
      : `1px dashed ${theme.contrast.lightSecondary}`};
  position: relative;
  text-align: center;
  /* width: 100px; */

  &.spareTable {
    background: #eaebf2 0% 0% no-repeat padding-box;
  }
  & p {
    font-size: ${fontSize.text};
  }

  &.noPadding {
    padding: 0rem;
  }

  /* &::before {
    content: '';
    height: 2rem;
    width: 1px;
    border-right: ${({ theme, border }) =>
    border ? `0.25px dashed ${theme.contrast.lightSecondary}` : 0};
    position: absolute;
    right: -1px;
    top: -14px;
  } */

  /* &::after {
    content: '';
    height: 2rem;
    width: 1px;
    border-right: ${({ theme, border }) =>
    border ? `0.25px dashed ${theme.contrast.lightSecondary}` : 0};
    position: absolute;
    right: -1px;
    bottom: -14px;
  } */
`;

export const Table = styled.table`
  width: 100%;

  &.nested {
    width: 90%;
    position: absolute;
    border-spacing: 0rem 2rem;
  }
  border-collapse: separate;
  border-spacing: 0rem 1.2rem;
  & > td,
  th {
    vertical-align: middle;
  }

  &.attached {
    border-spacing: 0px;

    & p {
      font-size: 1.8rem;
    }

    & .clip {
      display: block;
      height: 100%;
      overflow-y: auto;
    }

    & tr {
      border-bottom: 2px solid ${({ theme }) => theme.aux.secondary};
      background-color: ${({ theme }) => theme.table.normal};
      box-shadow: none;

      &.header {
        background-color: ${({ theme }) => theme.table.header};
        border-bottom: 1px dashed
          ${({ theme }) => theme.contrast.lightSecondary};
        position: sticky;
        top: -1rem;
        z-index: 1;
      }

      &.fillColor td {
        background-color: ${({ theme, color }) =>
          color || theme.core.secondary} !important;
      }
      &.active td {
        background-color: ${({ theme }) => theme.table.hightlight};
      }
    }

    & th,
    td {
      color: ${({ theme }) => theme.contrast.lightQuaternary};
      text-transform: capitalize;
      font-size: 1.8rem;
      border-right: ${({ theme, noBorder }) =>
        noBorder ? '0px' : `1px dashed ${theme.contrast.lightSecondary}`};
      border-radius: 0px !important;
      background-color: ${({ theme }) => theme.core.secondary};
      padding: 2rem 1rem;
    }

    td {
      border-bottom: 2px solid white;
    }

    td.groupHeading {
      border-bottom: 1px solid ${({ theme }) => theme.contrast.lightSecondary};
      border-right: none;
    }

    & th {
      color: ${({ theme }) => theme.contrast.darkSecondary};
    }
  }

  &.nested {
    &:first-child {
      background: red;
    }
  }
`;

export const ToogleGroupICon = styled.div`
  /* position: absolute;
  left: 2.5rem; */
  padding: 3px 5px;
  font-size: 2rem;
  cursor: pointer;

  & path:last-child {
    fill: ${({ theme }) => theme.contrast.quaternary} !important;
  }

  & path:first-child {
    fill: ${({ theme }) => theme.core.pureSecondary} !important;
  }
`;
