/* eslint-disable no-console */
/* eslint-disable max-len */
import { put } from 'redux-saga/effects';
import {
  setAccessTokenToHeader,
  setCustomHeader
} from '../../../services/axios/helper';
import { WebService } from '../../../services/axios/webServices';
import { Creators } from './action';
import { setUserDataToLocal } from '../../../shared/utility/helper';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { encodeUserDetails } from '../../../shared/utility';

export function* loginSaga({ payload }) {
  const { loginDetails, showChangePwdModal } = payload;
  try {
    yield put(Creators.onLoginStart());
    const config = {
      headers: {
        ...WebService.defaults.headers,
        Authorization: `Basic ${encodeUserDetails({
          userid: loginDetails.email,
          password: loginDetails.password
        })}`
      }
    };
    const loginResp = yield WebService.post(
      apiEndPoints.authenticate,
      {},
      config
    );
    // on 206 show change password model
    if (loginResp.status === 206) {
      showChangePwdModal(
        loginResp.data.data.message,
        loginResp.data.data.meta.keyField,
        loginResp.data.data.userData
      );
    } else if (loginResp.status >= 200 && loginResp.status < 300) {
      const { token, userData, meta } = loginResp?.data?.data;
      const userDetailsObj = userData;
      // set auth token to axios header
      yield setAccessTokenToHeader(loginDetails.email);
      yield setCustomHeader(userDetailsObj[meta.keyField]);
      // set access toke to store
      // yield put(Creators.setUserDetails({ token, userData: { ...testObj, email: testObj.mail } }));
      // this passed in auth header during api calls
      const uName = meta?.key ? userDetailsObj[meta?.key] : loginDetails.email;
      const email = userDetailsObj.mail;
      // local storage of token
      setUserDataToLocal({
        token,
        userData: { ...userDetailsObj, email, uName },
        meta,
        keyField: meta.keyField
      });

      yield put(
        Creators.setUserDetails({
          token,
          userData: { ...userDetailsObj, email, uName },
          meta,
          keyField: meta?.keyField
        })
      );
      yield put(Creators.onLoginSuccess());
    } else {
      throw loginResp;
    }
  } catch (error) {
    console.log('login erro', error);
    if (error?.response?.status) {
      switch (error.response.status) {
        case 400:
          // eslint-disable-next-line max-len
          yield put(
            Creators.onLoginFail({
              error:
                error?.response?.data?.data?.message ||
                error?.response?.data?.message ||
                'Bad request'
            })
          );
          break;
        case 500:
          yield put(
            Creators.onLoginFail({ error: 'Somthing went wrong try later' })
          );
          break;
        default:
          yield put(
            Creators.onLoginFail({ error: 'Unable to update. Try again later' })
          );
      }
    } else {
      yield put(
        Creators.onLoginFail({ error: 'No connection try again Later.' })
      );
    }
  }
}

export function* carouselDataSaga({ payload }) {
  try {
    yield put(Creators.getCarouselDataStart());
    // endpoint
    // params for Api request
    const params = { companyId: payload.companyId, lang: 'eng' };
    const carouselDataResp = yield WebService.get(apiEndPoints.CarouselData, {
      params
    });
    if (carouselDataResp.status >= 200 && carouselDataResp.status < 300) {
      // data keys
      const Data = carouselDataResp?.data?.data;
      // console.log(carouselDataResp, 'carouselDataResp');
      // set access toke to store
      yield put(Creators.setCarouselData({ Data }));
      yield put(Creators.getCarouselDataSuccess());
    } else {
      throw carouselDataResp;
    }
  } catch (error) {
    if (error?.response?.status) {
      switch (error.response.status) {
        case 400:
          // eslint-disable-next-line max-len
          yield put(
            Creators.getCarouselDataFail({
              error: error?.response?.data?.message
            })
          );
          break;
        case 500:
          yield put(
            Creators.getCarouselDataFail({
              error: 'Somthing went wrong try later'
            })
          );
          break;
        default:
          yield put(
            Creators.getCarouselDataFail({
              error: 'Unable to update. Try again later'
            })
          );
      }
    } else {
      yield put(
        Creators.getCarouselDataFail({
          error: 'No connection try again Later.'
        })
      );
    }
  }
}

export function* notificationDataSaga({ payload }) {
  try {
    yield put(Creators.getNotificationDataStart());
    // endpoint
    // params for Api request
    const params = { companyId: payload.companyId };
    const notificationDataResp = yield WebService.get(
      apiEndPoints.notifcationData,
      { params }
    );
    if (
      notificationDataResp.status >= 200 &&
      notificationDataResp.status < 300
    ) {
      // data keys
      const Data = notificationDataResp?.data?.data;

      // set access toke to store
      yield put(Creators.setNotificationData(Data));
      yield put(Creators.getNotificationDataSuccess());
    } else {
      throw notificationDataResp;
    }
  } catch (error) {
    if (error?.response?.status) {
      switch (error.response.status) {
        case 400:
          // eslint-disable-next-line max-len
          yield put(
            Creators.getNotificationDataFail({
              error: error?.response?.data?.message
            })
          );
          break;
        case 500:
          yield put(
            Creators.getNotificationDataFail({
              error: 'Somthing went wrong try later'
            })
          );
          break;
        default:
          yield put(
            Creators.getNotificationDataFail({
              error: 'Unable to update. Try again later'
            })
          );
      }
    } else {
      yield put(
        Creators.getNotificationDataFail({
          error: 'No connection try again Later.'
        })
      );
    }
  }
}

export function* onLogoutSaga() {
  try {
    setUserDataToLocal();
    yield put(Creators.onLogoutSuccess());
  } catch (error) {
    console.log(error);
  }
}
