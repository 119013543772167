/* eslint-disable max-len */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import AddEditFormDynamic from '../AddEditForm/AddEditFormDynamic';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { WebService } from '../../../services/axios/webServices';
import { SUCCESS } from '../../../constants';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';
import { isJsonString } from '../../Common/GenericForm/utils';
import Toast from '../../Common/Toast';
import PopUpModal from '../../Common/PopUpModal/Modal.view';
import { Heading } from '../../Common/PopUpModal/Modal.style';
import TabsTable from '../../Common/Tabs_Table';
import ButtonComp from '../../Common/Button/Button.view';

const EditScheduleDynamic = () => {
  const [error, setError] = useState('');
  const [plansModal, setPlansModal] = useState(false);
  const [sheduleDetails, setSheduleDetails] = useState({});

  const [newUploadInput, setNewUploadInput] = useState({});
  const history = useHistory();

  const DisplayMessage = useSelector(
    (state) => state.scheduleData.DisplayMessage
  );
  const plans = useSelector((state) => state.scheduleData.plan);
  const scheduleData = useSelector((state) => state.scheduleData.details);

  useEffect(() => {
    if (!isEmpty(scheduleData)) {
      setSheduleDetails(scheduleData);
    }
  }, [scheduleData]);

  const editSchedule = async (ele, setSubmitLoader) => {
    setSubmitLoader(true);
    if (plans === '' || isEmpty(plans)) {
      const newinput = {
        data: [ele],
        message: 'success'
      };
      try {
        const fullUrl = `${apiEndPoints.updateSchedule}`;
        // eslint-disable-next-line prefer-const, object-shorthand
        let res = await WebService.post(fullUrl, JSON.stringify(newinput));
        if (res.data?.message === SUCCESS) {
          history.push({
            pathname: '/home/schedule/details',
            state: { details: ele, scheduleType: true }
          });
        } else {
          setError(res.data?.message);
        }
      } catch (err) {
        console.log('error facing location:', err);
        const payload = sagaCatchBlockHandling(err);
        setError(payload?.error);
      } finally {
        setSubmitLoader(false);
      }
    } else {
      setSubmitLoader(false);
      setNewUploadInput(ele);
      setPlansModal(true);
    }
  };

  useEffect(() => {
    let formatedData;
    if (scheduleData) {
      Object.keys(scheduleData).forEach((key) => {
        if (isJsonString(scheduleData[key])) {
          formatedData = {
            ...formatedData,
            [key]: JSON.parse(scheduleData[key])
          };
        } else {
          formatedData = {
            ...formatedData,
            [key]: scheduleData[key]
          };
        }
      });
      setSheduleDetails(formatedData);
    }
  }, [scheduleData]);

  const updatePlan = async () => {
    // eslint-disable-next-line prefer-const
    const newinput = {
      data: [newUploadInput],
      plans: plans[0],
      message: 'success'
    };
    try {
      const fullUrl = `${apiEndPoints.updateSchedule}`;
      // eslint-disable-next-line prefer-const, object-shorthand
      const res = await WebService.post(fullUrl, JSON.stringify(newinput));
      if (res.data?.message === SUCCESS) {
        setPlansModal(false);
        history.push({
          pathname: '/home/schedule/details',
          state: { details: scheduleData, scheduleType: true }
        });
      } else {
        setError(res.data?.message);
      }
    } catch (err) {
      console.log('error facing location:', err);
      const payload = sagaCatchBlockHandling(err);
      setError(payload?.error);
    } finally {
      setPlansModal(false);
    }
  };

  const plansMeta = {
    columns: [
      {
        key: 'equipmentName',
        title: 'equipment Name',
        sortable: false,
        searchable: false,
        visible: true,
        options: []
      },
      {
        key: 'startDate',
        title: 'Date',
        searchable: false,
        visible: true,
        options: []
      },
      {
        key: 'startTime',
        title: 'Time',
        searchable: false,
        visible: true,
        options: []
      },
      {
        key: 'UpdatedBy',
        title: 'Updated By',
        searchable: false,
        visible: true,
        options: []
      },
      {
        key: 'UpdatedOn',
        title: 'Updated On',
        searchable: false,
        visible: true,
        options: []
      }
    ]
  };

  return (
    <>
      <AddEditFormDynamic
        data={sheduleDetails}
        isEdit={true}
        handleSave={editSchedule}
      />
      {error && (
        <Toast
          message={error}
          header='Error'
          style={{ right: 30, zIndex: 20 }}
          fn={() => setError('')}
        />
      )}
      {plansModal && (
        <PopUpModal
          closeModalHandler={() => {
            setPlansModal(false);
          }}
          width='60%'
        >
          <Heading>{DisplayMessage}</Heading>
          <TabsTable
            metaDetails={plansMeta}
            dataDetails={plans[0]}
            showPagiation={false}
            showShortlistSelection={false}
            noItemsFoundMessage='No Plans Found'
            showRowOptionAtStart={false}
            onItemSelect={() => {}}
            id='UserID'
          />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ButtonComp onClick={() => setPlansModal(false)}>Cancel</ButtonComp>
            <ButtonComp
              style={{ margin: '0px 2.2rem 0px' }}
              dark
              onClick={updatePlan}
            >
              Update
            </ButtonComp>
          </div>
        </PopUpModal>
      )}
    </>
  );
};

export default EditScheduleDynamic;
