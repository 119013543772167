/* eslint-disable max-len */
import { createReducer } from 'reduxsauce';
import { spareDetails } from '../../../../constants/sampleData';
import { Types } from './action';

const INITIAL_STATE = {
  isLoading: false,
  error: '',
  sparesListMeta: [],
  sparesListData: [],
  sparesSearchQueries: {},
  spareDetailedInfo: {
    data: {},
    field: [],
    spare_name: '',
    GRN_id: '',
    quantity: '',
    store: '',
    category: '',
    added: '',
    last_updated: '',
    attachments: spareDetails.attachments,
    issue_history: spareDetails.issue_history,
    isLoading: false,

    spareListOptions: [],
    bulkDocumentListData: [],
    bulkDocumentListMeta: []
  }
};

const getSpareListStart = (state = INITIAL_STATE, action) => ({
  ...state,
  isLoading: true
});
const getSpareListSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  isLoading: false,
  sparesListMeta: action.payload.meta,
  sparesListData: action.payload.SpareList
});
const getSpareListFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isLoading: false,
  error: action.payload.error
});

const setSpareDetailsStart = (state = INITIAL_STATE, action) => ({
  ...state,
  spareDetailedInfo: { ...INITIAL_STATE.spareDetailedInfo, isLoading: true }
});

const setSpareDetailsSuccess = (state = INITIAL_STATE, action) => {
  const { spareDetailedInfo } = action.payload;
  return {
    ...state,
    spareDetailedInfo: { ...spareDetailedInfo, isLoading: false }
  };
};

const setSpareDetailsFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    spareDetailedInfo: { ...state.spareDetailedInfo, isLoading: false, error }
  };
};

const getSpareListMenuStart = (state = INITIAL_STATE, action) => ({
  ...state,
  isLoading: true
});

const getSpareListMenuSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  isLoading: false,
  spareListOptions: action?.payload?.data
});
const getSpareListMenuFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isLoading: false,
  error: action.payload.error
});

const setSparesSearchQueries = (state = INITIAL_STATE, action) => ({
  ...state,
  sparesSearchQueries: { ...action.payload }
});

const resetSparesSearchQueries = (state = INITIAL_STATE) => ({
  ...state,
  sparesSearchQueries: INITIAL_STATE.sparesSearchQueries
});

const getBulkDocumentListStart = (state = INITIAL_STATE, action) => ({
  ...state,
  isLoading: true
});
const getBulkDocumentListSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  isLoading: false,
  bulkDocumentListMeta: action.payload.meta,
  bulkDocumentListData: action.payload.bulkList
});
const getBulkDocumentListFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isLoading: false,
  error: action.payload.error
});

const HANDLERS = {
  [Types.SET_SPARE_DETAILS_START]: setSpareDetailsStart,
  [Types.SET_SPARE_DETAILS_SUCCESS]: setSpareDetailsSuccess,
  [Types.SET_SPARE_DETAILS_FAILURE]: setSpareDetailsFailure,
  [Types.GET_SPARE_LIST_START]: getSpareListStart,
  [Types.GET_SPARE_LIST_SUCCESS]: getSpareListSuccess,
  [Types.GET_SPARE_LIST_FAILURE]: getSpareListFailure,
  [Types.SET_SPARES_SEARCH_QUERIES]: setSparesSearchQueries,
  [Types.RESET_SPARES_SEARCH_QUERIES]: resetSparesSearchQueries,

  [Types.GET_SPARE_LIST_MENU_SUCCESS]: getSpareListMenuSuccess,
  [Types.GET_SPARE_LIST_MENU_FAILURE]: getSpareListMenuFailure,
  [Types.GET_SPARE_LIST_MENU_START]: getSpareListMenuStart,

  [Types.GET_BULK_DOCUMENT_LIST_START]: getBulkDocumentListStart,
  [Types.GET_BULK_DOCUMENT_LIST_SUCCESS]: getBulkDocumentListSuccess,
  [Types.GET_BULK_DOCUMENT_LIST_FAILURE]: getBulkDocumentListFailure
};

export const spareDetailsReducer = createReducer(INITIAL_STATE, HANDLERS);
