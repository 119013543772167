/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Header,
  HeaderContainer
} from '../../../../component/Common/CommonStyles';
import Pagination from '../../../../component/Common/Pagination/Pagination.view';
import Filter from '../../../Filters/Filter.view';
// import { Creators as SpareDetailsCreators } from '../store';
import useGetSpares from '../useGetSpares';
import PaginationWithPage from '../../../../component/Common/Pagination/PaginationWithPage.view';
import { useActions } from '../../../../store/hooks/useActions';
import SpareBulkUpdateView from '../SpareBulkUpdateView';
import Toast from '../../../../component/Common/Toast';

function Spares({ menuId }) {
  const dispatch = useDispatch();
  const { sparesListMeta, sparesSearchQueries, spareListOptions } = useSelector(
    (state) => state.spareDetails
  );
  const { showFilterResult, filtersPayload } = useSelector(
    (state) => state.filterData
  );
  // const handlePageChange = (currentPage) => {
  //   if (filtersPayload) {
  //     dispatch(SpareDetailsCreators.getSpareList({ payload: filtersPayload, pagesize, page: currentPage }));
  //   }
  // };
  const { handlePageChange, pagesize } = useGetSpares();

  const [spareMenuOptions, setSpareMenuOptions] = useState([]);
  const [showUploadBulk, setShowUploadBulk] = useState(false);
  const [bulkUpdateDetails, setBulkUpdateDetails] = useState();

  const isMounted = useRef(false);
  useEffect(() => {
    if (isMounted.current) handlePageChange(1);
    else {
      isMounted.current = true;
    }
  }, [filtersPayload]);

  const { getSpareListMenu } = useActions();
  useEffect(() => {
    getSpareListMenu();
  }, []);

  useEffect(() => {
    const modifedList = [];
    spareListOptions?.forEach((spare) => {
      const spareItem = {
        label: spare?.Name,
        id: spare?.Id,
        role: spare?.Name,
        ...spare
      };
      modifedList.push(spareItem);
    });
    setSpareMenuOptions(modifedList);
  }, [spareListOptions]);
  const handleCloseUpdate = () => {
    setShowUploadBulk(false);
  };

  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  return (
    <div
      style={{
        height: '100%',
        overflowY: 'auto',
        padding: '1rem',
        overflowX: 'hidden'
      }}
    >
      <HeaderContainer>
        <Header>Spares</Header>
        {showFilterResult && (
          <PaginationWithPage
            ItemsPerPage={pagesize}
            total={sparesListMeta?.count}
            handlePageChange={handlePageChange}
            searchParams={sparesSearchQueries}
          />
        )}
      </HeaderContainer>
      <Filter
        subHeightBy={8}
        menuId={menuId}
        filterFor='spares'
        setResultTypeAs='spares'
        filterMenuOptions={spareMenuOptions}
        setUploadBulk={setShowUploadBulk}
        setBulkUpdateDetails={setBulkUpdateDetails}
      />
      {/* setResultTypeAs='spares' */}
      {showUploadBulk && (
        <SpareBulkUpdateView
          handleCloseUpdate={handleCloseUpdate}
          bulkUpdateDetails={bulkUpdateDetails}
          setError={setError}
          setSuccess={setSuccess}
        />
      )}
      {error && (
        <Toast
          message={error}
          header='Error'
          style={{ right: 30 }}
          fn={() => setError('')}
        />
      )}
      {success && (
        <Toast
          message={success}
          header='Success'
          style={{ right: 30 }}
          fn={() => setSuccess('')}
        />
      )}
    </div>
  );
}

export default Spares;
