/* eslint-disable max-len */
import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Creators as UpsertWorkOrderCreators } from '../store';
import Loader from '../../../Common/Loader/Loader';
// import { assetListData, assetListMeta } from '../../../../constants/sampleData';
import TabsTable from '../../../Common/Tabs_Table';
// import { Header } from '../CreateWorkorder.style';
import { Div, FlexContainer } from '../../../../globalStyles';
import DynamicSVGIcon from '../../../Common/DynamicSVGIcon';
import DropArrow from '../../../../assets/icons/layout/dropdownarrow.svg';
import { HeaderText } from '../../WorkOrderDetails/WorkOrderDetails.style';
// import { Bttn } from '../../../../views/WorkOrders/WorkOrders.styles';
import Pagination from '../../../Common/Pagination/Pagination.view';
import { ItemsNo, Bttn } from './AssetSelection.style';
import { WorkOrderCreators } from '../../../../views/WorkOrders/store';
import { Creators as FilterCreators } from '../../../../views/Filters/store';
import { WO_TYPES } from '../../../../constants';
import SpareDetails from '../../../Stores/Spares/SpareDetails/SpareDetails.view';
import Header from '../Header';
import CartButton from '../../../Common/CartButton/CartButton';
import { isRowMatched } from '../../../Common/Tabs_Table/utility';

const AssetSelection = () => {
  const dispatch = useDispatch();
  const { showFilterResult, filtersPayload, assetType } = useSelector((state) => state.filterData);
  const {
    type,
    assetListLoading,
    assetShortlist,
    assetListMeta,
    assetListData,
    assetSearchQueries,
    workOrderCreationFrom
  } = useSelector((state) => state.upsertWorkOrder);
  const planDetails = useSelector((state) => state.plansData.planDetails);

  const setAssetSearchQueries = (searchQueries) => {
    dispatch(UpsertWorkOrderCreators.setAssetSearchQueries(searchQueries));
  };

  const pageSize = 10;
  const handlePageChange = (currentPage, rest = {}) => {
    const { searchParams } = rest;
    if (filtersPayload) {
      dispatch(
        UpsertWorkOrderCreators.getAssetList({
          filtersPayload,
          pageSize,
          pageNo: currentPage,
          WFTid: type.workflowTemplateId,
          searchParams
        })
      );
    }
  };

  useEffect(() => {
    // dispatch(UpsertWorkOrderCreators.resetAssetShortlist());
  }, []);

  const isMounted = useRef(false);
  useEffect(() => {
    if (isMounted.current) handlePageChange(1);
    else {
      isMounted.current = true;
    }
  }, [filtersPayload]);
  const history = useHistory();
  const handleNext = () => {
    dispatch(
      WorkOrderCreators.getUserDataByTemplateId({
        WFTid: type.workflowTemplateId,
        assetType,
        workOrderCreationFrom,
        scheduleId: planDetails.scheduleId,
        selections: assetShortlist
      })
    );
    history.push('/home/work_orders/create_WO/form');
  };

  // console.log('assetListLoading, assetListMeta, assetListData', assetListLoading, assetListMeta, assetListData);
  // if (assetListLoading) return <Loader />;
  // duplicate
  const handleShortList = (entry) => {
    const check = assetShortlist.some((ele) => isRowMatched(ele, entry));
    if (!check) {
      dispatch(UpsertWorkOrderCreators.setAssetShortlist([...assetShortlist, entry]));
    } else {
      dispatch(
        UpsertWorkOrderCreators.setAssetShortlist(
          assetShortlist.filter(
            (ele) => !(ele.mainPartNumber === entry.mainPartNumber && ele.inventPartnumber === entry.inventPartnumber)
          )
        )
      );
    }
  };

  const handleBack = () => {
    dispatch(
      FilterCreators.universalFilterSetter({
        key: 'showFilterResult',
        value: false
      })
    );
  };

  // for spare details modal...
  const [showSpareDetailsModal, setShowSpareDetailsModal] = useState(false);
  const [currentSpareId, setCurrentSpareId] = useState('');
  const handleShowDetails = (spare) => {
    setCurrentSpareId(spare.mainPartNumber);
    setShowSpareDetailsModal(true);
  };

  const resetSearchQueries = () => {
    dispatch(UpsertWorkOrderCreators.resetAssetSearchQueries());
  };

  useCallback((payload) => dispatch(UpsertWorkOrderCreators.setAssetShortlist(payload)), [dispatch]);
  return (
    <div style={{ flex: 1, overflowY: 'auto' }}>
      <Header handleBack={handleBack}>
        {/*  name='Next' onClick={handleNext} disableNext={isDisabled} */}
        <FlexContainer className='ai_center'>
          <Bttn className='white' onClick={handleBack}>
            Cancel
          </Bttn>
          <CartButton
            showShortlistHandler={() => {
              history.push('/home/asset-shortlist');
            }}
            shortlistCount={assetShortlist.length}
            onNextHandler={handleNext}
            nextLabel='Next'
          />
        </FlexContainer>
      </Header>

      <TabsTable
        handleShortList={handleShortList}
        handlePageChange={handlePageChange}
        assetShortlist={assetShortlist}
        handleTabSelect={() => {}}
        pageSize={pageSize}
        metaDetails={assetListMeta}
        dataDetails={assetListData}
        assetListLoading={assetListLoading}
        onItemSelect={handleShowDetails}
        saveSearchQueries={setAssetSearchQueries}
        savedSearchQueries={assetSearchQueries}
        resetSearchQueries={resetSearchQueries}
      />
      {
        // type.Type === WO_TYPES.SPARE_REQUEST &&
        showSpareDetailsModal && <SpareDetails onCancel={setShowSpareDetailsModal} id={currentSpareId} />
      }
    </div>
  );
};

export default AssetSelection;
