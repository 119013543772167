/* eslint-disable max-len */
import React from 'react';
import { FlexContainer } from '../../../globalStyles';
import { Input } from '../Input/Input';
import { DropBtn } from './DropDownModal.style';
import { ReactComponent as Arrow } from '../../../assets/icons/layout/dropdownarrow.svg';

const InputDropdown = (props) => {
  const {
    keyName, // used to manage dropdown visibilty
    value,
    handleDropdownVisibility,
    width,
    placeholder,
    isEdit = false,
  } = props;
  // adding is Edit key and disabling feature as per request in Jira id : https://vistrian.atlassian.net/browse/UX22-206
  return (
    <div>
      <FlexContainer
        className='can-disable-input'
        style={{ position: 'relative', opacity: isEdit ? '0.5' : '1' }}
        onClick={isEdit ? () => { } : () => handleDropdownVisibility(keyName)}
      >
        <Input disabled={isEdit} width={width} placeholder={placeholder} value={value} />
        <DropBtn className='ai_center jc_center'>
          <Arrow />
        </DropBtn>
      </FlexContainer>
    </div>
  );
};

export default InputDropdown;
