/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useMemo, useState, useEffect, useRef } from 'react';
import { INPUT_TYPES } from '../../../../constants';
import { WebService } from '../../../../services/axios/webServices';
import { useActions } from '../../../../store/hooks/useActions';
import { sagaCatchBlockHandling } from '../../../../store/utility/sagaErrorHandling';
import DropDownModal from '../../DropDownModal/DropdDownModal_V2';
import InputDropdown from '../../DropDownModal/InputDropdown';
import ToggleableFieldValue from '../../GenericForm/ToggleableFieldValue';
import {
  getFetchDetailsOfWidgetData,
  getReqPayloadForDependentField
} from '../../GenericForm/utils';
import MultiselectInput from '../../MultiselectInput/MultiselectInput';

const DropdownTypeComponent = (props) => {
  const {
    item,
    handleClick,
    formStructure,
    value = {},
    formInput, // for handling dependency
    tobeRefetched = false, // check for refetching the data
    resetFieldsTobeRefetched = () => {}, // reset the FieldsTobeRefetched
    isEditable,
    multiDropdown = false,
    isUser = false
  } = props;
  const { key, label, placeholder, dataSource, isReadOnly } = item;
  const { data, dataKey, source, responseKeys, dependencies } = dataSource;
  const {
    setToastError
    // setFormStructureFailure
  } = useActions();
  const { labelKey, valueKey } = responseKeys || {};
  const labelKeyFinal = labelKey || 'label';
  const [dpData, setDpData] = useState(data);
  const [multiRole, setMultiRole] = useState(0);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userData, setUserData] = useState(null);
  const selectedItemLabel = useMemo(() => {
    if (typeof value !== 'object') {
      const obj = dpData?.find((e) => {
        const res =
          e.value?.toString()?.toLowerCase() ===
            value?.toString()?.toLowerCase() ||
          e.label?.toString()?.toLowerCase() ===
            value?.toString()?.toLowerCase();
        return res;
      });
      // TODO: mutating the state directly
      if (obj) {
        formInput[key] = obj;
      }
      return obj?.label;
    }
    return value?.[labelKey] || value?.label || '';
  }, [value, dpData]);
  const [isActive, setIsActive] = useState(false);
  const handleDropdownVisibility = () => {
    setIsActive(!isActive);
  };

  const fetchDetails = async (args) => {
    try {
      const { additionalPayload } = args || {};
      if (dataKey === 'source' && source) {
        // console.log(`fetching dp details of ${key}`);
        const fetchUrl = dataSource.source; // {method, UrlType, Url}
        const { fullURL, method } = getFetchDetailsOfWidgetData(fetchUrl);
        let payload = additionalPayload;
        if (method === 'get') {
          payload = { params: additionalPayload };
        }
        const resp = await WebService?.[method](fullURL, payload);
        if (resp.status >= 200 && resp.status < 300) {
          setDpData(resp.data?.data);
          setUserData(resp.data?.data);
          setMultiRole(resp?.data?.MultiRole);
        } else {
          throw resp;
        }
      }
    } catch (e) {
      const error = sagaCatchBlockHandling(e);
      console.log('e', e, error.error);
      setToastError(error.error);
      // setFormStructureFailure(error.error);
    } finally {
      resetFieldsTobeRefetched();
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  useEffect(() => {
    if (multiRole || !multiDropdown) {
      const modifiedString = formInput?.Roles?.replace(/\s/g, '');
      const rolesArray = modifiedString?.split(',');
      const selected = [];
      dpData?.forEach((data) => {
        if (rolesArray?.includes(data?.value.toString())) {
          selected.push(data);
        }
      });
      setSelectedUsers(selected);
    } else {
      setSelectedUsers(formInput[key] || []);
    }
  }, [dpData]);

  useEffect(() => {
    if (tobeRefetched) {
      // console.log('refetching....', key);
      const newPayload = getReqPayloadForDependentField(
        formInput,
        dependencies,
        formStructure
      );
      fetchDetails({ additionalPayload: newPayload });
    }
  }, [tobeRefetched]);
  // TODO: add loader for dropdown data if from api
  return (
    <div>
      {multiRole === 1 || multiDropdown ? (
        <div>
          <ToggleableFieldValue
            type={INPUT_TYPES.CHECKBOX}
            isEditable={isEditable}
            value={formInput?.[key]}
          >
            <MultiselectInput
              handleClick={handleClick}
              selectedData={selectedUsers}
              setselectedData={setSelectedUsers}
              suggestions={dpData}
              setSuggestions={setDpData}
              data={userData}
              itemKey={key === 'RoleId' ? [key, 'Roles'] : key}
              itemLabel={labelKeyFinal}
              itemValueKey={isUser ? 'id' : labelKeyFinal}
              placeholder={placeholder}
              item={item}
              module={multiRole && 'FormData'}
            />
          </ToggleableFieldValue>
        </div>
      ) : (
        <ToggleableFieldValue
          type={INPUT_TYPES.CHECKBOX}
          isEditable={isEditable}
          value={formInput?.[key]}
        >
          <DropDownModal
            setModalIsVisibal={setIsActive}
            width={window.innerWidth > 767 ? '58rem' : '100%'}
            data={dpData}
            isActive={isActive}
            handleClick={handleClick}
            itemKey={key}
            label={labelKeyFinal}
            singleSelection
            noContainerPadding={true}
            multiRole={multiRole}
          >
            <InputDropdown
              isReadOnly={isReadOnly}
              handleDropdownVisibility={handleDropdownVisibility}
              placeholder={placeholder || ''}
              value={selectedItemLabel}
            />
          </DropDownModal>
        </ToggleableFieldValue>
      )}
    </div>
  );
};

export default DropdownTypeComponent;
