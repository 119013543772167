/* eslint-disable max-len */
import React, { useRef } from 'react';
import styled from 'styled-components';
import { fontSize } from '../../../constants';
import { shadow } from '../../../constants/colors';

export const Input = styled.input`
  background-color: ${({ theme }) => theme.core.secondary};
  box-shadow: ${shadow.inputInset};
  border-radius: 1rem;
  border: 0rem;
  width: ${(props) => props.width || '100%'};
  height: 45px;
  padding-left: ${({ pl }) => pl || '2.1rem'};
  padding-right: ${({ pr }) => pr || '3.7rem'};
  color: ${({ theme }) => theme.black} !important;
  font-size: ${fontSize.text};

  &.gray {
    background-color: ${({ theme }) => theme.input.gray};
  }

  &::placeholder {
    color: #9f9f9f;
  }

  &.input {
    padding-left: 2rem;
    padding-right: 0.1rem;
    padding-top: 5px;
    text-transform: uppercase;
    color: #717171;
  }
  &.input::-webkit-calendar-picker-indicator {
    width: 5rem;
    padding: -10px;
    padding-bottom: 5px;
    font-size: 4.5rem;
    filter: invert(0.5);
  }

  &.timeInput::-webkit-calendar-picker-indicator {
    width: 4rem;
    font-size: 3rem;
    filter: invert(0.5);
  }

  &.read-only {
    background-color: #e1e2e8;
  }

  &:focus {
    outline: none;
  }

  & + .searchIcon {
    position: absolute;
    right: 8px;
  }
  ::placeholder {
    color: #717171;
    opacity: 0.5;
    font-size: ${fontSize.loginInputSize};
  }

  &.dateInput {
    width: 28.5rem;
  }

  &.input::-webkit-datetime-edit-ampm-field {
    display: none;
  }
`;
const Index = (props) => {
  const {
    disabled,
    placeholder,
    value,
    setValue,
    type,
    margin,
    width,
    variant,
    className,
    style
  } = props;
  const ref = useRef();
  return (
    <Input
      disabled={disabled}
      placeholder={placeholder || ''}
      value={value}
      onChange={(event) => setValue(event.target.value, value)}
      type={type}
      ref={ref}
      style={{ margin, ...style }}
      width={width}
      className={`${variant} ${className}`}
    />
  );
};

Index.defaultProps = {
  disabled: false,
  placeholder: 'Enter Text',
  value: '',
  setValue: () => null,
  type: 'text',
  width: '100%'
};

export default Index;
