/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import React from 'react';
import moment from 'moment';
import { CancelBlack } from '../../../../assets/icons';
import { FlexContainer } from '../../../../globalStyles';
import { isEmpty } from '../../../../shared/utility/isEmpty';
import DynamicSVGIcon from '../../DynamicSVGIcon';
import {
  EventContainer,
  EventContent,
  EventsSidePanelContainer,
  EventTitleContainer,
  HeaderContainer,
  Label,
  MainContainer,
  TimingContainer,
  Value
} from './EventsSidePanel.style';

const EventsSidePanel = ({ listDetails, eventSidePanelHandler, handleEventClick }) => {
  const { allSegs, date } = listDetails || {};
  const closeHandler = () => {
    eventSidePanelHandler({});
  };
  if (!date) {
    return <></>;
  }
  return (
    <EventsSidePanelContainer>
      <HeaderContainer>
        <FlexContainer>
          <Label>Date</Label>
          <Value>{date ? new Date(date).toDateString() : new Date().toDateString()}</Value>
        </FlexContainer>
        <div onClick={closeHandler}>
          <DynamicSVGIcon iconUrl={CancelBlack} width='1.8rem' />
        </div>
      </HeaderContainer>
      <MainContainer>
        {allSegs?.map((e, index) => (
          <Event key={index} handleEventClick={handleEventClick} eventDetails={e} />
        ))}
      </MainContainer>
    </EventsSidePanelContainer>
  );
};

function Event({ eventDetails, handleEventClick }) {
  const {
    event: {
      title,
      start,
      end,
      allDay,
      extendedProps: { details }
    }
  } = eventDetails;
  const { activity, asset, starttime, endtime, planId } = details || {};
  // TODO-2: start, end not reflecting with time(format events)
  // console.log('event', start, end, 'details', starttime, endtime);
  return (
    <EventContainer onClick={() => handleEventClick(eventDetails)}>
      <EventTitleContainer>
        {/* <EventContent>{title}</EventContent> */}
        {/* <EventContent>{planId}</EventContent> */}
        <EventContent>{asset}</EventContent>
        <EventContent>{activity}</EventContent>
      </EventTitleContainer>
      <TimingContainer>
        <span>{starttime}</span>
        {endtime ? (
          <>
            <div style={{ display: 'block', textAlign: 'center' }}>to</div>
            <span>{endtime}</span>
          </>
        ) : null}
      </TimingContainer>
    </EventContainer>
  );
}

export default EventsSidePanel;
