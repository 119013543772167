import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  getWorkOrders: ['payload'],
  getWorkOrdersStart: ['payload'],
  getWorkOrdersSuccess: ['payload'],
  getWorkOrdersFailure: ['payload'],
  setWorkOrderSearchQueries: ['payload'],
  resetWorkOrderSearchQueries: ['payload'],

  getWorkOrderTypes: ['payload'],
  getWorkOrderTypesStart: ['payload'],
  getWorkOrderTypesSuccess: ['payload'],
  getWorkOrderTypesFailure: ['payload'],

  getWorkOrderDetails: ['payload'],
  getWorkOrderDetailsStart: ['payload'],
  getWorkOrderDetailsSuccess: ['payload'],
  getWorkOrderDetailsFailure: ['payload'],
  resetWorkOrderDetails: ['payload'],

  getWorkOrdersCountByGroup: ['payload'],
  getWorkOrdersCountByGroupStart: ['payload'],
  getWorkOrdersCountByGroupSuccess: ['payload'],
  getWorkOrdersCountByGroupFailure: ['payload'],

  getFormStructure: ['payload'],
  getFormStructureStart: ['payload'],
  getFormStructureSuccess: ['payload'],
  getFormStructureFailure: ['payload'],

  getUserDataByTemplateId: ['payload'],
  getUserDataStart: ['payload'],
  getUserDataSuccess: ['payload'],
  getUserDataFailure: ['payload'],
  resetUserData: ['payload'],

  setWorkOrderFilter: ['payload'],

  getWOSidePanelDetails: ['payload'],
  getWOSidePanelDetailsStart: ['payload'],
  getWOSidePanelDetailsSuccess: ['payload'],
  getWOSidePanelDetailsFailure: ['payload'],

  getRemark: ['payload'],
  getRemarkStart: ['payload'],
  getRemarkSuccess: ['payload'],
  getRemarkFailure: ['payload']
});
