/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { UserImage, UserPill } from './MultiselectInput.Style';

const Pills = ({ image, text, onClick }) => (
  <UserPill onClick={onClick}>
    {/* <UserImage src={image} alt={text} /> */}
    <span>
      {text || ''}
      {' '}
      &times;
    </span>
  </UserPill>
);

export default Pills;
