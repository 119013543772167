/* eslint-disable max-len */
import { createActions } from 'reduxsauce';

export const { Types: ScheduleTypes, Creators: ScheduleCreators } = createActions({
  setSearchQueries: ['payload'],
  setNewSchedule: ['payload'],
  editSchedule: ['payload'],
  getSchedule: ['payload'],
  getScheduleStart: ['payload'],
  getScheduleSuccess: ['payload'],
  getScheduleFailure: ['payload'],
  getScheduleDetails: ['payload'],
  getScheduleDetailsStart: ['payload'],
  getScheduleDetailsSuccess: ['payload'],
  getScheduleDetailsFailure: ['payload'],
  createSchedule: ['payload'],
  updateSchedule: ['payload']
});
