/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch
} from 'react-router-dom';
import WorkOrderDetails from '../../component/WorkOrder/WorkOrderDetails/WorkOrderDetails.view';
import WorkOrdersList from './WorkOrders';
import CreateWORoutes from './createWORoutes';
import { WO_CREATION_FROM } from '../../constants';
import { fetchUserAndLocation } from '../../component/Sidenav/fetchPageStatistics';

const WorkOrders = () => {
  const { path } = useRouteMatch();
  const { type, workOrderCreationFrom } = useSelector(
    (state) => state.upsertWorkOrder
  );
  const location = useLocation();
  const { userData } = useSelector((state) => state.loginData);
  const { finalMenu } = useSelector((state) => state.home);

  useEffect(() => {
    const name = finalMenu.find((ele) => ele.path === location.pathname);
    fetchUserAndLocation(location.pathname, userData, {
      pageType: name?.name
    });
  }, []);

  return (
    <Switch>
      <Route
        path={`${path}/create_WO`}
        name='create_WO'
        // eslint-disable-next-line no-confusing-arrow
        render={(props) => {
          // from plan details use seperate page instead of popup
          const {
            location: { state }
          } = props;
          const { createdFrom } = state || {};
          return type?.workflowTemplateId ||
            createdFrom === WO_CREATION_FROM.PLAN_DETAILS ? (
            <CreateWORoutes createdFrom={createdFrom} {...props} />
          ) : (
            <Redirect to={`${path}`} />
          );
        }}
      />
      <Route
        path={`${path}/:type/:workOrderId`}
        exact
        name='create_WO'
        render={(props) => <WorkOrderDetails />}
      />
      <Route
        path='/'
        name='work_orders_list'
        render={(props) => <WorkOrdersList />}
      />
    </Switch>
  );
};

export default WorkOrders;
