/* eslint-disable max-len */

import { format as defaultFormat } from '../../config/index';

const moment = require('moment');

export const getFormatedTimestamp = (date, format = defaultFormat) => moment(date).format(format);

export const getSpecificDate = (month, dayOfMonth, year) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  moment(`${month}-${dayOfMonth}-${year}`, 'MM-DD-YYYY').toDate();

export const getDayOfMonth = (date) => moment(date).date();

export const getMonth = (date) => moment(date).month();

export const getYear = (date) => moment(date).year();

export const getToday = () => moment().toDate();

export const getReadableWeekday = (date) => moment(date).format('dddd');

export const getReadableMonthDate = (date) => moment(date).format('MMMM Do');

export const getDayMonthYear = (date) => moment(date).format('DD-MM-YYYY');

export const getMonthDayYear = (date) => moment(date).format('MM-DD-YYYY');

export const getYearMonthDay = (date) => moment(date).format('YYYY-MM-DD');

export const compareDates = (d1, d2) => moment(d1).isAfter(d2);

export const getDayWeekDay = (date) => moment(date).format('DD ddd');

export const getMonthYear = (date) => moment(date).format('MMM YY');

export const getMothSmall = (date) => moment(date).format('MMM');

export const getDay = (date) => moment(date).format('DD');

export const endOfMonth = () => moment().endOf('month');

export const getReadableDayMonthYear = (date) => moment(date).format('D MMM YY');

export const getYesterdayDate = () => moment().subtract(1, 'day');

export const ismoreThanWeek = (startDate, endDate) => {
  const start = moment(startDate);
  const end = moment(endDate);
  const diff = end.diff(start, 'days');
  return diff > 7;
};

export const getTime = (time) => moment(time).format('h:mm A');
export const getDateAtime = (date) => moment(date).format('DD-MM-YYYY HH:mm:ss');
export const getISOAddingTimezone = (date) => moment(date).format('YYYY-MM-DDTHH:mm:ss');
