import styled from 'styled-components';

export const BodyContainer = styled.div`
  background: #ffffff;
  box-shadow: -5px -5px 11px #fffffff5;
  border-radius: 10px;
  margin: 2rem;
  padding: 3.5rem;
  height: calc(100% - 11rem);
`;
