/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FlexContainer, IconContainer, Title } from '../../../globalStyles';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { WebService } from '../../../services/axios/webServices';
import { Modal } from '../../Common/PopUpModal/Modal.style';
import TabsTable from '../../Common/Tabs_Table';
import { TableContainer } from '../../Common/Tabs_Table/TabsTable.style';
import { Bttn } from '../CreateWorkOrder/AssetSelection/AssetSelection.style';
import { Creators as UpsertWorkOrderCreators } from '../CreateWorkOrder/store';
import { CountSpan, ModalListHeader } from './WorkOrderDetails.style';
import Loader from '../../Common/Loader/Loader';
import { TabsContainer } from '../../Common/Tabs/Tabs.styles';
import WoSelectedAsset from './WoSelectedAsset';
import { useActions } from '../../../store/hooks/useActions';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';
import Toast from '../../Common/Toast';
import PopUpModal from '../../Common/PopUpModal/Modal.view';
import DynamicSVGIcon from '../../Common/DynamicSVGIcon';
import { CancelBlack } from '../../../assets/icons';

const WoListModal = ({ setAssetListModal, loading }) => {
  const history = useHistory();
  const { assetShortlist, assetListMeta, assetListData, assetSearchQueries } = useSelector(
    (state) => state.upsertWorkOrder
  );
  const { workOrderId } = useSelector((state) => state.workOrders);
  const { filtersPayload } = useSelector((state) => state.filterData);
  const [showShortListData, setShowShortListData] = useState(false);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState('');

  const dispatch = useDispatch();
  const { getModalAssetsSuccess } = useActions();

  const handlePageChange = async (currentPage, rest) => {
    const { searchParams } = rest || {};

    setLoader(true);
    const fullUrl = `${apiEndPoints.GetJobSpares}`;
    const page = currentPage;
    const pageSize = 10;
    const initialPayload = { type: 'AddParts' };

    try {
      const res = await WebService.post(`${fullUrl}${workOrderId}/${page}/${pageSize}`, {
        SearchFilters: { ...initialPayload, ...searchParams }
      });

      if (res.status === 200) {
        const { meta, data } = res.data;
        dispatch(UpsertWorkOrderCreators.getAssetListSuccess({ assetListMeta: meta, assetListData: data }));
        setLoader(false);
      } else {
        throw res;
      }
    } catch (err) {
      console.log(err, 'error');
      const payload = sagaCatchBlockHandling(err);
      setError(payload?.error);
    } finally {
      setLoader(false);
    }
  };

  const isMounted = useRef(false);
  useEffect(() => {
    if (isMounted.current) handlePageChange(1);
    else {
      isMounted.current = true;
    }
  }, [filtersPayload]);

  const handleShortList = (entry) => {
    const check = assetShortlist.some(
      (ele) =>
        ele.mainPartNumber === entry.mainPartNumber &&
        ele.inventPartnumber === entry.inventPartnumber &&
        ele.qtyAvailable === entry.qtyAvailable
    );
    if (!check) {
      dispatch(UpsertWorkOrderCreators.setAssetShortlist([...assetShortlist, entry]));
    } else {
      dispatch(
        UpsertWorkOrderCreators.setAssetShortlist(
          assetShortlist.filter(
            (ele) => !(ele.mainPartNumber === entry.mainPartNumber && ele.inventPartnumber === entry.inventPartnumber)
          )
        )
      );
    }
  };
  const setAssetSearchQueries = (searchQueries) => {
    dispatch(UpsertWorkOrderCreators.setAssetSearchQueries(searchQueries));
  };
  const resetSearchQueries = () => {
    dispatch(UpsertWorkOrderCreators.resetAssetSearchQueries());
  };

  const handleCancel = () => {
    setAssetListModal(false);
    dispatch(UpsertWorkOrderCreators.setAssetShortlist([]));
  };

  const handleConfirm = () => {
    setShowShortListData(true);
  };

  const backHandler = () => {
    setShowShortListData(false);
  };
  return (
    <>
      <PopUpModal closeModalHandler={() => null} width='70%' style={{ height: '100%' }}>
        {/* TODO: repeated styles to match child */}
        <FlexContainer className='jc_spacebetween ai_center' style={{ width: '97%', margin: '0px auto' }}>
          <div>
            <Title className='block'>Add Parts</Title>
          </div>
          <IconContainer onClick={handleCancel}>
            <DynamicSVGIcon iconUrl={CancelBlack} />
          </IconContainer>
        </FlexContainer>
        {showShortListData ? (
          <WoSelectedAsset backHandler={backHandler} setAssetListModal={setAssetListModal} />
        ) : (
          //  actual list
          <>
            {loading ? (
              <FlexContainer className='jc_center'>
                <Loader />
              </FlexContainer>
            ) : (
              <FlexContainer style={{ height: '90%', overflow: 'auto' }} className='jc_center'>
                <TabsTable
                  handleShortList={handleShortList}
                  metaDetails={assetListMeta}
                  dataDetails={assetListData}
                  assetShortlist={assetShortlist}
                  handleTabSelect={() => {}}
                  saveSearchQueries={setAssetSearchQueries}
                  assetListLoading={loader}
                  savedSearchQueries={assetSearchQueries}
                  resetSearchQueries={resetSearchQueries}
                  handlePageChange={handlePageChange}
                  pageSize={10}
                />
              </FlexContainer>
            )}
            <FlexContainer className='jc_end ac_center' style={{ gap: '2rem' }}>
              <Bttn dark onClick={handleCancel}>
                Cancel
              </Bttn>
              <Bttn dark onClick={handleConfirm} disabled={assetShortlist?.length < 1}>
                Next
                <CountSpan>{` ${assetShortlist.length}`}</CountSpan>
              </Bttn>
            </FlexContainer>
          </>
        )}
      </PopUpModal>
      {error && <Toast message={error} header='Error' style={{ right: 30, zIndex: 20 }} fn={() => setError('')} />}
    </>
  );
};

export default WoListModal;
