/* eslint-disable max-len */
import React, { useRef } from 'react';
import styled from 'styled-components';
import { fontSize } from '../../../constants';
import { shadow } from '../../../constants/colors';

export const Input = styled.input`
  background-color: ${({ theme }) => theme.core.secondary};
  box-shadow: ${shadow.inputInset};
  border-radius: 1rem;
  border: 0rem;
  width: ${(props) => props.width};
  height: 45px;
  padding-left: 2.1rem;
  padding-right: 3.7rem;
  color: ${({ theme }) => theme.black};
  font-size: ${fontSize.text};

  &.gray {
    background-color: ${({ theme }) => theme.input.gray};
  }

  &.read-only {
    background-color: #e1e2e8;
  }

  &:focus {
    outline: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.placeHolderText};
    font-size: ${fontSize.loginInputSize};
  }
`;

const Index = (props) => {
  const { disabled, placeholder, value, setValue, inputKey, type, margin, width, variant, className, style } = props;
  const ref = useRef();
  return (
    <Input
      disabled={disabled}
      placeholder={placeholder || ''}
      value={value}
      onChange={(event) => setValue(event.target.value, inputKey)}
      type={type}
      ref={ref}
      style={{ margin, ...style }}
      width={width}
      className={`${variant} ${className}`}
    />
  );
};

Index.defaultProps = {
  disabled: false,
  placeholder: 'Enter Text',
  value: '',
  setValue: () => null,
  type: 'text',
  width: '100%'
};

export default Index;
