import React from 'react';
import { useSelector } from 'react-redux';
import KeyInputLayoutView from '../../../Common/KeyValueLayout/KeyInputLayout.view';
import NavigationBtns from './NavigationBtns';
import SelectWOInputType from './SelectWOType.view';
import Header from '../Header';
import { Div, FlexContainer } from '../../../../globalStyles';

const SelectWOTypeInPage = (props) => {
  const { type } = useSelector((state) => state.upsertWorkOrder);
  return (
    <Div px='2'>
      <Header>
        <FlexContainer className='ai_center'>
          <NavigationBtns {...props} />
        </FlexContainer>
      </Header>
      <Div px='3'>
        <KeyInputLayoutView label='Select Work Order Type'>
          <SelectWOInputType {...props} />
        </KeyInputLayoutView>
      </Div>
    </Div>
  );
};

export default SelectWOTypeInPage;
