import React from 'react';
import { FlexContainer } from '../../../../globalStyles';
import NavigationBtns from './NavigationBtns';
import SelectWOInputType from './SelectWOType.view';

const SelectWOTypeInPopup = (props) => (
  <>
    <SelectWOInputType {...props} />
    <FlexContainer mt='6' mb='3.5' className='ai_center jc_end'>
      <NavigationBtns {...props} />
    </FlexContainer>
  </>
);

export default SelectWOTypeInPopup;
