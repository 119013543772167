import styled from 'styled-components';

export const CardContainer = styled.div`
  background-color: ${({ theme }) => theme.cardBg};
  display: flex;
  align-items: center;
  background: #f9f9f9;
  border-radius: 10px;
  padding: 1rem;
  padding-right: 2rem;
  margin-top: 2rem;
`;
