/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import { AvatarComponent } from '../..';
import { FlexContainer } from '../../../globalStyles';
import { device, fontFamily } from '../../constants';
import { ImageContainer } from '../../Common/CommentItem/CommentItem.style';
import { getFormatedTimestamp } from '../../../services/utility/moment';

export const Container = styled.div`
  padding-left: 6rem;
  width: 100%;
  position: relative;
`;

export const ItemContainer = styled.div`
  border-radius: 10px;
  box-shadow: -5px -5px 11px #fffffff5;
  margin: 2rem 2.5rem 0rem 0rem;
  padding: 2rem;
  & .mainDetails {
    font: 1.8rem ${fontFamily.circularMedium};
    color: #4a4a4a;
    margin-bottom: 1rem;
  }
  & .subDetails {
    font: 1.4rem ${fontFamily.circularMedium};
    color: #393939;
  }
`;

export const UsernameText = styled.div`
  color: #4a4a4a;
  font: 1.8rem ${fontFamily.circularMedium};
  &.ml {
    margin-left: 1rem;
  }
  @media ${device.tablet} {
        font: 1.6rem ${fontFamily.circularBook};

    
  }
`;

export const Content = styled.div`
  color: #404046;
  font: 1.6rem ${fontFamily.circularBook};
  padding-top: 0.2rem;
  &.ml {
    margin-left: 1rem;
  }
   @media ${device.tablet} {
        font: 1.4rem ${fontFamily.circularBook};

    
  }
`;

const RemarksList = ({ ele }) => {
  const { by, timestamp, remarks } = ele;
  return (
    <Container>
      <ImageContainer style={{ height: '100%' }} className='ai_center'>
        <AvatarComponent userdata={{ firstName: by }} circle={true} />
      </ImageContainer>
      <ItemContainer>
        <FlexContainer className='jc_spacebetween'>
          <FlexContainer className='ai_center'>
            <UsernameText className='ml'>{`${by}`}</UsernameText>
            <Content className='ml'>
              <p dangerouslySetInnerHTML={{ __html: remarks || '-' }} />
            </Content>
          </FlexContainer>
          <Content>{timestamp ? getFormatedTimestamp(timestamp) : '-'}</Content>
        </FlexContainer>
      </ItemContainer>
    </Container>
  );
};

export default RemarksList;
