/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable no-undef */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable import/no-duplicates */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  ContentText,
  SidePanelContainer,
  ItemContainer,
  AddButton
} from './WorkOrderDetails.style';

import TabsComponent from '../../Common/Tabs/Tabs';
import { share, checkList, attach } from '../../../assets/icons';
import { Div, FlexContainer } from '../../../globalStyles';
import { AvatarComponent } from '../..';
import { getDateAtime } from '../../../services/utility/moment';
import {
  WebService,
  WebServiceMultipart
} from '../../../services/axios/webServices';
import { Creators as UpsertWorkOrderCreators } from '../CreateWorkOrder/store';
import { Creators as FilterCreators } from '../../../views/Filters/store';
import ButtonComp from '../../Common/Button/Button.view';
import { WO_PANEL_DETAILS } from '../../../constants';
import { useActions } from '../../../store/hooks/useActions';
import Loader from '../../Loader/Loader';
import Toast from '../../Common/Toast';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';
import { apiEndPoints } from '../../../services/axios/endPoints';
import ConfirmationModal from '../../Common/ConfirmationBox/ConfirmationBox.view';

const WOSidePanel = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { getWOSidePanelDetails, getWOSidePanelDetailsSuccess } = useActions();
  const { userData } = useSelector((state) => state.loginData);

  const { workOrderTypes, sidePanelInfo, sidePanelDetailsLoader } = useSelector(
    (state) => state.workOrders
  );
  const { Attachments, CheckLists, RelatedWorkItems } = sidePanelInfo;
  const [error, setError] = useState('');
  const [state, setState] = useState('spares');
  const [confirmationModal, setConfirmationModal] = useState(false);

  const groupBy = (array = [], key) =>
    array.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});

  const RequestItem = ({ item }) => {
    const { transaction_id, workflow_name, lastupdatedon, current_state } =
      item;

    return (
      <ItemContainer
        className='pointer'
        onClick={() =>
          history.push(`/home/work_orders/${workflow_name}/${transaction_id}`)
        }
      >
        <div className='mainDetails'>{`${transaction_id} ${workflow_name}`}</div>
        <div className='subDetails'>
          {`Updated: ${moment(new Date(lastupdatedon)).format(
            'DD-MM-YY'
          )} | Current State: ${current_state}`}
        </div>
      </ItemContainer>
    );
  };

  const download = (url, filename) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      })
      .catch(console.error);
  };

  const selectedDocforDeletion = useRef();
  const onHandleDelete = (item, type, activeDoc) => {
    selectedDocforDeletion.current = {
      ...activeDoc,
      id: item.Id?.toString(),
      type
    };
    setConfirmationModal(true);
  };

  const resolvedDetailsOf = (detailsOf) => {
    if (detailsOf === 'checklist') {
      return WO_PANEL_DETAILS.CHECKLIST;
    }
    return WO_PANEL_DETAILS.ATTACHMENT;
  };

  const refetchDetails = (detailsOf) => {
    if (detailsOf === 'attachments' && Attachments) {
      getWOSidePanelDetails({
        refreshObj: Attachments.refreshUrl,
        detailsOf: WO_PANEL_DETAILS.ATTACHMENT
      });
    } else if (detailsOf === 'checklist' && CheckLists) {
      getWOSidePanelDetails({
        refreshObj: CheckLists.refreshUrl,
        detailsOf: WO_PANEL_DETAILS.CHECKLIST
      });
    }
  };
  const [deleteLoading, setDeleteLoading] = useState(false);
  const handleDeleteAttachment = async (reason) => {
    try {
      const activeDoc = { ...selectedDocforDeletion.current, reason };
      setDeleteLoading(true);
      const res = await WebService.post(
        apiEndPoints.deleteDocument,
        JSON.stringify({ data: [{ ...activeDoc }] })
      );
      if (res.status === 200) {
        // refetchDetails(activeDoc.type);
        getWOSidePanelDetailsSuccess({
          data: res.data,
          detailsOf: resolvedDetailsOf(activeDoc.type)
        });
      } else {
        throw res;
      }
    } catch (err) {
      const payload = sagaCatchBlockHandling(err);
      console.log(err, 'error');
      setError(payload);
    } finally {
      setDeleteLoading(false);
      selectedDocforDeletion.current = { Id: null, type: null, reason: '' };
      setConfirmationModal(false);
    }
  };

  const CheckList = ({ item }) => {
    const { fileName } = item;

    return (
      <ItemContainer>
        <div className='mainDetails'>{fileName}</div>
      </ItemContainer>
    );
  };

  const Attachment = ({ item }) => {
    const {
      created_date,
      uploadedOn,
      fileName,
      uploadedBy,
      downloadlink,
      IsDelete
    } = item;
    const attachedBy = { firstName: uploadedBy };

    return (
      <ItemContainer>
        <FlexContainer>
          {/* <AvatarComponent userdata={attachedBy} circle={true} /> */}
          <Div mx='1'>
            {/* <div className='mainDetails'>{`${uploadedBy}`}</div> */}
            <div className='mainDetails'>{fileName}</div>
            <div className='subDetails'>{`${uploadedBy} on ${getDateAtime(
              uploadedOn
            )}`}</div>
          </Div>
        </FlexContainer>
        {downloadlink && (
          <AddButton
            style={{
              position: 'relative',
              left: '18rem',
              top: '1rem',
              fontSize: '1.6rem'
            }}
            onClick={() => {
              download(downloadlink, `${fileName}`);
            }}
          >
            Download
          </AddButton>
        )}
        <AddButton
          style={{
            position: 'relative',
            left: '0rem',
            top: '1rem',
            fontSize: '1.7rem'
          }}
          disabled={!IsDelete}
          onClick={() => {
            onHandleDelete(item, state);
          }}
        >
          Delete
        </AddButton>
      </ItemContainer>
    );
  };

  const [file, setFile] = useState(null);
  const [fileNotSupported, setFileNotSupported] = useState();
  const [loaderToSave, setLoaderToSave] = useState(false);

  const fileSelectedHandler = (event, WriteUrl) => {
    try {
      const { types, size } = WriteUrl;
      // string to array
      const typeofFile = types.replace(/\[|\]/g, '').split(',');
      // mb to bytes
      const sizeofFile = size * 1048576;
      // size, filetype check
      if (!typeofFile.some((e) => event.target.files[0]?.name.endsWith(e))) {
        setFileNotSupported('File format is not supported');
      } else if (event.target.files[0]?.size > sizeofFile) {
        setFileNotSupported(
          `File size is exceeding the limit, Please upload file less than ${size}MB`
        );
      } else {
        setFile(event.target.files[0]);
      }
      event.target.value = null;
    } catch (e) {
      setError('Error occured, Please try again later');
    }
  };

  let inputRef;
  const handleAction = async (upLoadRef) => {
    if (upLoadRef) {
      inputRef.click();
    }
  };

  // save attachments, checklist handler
  const handleSave = async (actionObj, refreshObj, type) => {
    setLoaderToSave(true);
    const { method, url, types, size } = actionObj;
    // eslint-disable-next-line no-undef
    const formData = new FormData();
    formData.append('File', file);
    formData.append('userId', userData.userId);

    try {
      const res = await WebServiceMultipart[method](url, formData);

      if (res.status >= 200 && res.status < 300) {
        setFile(null);
        getWOSidePanelDetails({ refreshObj, detailsOf: type });
        setLoaderToSave(false);
      } else {
        throw res;
      }
    } catch (err) {
      console.log(err, 'error');
      const payload = sagaCatchBlockHandling(err);
      setError(payload?.error);
    } finally {
      setLoaderToSave(false);
      setFile(null);
    }
  };

  const removeSelectedFile = () => {
    setFile(null);
  };
  const handleWOCreation = (typeId, parentTransactionId) => {
    dispatch(FilterCreators.resetFilterState());
    dispatch(UpsertWorkOrderCreators.resetAssetShortlist());
    dispatch(
      UpsertWorkOrderCreators.setWorkOrderType({
        ...workOrderTypes.find((e) => e.workflowTemplateId === typeId),
        parentTransactionId
      })
    );
    history.push('/home/work_orders/create_WO/filter');
  };

  // fetch details on tab switch
  useEffect(() => {
    refetchDetails(state);
  }, [state]);
  return (
    <SidePanelContainer>
      {/* <HeaderText>Timeline</HeaderText> */}

      <TabsComponent getactiveTab={setState} tab={state}>
        {/* RelatedWorkItems */}
        <div
          iconUrl={share}
          iconType={true}
          label='spares'
          enabled={RelatedWorkItems?.enabled}
        >
          <FlexContainer className='jc_spacebetween' px='1'>
            {RelatedWorkItems?.buttons.map((button, i) => (
              <AddButton
                key={i}
                onClick={() => handleWOCreation(button.value, button.tag)}
                disabled={
                  !(button.enabled === true || button.enabled === 'true')
                }
              >
                {button.text}
              </AddButton>
            ))}
          </FlexContainer>

          {Object.entries(groupBy(RelatedWorkItems?.data, 'workflow_name')).map(
            (el) => {
              const [key, array] = el;
              return (
                <>
                  <ContentText
                    key={key}
                    className='label'
                    style={{ margin: '2rem' }}
                  >
                    {key}
                  </ContentText>
                  {array?.map((item, i) => (
                    <RequestItem item={item} key={i} />
                  ))}
                </>
              );
            }
          )}
        </div>

        {/* tab attachments */}
        <div
          iconUrl={attach}
          iconType={true}
          label='attachments'
          enabled={Attachments?.enabled}
        >
          {/* btns */}
          <input
            hidden
            type='file'
            ref={(refParam) => {
              inputRef = refParam;
            }}
            onChange={(e) => fileSelectedHandler(e, Attachments.WriteUrl)}
          />
          <FlexContainer className='jc_spacebetween ai_center' px='1'>
            {Attachments?.buttons.map((button, i) => (
              <>
                <AddButton
                  key={i}
                  onClick={() => handleAction(inputRef)}
                  disabled={!Attachments?.WriteUrl?.url}
                >
                  {button.text}
                </AddButton>
              </>
            ))}
          </FlexContainer>
          {/* uploaded file name preview */}
          {file !== null ? (
            <ItemContainer className='selectedFile'>
              <div className='mainDetails' style={{ fontSize: '2.2rem' }}>
                {file.name}
                {loaderToSave ? (
                  <Loader className='attachmentSaver' />
                ) : (
                  <FlexContainer className='jc_spaceevenly ai_center'>
                    <ButtonComp
                      dark
                      onClick={() =>
                        handleSave(
                          Attachments.WriteUrl,
                          Attachments.refreshUrl,
                          WO_PANEL_DETAILS.ATTACHMENT
                        )
                      }
                    >
                      Save
                    </ButtonComp>
                    <ButtonComp dark onClick={removeSelectedFile}>
                      Cancel
                    </ButtonComp>
                  </FlexContainer>
                )}
              </div>
            </ItemContainer>
          ) : null}
          {/* list of attachments */}
          {sidePanelDetailsLoader ? (
            <Loader />
          ) : (
            Attachments?.data.map((ele, i) => <Attachment key={i} item={ele} />)
          )}
        </div>

        {/* tab3 checklist */}
        <div
          iconUrl={checkList}
          iconType={true}
          label='checklist'
          enabled={CheckLists?.enabled}
        >
          <input
            hidden
            type='file'
            ref={(refParam) => {
              inputRef = refParam;
            }}
            onChange={(e) => fileSelectedHandler(e, CheckLists.writeUrl)}
          />
          <FlexContainer className='jc_spacebetween ai_center' px='1'>
            {CheckLists?.buttons.map((button, i) => (
              <>
                <AddButton
                  key={i}
                  onClick={() => handleAction(inputRef)}
                  disabled={!CheckLists?.writeUrl?.url}
                >
                  {button.text}
                </AddButton>
              </>
            ))}
          </FlexContainer>
          {/* uploaded file name preview */}
          {file !== null ? (
            <ItemContainer className='selectedFile'>
              <div className='mainDetails' style={{ fontSize: '2.2rem' }}>
                {file.name}
                {loaderToSave ? (
                  <Loader className='attachmentSaver' />
                ) : (
                  <FlexContainer className='jc_spaceevenly ai_center'>
                    <ButtonComp
                      className='sidePanelButton'
                      dark
                      onClick={() =>
                        handleSave(
                          CheckLists.writeUrl,
                          CheckLists.refreshUrl,
                          WO_PANEL_DETAILS.CHECKLIST
                        )
                      }
                    >
                      Save
                    </ButtonComp>
                    <ButtonComp
                      className='sidePanelButton'
                      dark
                      onClick={removeSelectedFile}
                    >
                      Cancel
                    </ButtonComp>
                  </FlexContainer>
                )}
              </div>
            </ItemContainer>
          ) : (
            ''
          )}
          {/* list of checklist */}
          {sidePanelDetailsLoader ? (
            <div>
              <Loader />
            </div>
          ) : (
            CheckLists?.data.map((ele, i) => <Attachment key={i} item={ele} />)
          )}
        </div>
      </TabsComponent>
      {fileNotSupported && (
        <Toast
          message={fileNotSupported}
          header='Error'
          style={{ right: 30, zIndex: 20 }}
          fn={() => setFileNotSupported('')}
        />
      )}
      {error && (
        <Toast
          message={error}
          header='Error'
          style={{ right: 30, zIndex: 20 }}
          fn={() => setFileNotSupported('')}
        />
      )}
      <ConfirmationModal
        closeModalHandler={setConfirmationModal}
        showModal={confirmationModal}
        handleDelete={handleDeleteAttachment}
        message='Do you really want to delete?'
        loading={false}
        buttonLoading={deleteLoading}
        needReason
      />
    </SidePanelContainer>
  );
};

export default WOSidePanel;
