import styled from 'styled-components';
import { FlexContainer } from '../../../../globalStyles';

const WIDTH = '20rem';

export const MonthPickerContainer = styled(FlexContainer)`
  /* width: ${WIDTH}; */
  width: 100%;
  flex-wrap: wrap;
  /* height: 70%; */
  flex: 1;
  & + & {
    margin-left: 1rem;
  }
`;

export const MonthContainer = styled.div`
  /* min-width: calc(${WIDTH} / 4); */
  padding: 1rem 0rem;

  display: flex;
  flex-basis: calc(100% / 4);
  align-items: center;
  justify-content: center;

  &.hovered {
    background: ${({ theme }) => theme.filter.hover};
  }
  &.hightlight {
    /* green; */
    color: ${({ theme }) => theme.core.secondary};
    background: ${({ theme }) => theme.contrast.darkSecondary};
  }

  &.hightlight + &.hightlight {
    border-radius: 0rem;
  }

  &.startDate {
    /* border-radius: 1rem 0rem 0rem 1rem; */
  }
  &.hightlight.endDate {
    /* border-radius: 0rem 1rem 1rem 0rem !important; */
  }
`;

export const MonthWrapper = styled.div`
  padding: 0rem 1rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  & + & {
    border-left: 1px solid black;
  }
  & * {
    font-size: 1.5rem;
  }
`;
