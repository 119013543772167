/* eslint-disable max-len */

import LoginForm from '../views/auth/login/LoginForm';
import ForgotPassword from '../views/auth/ForgotPassword/ForgotPassword';
import OtpView from '../views/auth/OtpView/Otp.View';
import ResetPassword from '../views/auth/ResetPassword/ResetPassword.view';
import ResetSuccess from '../views/auth/ResetSuccess/ResetSuccess.view';
import Card from '../component/Card';
import Search from '../views/Search/Search.view';
import WorkOrders from '../views/WorkOrders';
import AssetShortlist from '../component/WorkOrder/CreateWorkOrder/AssetSelection/AssetShortlist';
import ActivityRoutes from '../views/Activity/ActivityRoutes';
import ScheduleRoutes from '../views/Schedule/ScheduleRoutes';
import ScheduleTypeRoutes from '../views/ScheduleType/ScheduleTypesRoutes';
import PlanRoutes from '../views/Plan/PlanRoutes';
import UserRoute from '../views/Users/UsersRoute';

export const AuthRoutes = [
  { path: '/', exact: true, name: 'login', component: LoginForm },
  {
    path: '/forgot-password',
    exact: true,
    name: 'forgotPassword',
    component: ForgotPassword
  },
  {
    path: '/email-verify',
    exact: true,
    name: 'emailVerify',
    component: OtpView
  },
  {
    path: '/reset-password',
    exact: true,
    name: 'resetPassword',
    component: ResetPassword
  },
  {
    path: '/reset-success',
    exact: true,
    name: 'resetSuccess',
    component: ResetSuccess
  }
];

// ones which are not covered from menu layout
export const commonRoutes = [
  ...ActivityRoutes,
  ...ScheduleRoutes,
  ...ScheduleTypeRoutes,
  ...PlanRoutes,
  ...UserRoute,
  { path: '/home/search', exact: true, name: 'search', Component: Search },
  { path: '/home/work_orders', exact: false, name: 'work orders', Component: WorkOrders },
  { path: '/home/asset-shortlist', exact: false, name: 'asset shortlist', Component: AssetShortlist },
  { path: '', exact: true, name: 'home', Component: Card }
];
