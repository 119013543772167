/* eslint-disable max-len */
import { put } from 'redux-saga/effects';
import { WebService } from '../../../services/axios/webServices';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { SchedultTypesCreators as Creators } from './action';

export function* getScheduleTypeSaga({ payload }) {
  const { page, pageSize, type, lang, searchQueries } = payload;
  try {
    yield put(Creators.getScheduleTypeStart());

    const endpoint = `${payload.endpoint}/${lang || 'en'}/${page || 2}/${pageSize || 10}`;
    const response = yield WebService.post(endpoint, { SearchFilters: { ...searchQueries } });
    if (response.status >= 200 && response.status < 300) {
      // console.log(response.data);
      const { data, meta } = response.data;

      yield put(Creators.getScheduleTypeSuccess({ meta, scheduleType: data || [] }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message || error.response.data.Message
          };
          yield put(Creators.getScheduleTypeFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getScheduleTypeFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.getScheduleTypeFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getScheduleTypeFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        // error: error.message
        error: 'No connection try again Later.'
      };

      yield put(Creators.getScheduleTypeFailure(payload));
    }
  }
}

export function* getScheduleTypeDetailsSaga({ payload }) {
  const { scheduleTypeId } = payload;
  try {
    yield put(Creators.getScheduleTypeDetailsStart());

    const fullURL = yield `${apiEndPoints.getScheduleTypeDetails}/${scheduleTypeId}`;
    const response = yield WebService.get(fullURL);
    // console.log('response', response);
    if (response.status >= 200 && response.status < 300) {
      const scheduleData = response.data.data;
      // console.log(scheduleData[0], 'payload');
      yield put(Creators.getScheduleTypeDetailsSuccess(scheduleData[0]));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message || error.response.data.Message
          };

          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };

          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };

          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
      }
    } else {
      // for custom error
      payload = {
        error: 'No connection try again Later.'
        // error: 'custom error'
      };
    }
    yield put(Creators.getScheduleTypeDetailsFailure(payload));
  }
}
