/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { leftArrow, rightArrow, leftArrowDisable, rightArrowDisable } from '../../../assets/icons/layout';
import { fontFamily, fontSize } from '../../constants/font';
import { device } from '../../constants';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.img`
  width: 4rem;
  cursor: pointer;
`;
const RangeText = styled.p`
  font-size: ${fontSize.title};
  font-family: ${fontFamily.circularBold};
  margin-right: 5px;
  @media ${device.tablet} {
    font-size: 14px;
  }
`;

const Total = styled.div`
  font-size: ${fontSize.title};
  font-family: ${fontFamily.circularBook};
  @media ${device.tablet} {
    font-size: 14px;
  }
`;

const Pagination = (props) => {
  const { handlePageChange, total, ItemsPerPage = 20, isLoading, ...rest } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastItem = currentPage * ItemsPerPage;
  const indexOfFirstItem = indexOfLastItem - ItemsPerPage;

  // useEffect(() => {
  //   if (currentPage !== 1) handlePageChange(currentPage);
  // }, [currentPage]);
  const { searchParams } = rest;
  useEffect(() => {
    setCurrentPage(1);
  }, [searchParams]);

  const handlePageDown = () => {
    handlePageChange(currentPage - 1, rest);
    setCurrentPage(currentPage - 1);
  };

  const handlePageUp = () => {
    handlePageChange(currentPage + 1, rest);
    setCurrentPage(currentPage + 1);
  };

  // TODO: need test(used in plan list view)
  useEffect(() => {
    // change in total is infered as new list
    setCurrentPage(1);
  }, [total]);

  if (!total ?? true) {
    return <></>;
  }
  return (
    <Container>
      <Icon
        src={indexOfFirstItem !== 0 ? leftArrow : leftArrowDisable}
        alt=''
        onClick={indexOfFirstItem !== 0 ? handlePageDown : null}
      />
      <RangeText>
        {indexOfLastItem < total
          ? `${indexOfFirstItem + 1}-${indexOfLastItem}`
          : total % indexOfFirstItem === 1
          ? total
          : `${indexOfFirstItem + 1}-${total}`}
      </RangeText>
      <Total> of {total}</Total>

      <Icon
        src={indexOfLastItem < total ? rightArrow : rightArrowDisable}
        alt=''
        onClick={indexOfLastItem < total ? handlePageUp : null}
      />
    </Container>
  );
};

export default Pagination;
