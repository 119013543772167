/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React from 'react';
import { useSelector } from 'react-redux';
// import moment from 'moment';
// import { Form } from 'react-formio';
import moment from 'moment';
import {
  ContentText,
  DetailsContainer,
  LabelToolTip
  // DetailSection, NormalText
} from './WorkOrderDetails.style';
// import { STATUSES } from '../../../constants';
import './RenderDetails.css';
// import { getWorkOrderDetails } from './getDetails';
import { Table } from '../..';
import DynamicSVGIcon from '../../Common/DynamicSVGIcon';
import { help } from '../../../assets/icons';
import { format as dateFormat } from '../../../config/index';

const LabelItem = ({
  label,
  value,
  tooltip
  //  nonEditable
}) => (
  <DetailsContainer className='detail ai' width='100%'>
    <ContentText className='heading'>
      {label}
      {tooltip && (
        <div style={{ marginLeft: '3px' }}>
          <DynamicSVGIcon iconUrl={help} />
          <LabelToolTip className='top'>
            <ContentText>{tooltip}</ContentText>
          </LabelToolTip>
        </div>
      )}
    </ContentText>
    <span>:</span>
    <ContentText className='label ml'>{value || '-'}</ContentText>
  </DetailsContainer>
);

// const LabelColumnItems = ({ properties }) => (
//   <DetailSection>
//     {[...properties].map((property) => {
//       const { label, value, nonEditable } = property;
//       return <LabelItem label={label} value={value} nonEditable={nonEditable} />;
//     })}
//   </DetailSection>
// );

// const Spares = ({ spares }) => (
//   <div>
//     <NormalText>Spare Parts</NormalText>
//     <DetailsContainer width='80%' style={{ marginTop: '3rem', flexDirection: 'column' }}>
//       {spares.map((spare) => (
//         <DetailsContainer>
//           <NormalText className='hightlight' style={{ marginRight: '20%' }}>
//             {spare?.mainPartNumber}
//           </NormalText>
//           <LabelItem label='Request Quantity' value={spare?.requestQuant} />
//         </DetailsContainer>
//       ))}
//     </DetailsContainer>
//   </div>
// );

const RenderDetails = () => {
  // const {
  //   workOrderId,
  //   workOrderType,
  //   equipmentName,
  //   equipmentLocation,
  //   description,
  //   status,
  //   assignedTo,
  //   type: workOrderRawType,
  //   spareRequestId,
  //   store,
  //   spares
  // } = useSelector((state) => state.upsertWorkOrder);
  // const timeStamp = moment(new Date()).format('DD-MM-YY HH:mm:ss:sss');

  const { workOrderDetails } = useSelector((state) => state.workOrders);
  // console.log('workOrderDetails===>', workOrderDetails);

  return (
    <>
      {workOrderDetails && (
        <DetailsContainer width='100%'>
          {workOrderDetails?.map((detail, i) => {
            const { label, value, defaultValue, format, tooltip } = detail;
            if ((value && typeof value !== 'object') || (defaultValue && typeof defaultValue !== 'object')) {
              // console.log('lable-1', label, 'value-1', value, defaultValue);
              return (
                <React.Fragment key={i}>
                  <LabelItem
                    label={label}
                    tooltip={tooltip}
                    // value={format ? moment(value).format(format) : value || defaultValue}
                    value={format ? (value ? moment(value).format(dateFormat) : '-') : value || defaultValue}
                  />
                </React.Fragment>
              );
            }
          })}
          {workOrderDetails?.map((detail, i) => {
            const { label, value, defaultValue } = detail;

            // if (typeof value === 'object' && value.values?.length) {
            if (
              (value && typeof value === 'object' && value.values?.length) ||
              (defaultValue && typeof defaultValue === 'object' && defaultValue.values?.length)
            ) {
              // console.log('label-2', label, 'value-2', value, defaultValue);
              return (
                <div style={{ width: '100%' }} key={i}>
                  <ContentText className='table heading'>{label}</ContentText>
                  <Table colProps={value.labels} data={value.values} defaultValue={value?.defaultValues[0]} />
                </div>
              );
            }
          })}
        </DetailsContainer>
      )}
    </>
  );

  // const valueMappings = {
  //   WOId: { label: 'Work Order No', value: workOrderId },
  //   equipName: { label: 'Equipment', value: equipmentName },
  //   createdBy: { label: 'Created By', value: 'Vistrian' },
  //   WOType: { label: 'Work Order Type', value: workOrderType },
  //   equipLoc: { label: 'Location ', value: equipmentLocation },
  //   createdOn: { label: 'Created On', value: timeStamp },
  //   assignedTo: { label: 'Assigned to', value: assignedTo },
  //   description: { label: 'Description', value: description },
  //   spareReqId: { label: 'Spare Request Id', value: spareRequestId },
  //   store: { label: 'Store', value: store },
  //   spares: { label: 'Spare Parts', value: spares }
  // };

  // const defaultFeildDetails = [
  //   valueMappings.WOId,
  //   valueMappings.equipName,
  //   valueMappings.createdBy,
  //   valueMappings.WOType,
  //   valueMappings.equipLoc,
  //   valueMappings.createdOn,
  //   valueMappings.assignedTo,
  //   valueMappings.description
  // ];

  // const spareReqDetails = [valueMappings.spareReqId, valueMappings.store, valueMappings.WOType, valueMappings.spares];

  // return (
  //   <>
  //     {(() => {
  //       switch (workOrderRawType.value) {
  //         default:
  //         case 'Preventive Maintenance':
  //           return (
  //             <div>
  //               <DetailsContainer width='100%'>
  //                 <LabelColumnItems properties={defaultFeildDetails.slice(0, 3)} />
  //                 <LabelColumnItems properties={defaultFeildDetails.slice(3, 6)} />
  //               </DetailsContainer>
  //               {status !== STATUSES.TO_BE_ASSIGNED && (
  //                 <LabelItem label={defaultFeildDetails[6].label} value={defaultFeildDetails[6].value} />
  //               )}
  //               <LabelItem label={defaultFeildDetails[7].label} value={defaultFeildDetails[7].value} />
  //             </div>
  //           );

  //         case 'Spare Request':
  //           return (
  //             <div>
  //               <DetailsContainer width='100%'>
  //                 <LabelColumnItems properties={spareReqDetails.slice(0, 2)} />
  //                 <LabelColumnItems properties={spareReqDetails.slice(2, 3)} />
  //               </DetailsContainer>
  //               <Spares spares={spares} />
  //             </div>
  //           );
  //       }
  //     })()}
  //   </>
  // );

  // const obj = test[0].formdefinition;

  // useEffect(() => {
  //   getWorkOrderDetails(formdefinition, user_data?.data);
  // }, [formdefinition]);
  // return (
  //   <>
  //     {/* {formdefinition ? <div>hhe</div> : <div>eee</div>} */}
  //     {formdefinition && (
  //       <Form
  //         // {...props}
  //         src={formdefinition}
  //         submission={user_data}
  //         // onSubmitDone={onSubmitDone}
  //         // options={optionsData}
  //         // formReady={onFormReady}
  //       />
  //     )}
  //   </>
  // );
};

export default RenderDetails;
