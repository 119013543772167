/* eslint-disable react/jsx-indent */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  leftArrow,
  rightArrow,
  leftArrowDisable,
  rightArrowDisable
} from '../../../assets/icons/layout';
import { fontFamily, fontSize } from '../../constants/font';
import { device } from '../../constants';
import ButtonComp, { Button } from '../Button/Button.view';
import DropDownModal from '../DropDownModal/DropDownModal';
import InputDropdown from '../DropDownModal/InputDropdown';
import { TextPage } from '../../../views/Stores/Spares/BulkList.styles';

const Container = styled.div`
  display: flex;
  align-items: center;
  @media ${device.tablet} {
    margin-left: 2rem;
  }
`;

const Icon = styled.img`
  width: 4rem;
  cursor: pointer;
`;
const RangeText = styled.p`
  font-size: ${fontSize.title};
  font-family: ${fontFamily.circularBold};
  margin: 0 8px 10px 10px;
  @media ${device.tablet} {
    font-size: 14px;
  }
`;

const Total = styled.div`
  font-size: ${fontSize.title};
  font-family: ${fontFamily.circularBook};
  @media ${device.tablet} {
    font-size: 14px;
  }
`;

const PaginationWithPage = (props) => {
  const {
    handlePageChange,
    total,
    ItemsPerPage = 20,
    isLoading,
    setPageSize,
    buttonStyle = {},
    ...rest
  } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastItem = currentPage * ItemsPerPage;
  const indexOfFirstItem = indexOfLastItem - ItemsPerPage;
  const totalPages = Math.ceil(total / ItemsPerPage);
  const [pageArray, setPageArray] = useState([]);

  useEffect(() => {
    const page = [];
    for (let i = 0; i < 4; i += 1) {
      if (i < totalPages) {
        page.push(i + 1);
      }
    }
    setPageArray(page);
  }, [totalPages]);

  const { searchParams } = rest;
  useEffect(() => {
    setCurrentPage(1);
  }, [searchParams]);

  const handlePageDown = () => {
    handlePageChange(currentPage - 1, rest);
    setCurrentPage(currentPage - 1);
  };

  const handlePageUp = () => {
    handlePageChange(currentPage + 1, rest);
    setCurrentPage(currentPage + 1);
  };

  // TODO: need test(used in plan list view)
  useEffect(() => {
    // change in total is infered as new list
    setCurrentPage(1);
  }, [total]);

  const [isActive, setIsActive] = useState(false);
  const handleDropdownVisibility = () => {
    setIsActive(!isActive);
  };

  const handleClick = (e) => {
    setPageSize(e.value);
  };

  const dpData = [
    {
      label: '10 Entries',
      value: 10,
      id: 1
    },
    {
      label: '20 Entries',
      value: 20,
      id: 1
    },
    {
      label: '50 Entries',
      value: 50,
      id: 1
    }
  ];

  if (!total ?? true) {
    return <></>;
  }

  if (!setPageSize) {
    return (
      <Container>
        <>
          {totalPages > 3 && (
            <>
              <ButtonComp
                dark={currentPage === 1}
                style={
                  currentPage === 1
                    ? { minWidth: 0, marginRight: '5px' }
                    : { minWidth: 0, marginRight: '5px', ...buttonStyle }
                }
                onClick={() => {
                  handlePageChange(1, rest);
                  setCurrentPage(1);
                  const page = [];
                  for (let i = 0; i < 4; i += 1) {
                    if (i < totalPages) {
                      page.push(i + 1);
                    }
                  }
                  setPageArray(page);
                }}
              >
                First
              </ButtonComp>
              {!pageArray.includes(1) && <RangeText>...</RangeText>}
            </>
          )}
        </>
        {totalPages > 3
          ? pageArray?.map((page) => (
              <>
                <ButtonComp
                  // style={{ minWidth: 0, marginRight: '5px' }}
                  style={
                    page === currentPage
                      ? { minWidth: 0, marginRight: '5px' }
                      : { minWidth: 0, marginRight: '5px', ...buttonStyle }
                  }
                  dark={page === currentPage}
                  onClick={() => {
                    handlePageChange(page, rest);
                    setCurrentPage(page);
                    if (page > currentPage) {
                      if (page + 1 <= totalPages) {
                        if (page + 2 <= totalPages) {
                          setPageArray([page - 1, page, page + 1, page + 2]);
                        } else {
                          setPageArray([page - 2, page - 1, page, page + 1]);
                        }
                      } else {
                        setPageArray([page - 3, page - 2, page - 1, page]);
                      }
                    } else if (page - 1 > 0) {
                      if (page - 2 > 0) {
                        setPageArray([page - 2, page - 1, page, page + 1]);
                      } else {
                        setPageArray([page - 1, page, page + 1, page + 2]);
                      }
                    } else {
                      setPageArray([page, page + 1, page + 2, page + 3]);
                    }
                  }}
                >
                  {page}
                </ButtonComp>
              </>
            ))
          : pageArray?.map((page) => (
              <>
                <ButtonComp
                  dark={page === currentPage}
                  style={
                    page === currentPage
                      ? { minWidth: 0, marginRight: '5px' }
                      : { minWidth: 0, marginRight: '5px', ...buttonStyle }
                  }
                  // style={{ minWidth: 0, marginRight: '5px' }}
                  onClick={() => {
                    handlePageChange(page, rest);
                    setCurrentPage(page);
                    if (page > currentPage) {
                      if (page + 3 <= totalPages) {
                        setPageArray([page, page + 1, page + 2]);
                      }
                    } else if (page - 2 > 0) {
                      setPageArray([page - 2, page - 1, page]);
                    }
                  }}
                >
                  {page}
                </ButtonComp>
              </>
            ))}
        <>
          {totalPages > 3 && (
            <>
              {!pageArray.includes(totalPages) && <RangeText>...</RangeText>}
              <ButtonComp
                style={
                  currentPage === totalPages
                    ? { minWidth: 0, marginRight: '5px' }
                    : { minWidth: 0, marginRight: '5px', ...buttonStyle }
                }
                dark={currentPage === totalPages}
                onClick={() => {
                  handlePageChange(totalPages, rest);
                  setCurrentPage(totalPages);
                  setPageArray([totalPages - 2, totalPages - 1, totalPages]);
                }}
              >
                Last
              </ButtonComp>
            </>
          )}
        </>
      </Container>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: '2rem'
      }}
    >
      {setPageSize && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <TextPage>Show</TextPage>
          <DropDownModal
            setModalIsVisibal={setIsActive}
            width='20rem'
            data={dpData}
            isActive={isActive}
            handleClick={handleClick}
            itemKey='id'
            label='label'
            singleSelection
            noContainerPadding={true}
          >
            <InputDropdown
              isReadOnly={false}
              handleDropdownVisibility={handleDropdownVisibility}
              placeholder='Entries'
              value={`${ItemsPerPage} Entries`}
            />
          </DropDownModal>
        </div>
      )}

      <Container>
        <>
          {totalPages > 3 && (
            <>
              <ButtonComp
                dark={currentPage === 1}
                style={
                  currentPage === 1
                    ? { minWidth: 0, marginRight: '5px' }
                    : { minWidth: 0, marginRight: '5px', ...buttonStyle }
                }
                onClick={() => {
                  handlePageChange(1, rest);
                  setCurrentPage(1);
                  const page = [];
                  for (let i = 0; i < 4; i += 1) {
                    if (i < totalPages) {
                      page.push(i + 1);
                    }
                  }
                  setPageArray(page);
                }}
              >
                First
              </ButtonComp>
              {!pageArray.includes(1) && <RangeText>...</RangeText>}
            </>
          )}
        </>
        {totalPages > 3
          ? pageArray?.map((page) => (
              <>
                <ButtonComp
                  // style={{ minWidth: 0, marginRight: '5px' }}
                  style={
                    page === currentPage
                      ? { minWidth: 0, marginRight: '5px' }
                      : { minWidth: 0, marginRight: '5px', ...buttonStyle }
                  }
                  dark={page === currentPage}
                  onClick={() => {
                    handlePageChange(page, rest);
                    setCurrentPage(page);
                    if (page > currentPage) {
                      if (page + 1 <= totalPages) {
                        if (page + 2 <= totalPages) {
                          setPageArray([page - 1, page, page + 1, page + 2]);
                        } else {
                          setPageArray([page - 2, page - 1, page, page + 1]);
                        }
                      } else {
                        setPageArray([page - 3, page - 2, page - 1, page]);
                      }
                    } else if (page - 1 > 0) {
                      if (page - 2 > 0) {
                        setPageArray([page - 2, page - 1, page, page + 1]);
                      } else {
                        setPageArray([page - 1, page, page + 1, page + 2]);
                      }
                    } else {
                      setPageArray([page, page + 1, page + 2, page + 3]);
                    }
                  }}
                >
                  {page}
                </ButtonComp>
              </>
            ))
          : pageArray?.map((page) => (
              <>
                <ButtonComp
                  dark={page === currentPage}
                  style={
                    page === currentPage
                      ? { minWidth: 0, marginRight: '5px' }
                      : { minWidth: 0, marginRight: '5px', ...buttonStyle }
                  }
                  // style={{ minWidth: 0, marginRight: '5px' }}
                  onClick={() => {
                    handlePageChange(page, rest);
                    setCurrentPage(page);
                    if (page > currentPage) {
                      if (page + 3 <= totalPages) {
                        setPageArray([page, page + 1, page + 2]);
                      }
                    } else if (page - 2 > 0) {
                      setPageArray([page - 2, page - 1, page]);
                    }
                  }}
                >
                  {page}
                </ButtonComp>
              </>
            ))}
        <>
          {totalPages > 3 && (
            <>
              {!pageArray.includes(totalPages) && <RangeText>...</RangeText>}
              <ButtonComp
                style={
                  currentPage === totalPages
                    ? { minWidth: 0, marginRight: '5px' }
                    : { minWidth: 0, marginRight: '5px', ...buttonStyle }
                }
                dark={currentPage === totalPages}
                onClick={() => {
                  handlePageChange(totalPages, rest);
                  setCurrentPage(totalPages);
                  setPageArray([totalPages - 2, totalPages - 1, totalPages]);
                }}
              >
                Last
              </ButtonComp>
            </>
          )}
        </>
      </Container>
    </div>
  );
};

export default PaginationWithPage;

//   <Container>
//     {pageArray?.map((page) => (
//       <>
//         {page <= 3 && (
//           <Button style={{ minWidth: 0, marginRight: '5px' }} dark>
//             {page}
//           </Button>
//         )}
//       </>
//     ))}
//   </Container>;
