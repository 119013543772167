/* eslint-disable max-len */
import styled from 'styled-components';
import { Button } from '../../../globalStyles';
import { fontFamily, fontSize } from '../../constants';

export const BtnAddActivity = styled(Button)`
  padding: 1.2rem 6rem;
  margin-left: 2rem;

  &.cancel_btn {
    background-color: white;
    color: black;
  }
`;

export const DetailContainer = styled.div`
  width: 98%;
  height: 75vh;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  border-radius: 10px;
`;

export const DetailHead = styled.div`
  width: 70%;
  margin: 3rem;
  margin-top: 5rem;
  display: flex;
  align-items: flex-start;
`;

export const H1 = styled.h1`
  font-size: ${fontSize.titleBig};
  font-family: ${fontFamily.circularBook};
  color: #9b9b9b;
  margin-bottom: 2rem;
`;

export const Detail = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const H3 = styled.h3`
  font-size: ${fontSize.medium};
  font-family: ${fontFamily.circularBook};
  color: var(--black);
  padding: 0.8rem;
  margin-top: 1rem;
`;

export const OuterDiv = styled.div`
  width: 100%;
`;

export const InnerDiv = styled.div`
  width: 20rem;
  color: black;
  display: flex;
  align-items: center;
`;

export const InputSpan = styled.div`
  display: flex;
  width: 42.5rem;
`;

export const DivContainer = styled.div`
  &.conatiner {
    display: 'flex';
    align-items: 'flex-start';
    margin: '2rem';
  }
`;
