import { WebService, WebServiceMultipart } from './webServices';

export const setAccessTokenToHeader = (token) => {
  // const token = 'md.akhil@panorbit.in';
  if (token) {
    WebService.defaults.headers.Authorization = `${token}`;
    WebServiceMultipart.defaults.headers.Authorization = `${token}`;
  } else {
    delete WebService.defaults.headers.Authorization;
    delete WebServiceMultipart.defaults.headers.Authorization;
  }
};

export const setCustomHeader = (value) => {
  if (value) {
    WebService.defaults.headers.common['user-ID'] = `${value}`;
    WebServiceMultipart.defaults.headers.common['user-ID'] = `${value}`;
  } else {
    delete WebService.defaults.headers.common['user-ID'];
    delete WebServiceMultipart.defaults.headers.common['user-ID'];
  }
};
