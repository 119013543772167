/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NavLink, useHistory, useParams } from 'react-router-dom';
import { PlanCreators } from '../../../views/Plan/store';
import ButtonComp from '../../Common/Button/Button.view';
import { Hr, Header } from '../../Common/CommonStyles';
import HeaderComp from '../../Common/Header/Header';
import KeyValueLayout from '../../Common/KeyValueLayout/KeyValueLayout';
import { BodyContainer } from './PlanDetails.styles';
import Createworkorder from '../../WorkOrder/CreateWorkOrder/CreateWorkorder';
import Loader from '../../Loader/Loader';
import Toast from '../../Common/Toast';
import { useActions } from '../../../store/hooks/useActions';
import { SUCCESS, WO_CREATION_FROM } from '../../../constants';
import { WebService } from '../../../services/axios/webServices';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';
import useGetWorkOrderTypes from '../../WorkOrder/CreateWorkOrder/hooks/useGetWorkOrderTypes';
import PopUpModal from '../../Common/PopUpModal/Modal.view';
import { BtnWrapper, Button } from '../../Common/PopUpModal/Modal.style';
import KeyInputLayoutView from '../../Common/KeyValueLayout/KeyInputLayout.view';
import { Input } from '../../Common/Input/Input';

const PlanDetails = () => {
  // sample details
  //   {
  //     "scheduleId": 5,
  //     "scheduleName": "Break DownRecurring Schedule Second",
  //     "scheduleType": "monthly",
  //     "asset": "MP3K",
  //     "activityTitle": "Half-Yearly Activity",
  //     "startDate": "2022-12-10T00:00:00",
  //     "endDate": "2022-12-10T00:00:00",
  //     "createdDate": "2022-11-10T11:56:39.13",
  //     "activityType": "Break Down",
  //     "scheduleTypetime": "02:20:10 to 02:40:00",
  //     "jobOrderId": "",
  //     "jobStatus": ""
  // }

  // const [details, setDetails] = useState({});
  const {
    // scheduleId,
    planId
  } = useParams();
  const { loading, error } = useSelector((state) => state.plansData);
  const planDetails = useSelector((state) => state.plansData.planDetails);
  const { workOrderTypes } = useSelector((state) => state.workOrders);
  const [showEdit, setShowEdit] = useState(false);
  const [errors, setError] = useState('');
  const [input, setInput] = useState({
    planId: '',
    startDate: '',
    startTime: ''
  });
  useGetWorkOrderTypes();
  const dispatch = useDispatch();
  // const workOrder  componentPaths;
  useEffect(() => {
    dispatch(PlanCreators.getPlanDetails({ id: planId }));
  }, [planId]);
  const {
    scheduleName,
    asset,
    activityTitle,
    startDate,
    // scheduleType,
    // endDate,
    // createdDate,
    // activityType,
    // scheduleTypetime,
    jobOrderId,
    jobStatus,
    starttime,
    endtime,
    workflow_templateid
  } = planDetails || {};
  useEffect(() => {
    setInput({
      planId,
      startDate,
      startTime: starttime
    });
  }, [planDetails]);
  const history = useHistory();
  const handleBack = () => {
    history.goBack();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setInput({ ...input, [name]: value });
  };

  const updatePlan = async () => {
    const data = [
      {
        ...input,
        startDate: moment(input?.startDate).format('MMM DD YYYY')
      }
    ];
    try {
      const fullUrl = `${apiEndPoints.updatePlan}`;
      // eslint-disable-next-line prefer-const, object-shorthand
      let res = await WebService.post(fullUrl, JSON.stringify({ data: data }));
      if (res.data?.message === SUCCESS) {
        dispatch(PlanCreators.getPlanDetails({ id: planId }));
        setShowEdit(false);
      } else {
        setError(res.data?.message);
      }
    } catch (err) {
      console.log('error facing location:', err);
      const payload = sagaCatchBlockHandling(err);
      setError(payload?.error);
    } finally {
      // setSubmitLoader(false);
    }
  };

  const { getPlanDetailsFailure, getPlanDetailsStart } = useActions();
  const { setWorkOrderDetail, getUserDataByTemplateId } = useActions();

  // navigation to work order creation step3
  const onSuccessNavigation = (woId) => {
    history.push('/home/work_orders/create_WO/form', {
      createdFrom: WO_CREATION_FROM.PLAN_DETAILS,
      WFTid: woId
    });
  };
  const handleWorkOrder = (id) => {
    getPlanDetailsStart();
    setWorkOrderDetail({
      workOrderCreationFrom: WO_CREATION_FROM.PLAN_DETAILS
    });
    getUserDataByTemplateId({
      workOrderCreationFrom: WO_CREATION_FROM.PLAN_DETAILS,
      planId,
      onSuccessNavigation
    });
  };

  const woType = workOrderTypes?.find((wo) => {
    const workflowTemplateId = workflow_templateid || 1;
    // workflow_templateid is comming as string
    return wo.workflowTemplateId === parseInt(workflowTemplateId, 10);
  })?.Type;
  const workOrderLink = (
    <>
      {jobOrderId ? (
        // remove hard-coded WO type
        <NavLink
          className='underline'
          to={`/home/work_orders/${woType}/${jobOrderId}`}
        >
          {jobOrderId}
        </NavLink>
      ) : (
        <ButtonComp onClick={handleWorkOrder} dark>
          <span>+ Create Work Order</span>
        </ButtonComp>
      )}
    </>
  );

  const clearError = () => {
    dispatch(
      PlanCreators.planValueSetter({
        key: 'error',
        value: ''
      })
    );
  };

  if (loading) return <Loader />;
  return (
    <>
      <HeaderComp header='Plan' handleBack={handleBack} hasBack />
      <BodyContainer>
        <KeyValueLayout label='Asset' value={asset} />
        <KeyValueLayout label='Activity' value={activityTitle} />
        {/* <KeyValueLayout label='Schedule Type' value={scheduleType} /> */}
        <Hr />
        <Header mrgBtm='3rem'>Timeline</Header>
        <KeyValueLayout
          label='Start Date'
          value={moment(startDate).format('DD-MM-YYYY')}
          isEditable={planDetails?.isEditable}
          setShowEdit={setShowEdit}
        />

        {/* {endDate ? <KeyValueLayout label='End Date' value={moment(endDate).format('DD-MM-YYYY')} /> : null} */}
        {starttime ? (
          <KeyValueLayout label='Start Time' value={starttime} />
        ) : null}
        {endtime ? <KeyValueLayout label='End Time' value={endtime} /> : null}
        <KeyValueLayout label='Title' value={scheduleName} />
        <Hr />
        <Header mrgBtm='3rem'>Work Order</Header>
        <KeyValueLayout label='Work Order ID' value={workOrderLink} />
        <KeyValueLayout label='Status' value={jobStatus || 'NA'} />
      </BodyContainer>
      {/* remove hardcoded menuId */}
      {/* {showCreateWOModal && <Createworkorder menuId={2000} onCancel={() => setShowCreateWOModal(false)} />} */}
      {error && (
        <Toast
          message={error}
          className='right'
          header='Error'
          fn={clearError}
        />
      )}
      {showEdit && (
        <PopUpModal
          closeModalHandler={() => {
            setShowEdit(false);
          }}
          width='40%'
        >
          <KeyInputLayoutView className='required editable' label='Start Date'>
            <Input
              type='date'
              step={1}
              className='input dateInput'
              name='startDate'
              min={new Date().toISOString().split('T')[0]}
              value={input?.startDate || planDetails?.startDate}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </KeyInputLayoutView>
          <KeyInputLayoutView className='required editable' label='Start Time'>
            <Input
              type='time'
              step={1}
              className='dateInput'
              name='startTime'
              value={input?.startTime || planDetails?.startTime}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </KeyInputLayoutView>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ButtonComp onClick={() => setShowEdit(false)}>Cancel</ButtonComp>
            <ButtonComp
              style={{ margin: '0px 2.2rem 0px' }}
              dark
              onClick={updatePlan}
            >
              Update
            </ButtonComp>
          </div>
        </PopUpModal>
      )}
    </>
  );
};

export default PlanDetails;
