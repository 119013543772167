/* eslint-disable max-len */
import { put } from 'redux-saga/effects';
import { ScheduleCreators as Creators } from './action';
import { WebService } from '../../../services/axios/webServices';
import { apiEndPoints } from '../../../services/axios/endPoints';

export function* getScheduleSaga({ payload }) {
  const { page, pageSize, type, lang, searchQueries } = payload;
  try {
    yield put(Creators.getScheduleStart());

    const endpoint = `${payload.endpoint}/${lang || 'en'}/${page || 2}/${
      pageSize || 10
    }`;
    const response = yield WebService.post(endpoint, {
      SearchFilters: { ...searchQueries }
    });
    if (response.status >= 200 && response.status < 300) {
      const { data, meta } = response.data;
      yield put(
        Creators.getScheduleSuccess({ meta, scheduleData: data || [] })
      );
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message || error.response.data.Message
          };
          yield put(Creators.getScheduleFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getScheduleFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.getScheduleFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getScheduleFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        // error: error.message
        error: 'No connection try again Later.'
      };

      yield put(Creators.getScheduleFailure(payload));
    }
  }
}

export function* getScheduleDetailsSaga({ payload }) {
  const { scheduleId } = payload;

  try {
    yield put(Creators.getScheduleDetailsStart());

    const fullURL = yield `${apiEndPoints.getScheduleDetails}/${scheduleId}`;
    const response = yield WebService.get(fullURL);

    if (response.status >= 200 && response.status < 300) {
      const schedule = response.data?.data?.[0];
      const plan = response?.data?.plans;
      const DisplayMessage = response?.data?.DisplayMessage;
      yield put(
        Creators.getScheduleDetailsSuccess({ schedule, plan, DisplayMessage })
      );
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message || error.response.data.Message
          };

          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };

          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };

          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
      }
    } else {
      // for custom error
      payload = {
        error: 'No connection try again Later.'
        // error: 'custom error'
      };
    }
    yield put(Creators.getScheduleDetailsFailure(payload));
  }
}
