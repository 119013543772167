/* eslint-disable indent */
/* eslint-disable function-paren-newline */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
import moment from 'moment';
import React, { createRef, useEffect, useRef, useState } from 'react';
// import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CancelBlack, searchBlack, DropDownArrow, rightArrow } from '../../assets/icons';
import { AvatarComponent, PopUpModal } from '../../component';
import DynamicSVGIcon from '../../component/Common/DynamicSVGIcon';
import DropDownModal from '../../component/Common/DropDownModal/DropDownModal';
import { FlexContainer } from '../../globalStyles';
import { isDate as isDateCheck } from '../../shared/utility';

import {
  CellTemplate,
  RowTemplate,
  TableContainerTemplate,
  Title,
  Btn,
  CellText,
  DateDropDown,
  Td,
  Span
} from './Table.style';
import { Table as Tabel, Th, Tr } from '../../component/Common/Table/SpacedRows.styles';
import { SearchIcon, SearchInput } from '../../component/Common/Tabs_Table/TabsTable.style';
import { ToastContainer } from '../../component/Common/Toast/Toast.style';
import Toast from '../../component/Common/Toast';
import { WorkOrderCreators } from './store';
// import { metaColumns } from './data';
import { DateTimePicker } from '../../component/RangeSelectors/DateTimePicker';
import DateRangePicker from '../../component/RangeSelectors/DateRangePicker/DateRangePicker';
import ButtonComp from '../../component/Common/Button/Button.view';
import { curryIsOptionsVisible, OPTIONS_TYPES } from '../../component/Common/Tabs_Table/utility';
// import { Creators as UpserWorkOrderCreators } from '../../component/WorkOrder/CreateWorkOrder/store';

const checkData = (e) => e != null && e !== '';

const getName = (name) => {
  if (name !== null && name !== '') {
    return name;
  }
  return '';
};

const _renderCellData = (name, data, isDate) => {
  if (name === 'assignedTo') {
    const { firstName, lastName, email } = data;
    return (
      <>
        {checkData(firstName) || checkData(lastName) || checkData(email) ? (
          <FlexContainer className='ai_center jc_center'>
            {/* <div style={{ background: 'red', textOverflow: 'auto' }} className='hideScrollBar'>
              <FlexContainer className='jc_center ai_center'> */}
            <AvatarComponent userdata={data} circle={true} />
            <Title>
              {`${getName(data.firstName)} ${getName(data.lastName)}`}
              {/* {`${data.firstName} ${data?.lastName}`} */}
            </Title>
            {/* </FlexContainer>
            </div> */}
          </FlexContainer>
        ) : (
          <CellTemplate>{data || '-'}</CellTemplate>
        )}
      </>
    );
  }

  return (
    <Span>
      <CellTemplate
        // className='trunCate'
        style={{
          overflow: 'hidden'
        }}
      >
        {/* <CellText className='hideScrollBar'> */}
        {isDate ? data.split('T')[0] : data || '-'}
        {/* </CellText> */}
      </CellTemplate>
    </Span>
  );
};

const RenderNode = ({ colProps, data, collapse, handleDetails }) => {
  const [active, setActive] = useState(false);

  const toggle = () => {
    if (collapse && active) {
      setActive(false);
    } else {
      setActive(!active);
    }
  };

  useEffect(() => {
    if (collapse) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [collapse]);

  return (
    <>
      <Tr>
        {/* add a extra column for parent flag */}
        <Td style={{ position: 'relative' }}>
          <>-</>
          {data?.child?.length > 0 && (
            <>
              <Btn onClick={toggle}>
                <DynamicSVGIcon iconUrl={DropDownArrow} rotate={active ? 90 : 0} />
              </Btn>
            </>
          )}
        </Td>
        {colProps.map((col, index) => {
          const { key, type, visible } = col;
          const coldata = data[key];

          return (
            <>
              {visible && (
                <Td key={index}>
                  <CellText className='hideScrollBar'>
                    <span onClick={() => handleDetails(data)}>
                      {_renderCellData(key, coldata || '-', type === 'DateTime')}
                    </span>
                  </CellText>
                </Td>
              )}
            </>
          );
        })}
      </Tr>

      {active &&
        data.child?.map((el, index) => (
          <Tr key={index} onClick={() => handleDetails(el)}>
            {/* extra cell */}
            <Td style={{ position: 'relative' }}>
              <></>
              {el?.child?.length && (
                <>
                  <Btn onClick={toggle}>
                    <DynamicSVGIcon iconUrl={DropDownArrow} rotate={active ? 90 : 0} />
                  </Btn>
                </>
              )}
            </Td>
            {colProps.map((col, index) => (
              <>
                {col.visible ? (
                  <Td key={index} style={{ textAlign: 'center' }}>
                    {_renderCellData(col.key, el?.[col.key], col.type === 'DateTime')}
                  </Td>
                ) : null}
              </>
            ))}
          </Tr>
        ))}
    </>
  );
};

const Table = ({ colProps = [], data = [], collapse, fetchFilteredWorkOrders, pageSize }) => {
  // to try different column meta
  // const colProps = metaColumns;
  const history = useHistory();
  const dispatch = useDispatch();
  const { localeStrings } = useSelector((state) => state.localeStrings);
  const { woSearchQueries, woCount } = useSelector((state) => state.workOrders);
  const handleDetails = ({ jobOrderId, status, type }) => {
    history.push(`/home/work_orders/${type}/${jobOrderId}`);
  };
  const [showSearchInp, setShowSearchInp] = useState({});
  const [showDropdown, setShowDropdown] = useState({});
  const [searchQuery, setSearchQuery] = useState(woSearchQueries);
  const toastMsg = useRef();
  // compute inputs
  useEffect(() => {
    const showFeildInputs = Object.keys(woSearchQueries).reduce(
      (acc, key) => ({ ...acc, [key]: woSearchQueries[key] && true }),
      {}
    );
    setShowSearchInp(showFeildInputs);
  }, []);

  // const searchRefs = useRef([]);

  // searchRefs.current = colProps.map((element, i) => searchRefs.current[i] ?? useRef());
  // const [searchResults, setSearchResults] = useState([]);
  // console.log('searchQuery', searchQuery);

  const fetchData = (searchQueryParam) => {
    // console.log('searchQuery-ssubmit', key, searchQuery);
    dispatch(WorkOrderCreators.setWorkOrderSearchQueries(searchQueryParam || searchQuery));
    fetchFilteredWorkOrders(1, { searchParams: searchQueryParam || searchQuery });
  };

  const [showToast, setShowToast] = useState(false);
  const handleSearchQuery = (field, e, isDirectValue = false) => {
    toastMsg.current = 'Please press enter to search';
    let locSearchQuery = !isDirectValue ? e?.target?.value || '' : e;
    const savedSearchQuery = woSearchQueries[field] || '';
    // if direct use the value directly
    if (isDirectValue) {
      locSearchQuery = e;
    }
    if (!isDirectValue) {
      // toast handling
      if (!showToast && locSearchQuery.length > 0) {
        setShowToast(true);
      } else if (locSearchQuery.length < 1) {
        setShowToast(false);
      }
    }

    const updatedSearchQueries = { ...searchQuery, [field]: locSearchQuery };
    setSearchQuery(updatedSearchQueries);

    // fetch data if complete data is present(isDirectValue===true)
    if (isDirectValue && savedSearchQuery !== locSearchQuery) {
      fetchData({ ...searchQuery, [field]: locSearchQuery });
    }
  };

  const cummFetchData = () => {
    setShowToast(false);
    fetchData();
  };

  const _handleKeyPress = (e) => {
    e.preventDefault();
    if (e.key === 'Enter') {
      cummFetchData();
    }
  };

  const handleUICompVisiblity = ({ field, index, type }, uiComp = 'input') => {
    if (uiComp === 'dropdown') {
      // old date time picker
      // if (type === 'DateTime' && showDropdown[field]) {
      //   cummFetchData();
      // }
      setShowDropdown({ ...showDropdown, [field]: !showDropdown[field] });
    } else {
      // closing the searchinput(click cancel icon)
      if (showSearchInp[field]) {
        handleSearchQuery(field, '', true);
      }

      setShowSearchInp({ ...showSearchInp, [field]: !showSearchInp[field] });
    }
  };

  const handleDropdownItem = (field, item) => {
    if (searchQuery[field] === (item.value || item.label)) {
      handleSearchQuery(field, '', true);
    } else {
      handleSearchQuery(field, item.value || item.label, true);
    }
    setShowDropdown({ ...showDropdown, [field]: !showDropdown[field] });
  };

  const closeDropdown = (field) => setShowDropdown({ ...showDropdown, [field]: false });

  const handleDateSelection = (value, rest) => {
    const { label } = rest;
    handleSearchQuery(label, { target: { value: value.rawValue } });
    // old datetime handling
    // setShowToast(true);
    // toastMsg.current = 'Please close the dropdown to search';
  };

  const closeDateModal = (field) => {
    const updatedSearchQueries = { ...searchQuery, [field]: woSearchQueries[field] };
    setSearchQuery(updatedSearchQueries);
    setShowDropdown({ ...showDropdown, [field]: !showDropdown[field] });
  };

  const DateRangeModal = (col) => (
    <PopUpModal
      style={{ width: 'fit-content' }}
      closeModalHandler={() => {
        closeDateModal(col.key);
      }}
    >
      <DateRangePicker
        initValue={searchQuery[col.key]}
        handleSelection={(e) => {
          handleDateSelection(e, { label: col.key });
        }}
      />
      <FlexContainer style={{ marginTop: '2rem', justifyContent: 'flex-end' }}>
        <ButtonComp
          style={{ marginRight: '2rem' }}
          onClick={() => {
            closeDateModal(col.key);
          }}
        >
          Discard
        </ButtonComp>
        <ButtonComp dark onClick={() => cummFetchData()}>
          Apply
        </ButtonComp>
      </FlexContainer>
    </PopUpModal>
  );

  return (
    <TableContainerTemplate>
      <Tabel>
        {/* <Tr> */}
        <Th />
        {colProps.map((col, index, arry) => {
          const { key, title, visible, searchable, sortable, options, type } = col;
          const columnOptionsConfig = {
            searchable,
            sortable,
            options,
            type
          };
          const isOptionVisible = curryIsOptionsVisible(columnOptionsConfig);
          return visible ? (
            <Th key={key}>
              <FlexContainer className='ai_center jc_center'>
                {/* search handling(input and text) */}
                {showSearchInp[col.key] && isOptionVisible(OPTIONS_TYPES.SEARCHABLE) ? (
                  <SearchInput
                    className='show'
                    // ref={searchRefs.current[index]}
                    // onBlur={() => setShowToast(false)}
                    onKeyUp={(e) => _handleKeyPress(e)}
                    type='text'
                    placeholder={`Search ${title}s`}
                    onChange={(e) => handleSearchQuery(col.key, e)}
                    value={searchQuery[col.key]}
                  />
                ) : (
                  <FlexContainer className='col ai_center' style={{ position: 'relative' }}>
                    {/* show title for all and dropdown value if present */}
                    <p className='title' style={{ fontSize: '1.8rem' }}>
                      {`${localeStrings[title] || title} ${col.options?.length && searchQuery[col.key] ? `= ${searchQuery[col.key]}` : ''
                        }`}
                    </p>
                    {/* show daterange value */}
                    <p>
                      {`${col.type === 'DateTime' && searchQuery[col.key]
                        ? `= ${moment(searchQuery[col.key]?.startDate)?.format('DD-MM-YYYY')} => ${moment(
                          searchQuery[col.key]?.endDate
                        )?.format('DD-MM-YYYY')}`
                        : ''
                        }`}
                    </p>
                  </FlexContainer>
                )}
                {/* search/cancel icon only for input type */}
                {isOptionVisible(OPTIONS_TYPES.SEARCHABLE) && (
                  <SearchIcon onClick={() => handleUICompVisiblity({ field: col.key, index })}>
                    {!showSearchInp[col.key] ? (
                      <DynamicSVGIcon width='1.6rem' iconUrl={searchBlack} />
                    ) : (
                      <DynamicSVGIcon width='1.6rem' iconUrl={CancelBlack} />
                    )}
                  </SearchIcon>
                )}

                {/* dropdown icon for open/close state */}
                {(isOptionVisible(OPTIONS_TYPES.HAS_OPTIONS) || isOptionVisible(OPTIONS_TYPES.IS_DATE_TIME)) && (
                  // dropdown/cancel icon based on value
                  <SearchIcon>
                    {!searchQuery[col.key] ? (
                      <div
                        style={{
                          height: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          transform: 'rotate(90deg)'
                        }}
                        onClick={() => handleUICompVisiblity({ field: col.key, index, type: col.type }, 'dropdown')}
                      >
                        <DynamicSVGIcon iconUrl={rightArrow} width='3.5rem' />
                      </div>
                    ) : (
                      <FlexContainer>
                        <div
                          style={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            transform: 'rotate(270deg)'
                          }}
                          onClick={() => handleSearchQuery(col.key, '', true)}
                        >
                          <DynamicSVGIcon width='1.6rem' iconUrl={CancelBlack} />
                        </div>
                      </FlexContainer>
                    )}
                  </SearchIcon>
                )}
              </FlexContainer>

              {/* dropdown ui */}
              <FlexContainer className='ai_center, jc_center' style={{ width: '100%', position: 'relative' }}>
                {col.type !== 'DateTime' && showDropdown[col.key] && (
                  <DropDownModal
                    ContainerStyle={{ top: '0px' }}
                    version={2}
                    isActive={showDropdown[col.key]}
                    data={col.options}
                    handleClick={handleDropdownItem}
                    setModalIsVisibal={closeDropdown}
                    field={col.key}
                  />
                )}
              </FlexContainer>
              {/* date dropdown ui */}
              <div>{col.type === 'DateTime' && showDropdown[col.key] && <>{DateRangeModal(col)}</>}</div>
            </Th>
          ) : null;
        })}
        {/* </Tr> */}
        {/* filteredData */}
        {data.map((entry, index) => (
          <RenderNode colProps={colProps} data={entry} collapse={collapse} key={index} handleDetails={handleDetails} />
        ))}
        {showToast && (
          <Toast
            message={toastMsg.current}
            style={{ right: '20px', bottom: '30px' }}
            header='note'
            shouldFade={false}
          />
        )}
      </Tabel>
    </TableContainerTemplate>
  );
};

export default Table;
