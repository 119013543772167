import styled, { css } from 'styled-components';
import { fontFamily, fontSize } from '../constants';
import { shadow } from '../constants/colors';

export const Div = styled.div`
  ${(props) =>
    props.m &&
    css`
      margin: ${props.m}rem;
    `};

  ${(props) =>
    props.ml &&
    css`
      margin-left: ${props.ml}rem;
    `};

  ${(props) =>
    props.mr &&
    css`
      margin-right: ${props.mr}rem;
    `};

  ${(props) =>
    props.mt &&
    css`
      margin-top: ${props.mt}rem;
    `};

  ${(props) =>
    props.mb &&
    css`
      margin-bottom: ${props.mb}rem;
    `};

  ${(props) =>
    props.mx &&
    css`
      margin: 0 ${props.mx}rem;
    `};

  ${(props) =>
    props.my &&
    css`
      margin: ${props.my}rem 0;
    `};

  ${(props) =>
    props.p &&
    css`
      pading: ${props.p}rem;
    `};

  ${(props) =>
    props.pl &&
    css`
      padding-left: ${props.pl}rem;
    `};

  ${(props) =>
    props.pr &&
    css`
      padding-right: ${props.pr}rem;
    `};

  ${(props) =>
    props.pt &&
    css`
      padding-top: ${props.pt}rem;
    `};

  ${(props) =>
    props.pb &&
    css`
      padding-bottom: ${props.pb}rem;
    `};

  ${(props) =>
    props.px &&
    css`
      padding: 0 ${props.px}rem;
    `};

  ${(props) =>
    props.py &&
    css`
      padding: ${props.py}rem 0;
    `};
`;

export const FlexContainer = styled(Div)`
  display: flex;
  &.col {
    flex-direction: column;
  }

  &.jc_center {
    justify-content: center;
  }

  &.jc_end {
    justify-content: flex-end;
  }

  &.jc_spacebetween {
    justify-content: space-between;
  }

  &.ai_center {
    align-items: center;
  }

  &.pointer {
    cursor: pointer;
  }
  &.relative {
    position: relative;
  }

  &.jc_spaceevenly {
    justify-content: space-evenly;
  }
`;

export const Image = styled.img`
  width: ${({ width }) => `${width}rem`};
  height: ${({ width }) => `${width}rem`};
`;

export const Button = styled.button`
  padding: 2rem 3.5rem;

  font-size: ${fontSize.titleBig};
  font-family: ${fontFamily.circularMedium};
  text-transform: capitalize;

  background-color: ${({ theme }) => theme.customTheme.primary};
  color: white;

  display: inline;
  text-align: 'center';

  border-radius: 5px;
  border: 0px;

  box-shadow: ${shadow.out};
  cursor: pointer;

  &:disabled {
    background-color: ${({ theme }) => theme.customTheme.secondary};
    pointer-events: none;
    opacity: 0.6;
  }
  &.flex {
    display: flex;
  }

  &.black {
    background-color: black;
    color: white;
  }

  &.white {
    color: black;
    background-color: white;
  }

  &.jc_center {
    justify-content: center;
  }

  &.jc_spacebetween {
    justify-content: space-between;
  }

  &.ai_center {
    align-items: center;
  }

  & > .dynamicSVGIcon {
    margin: auto 1rem;
  }

  ${({ width }) =>
    width &&
    css`
      width: ${width}rem;
    `};

  ${({ height }) =>
    height &&
    css`
      height: ${height}rem;
    `};
`;

export const Title = styled.span`
  font-size: 2.2rem;
  font-family: ${fontFamily.circularBold};
  &.block {
    display: block;
  }
`;

export const IconContainer = styled.div`
  padding: 1rem;
  cursor: pointer;
`;

export const Span = styled.span`
  font-size: 1.6rem;
  &.padding {
    padding: ${({ padding }) => padding || '2rem'};
  }
`;
