/* eslint-disable max-len */
// import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FlexContainer } from '../../../globalStyles';
import { isEmpty } from '../../../shared/utility/isEmpty';
import { MONTHS } from '../MonthRangePicker/utility/constants';
import QuarterPicker from './QuarterPicker/QuarterPicker';

export default function QuarterRangePicker({ initValue, handleSelection, quarterStartsOn }) {
  const [isAtStart, setIsAtStart] = useState(1);

  const [hoveredDate, setHoveredDate] = useState('');
  const todaysYear = new Date().getFullYear();
  const todaysMonth = new Date().getMonth();

  const startInit = quarterStartsOn;
  let start = startInit;
  // get the correct quarter month range of the currentMonth
  while (!((start < todaysMonth && todaysMonth < start + 2) || todaysMonth === start || todaysMonth === start + 2)) {
    start = (start + 3) % 12;
  }
  const end = start + 2;
  const startObj = {
    month: start % 12,
    year: todaysYear + Math.floor(start / 12)
  };
  const endObj = {
    month: end % 12,
    year: todaysYear + Math.floor(end / 12)
  };
  const currDate = { start: startObj, end: endObj };
  const [selectedRange, setSelectedRange] = useState({
    start: currDate,
    end: currDate
  });
  const [year, setYear] = useState({ start: todaysYear - 1, end: todaysYear });

  const formatObj = (obj) =>
    `${MONTHS[obj.start.start.month]} ${obj.start.start.year.toString().substring(2)} - ${
      MONTHS[obj.end.end.month]
    } ${obj.end.end.year.toString().substring(2)}`;

  const formatSelectionObj = (obj) => ({
    value: formatObj(obj),
    rawValue: obj
  });

  useEffect(() => {
    if (!isEmpty(initValue)) {
      setSelectedRange(initValue);
    } else {
      handleSelection(formatSelectionObj(selectedRange));
    }
  }, []);

  return (
    <FlexContainer style={{ width: '100%' }}>
      <QuarterPicker
        selectedRange={selectedRange}
        setSelectedRange={setSelectedRange}
        isAtStart={isAtStart}
        setIsAtStart={setIsAtStart}
        title='start'
        hoveredDate={hoveredDate}
        setHoveredDate={setHoveredDate}
        year={year}
        setYear={setYear}
        startInit={startInit}
        handleSelection={handleSelection}
        formatSelectionObj={formatSelectionObj}
      />
      <QuarterPicker
        isAtStart={isAtStart}
        title='end'
        setIsAtStart={setIsAtStart}
        selectedRange={selectedRange}
        setSelectedRange={setSelectedRange}
        hoveredDate={hoveredDate}
        setHoveredDate={setHoveredDate}
        year={year}
        setYear={setYear}
        startInit={startInit}
        handleSelection={handleSelection}
        formatSelectionObj={formatSelectionObj}
      />
    </FlexContainer>
  );
}
