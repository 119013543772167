/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { MainBodyContainer } from '../../component/Common/CommonStyles';
// import Pagination from '../../component/Common/Pagination/Pagination.view';
import TabsTable from '../../component/Common/Tabs_Table';
// import {
//   columnsDetails,
//   userData,
//   userformStructure
// } from '../../component/Users/data';
import { useActions } from '../../store/hooks/useActions';
import {
  Container,
  HeaderText,
  HeadingBox,
  HeadingContainer,
  Button,
  ButtonContainer
} from './Users.style';
import UsersHeader from './UsersHeader';
import Loader from '../../component/Loader/Loader';
import Toast from '../../component/Common/Toast';
import { apiEndPoints } from '../../services/axios/endPoints';
import { isEmpty } from '../../shared/utility/isEmpty';
import { fetchUserAndLocation } from '../../component/Sidenav/fetchPageStatistics';

const selectionTypeandPosition = {
  isCheckboxTypeSelection: true,
  isSelectionAtFirst: true
};

export const Users = (props) => {
  const pageSize = 20;
  const [assetShortlist, setAssetShortlist] = useState([]);
  const history = useHistory();
  const {
    userReducerValueSetter,
    updateUsersData,
    getUserTableData,
    resetUserDataFlags,
    getUsersList,
    getUsersListFailure,
    setSearchQueries
  } = useActions();
  const {
    usersData,
    usersMeta,
    userPermissions,
    loading,
    error,
    userCount,
    searchQueries
  } = useSelector((state) => state.usersDetails);

  // const userPermissions = {
  //   CanAdd: 1,
  //   CanChangePassword: 1,
  //   CanEdit: 0
  // };
  const locations = useLocation();
  const { state } = locations;
  const { record } = state || {};

  const handlePageChange = (page, rest = {}) => {
    const { searchParams } = rest;
    getUsersList({ page, pageSize, searchParams });
  };

  const setAssetSearchQueries = (searchQueries) => {
    setSearchQueries(searchQueries || {});
  };

  useEffect(() => {
    handlePageChange(1);

    return () => {
      setSearchQueries();
    };
  }, []);

  const handleShortList = (e, isCheckRequired = true) => {
    if (isCheckRequired) {
      if (!assetShortlist.includes(e)) {
        setAssetShortlist([...assetShortlist, e]);
      } else {
        assetShortlist.splice(assetShortlist.indexOf(e), 1);
        setAssetShortlist([...assetShortlist]);
      }
    } else {
      setAssetShortlist([...e]);
    }
  };

  const [showDataDiffModal, setShowDataDiffModal] = useState(false);

  const handleNextAfterUpload = () => {
    setShowDataDiffModal(true);
  };
  const saveFinalRecords = (records) => {
    const { resolvedRecords, freshRecords } = records;
    console.log('saving records....', resolvedRecords, freshRecords);
    updateUsersData({
      resolvedRecords, // object
      freshRecords // array
    });
    setTimeout(() => {
      updateUsersData({
        resolvedRecords: {}, // object
        freshRecords: [] // array
      });
      resetUserDataFlags();
    }, [3000]);
  };

  const saveEditedRecord = (record) => {
    updateUsersData({
      resolvedRecords: {
        [record.employeeId]: record
      }, // object
      freshRecords: [] // array
    });
    setTimeout(() => {
      updateUsersData({
        resolvedRecords: {}, // object
        freshRecords: [] // array
      });
      resetUserDataFlags();
    }, [3000]);
  };

  useEffect(() => {
    if (record) {
      saveEditedRecord(record);
    }
  }, []);

  const handleUserCreate = () => {
    history.push({
      pathname: '/home/user/add',
      state: {
        isFormEditable: true,
        formInput: {},
        formApi: apiEndPoints.getUserForm,
        saveForm: apiEndPoints.createUserDetails
      }
    });
  };

  const handleShowDetails = (ele) => {
    history.push('/home/user/details', {
      formInput: ele,
      isFormEditable: false,
      formApi: apiEndPoints.getUserUpdateForm,
      saveForm: apiEndPoints.updateUserDetails
    });
  };

  const clearError = () => {
    getUsersListFailure('');
  };
  const rowOptions =
    userPermissions?.CanEdit && userPermissions?.CanChangePassword
      ? [
          { id: 1, label: 'Edit details', value: 'editDetails' },
          { id: 2, label: 'Change Password', value: 'changePassword' }
        ]
      : userPermissions?.CanEdit && !userPermissions?.CanChangePassword
      ? [{ id: 1, label: 'Edit details', value: 'editDetails' }]
      : !userPermissions?.CanEdit && userPermissions?.CanChangePassword
      ? [{ id: 2, label: 'Change Password', value: 'changePassword' }]
      : [];

  const handleRowOptionsItemClick = ({ value }, { activeRowItem }) => {
    if (value === 'editDetails') {
      history.push({
        pathname: '/home/user/edit',
        state: {
          isFormEditable: true,
          formInput: activeRowItem,
          formApi: apiEndPoints.getUserUpdateForm,
          saveForm: apiEndPoints.updateUserDetails
        }
      });
    } else if (value === 'changePassword') {
      history.push({
        pathname: '/home/user/edit',
        state: {
          isFormEditable: true,
          isFormChangePass: true,
          formInput: activeRowItem,
          formApi: apiEndPoints.getChangePasswordForm,
          saveForm: apiEndPoints.passwordUser
        }
      });
    }
  };
  const location = useLocation();
  const { userData } = useSelector((state) => state.loginData);
  const { finalMenu } = useSelector((state) => state.home);
  useEffect(() => {
    const name = finalMenu.find((ele) => ele.path === location.pathname);
    fetchUserAndLocation(location.pathname, userData, {
      pageType: name?.name
    });
  }, []);

  return (
    <>
      <MainBodyContainer>
        <UsersHeader
          hasBack={false}
          title='Users'
          isCreate
          canAdd={!!userPermissions?.CanAdd}
          canEdit={userPermissions?.CanEdit}
          handleUserCreate={handleUserCreate}
          handleNext={handleNextAfterUpload}
          handlePageChange={handlePageChange}
          pageSize={pageSize}
          userCount={userCount}
        />
        {loading ? (
          <Loader />
        ) : (
          <TabsTable
            metaDetails={usersMeta}
            dataDetails={usersData}
            showPagiation={false}
            showShortlistSelection={false}
            // handleShortList={handleShortList}
            onItemSelect={handleShowDetails}
            assetShortlist={assetShortlist}
            handlePageChange={handlePageChange}
            styleType='strapedTable'
            noItemsFoundMessage='No Users Found'
            rowOptions={!isEmpty(rowOptions) && rowOptions}
            showRowOptionAtStart={false}
            id='UserID'
            handleRowOptionsItemClick={handleRowOptionsItemClick}
            shortListSelectionDetails={selectionTypeandPosition}
            saveSearchQueries={setAssetSearchQueries}
            savedSearchQueries={searchQueries}
            resetSearchQueries={setAssetSearchQueries}
          />
        )}
      </MainBodyContainer>
      {error && (
        <Toast
          header='Error'
          className='fixed right'
          message={error}
          fn={clearError}
        />
      )}
    </>
  );
};
