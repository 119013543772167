import styled from 'styled-components';

export const Container = styled.div`
  z-index: 100;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  &.position {
    position: absolute;
  }

  &.submitBtn {
    /* position: absolute; */
    width: 4.4rem;
    padding: 2px;
    height: 0;
  }

  &.attachmentSaver {
    width: 30%;
    height: 30%;
    padding: 0;
    position: relative;
    left: 35%;
  }
  &.insideTable {
    position: absolute;
    top: 0;
    right: 0;
  }

  & path {
    stroke: ${({ theme }) => theme.customTheme.primary};
  }
  & circle {
    fill: ${({ theme }) => theme.customTheme.primary};
  }
`;
