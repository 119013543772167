/* eslint-disable max-len */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Loader from '../Loader/Loader';
import PopUpModal from '../PopUpModal/Modal.view';

import { BtnWrapper, Button, ContentText, Heading } from './ConfimationBox.styles';
import { Input } from '../..';

const ConfirmationModal = ({
  Header,
  closeModalHandler,
  showModal,
  handleDelete,
  message,
  loading,
  buttonLoading,
  needReason
}) => {
  const localeStrings = useSelector((state) => state.localeStrings.localeStrings);
  const [reason, setReason] = useState('');
  if (!showModal) return <></>;
  return (
    <PopUpModal closeModalHandler={closeModalHandler}>
      <Heading>{Header || localeStrings.Delete || 'Delete'}</Heading>
      {loading && (
        <div style={{ position: 'relative', width: '100%', height: '100%', background: 'red' }}>
          <Loader />
        </div>
      )}
      <ContentText>{message}</ContentText>
      {needReason && (
        <Input
          type='text'
          placeholder='Please enter reason for deletion'
          value={reason}
          handleInputChange={(e) => {
            setReason(e.target.value);
          }}
        />
      )}
      <BtnWrapper>
        <Button onClick={() => closeModalHandler(false)}>{localeStrings.cancel || 'Cancel'}</Button>
        <Button onClick={() => handleDelete(reason)} className={buttonLoading ? 'loading' : 'dark'}>
          {buttonLoading ? (
            <div style={{ position: 'absolute', width: '30%', height: '4rem' }}>
              <Loader position='relative' />
            </div>
          ) : (
            localeStrings.Delete || 'Delete'
          )}
        </Button>
      </BtnWrapper>
    </PopUpModal>
  );
};

export const ConfirmationActionModal = ({
  closeModalHandler,
  showModal,
  action,
  handleConfirm,
  message,
  loading,
  middleBtnMsg = '',
  showMiddleBtn,
  handleMiddleBtn = () => {}
}) => {
  const localeStrings = useSelector((state) => state.localeStrings.localeStrings);
  if (!showModal) return <></>;
  return (
    <PopUpModal closeModalHandler={closeModalHandler}>
      {/* <Heading>{localeStrings.Delete || 'Delete'}</Heading> */}
      {loading && (
        <div style={{ position: 'relative', width: '100%', height: '100%', background: 'red' }}>
          <Loader />
        </div>
      )}
      <ContentText>{message}</ContentText>
      <BtnWrapper>
        <Button onClick={() => closeModalHandler(false)}>{localeStrings.cancel || 'Cancel'}</Button>
        <Button style={{ margin: '0px 2.2rem 0px' }} onClick={handleMiddleBtn}>
          {middleBtnMsg}
        </Button>
        {showMiddleBtn && (
          <Button onClick={handleConfirm} className='dark'>
            {action}
          </Button>
        )}
      </BtnWrapper>
    </PopUpModal>
  );
};

export default ConfirmationModal;
