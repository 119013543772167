/* eslint-disable max-len */
import styled from 'styled-components';

//  border: ${({ theme, isActive }) => (isActive ? `1px solid ${theme.specificDetails.primary}` : '')};

export const TitleContainer = styled.span`
  font-weight: bold;
  text-transform: capitalize;
  border: ${({ theme, isActive }) => (isActive ? '1px solif black' : '')};
  border-radius: 1rem;
  padding: 1rem 0rem 0.5rem 1rem;
  background: ${({ theme }) => theme.filter.hover};
`;

export const Icons = styled.div`
  display: flex;

  & * {
    fill: black !important;
  }
`;
