/* eslint-disable no-case-declarations */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import moment from 'moment';
import { getDateObj } from '../../component/Common/Calendar/utility';
import { POSITION_MAPPING, SCHEDULE_FREQUENCY_TYPES } from '../../constants';
import { getISOAddingTimezone } from '../../services/utility/moment';
import { toIsoString } from '../../services/utility/toIsoDateWithoutTimezone';
import { uid } from '../../shared/utility';

const resolveWeekLabel = (day) => day?.toLowerCase()?.substring(0, 2);
// get rules from https://jakubroztocil.github.io/rrule/
// get recursive rules
const getRrRule = (payload, start) => {
  // include start date for events
  let freqUnit = 1;
  const {
    scheduleFrequency,
    frequency_day,
    frequency_dayofEvery,
    frequency_firstLast,
    frequency_firstLastDay,
    frequency_firstLastDayEvery,
    frequency_recurring,
    frequency_recurringDay
  } = payload;
  let rrRule = {};
  switch (scheduleFrequency) {
    case SCHEDULE_FREQUENCY_TYPES.DAILY:
      rrRule = {
        freq: 'Daily',
        interval: frequency_recurring || 0
      };
      break;
    case SCHEDULE_FREQUENCY_TYPES.WEEKLY:
    case SCHEDULE_FREQUENCY_TYPES.BI_WEEKLY:
      if (scheduleFrequency === SCHEDULE_FREQUENCY_TYPES.BI_WEEKLY) {
        freqUnit = 2;
      }
      rrRule = {
        freq: 'Weekly',
        interval: freqUnit * frequency_recurring || 0,
        byweekday: [resolveWeekLabel(frequency_recurringDay) || 'mo']
      };
      break;
    case SCHEDULE_FREQUENCY_TYPES.MONTHLY:
    case SCHEDULE_FREQUENCY_TYPES.BI_MONTHLY:
      if (scheduleFrequency === SCHEDULE_FREQUENCY_TYPES.BI_MONTHLY) {
        freqUnit = 1;
      }
      if (frequency_day) {
        // option 1: day 20 of 1 every month
        rrRule = {
          freq: 'Monthly',
          interval: freqUnit * frequency_dayofEvery || 0,
          bymonthday: [frequency_day || 1]
        };
        break;
      }
      // option 2: first sunday of every month
      rrRule = {
        freq: 'Monthly',
        interval: freqUnit * frequency_firstLastDayEvery || 0,
        bysetpos: [POSITION_MAPPING[frequency_firstLast] || 1], // first, second, third, fourth mapping
        byweekday: [resolveWeekLabel(frequency_firstLastDay) || 'mo']
      };
      break;
    case SCHEDULE_FREQUENCY_TYPES.YEARLY:
    case SCHEDULE_FREQUENCY_TYPES.HALF_QUARTERLY:
    case SCHEDULE_FREQUENCY_TYPES.QUARTERLY:
      if (scheduleFrequency === SCHEDULE_FREQUENCY_TYPES.HALF_QUARTERLY) {
        freqUnit = 1;
      } else if (scheduleFrequency === SCHEDULE_FREQUENCY_TYPES.QUARTERLY) {
        freqUnit = 4;
      }
      if (frequency_day) {
        // option 1: day 20 of 1 every year
        rrRule = {
          freq: 'Yearly',
          interval: freqUnit * frequency_dayofEvery || 0,
          byyearday: [frequency_day || 0]
        };
        break;
      }
      // option 2: first sunday of every year
      rrRule = {
        freq: 'Yearly',
        interval: freqUnit * frequency_firstLastDayEvery || 0,
        bysetpos: [POSITION_MAPPING[frequency_firstLast] || 1], // first, second, third, fourth mapping
        byweekday: [resolveWeekLabel(frequency_firstLastDay) || 'mo']
      };
      break;

    default:
      break;
  }
  // console.log('dtstart', start);
  return { ...rrRule, dtstart: start };
};

export const formatEvents = (data) =>
  // console.log('formatt data', data);
  data.map((e, index) => {
    const { planId, activity, asset, starttime, endtime, startDate, enddate } = e;
    const sd = getDateObj(startDate, true); // isMoment=true
    const ed = getDateObj(enddate, true);
    if (starttime) {
      const [h, m, s] = starttime.split(':');
      sd.set({ hours: h, minutes: m, seconds: s });
    }
    if (endtime) {
      const [h, m, s] = endtime.split(':');
      ed.set({ hours: h, minutes: m, seconds: s });
    }
    // TODO: not reflecting in the event payload of sidepanel
    const timeRange = {
      start: toIsoString(new Date(getDateObj(sd))),
      end: toIsoString(new Date(getDateObj(ed)))
    };
    const dstart = getISOAddingTimezone(getDateObj(sd));
    // console.log('e', timeRange, enddate);
    return {
      id: uid(),
      // api
      title: e.scheduleName,
      // local
      // title: e.title,
      ...timeRange,
      allDay: false,
      // rrule: getRrRule(e, dstart),
      rrRule: { dtstart: dstart },
      details: { planId, activity, asset, starttime, endtime, startDate, enddate }
    };
  });
