/* eslint-disable no-useless-escape */
/* eslint-disable max-len */
import { v4 as uuid } from 'uuid';

export const uid = () => uuid();

export const isDate = (value) => new Date(value).getTime() > 0;

const isoRegex =
  /^([\+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24\:?00)([\.,]\d+(?!:))?)?(\17[0-5]\d([\.,]\d+)?)?([zZ]|([\+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/;
export const isIsoDateString = (obj) => obj.match(isoRegex);
export const Convert24To12 = (timeArg) => {
  // Check correct time format and split into components
  let time = timeArg.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [timeArg];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(''); // return adjusted time or original string
};

function encodeToHex(input) {
  let hex = '';
  for (let i = 0; i < input.length; i += 1) {
    hex += input.charCodeAt(i).toString(16);
  }
  return hex;
}

function reverseString(input) {
  return input.split('').reverse().join('');
}

function encodeToBase64(input) {
  return btoa(input);
}

export const encodeUserDetails = (inputString = { userid: 'Lino', password: 'Code&50' }) => {
  const encodedHex = encodeToHex(JSON.stringify(inputString));
  // console.log('Encoded HEX:', encodedHex);

  const reversedHex = reverseString(encodedHex);
  // console.log('Reversed HEX:', reversedHex);

  const encodedBase64 = encodeToBase64(reversedHex);
  // console.log('Encoded BASE64:', encodedBase64);

  const reversedBase64 = reverseString(encodedBase64);
  // console.log('Reversed BASE64:', reversedBase64);

  const encodedBase64Again = encodeToBase64(reversedBase64);
  // console.log('Encoded BASE64 Again:', encodedBase64Again);

  return encodedBase64Again;
};
