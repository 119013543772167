/* eslint-disable max-len */
import React, { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from './globalStyles/globalStyles';
import { colors } from './constants/colors';
import Routes from './routes/Routes';
import { getUserDataFromLocal } from './shared/utility/helper';
import { Creators as loginCreators } from './views/auth/store';
import { Creators as localeStringsCreators } from './localeStrings/index';
import { Creators as configCreators } from './config/action';
import { setAccessTokenToHeader, setCustomHeader } from './services/axios/helper';

function App() {
  const { theme, language, colors: customTheme } = useSelector((state) => state.configData);
  const { userData, keyField } = useSelector((state) => state.loginData);
  const { uName, userId, email } = userData;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(localeStringsCreators.getLocaleStrings({ pageId: '1023', lang: language }));
    const userData = getUserDataFromLocal();
    if (userData?.token) {
      dispatch(loginCreators.setUserDetails({ token: userData.token, userData: userData.userData }));
    }
  }, []);
  useEffect(() => {
    dispatch(configCreators.setThemeColors({ company: 'wd', theme }));
  }, [theme]);

  // setToken to headers
  useEffect(() => {
    // setting the token as whatever is used for login(uName)
    if (uName || userId || email) {
      // console.log('setting', uName, userId, email);
      setAccessTokenToHeader(uName || userId || email);
      setCustomHeader(userData[keyField]);
    }
  }, [userData, keyField]);

  // console.log('theme', { ...colors[theme], customTheme });
  return (
    <Suspense fallback={<div>Loading... </div>}>
      <ThemeProvider theme={{ ...colors[theme], customTheme }}>
        <GlobalStyles />
        <Routes />
      </ThemeProvider>
    </Suspense>
  );
}

export default App;
