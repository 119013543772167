/* eslint-disable max-len */
/* eslint-disable prefer-destructuring */
import moment from 'moment';
import { createReducer } from 'reduxsauce';
import { Types } from './action';

const INITIAL_STATE = {
  error: null,
  loading: false,
  depedentLoading: false,
  iframeLoading: false,
  tags: {},
  cumulativeTags: {},
  selectedTabs: {},
  deletingTag: null,
  selectedItem: [],
  selectedDate: moment(),
  isDropDownActive: false,
  searchResult: null,
  resultType: 'iframe',
  filterData: null,
  meta: {},
  showFilterResult: false,
  iframeRes: null,
  showFilter: false,
  // singleSelectionGroups: [],
  menuIdofFilter: '',
  filterPayload: null,
  assetType: '',
  partNumber: '',
  leafLevels: {},
  selectedParent: null,
  breadCrumbs: [],
  activeBreadCrumb: 0,
  dependencies: [],
  dependents: {},
  keyTitleMapping: {},
  inputs: {}
};

const setMenuIdOfFilter = (state = INITIAL_STATE, action) => ({
  ...state,
  menuIdofFilter: action.payload
});

const handleFilterChange = (state = INITIAL_STATE, action) => ({
  ...state,
  filterData: INITIAL_STATE.filterData,
  showFilterResult: INITIAL_STATE.showFilterResult,
  // selectedParent: INITIAL_STATE.selectedParent,
  showFilter: INITIAL_STATE.showFilter,
  iframeRes: INITIAL_STATE.iframeRes
});

// const setSingleSelectionGroups = (state = INITIAL_STATE, action) => ({
//   ...state,
//   singleSelectionGroups: action.payload
// });

const setTags = (state = INITIAL_STATE, action) => ({
  ...state,
  tags: action.payload,
  deletingTags: null
});

// const addTags = (state = INITIAL_STATE, action) => {
//   const { key, value } = action;
//   return {
//     ...state,
//     tags: { ...state.tags, [key]: value }
//   };
// };

const populateFilterTags = (state = INITIAL_STATE, action) => {
  const cummTags = action.payload;
  const groups = Object.keys(cummTags);
  const referenceObj = {};
  groups.forEach((group) => {
    referenceObj[group] = cummTags[group][0];
  });
  return {
    ...state,
    tags: { ...referenceObj },
    cumulativeTags: cummTags
  };
};

const populateFilterSelectedObjs = (state = INITIAL_STATE, action) => {
  const cummSelObjs = action.payload;
  const groups = Object.keys(cummSelObjs);
  const selectionObj = {};
  const cummlativeSelectionObj = {};
  groups.forEach((group) => {
    selectionObj[group] = cummSelObjs[group][0];
    cummlativeSelectionObj[`cumulative${group}`] = cummSelObjs[group];
  });
  return {
    ...state,
    ...selectionObj,
    ...cummlativeSelectionObj
  };
};

const setCummulativeTags = (state = INITIAL_STATE, action) => ({
  ...state,
  cumulativeTags: action.payload
});

const setSingleTag = (state = INITIAL_STATE, action) => {
  const { groupTitle } = action.payload;
  return {
    ...state,
    tags: { ...state.tags, [groupTitle]: action.payload[groupTitle] },
    cumulativeTags: {
      ...state.cumulativeTags,
      [groupTitle]: {
        // ...state.cumulativeTags[groupTitle],
        // Object.keys(state.cumulativeTags).length - 1
        0: {
          ...action.payload[groupTitle]
        }
      }
    },
    [groupTitle]: { ...action.payload.selectedObj },
    [`cumulative${groupTitle}`]: { 0: { ...action.payload.selectedObj } }
  };
};

const setSelectedTabs = (state = INITIAL_STATE, action) => ({
  ...state,
  selectedTabs: action.payload
});

const deletingTags = (state = INITIAL_STATE, action) => ({
  ...state,
  deletingTag: action.payload
});

const setSelectedItem = (state = INITIAL_STATE, action) => ({
  ...state,
  selectedItem: action.payload
});

const universalFilterSetter = (state = INITIAL_STATE, action) => ({
  ...state,
  [action.payload.key]: action.payload.value
});

const getFilterDataStart = (state = INITIAL_STATE) => ({
  ...state,
  loading: true
});

const getFilterDependencyDataStart = (state = INITIAL_STATE, action) => ({
  ...state,
  depedentLoading: true,
  dependentOn: action.payload.dependentOn
});

const getFilterDataSuccess = (state = INITIAL_STATE, action) => {
  const { assetType, data, meta, resultType, parent, dependencies, dependents, keyTitleMapping, menuId } =
    action.payload;
  return {
    ...state,
    loading: false,
    filterData: data,
    meta,
    resultType,
    assetType,
    selectedParent: parent,
    dependencies,
    dependents,
    keyTitleMapping,
    menuIdofFilter: menuId || state.menuIdofFilter
  };
};

const getFilterDependencyDataSuccess = (state = INITIAL_STATE, action) => {
  const { filterData, resetDependents } = action.payload;
  const nonDependentFilterGroups = state.filterData.filter((e) => filterData.every((inner) => inner.id !== e.id));
  return {
    ...state,
    depedentLoading: false,
    filterData: [...nonDependentFilterGroups, ...filterData],
    ...resetDependents,
    dependentOn: ''
  };
};

const getFilterDataFail = (state = INITIAL_STATE, action) => ({
  ...INITIAL_STATE,
  loading: false,
  menuIdofFilter: state.menuIdofFilter,
  error: action.payload.error,
  selectedParent: action.payload.parent
});

// TO-DO error handling for filterDependency fetching
const getFilterDependencyDataFail = (state = INITIAL_STATE, action) => {
  const dependents = action.payload;
  let dupCumulativeTags = { ...state.cumulativeTags };
  let dupTags = { ...state.tags };
  const objs = dependents
    .map((e) => state.keyTitleMapping[e])
    .reduce((final, curr) => {
      dupCumulativeTags = { ...dupCumulativeTags, [curr]: {} };
      dupTags = { ...dupTags, [curr]: {} };
      return {
        ...final,

        [curr]: {},
        [`cumulative${curr}`]: {}
      };
    }, {});
  return {
    ...state,
    depedentLoading: false,
    dependentOn: '',
    filterData: state.filterData.filter((e) => !dependents.includes(e.keyName)),
    ...objs,
    cumulativeTags: dupCumulativeTags,
    tags: dupTags
  };
};

const getIFrameDataStart = (state = INITIAL_STATE) => ({
  ...state,
  iframeLoading: true
});

const getIFrameDataSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  iframeLoading: false,
  iframeRes: action.payload
});

const getIFrameDataFail = (state = INITIAL_STATE, action) => ({
  ...state,
  iframeLoading: false,
  error: action.payload.error
});

const resetFilterState = () => INITIAL_STATE;

const clearSelectedFilter = (state = INITIAL_STATE) => ({
  // need to fetch again onclear because of dependencies
  ...INITIAL_STATE,
  // filterData: state.filterData,
  showFilter: true,
  selectedParent: state.selectedParent
  // menuIdofFilter: ''
});

const populateFilterData = (state = INITIAL_STATE, action) => ({
  ...state,
  ...action.payload
});

const setPartNumber = (state = INITIAL_STATE, action) => {
  const { partNumber } = action.payload;

  return {
    ...state,
    partNumber
  };
};
const HANDLERS = {
  [Types.SET_TAGS]: setTags,
  [Types.SET_CUMMULATIVE_TAGS]: setCummulativeTags,
  [Types.SET_SELECTED_TABS]: setSelectedTabs,
  [Types.DELETING_TAGS]: deletingTags,
  [Types.SET_SELECTED_ITEM]: setSelectedItem,
  [Types.UNIVERSAL_FILTER_SETTER]: universalFilterSetter,
  [Types.SET_SINGLE_TAG]: setSingleTag,
  [Types.RESET_FILTER_STATE]: resetFilterState,
  [Types.GET_FILTER_DATA_START]: getFilterDataStart,
  [Types.GET_FILTER_DATA_SUCCESS]: getFilterDataSuccess,
  [Types.GET_FILTER_DATA_FAIL]: getFilterDataFail,
  [Types.CLEAR_SELECTED_FILTER]: clearSelectedFilter,
  [Types.GET_I_FRAME_DATA_START]: getIFrameDataStart,
  [Types.GET_I_FRAME_DATA_SUCCESS]: getIFrameDataSuccess,
  [Types.GET_I_FRAME_DATA_FAIL]: getIFrameDataFail,
  // [Types.SET_SINGLE_SELECTION_GROUPS]: setSingleSelectionGroups,
  [Types.POPULATE_FILTER_TAGS]: populateFilterTags,
  [Types.POPULATE_FILTER_SELECTED_OBJS]: populateFilterSelectedObjs,
  [Types.SET_MENU_ID_OF_FILTER]: setMenuIdOfFilter,
  [Types.SET_PART_NUMBER]: setPartNumber,
  [Types.HANDLE_FILTER_CHANGE]: handleFilterChange,
  [Types.POPULATE_FILTER_DATA]: populateFilterData,
  [Types.GET_FILTER_DEPENDENCY_DATA_START]: getFilterDependencyDataStart,
  [Types.GET_FILTER_DEPENDENCY_DATA_SUCCESS]: getFilterDependencyDataSuccess,
  [Types.GET_FILTER_DEPENDENCY_DATA_FAIL]: getFilterDependencyDataFail
  // [Types.ADD_TAGS]: addTags,
  // [Types.ADD_CUMMULATIVE_TAGS]: addCummulativeTags
  // [Types.SET_RESULT_DETAILS]: setResultDetails,
  // [Types.SET_KPV_SHORTLIST]: setKpvShorlist,
};

export const FilterReducer = createReducer(INITIAL_STATE, HANDLERS);
