import styled from 'styled-components';
import { fontFamily } from '../../../../../constants';

export const Header = styled.div`
  color: ${({ theme }) => theme.contrast.quaternary};
  margin-bottom: 3rem;
  font: 1.8rem ${fontFamily.circularBook};
`;

export const Instruction = styled.div`
  color: ${({ theme }) => theme.errorText};
  margin-bottom: 3rem;
  font: 1.2rem ${fontFamily.circularBook};
`;

export const ButtonContainer = styled.div`
  width: 100%;
  padding: 0px 10%;
  display: flex;
  & button {
    width: 20rem;
  }
`;
