/* eslint-disable max-len */
import React, { useState, useRef } from 'react';
import useDebounce from '../../shared/hooks/useDebounce';
import DropDownModal from './DropDownModal/DropDownModal';
import SearchBar from './SearchBar';

const SearchBarWithDropdown = (props) => {
  const {
    // required props
    itemLabel = 'scheduleName',
    onItemSelect: handleItemSelect = () => {},
    dataSource = {
      dataKey: 'data',
      data: [
        { id: 1, scheduleName: 'item1' },
        { id: 2, scheduleName: 'item2' },
        { id: 3, scheduleName: 'item3' },
        { id: 4, scheduleName: 'item4' }
      ]
    },
    placeholder = 'Search for schedules',
    // optional
    width = '30rem'
  } = props;
  const [input, setInput] = useState('');
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const { dataKey } = dataSource;
  const initialDataList = dataSource[dataKey] || [];
  const [filteredData, setFilteredData] = useState(initialDataList);
  const searchBarRef = useRef(null);
  const handleSearch = () => {
    if (!isDropdownActive && document.activeElement === searchBarRef.current) {
      setIsDropdownActive(true);
    }
    // data source url
    // console.log('api call');
    // local search live search
    const fData = initialDataList.filter((e) => e[itemLabel]?.includes(input));
    setFilteredData(fData);
  };
  useDebounce(input, handleSearch, 300);

  const onItemSelect = (...args) => {
    const [item] = args;
    // console.log('args', args, item, item[itemLabel]);
    setInput(item[itemLabel]);
    handleItemSelect(...args);
  };

  return (
    <>
      <DropDownModal
        setModalIsVisibal={setIsDropdownActive}
        isActive={isDropdownActive}
        handleClick={onItemSelect}
        label={itemLabel}
        data={filteredData}
        singleSelection
        width={width}
      >
        <SearchBar
          input={input}
          searchBarRef={searchBarRef}
          placeholder={placeholder}
          width={width}
          onChange={(e) => setInput(e.target.value)}
        />
      </DropDownModal>
    </>
  );
};

export default SearchBarWithDropdown;
