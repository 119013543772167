/* eslint-disable function-paren-newline */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FlexContainer } from '../../../globalStyles';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { WebService } from '../../../services/axios/webServices';
import TabsTable from '../../Common/Tabs_Table';
import { TableContainer } from '../../Common/Tabs_Table/TabsTable.style';
import { Bttn } from '../CreateWorkOrder/AssetSelection/AssetSelection.style';
import { Creators as UpsertWorkOrderCreators } from '../CreateWorkOrder/store';
import { ModalListHeader } from './WorkOrderDetails.style';
import { useActions } from '../../../store/hooks/useActions';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';
import Toast from '../../Common/Toast';

const WoSelectedAsset = ({ setAssetListModal, backHandler }) => {
  const { assetShortlist, assetListMeta, submitUrl, shortListWithQty } = useSelector((state) => state.upsertWorkOrder);
  const type = useSelector((state) => state.workOrders.type);
  const { workOrderId } = useSelector((state) => state.workOrders);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const dispatch = useDispatch();
  const { setAssetShortlist, getWorkOrderDetails } = useActions();

  const handleShortList = (entry) => {
    const check = assetShortlist.some(
      (ele) =>
        ele.mainPartNumber === entry.mainPartNumber &&
        ele.inventPartnumber === entry.inventPartnumber &&
        ele.qtyAvailable === entry.qtyAvailable
    );
    if (!check) {
      dispatch(UpsertWorkOrderCreators.setAssetShortlist([...assetShortlist, entry]));
    } else {
      dispatch(
        UpsertWorkOrderCreators.setAssetShortlist(
          assetShortlist.filter(
            (ele) => !(ele.mainPartNumber === entry.mainPartNumber && ele.inventPartnumber === entry.inventPartnumber)
          )
        )
      );
    }
  };

  const postReqOnConfirm = async () => {
    const url = submitUrl || apiEndPoints.addPartAdd;
    try {
      const res = await WebService.post(`${url}/${workOrderId}`, JSON.stringify(assetShortlist));
      dispatch(UpsertWorkOrderCreators.setAssetShortlist([]));
      if (res.status >= 200) {
        // 2 params required for details
        getWorkOrderDetails({ workOrderId, type });
      } else {
        throw res;
      }
    } catch (err) {
      console.log(err, 'error');
      const payload = sagaCatchBlockHandling(err);
      setError(payload?.error);
    }
  };

  const additionalColumns = [
    {
      label: 'Qty Required',
      key: 'qtyRequired',
      type: 'number',
      placeholder: '',
      defaultValue: 0,
      validation: (val) => val > 0
    }
  ];

  const inputValidations = () =>
    assetShortlist.every((e) =>
      additionalColumns.every((col) => {
        const { key, validation, type } = col;
        let value = e[key];
        if (type === 'number') {
          // string to int
          if (typeof value === 'string') {
            value = parseInt(value, 10);
          }
        }
        return validation(value);
      })
    );

  const onConfirm = () => {
    postReqOnConfirm();
    setAssetShortlist([]);
    setAssetListModal(false);
  };

  const isValid = inputValidations();

  const handleCancel = () => {
    setAssetListModal(false);
    setAssetShortlist([]);
  };

  useEffect(() => {
    setLoading(false);
  });

  return (
    <>
      <FlexContainer style={{ height: '90%', overflow: 'auto' }} className='jc_center'>
        <TabsTable
          handleShortList={handleShortList}
          //   handlePageChange={handlePageChange}
          showPagiation={false}
          assetShortlist={assetShortlist}
          handleTabSelect={() => {}}
          // pageSize={() => {}}
          metaDetails={assetListMeta}
          dataDetails={assetShortlist}
          assetListLoading={false}
          onItemSelect={() => {}}
          additionalColumns={additionalColumns}
        />
      </FlexContainer>
      <FlexContainer className='jc_end ac_center' style={{ gap: '2rem' }}>
        <Bttn dark onClick={backHandler}>
          Back
        </Bttn>
        {/* <Bttn dark onClick={handleCancel}>
          Cancel
        </Bttn> */}
        <Bttn dark disabled={!isValid} onClick={onConfirm}>
          Confirm
        </Bttn>
      </FlexContainer>
      {error && <Toast message={error} header='Error' style={{ right: 30, zIndex: 20 }} fn={() => setError('')} />}
    </>
  );
};

export default WoSelectedAsset;
