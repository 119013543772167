/* eslint-disable max-len */
import { createActions } from 'reduxsauce';

export const { Types: PlanTypes, Creators: PlanCreators } = createActions({
  planValueSetter: ['payload'],
  // calendar events
  setActiveRange: ['payload'],
  setIsListView: ['payload'],
  // details
  getPlanDetails: ['payload'],
  getPlanDetailsStart: ['payload'],
  getPlanDetailsSuccess: ['payload'],
  getPlanDetailsFailure: ['payload'],

  // list reducer
  getPlanList: ['payload'],
  getPlanListStart: ['payload'],
  getPlanListSuccess: ['payload'],
  getPlanListFailure: ['payload'],
  setSearchQueries: ['payload']
});
