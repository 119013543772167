/* eslint-disable max-len */
import { useDispatch, useSelector } from 'react-redux';
import { Creators as SpareDetailsCreators } from './store';

const useGetSpares = () => {
  const { filtersPayload } = useSelector((state) => state.filterData);
  const dispatch = useDispatch();
  const pagesize = 10;

  const handlePageChange = (currentPage, rest = {}) => {
    const { searchParams } = rest;
    if (filtersPayload) {
      dispatch(
        SpareDetailsCreators.getSpareList({ payload: filtersPayload, pagesize, page: currentPage, searchParams })
      );
    }
  };
  return { handlePageChange, pagesize };
};

export default useGetSpares;
