import AddScheduleType from '../../component/ScheduleType/AddScheduleType/AddScheduleTyoe';
import EditScheduleType from '../../component/ScheduleType/EditScheduleType/EditScheduleType';
import ScheduleTypeDetails from '../../component/ScheduleType/ScheduleTypeDetails/ScheduleTypeDetails';

const ScheduleTypeRoutes = [
  { path: '/home/schedule-type/details', exact: true, name: 'schedule-type-details', Component: ScheduleTypeDetails },
  { path: '/home/schedule-type/create', exact: true, name: 'createScheduleType', Component: AddScheduleType },
  { path: '/home/schedule-type/edit', exact: true, name: 'editScheduleType', Component: EditScheduleType }
];

export default ScheduleTypeRoutes;
