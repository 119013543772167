/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Editor from '../../../assets/Editor/Editor';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { WebService } from '../../../services/axios/webServices';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';
import ButtonComp from '../../Common/Button/Button.view';
import { useActions } from '../../../store/hooks/useActions';
import { AvatarComponent } from '../..';
import { EditorBtn, EditorWrapper, RemarkContainer } from './WorkOrderDetails.style';
import { Container } from './RemarksList';
import { ImageContainer } from '../../Common/CommentItem/CommentItem.style';
import Toast from '../../Common/Toast';

const CreateRemark = ({ workOrderId, setLoader }) => {
  const { userData } = useSelector((state) => state.loginData);
  const { getRemark } = useActions();
  const [error, setError] = useState('');
  const [input, setInput] = useState({
    remarks: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };

  const handleSave = async () => {
    setLoader(true);

    const val = {
      data: [
        {
          joid: workOrderId,
          remarks: input.remarks,
          // TODO: change this once handled form backend
          userId: userData.userId
        }
      ]
    };
    try {
      const fullUrl = `${apiEndPoints.updateRemarks}`;
      // eslint-disable-next-line prefer-const, object-shorthand
      let res = await WebService.post(fullUrl, JSON.stringify(val));
      if (res.status >= 200) {
        getRemark(workOrderId);
        setInput();
      } else {
        setError(res.data?.message);
      }
    } catch (err) {
      console.log('error facing location:', err);
      const payload = sagaCatchBlockHandling(err);
      setError(payload?.error);
    } finally {
      setLoader(false);
    }
  };

  const handleCancelBtn = () => {
    setInput({ remarks: '' });
  };

  return (
    // <RemarkContainer>
    <>
      <Container>
        <ImageContainer style={{ height: '100%' }} className='ai_center'>
          {/* currently userdata is hardcoded */}
          <AvatarComponent userdata={userData} circle={true} />
        </ImageContainer>

        <EditorWrapper>
          <Editor
            width='97%'
            value={input.remarks}
            name='remarks'
            onChange={(e) => {
              handleChange({ target: { name: 'remarks', value: e } });
            }}
          />
          <EditorBtn>
            <ButtonComp
              dark={true}
              disabled={input.remarks === '<p><br></p>' || input.remarks === ''}
              onClick={handleSave}
            >
              Save
            </ButtonComp>
            <ButtonComp dark={true} onClick={handleCancelBtn}>
              Cancel
            </ButtonComp>
          </EditorBtn>
        </EditorWrapper>
      </Container>
      {error && <Toast message={error} header='Error' style={{ right: 30, zIndex: 20 }} fn={() => setError('')} />}
    </>
    // </RemarkContainer>
  );
};

export default CreateRemark;
