/* eslint-disable max-len */
import { createReducer } from 'reduxsauce';
import { sampleActivityList } from '../sampleData';
import { Types } from './action';

const INITIAL_STATE = {
  loading: false,
  searchQueries: {},
  error: '',
  activityData: [],
  activityCount: null,
  activityColumn: [],
  activityId: '',
  details: null
};

const setSearchQueries = (state = INITIAL_STATE, action) => ({
  ...state,
  searchQueries: { ...action.payload }
});

const editActivity = (state = INITIAL_STATE, action) => {
  const { input, id: inputId } = action.payload;
  const editObj = state.activityList.map((trow) => {
    const { id } = trow;
    if (id === inputId) {
      return action.payload.input;
    }
    return trow;
  });
  return {
    ...state,
    activityData: editObj
  };
};

const getActivityStart = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: true
});

const getActivityFailure = (state = INITIAL_STATE, action) => {
  console.log('failure', action.payload);
  return {
    ...state
  };
};
const getActivitySuccess = (state = INITIAL_STATE, action) => {
  // console.log('success', action.payload);
  const { activityData, meta } = action.payload;
  return {
    ...state,
    activityData,
    activityCount: meta?.count,
    // activityColumn: sampleActivityList?.meta,
    activityColumn: meta,
    loading: false
  };
};

const getActivityDetailsStart = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: true
});

const getActivityDetailsSuccess = (state = INITIAL_STATE, action) => {
  const { data } = action.payload;
  // console.log(data[0], 'af6723489239');
  return {
    ...state,
    details: action.payload[0],
    loading: false
  };
};

const getActivityDetailsFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;

  return {
    ...state,
    error,
    loading: false
  };
};

const setActivity = (state = INITIAL_STATE, action) => {
  console.log(action.payload.inputData, 'inputnsdnk');
  return {
    ...state,
    activityData: [...state.activityData, action.payload.inputData]
  };
};

const HANDLERS = {
  [Types.SET_SEARCH_QUERIES]: setSearchQueries,
  [Types.SET_ACTIVITY]: setActivity,
  [Types.EDIT_ACTIVITY]: editActivity,
  [Types.GET_ACTIVITY_START]: getActivityStart,
  [Types.GET_ACTIVITY_FAILURE]: getActivityFailure,
  [Types.GET_ACTIVITY_SUCCESS]: getActivitySuccess,
  [Types.GET_ACTIVITY_DETAILS_START]: getActivityDetailsStart,
  [Types.GET_ACTIVITY_DETAILS_SUCCESS]: getActivityDetailsSuccess,
  [Types.GET_ACTIVITY_DETAILS_FAILURE]: getActivityDetailsFailure
};

export const activityReducer = createReducer(INITIAL_STATE, HANDLERS);
