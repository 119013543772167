/* eslint-disable max-len */
import { createGlobalStyle } from 'styled-components';
import { device, minDevice } from '../constants';
import { maxSizeDevice } from '../constants/device';

export const GlobalStyles = createGlobalStyle`

  html {
    height: 100%;
      font-size: 62.5%; // 10px
    
    /* 100% = 16px */

    // 1921px - 1441px
    @media ${device.laptopXL} and ${minDevice.laptopL} { 
      font-size: 50%; // 8px
    }

    // 2560px - 1920px
    @media ${device.desktop} and ${minDevice.laptopXL} {
      font-size: 62.5%; // 10px
    }
 
    // 1440px - 768px
    @media ${device.laptopL} and ${minDevice.tablet} {
      font-size: 47.5%; // 7.6px
    }
  }
  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.contrast.quaternary};
    height: 100%;
  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Circular Std Book', sans-serif;
    scrollbar-color: #000000 #F5F5F5;
    scrollbar-width: thin;
  }

  
  .field-col{
    min-width: 150px;
    margin-bottom: 1rem;
    margin-right: 8rem;
    /* border: 5px solid red; */
    & .field-colon{
      margin-right: 2rem;
    }
  }
  .col_sm{
    /* background: yellow; */
    & .field-layout{
        display: block;
    }
    & .field-colon{
        display: none;
    }  
    // 768px - 1439px 
    @media ${maxSizeDevice.laptopL} and ${minDevice.tablet} {
      width: 150px;
      & .field-layout{
        display: block;
      }
      & .field-colon{
        display: none;
      }
    }
    
    // 1920px - 1440px common
    @media ${maxSizeDevice.laptopXL} and ${minDevice.laptopL} {
      width: 200px;
    }

    // 1920px 
    @media  ${minDevice.laptopXL} {
      width: 250px;
    }
  }

  .col_md{
    /* background: orange; */
    & .field-layout{
        display: block;
    }
    & .field-colon{
        display: none;
    }
    // 768px - 1440px
    @media ${maxSizeDevice.laptopL} and ${minDevice.tablet} {
      width: 200px;
      & .field-layout{
        display: block;
      }
      & .field-colon{
        display: none;
      }
    }

    // 1920px - 1440px common
    @media ${maxSizeDevice.laptopXL} and ${minDevice.laptopL} {
      width: 250px;
    } 

    // 1920px  
    @media ${minDevice.laptopXL} {
      width: 300px;
    }
  }

  .col_lg{
    /* background: red; */
    & .field-layout{
        display: block;
    }
    & .field-colon{
        display: none;
    }
    // 768px - 1440px 
     @media ${maxSizeDevice.laptopL} and ${minDevice.tablet} {
      width: 250px;
      & .field-layout{
        display: block;
      }
      & .field-colon{
        display: none;
      }
    }

    // 1920px - 1440px common
    @media ${maxSizeDevice.laptopXL} and ${minDevice.laptopL} {
      width: 300px;
    }

    // 1920px
    @media ${minDevice.laptopXL} {
      width: 350px;
    }
  }

  .col_xl{
    /* background: blue; */
    & .field-layout{
        display: block;
    }
    & .field-colon{
        display: none;
    }
    // 768px - 1440px 
     @media ${maxSizeDevice.laptopL} and ${minDevice.tablet} {
      width: 300px;
      & .field-layout{
        display: block;
      }
      & .field-colon{
        display: none;
      }
    }

    // 1920px - 1440px common
    @media ${maxSizeDevice.laptopXL} and ${minDevice.laptopL} {
      width: 350px;
    }

    // 1920px - 2560px 
    @media ${minDevice.laptopXL} {
      width: 400px;
    }
  }
    
  .non-selectable{
    user-select: none;
  }

  /* p {
    user-select: none;
    } */
  a {
    text-decoration: none;
    &.underline{
    text-decoration: underline;
    }
  }
  .cursor,.pointer{
    cursor:pointer;
  }
  .disable{
    pointer-events: none;
    opacity: 0.4
  }
  .required:after {
    content:" *";
  }
  .text-overflow{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .dynamicSVGIcon{
    height: auto;
  }
    
    .capitalize{
      text-transform: capitalize;
    }
    
    ::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px ${({ theme }) => theme.core.pureSecondary};
      background-color: ${({ theme }) => theme.core.pureSecondary}; 
      border-radius: 10px;
    }
    
    ::-webkit-scrollbar
    {
      width: 5px;
      height: 5px;
      background-color: transprent;
    }
    
    ::-webkit-scrollbar-thumb
    {
      background-color: ${({ theme }) => theme.customTheme.primary};
      border-radius: 10px;
    }
    
    .hideScrollBar{
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
    /* Hide scrollbar for Chrome, Safari and Opera */
    .hideScrollBar::-webkit-scrollbar {
      display: none;
    }

    
    `;
