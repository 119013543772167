/* eslint-disable max-len */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SearchBarWithDropdown from '../SearchBarWithDropdown';

const ScheduleSearchBar = () => {
  const rawCalendarEvents = useSelector((state) => state.plansData.rawCalendarEvents);
  const planList = useSelector((state) => state.plansData.planList);
  const isListView = useSelector((state) => state.plansData.isListView);

  const history = useHistory();
  const handleselection = (...args) => {
    const [item] = args;
    const { scheduleId } = item || {};
    // console.log('pla', args, item);
    history.push(`/home/plan/details/${scheduleId}`);
  };
  const dataSource = {
    dataKey: 'data',
    data: isListView ? planList : rawCalendarEvents
  };
  return (
    <div style={{ display: 'inline-block', padding: '0rem 2rem' }} className='jc_center'>
      <SearchBarWithDropdown itemLabel='scheduleName' dataSource={dataSource} onItemSelect={handleselection} />
    </div>
  );
};

export default ScheduleSearchBar;
