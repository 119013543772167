/* eslint-disable function-paren-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable max-len */
/* eslint-disable indent */
import React, { useEffect, useRef, useState } from 'react';
// import './input.css';
import Pills from './Pills';
import {
  DivContainer,
  SearchContainer,
  SearchInput,
  SuggestionsList,
  SuggestionsListItem,
  UserInput
} from './MultiselectInput.Style';
import { isEmpty } from '../../../shared/utility/isEmpty';
import useClickOutside from '../../../shared/hooks/useClickOutside';

function MultiselectInput({
  selectedData,
  setselectedData,
  suggestions,
  itemKey,
  handleClick,
  data,
  itemLabel,
  itemValueKey,
  module,
  locSearch = true,
  placeholder,
  item
}) {
  const suggestionRef = useRef();

  const inputRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchedData, setSearchedData] = useState([]);
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const [selectedDataSet, setselectedDataSet] = useState(new Set());
  const [suggestionListVisible, setSuggestionListVisible] = useState(false);

  useClickOutside(suggestionRef, () => {
    setSuggestionListVisible(false);
  });

  // search users based on input type
  useEffect(() => {
    if (searchTerm.trim() === '') {
      setSearchedData([]);
      setSearchedData(data);
    } else {
      const filterSuggestionList = data?.filter(
        (data) =>
          data[itemLabel]?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
          data?.label?.toLowerCase()?.includes(searchTerm?.toLowerCase())
      );
      if (filterSuggestionList) {
        setSearchedData(filterSuggestionList);
      }
    }
  }, [searchTerm]);

  useEffect(() => {
    setSearchedData(suggestions);
  }, []);

  const handleSelectUser = (user) => {
    setselectedData([...selectedData, user]);
    setselectedDataSet(
      new Set([...selectedDataSet, user[itemValueKey] || user.value])
    );
    setSearchTerm('');
    inputRef.current.focus();
  };

  const handleRemoveUser = (user) => {
    const updatedUsers = selectedData?.filter(
      (selectedUser) =>
        (selectedUser[itemValueKey] || selectedUser.value) !==
        (user[itemValueKey] || user.value)
    );
    setselectedData(updatedUsers);

    const updatedEmails = new Set(selectedDataSet);
    updatedEmails.delete(user[itemValueKey] || user.value);
    setselectedDataSet(updatedEmails);
  };

  useEffect(() => {
    const roles = [];
    selectedData?.forEach((select) => {
      roles.push(select[itemValueKey] || select?.value);
    });
    const lastSelectedRole = roles[roles?.length - 1]?.toString();
    if (module === 'FormData') {
      if (Array.isArray(itemKey)) {
        handleClick(roles.toString(), {
          itemKey: itemKey[1],
          itemKey2: itemKey[0],
          ele2: lastSelectedRole
        });
      } else {
        handleClick(roles.toString(), { itemKey, ele2: lastSelectedRole });
      }
    } else {
      handleClick(selectedData, item);
    }

    const userIDs = [];
    selectedData?.forEach((user) => {
      userIDs.push(user[itemValueKey] || user.value);
    });
    setselectedDataSet(new Set(userIDs));
  }, [selectedData]);

  const handleKeyDown = (e) => {
    if (
      e.key === 'Backspace' &&
      e.target.value === '' &&
      selectedData.length > 0
    ) {
      const lastUser = selectedData[selectedData?.length - 1];
      handleRemoveUser(lastUser);
    } else if (e.key === 'ArrowDown' && searchedData?.length > 0) {
      e.preventDefault();
      setActiveSuggestion((prevIndex) =>
        prevIndex < searchedData?.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === 'ArrowUp' && searchedData?.length > 0) {
      e.preventDefault();
      setActiveSuggestion((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
    } else if (
      e.key === 'Enter' &&
      activeSuggestion >= 0 &&
      activeSuggestion < searchedData?.length
    ) {
      handleSelectUser(suggestions[activeSuggestion]);
    }
  };
  // <MultiselectInput selectedData={selectedData} setselectedData={setselectedData} />
  return (
    <SearchContainer>
      <SearchInput>
        {/* Pills */}
        {selectedData?.map((sugg) => (
          <Pills
            key={sugg.key}
            text={sugg[itemLabel] || sugg?.label}
            onClick={() => handleRemoveUser(sugg)}
          />
        ))}
        {/* input feild with search suggestions */}
        <DivContainer>
          <UserInput
            ref={inputRef}
            type='text'
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            placeholder={placeholder || 'Search For a Role...'}
            onKeyDown={handleKeyDown}
            onClick={() => {
              setSuggestionListVisible(true);
            }}
          />
          {/* Search Suggestions */}
          {suggestionListVisible && (
            <SuggestionsList ref={suggestionRef}>
              {searchedData?.map((sugg, index) =>
                !selectedDataSet.has(sugg[itemValueKey] || sugg.value) ? (
                  <SuggestionsListItem
                    className={index === activeSuggestion ? 'active' : ''}
                    key={sugg[itemValueKey] || sugg.value}
                    onClick={() => handleSelectUser(sugg)}
                  >
                    <span>{sugg[itemLabel] || sugg?.label}</span>
                  </SuggestionsListItem>
                ) : (
                  <></>
                )
              )}
            </SuggestionsList>
          )}
        </DivContainer>
      </SearchInput>
    </SearchContainer>
  );
}

export default MultiselectInput;
