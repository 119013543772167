/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { AddBtn, AddsBtn, InputAdd, MinusBtn } from './Input.style';
import { ReactComponent as Arrow } from '../../../assets/icons/layout/dropdownarrow.svg';
import { FlexContainer } from '../../../globalStyles';

export const InputCounter = ({
  valueSetter,
  value = 0,
  label,
  inputFor = 'hours' // minutes
}) => {
  let maxValue = 12;
  let minValue = 0;
  if (inputFor === 'minutes') {
    maxValue = 59;
  } else if (inputFor === 'months') {
    maxValue = 12;
  } else if (inputFor === 'days') {
    maxValue = 30;
  } else if (inputFor === 'recurs') {
    maxValue = 999;
    minValue = 1;
  }
  return (
    <div>
      <FlexContainer className='can-disable-input' style={{ position: 'relative' }}>
        <InputAdd
          value={value}
          onChange={(e) => {
            valueSetter({ label, value: e.target.value < maxValue ? e.target.value : maxValue });
          }}
        />

        <AddsBtn onClick={() => valueSetter({ label, value: value < maxValue ? value * 1 + 1 : minValue })}>
          <Arrow />
        </AddsBtn>
        <MinusBtn>
          <Arrow onClick={() => valueSetter({ label, value: value <= minValue ? maxValue : value - 1 })} />
        </MinusBtn>
      </FlexContainer>
    </div>
  );
};
