import styled from 'styled-components';

export const ItemContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;

  &.radio-container {
    font-size: 1.8rem;
    font: normal normal normal 1.8rem/2.6rem Circular Std Book;
    color: #717171;
    gap: 1rem;
  }
  &.checkbox-container {
    font-size: 1.8rem;
    font: normal normal normal 1.8rem/2.6rem Circular Std Book;
    color: #717171;
    gap: 1rem;
  }
`;
