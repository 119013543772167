/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { WebService } from '../../../services/axios/webServices';
import { SUCCESS } from '../../../constants';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';
import Toast from '../../Common/Toast';
import AddEditFormDynamic from '../AddEditForm/AddEditFormDynamic';
import { useActions } from '../../../store/hooks/useActions';

const AddScheduleDynamic = () => {
  const [error, setError] = useState('');

  const history = useHistory();
  const { resetFilterState } = useActions();

  const createSchedule = async (payload, setSubmitLoader) => {
    // eslint-disable-next-line prefer-const
    setSubmitLoader(true);

    const input = {
      data: [payload],
      message: 'success'
    };

    try {
      const fullUrl = `${apiEndPoints.createSchedule}`;
      const res = await WebService.post(fullUrl, JSON.stringify(input));
      if (res.data?.message === SUCCESS) {
        history.push({
          pathname: '/home/schedule/details',
          state: {
            details: { scheduleId: res?.data?.id },
            scheduleType: true
          }
        });
      } else {
        setError(res.data?.message);
      }
    } catch (err) {
      console.log('error facing location:', err);
      const payload = sagaCatchBlockHandling(err);
      setError(payload?.error);
    } finally {
      setSubmitLoader(false);
    }
  };

  useEffect(() => {
    resetFilterState();
  }, []);

  return (
    <>
      <AddEditFormDynamic handleSave={createSchedule} />
      {error && (
        <Toast
          message={error}
          header='Error'
          style={{ right: 30 }}
          fn={() => setError('')}
        />
      )}
    </>
  );
};

export default AddScheduleDynamic;
