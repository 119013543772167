/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import ButtonComp from '../../component/Common/Button/Button.view';
import HeaderComp from '../../component/Common/Header/Header';
import TabsTable from '../../component/Common/Tabs_Table';
import { FlexContainer } from '../../globalStyles';
import { apiEndPoints } from '../../services/axios/endPoints';
import { ScheduleCreators } from './store/action';
import PaginationWithPage from '../../component/Common/Pagination/PaginationWithPage.view';
import { fetchUserAndLocation } from '../../component/Sidenav/fetchPageStatistics';
import { useActions } from '../../store/hooks/useActions';

const rowOptions = [
  { id: 1, label: 'Edit', value: 'Edit' }
  // { id: 2, label: 'Delete', value: 'Delete' }
];

const rowOptionsDynamic = [
  { id: 2, label: 'Edit', value: 'EditNew' }
  // { id: 2, label: 'Delete', value: 'Delete' }
];

const Scedule = () => {
  const { loading, error, searchQueries, scheduleData, scheduleDataMeta } =
    useSelector((state) => state.scheduleData);
  const dispatch = useDispatch();
  const pageSize = 20;
  const lang = 'en';

  const { resetFilterState } = useActions();
  useEffect(() => {
    resetFilterState();
  }, []);

  const handlePageChange = (page) => {
    const endpoint = apiEndPoints.getSchedule;
    dispatch(
      ScheduleCreators.getSchedule({
        page,
        pageSize,
        endpoint,
        lang,
        searchQueries
      })
    );
  };

  const location = useLocation();
  const { userData } = useSelector((state) => state.loginData);
  const { finalMenu } = useSelector((state) => state.home);
  useEffect(() => {
    const name = finalMenu.find((ele) => ele.path === location.pathname);
    fetchUserAndLocation(location.pathname, userData, {
      pageType: name?.name
    });
  }, []);

  useEffect(() => {
    handlePageChange(1);
  }, [searchQueries]);

  useEffect(() => {
    handlePageChange(1);
  }, [searchQueries]);

  const history = useHistory();
  const handleShowDetails = (rowItem) => {
    history.push({
      pathname: '/home/schedule/details',
      state: {
        details: rowItem,
        columns: scheduleDataMeta?.columns
      }
    });
  };
  const saveSearchQueries = (payload) => {
    dispatch(ScheduleCreators.setSearchQueries(payload || {}));
  };

  const handleRowOptionsItemClick = (opt, addParams) => {
    const { activeRowItem } = addParams;
    const { scheduleId } = activeRowItem;

    // const payload = addParams.activeRowItem;
    // console.log(payload, 'sjjs');
    dispatch(ScheduleCreators.getScheduleDetails({ scheduleId }));
    if (opt.value === 'Edit') {
      history.push('/home/schedule/edit');
    }
    if (opt.value === 'EditNew') {
      history.push('/home/schedule/edit');
    }
  };

  const handleCreate = () => {
    history.push('/home/schedule/create');
  };

  return (
    <>
      <HeaderComp hasBack={false} header='Schedule'>
        <FlexContainer>
          <ButtonComp dark margin='0rem 2rem' onClick={handleCreate}>
            + Create Schedule
          </ButtonComp>
          <PaginationWithPage
            ItemsPerPage={pageSize}
            total={scheduleDataMeta?.count}
            handlePageChange={handlePageChange}
            searchParams={searchQueries}
          />
        </FlexContainer>
      </HeaderComp>
      <TabsTable
        id='scheduleId'
        className='noBorder'
        handlePageChange={handlePageChange}
        handleTabSelect={() => {}}
        pageSize={pageSize}
        metaDetails={scheduleDataMeta}
        dataDetails={scheduleData}
        assetListLoading={loading}
        onItemSelect={handleShowDetails}
        showShortlistSelection={false}
        showPagiation={false}
        saveSearchQueries={saveSearchQueries}
        savedSearchQueries={searchQueries}
        resetSearchQueries={saveSearchQueries}
        error={error}
        rowOptions={rowOptions}
        handleRowOptionsItemClick={handleRowOptionsItemClick}
      />
    </>
  );
};

export default Scedule;
