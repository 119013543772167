/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable import/no-duplicates */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import DynamicSVGIcon from '../../Common/DynamicSVGIcon';
import {
  BodyContainer,
  HeaderContainer,
  HeaderText,
  NormalContent,
  Container,
  StatusContainer,
  UpdatedStatusText,
  Main
} from './WorkOrderDetails.style';

import DropArrow from '../../../assets/icons/layout/dropdownarrow.svg';
import { AlignedDiv } from '../../Common/CommonStyles';
import RenderDetails from './RenderDetails';
import AssignModal from './AssignModal';
import RenderButtons from './RenderButtons';
import Loader from '../../Loader/Loader';
import RenderLogs from './RenderLogs';
import { WorkOrderCreators } from '../../../views/WorkOrders/store';
import WOSidePanel from './WO_SidePanel';
import { getFormatedTimestamp } from '../../../services/utility/moment';
import Toast from '../../Common/Toast';
import { WO_CREATION_FROM } from '../../../constants';
import { useActions } from '../../../store/hooks/useActions';

const WorkOrderDetails = () => {
  const { workOrderId, status, loading, error, lastCompletedState } = useSelector((state) => state.workOrders);
  const history = useHistory();
  const timeStamp = new Date();
  const [showAssignModal, setShowAssignModal] = useState(false);
  // const params = useParams();
  const { setWorkOrderDetail } = useActions();
  const dispatch = useDispatch();
  const { type: typeParam, workOrderId: workOrderIdParam } = useParams();
  useEffect(() => {
    if (typeParam && workOrderIdParam) {
      dispatch(WorkOrderCreators.getWorkOrderDetails({ type: typeParam, workOrderId: workOrderIdParam }));
    }
  }, [typeParam, workOrderIdParam]);

  useEffect(() => {
    dispatch(WorkOrderCreators.resetWorkOrderDetails());
    // TODO: test
    setWorkOrderDetail({
      workOrderCreationFrom: WO_CREATION_FROM.WORK_ORDERS_LIST
    });
  }, []);

  const clearToastMessage = () => {
    dispatch(WorkOrderCreators.getWorkOrdersFailure({ error: '' }));
  };

  return (
    <>
      {!loading ? (
        status && (
          <Container>
            <HeaderContainer>
              <AlignedDiv>
                <div
                  className='cursor'
                  onClick={() => {
                    history.goBack();
                    // history.push('/home/work_orders')
                  }}
                  style={{ paddingRight: '1rem' }}
                >
                  <DynamicSVGIcon iconUrl={DropArrow} width='2.2rem' rotate={180} />
                </div>
                <HeaderText>{`${workOrderId} | ${typeParam}`}</HeaderText>
              </AlignedDiv>
            </HeaderContainer>
            <BodyContainer>
              <Main>
                <StatusContainer>
                  <div>
                    <NormalContent> Last Completed Step</NormalContent>
                    <NormalContent className='imp'>{lastCompletedState}</NormalContent>
                  </div>
                  <UpdatedStatusText>{`Last Status updated: ${getFormatedTimestamp(timeStamp)}`}</UpdatedStatusText>
                </StatusContainer>
                <RenderButtons setShowAssignModal={setShowAssignModal} />
                <RenderDetails />
                <RenderLogs workOrderId={workOrderId} />
              </Main>
              <WOSidePanel typeParam={typeParam} workOrderIdParam={workOrderIdParam} />
            </BodyContainer>
            {showAssignModal && <AssignModal setShowAssignModal={setShowAssignModal} />}
          </Container>
        )
      ) : (
        <Loader />
      )}
      {error && <Toast style={{ right: 20 }} fn={clearToastMessage} header='Error' message={error} />}
    </>
  );
};

export default WorkOrderDetails;
