/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import DropDownModal from '../../DropDownModal/DropDownModal';
import { occuranceTypes } from '../utility';
import { OccurancePicker } from './OcurranceSelection.style';

const OcurranceSelection = (props) => {
  const { additionalProps } = props;
  const occurrenceType = useSelector((state) => state.plansData.occurrenceType);
  const { handleOcurranceSelection } = additionalProps;
  const [showModal, setShowModal] = useState(false);
  const headerRef = useRef();
  const modalRef = useRef();
  const handleSelection = (details) => {
    let payload = details;
    if (occurrenceType?.value === details.value) {
      payload = {};
    }
    handleOcurranceSelection(payload);
    setShowModal(false);
  };
  return (
    <OccurancePicker>
      {/* additionalProps */}
      {/* <span ref={headerRef} onClick={() => setShowModal(!showModal)}>
        {additionalProps?.occurrenceType}
      </span> */}
      <DropDownModal
        headerLabel={occurrenceType?.label || '--Select--'}
        isActive={showModal}
        activeItem={occurrenceType}
        showSelection
        setModalIsVisibal={setShowModal}
        data={occuranceTypes}
        handleClick={handleSelection}
        modalRef={modalRef}
        kababIconRef={headerRef}
        // ContainerStyle={{ width: '120px' }}
      >
        <div />
      </DropDownModal>
    </OccurancePicker>
  );
};

export default OcurranceSelection;
