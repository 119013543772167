/* eslint-disable max-len */
/* eslint-disable react/jsx-curly-brace-presence */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { editIcon } from '../../../assets/icons';
import { FlexContainer } from '../../../globalStyles';
import { ScheduleCreators } from '../../../views/Schedule/store';
import { LoaderContainer } from '../../Activity/ActivityDetails/ActivtyDetails.style';
import ButtonComp from '../../Common/Button/Button.view';
import { Span } from '../../Common/CommonStyles';
import DynamicSVGIcon from '../../Common/DynamicSVGIcon';
import HeaderComp from '../../Common/Header/Header';
import KeyValueLayout from '../../Common/KeyValueLayout/KeyValueLayout';
import { ContentText } from '../../Common/KeyValueLayout/KeyValueLayout.style';
import Loader from '../../Common/Loader/Loader';
import { Divider } from '../../ScheduleType/ScheduleTypeDetails/ScheduleTypeDetails.style';
import { BodyContainer } from './ScheduleDetails.style';
import { calDateFormat, format as dateTimeFormat } from '../../../config/index';
import { isJsonString } from '../../Common/GenericForm/utils';

const ScheduleDetails = (props) => {
  const scheduleDetails = useSelector((state) => state.scheduleData.details);
  const { loading } = useSelector((state) => state.scheduleData);

  const { location } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { details, columns, scheduleType } = location.state;
  const { scheduleId } = details;

  // useEffect(() => {
  //   let formatedData;
  //   if (scheduleData) {
  //     Object.keys(scheduleData).forEach((key) => {
  //       if (isJsonString(scheduleData[key])) {
  //         formatedData = {
  //           ...formatedData,
  //           [key]: JSON.parse(scheduleData[key])
  //         };
  //       } else {
  //         formatedData = {
  //           ...formatedData,
  //           [key]: scheduleData[key]
  //         };
  //       }
  //     });
  //     setSheduleDetails(formatedData);
  //   }
  // }, [scheduleData]);

  useEffect(() => {
    if (scheduleId) {
      dispatch(ScheduleCreators.getScheduleDetails({ scheduleId }));
    }
  }, [scheduleId]);
  const handleBack = () => {
    history.push('/home/schedule');
  };
  return loading ? (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  ) : (
    <>
      <HeaderComp
        className='fixed-height'
        handleBack={handleBack}
        header={scheduleDetails?.scheduleName}
        hasBack
      >
        <ButtonComp
          dark
          onClick={() => {
            history.push('/home/schedule/edit', {
              ...scheduleDetails
              // ...details,
              // startDate: new Date(details.startDate).toLocaleDateString(),
              // endDate: new Date(details.endDate).toLocaleDateString()
            });
          }}
        >
          <FlexContainer className='jc_center ai_center'>
            <DynamicSVGIcon width='2.2rem' iconUrl={editIcon} />
            <Span>Edit</Span>
          </FlexContainer>
        </ButtonComp>
      </HeaderComp>
      <BodyContainer>
        <KeyValueLayout
          name={'Title'}
          labelValue={scheduleDetails?.scheduleName || '-'}
        />
        <Divider />
        <KeyValueLayout
          name={'Activity'}
          labelValue={
            (isJsonString(scheduleDetails?.activity)
              ? JSON.parse(scheduleDetails?.activity)?.title
              : scheduleDetails?.activity?.title) || '-'
          }
        />
        <KeyValueLayout
          name={'Assets'}
          labelValue={scheduleDetails?.AssetNos}
        />
        <KeyValueLayout
          name={'Schedule Type'}
          labelValue={
            (isJsonString(scheduleDetails?.scheduleType)
              ? JSON.parse(scheduleDetails?.scheduleType)?.scheduleName
              : scheduleDetails?.scheduleType?.scheduleName) || '-'
          }
        />

        <Divider />
        <ContentText
          className='heading'
          style={{ marginBottom: '3rem', fontSize: '2.2rem' }}
        >
          TimeLine
        </ContentText>
        <KeyValueLayout
          name={'Start Date'}
          labelValue={
            scheduleDetails?.startDate
              ? moment(scheduleDetails?.startDate).format(dateTimeFormat)
              : '-'
          }
        />
        <KeyValueLayout
          name={'End Date'}
          labelValue={
            scheduleDetails?.endDate
              ? moment(scheduleDetails?.endDate).format(dateTimeFormat)
              : '-'
          }
        />
        {scheduleDetails?.startTime && (
          <KeyValueLayout
            name={'Start Time'}
            labelValue={scheduleDetails?.startTime || '-'}
          />
        )}
        {scheduleDetails?.endTime && (
          <KeyValueLayout
            name={'End Time'}
            labelValue={scheduleDetails?.endTime || '-'}
          />
        )}
      </BodyContainer>
    </>
  );
};

export default ScheduleDetails;
