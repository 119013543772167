import styled from 'styled-components';
// import { Input } from '../Input/Input';
import { Input } from '../SearchBar/SearchBar.styles';

export const Div = styled.div`
  display: flex;
  margin-top: 2rem;
  margin-bottom: 3rem;
  width: ${(props) => props.width || '100%'};
  align-items: center;

  /* inputs should be disabled when not in active state */
  &.disable-children .can-disable-input,
  &.disable-children ${Input} {
    pointer-events: none;
    opacity: 0.4;
  }

  &.select_options {
    align-items: flex-start;
  }
  &.show_stats {
    align-items: flex-start;
  }
`;
