/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Table as TableContainer, Td, Th, Tr } from '../SpacedRows.styles';
import { CellTemplate, CellText, RowTemplate, TableContainerTemplate } from './Table.style';

const getName = (obj, col) => {
  const colArr = col.split('.');
  let tempObj = obj;
  colArr.forEach((colPart) => {
    if (tempObj) tempObj = tempObj[colPart];
  });
  return tempObj;
};

const evaluate = (row, caliculateValue) => {
  let value;
  try {
    // eslint-disable-next-line no-eval
    eval(caliculateValue);
  } catch (e) {
    console.log(e);
  }
  return value;
};

const Table = ({ colProps = [], data = [], defaultValue = [] }) => (
  <TableContainer className='attached'>
    <Tr className='header '>
      {colProps.map((col, index) => (
        <Th key={index} width={col.width}>
          {/* <CellText className='hideScrollBar'>{col.label}</CellText> */}
          {col.label}
        </Th>
      ))}
    </Tr>
    {data.map((entry, i) => (
      <Tr key={i}>
        {colProps.map((col, index) => {
          const row = entry;
          // console.log('row, col, entry', row, col, col.value, col.name, entry);
          return (
            <Td key={index} width={col.width}>
              {/* <CellText className='hideScrollBar'> */}
              <p>
                {col.customizedValue
                  ? evaluate(row, col.customizedValue)
                  : entry[col.name] ?? getName(entry, col.value) ?? getName(defaultValue, col.value)}
              </p>
              {/* </CellText> */}
            </Td>
          );
        })}
      </Tr>
    ))}
  </TableContainer>
);

export default Table;
