/* eslint-disable max-len */
import { createReducer } from 'reduxsauce';
// import { occuranceTypes } from '../../../component/Common/Calendar/utility';
import { PlanTypes as Types } from './action';

const INITIAL_STATE = {
  calendarApi: {},
  occurrenceType: {},
  loading: false,
  searchQueries: {},
  error: '',
  calendarEvents: [],
  rawCalendarEvents: [],
  calendarConfig: {},
  activeRange: {},
  planList: [],
  planCount: null,
  planListMeta: {},
  planColumn: [],
  planId: '',
  planDetails: {},
  isListView: false
};

const planValueSetter = (state = INITIAL_STATE, action) => {
  const { key, value } = action.payload;
  return {
    ...state,
    [key]: value
  };
};

const setIsListView = (state = INITIAL_STATE, action) => ({
  ...state,
  isListView: action.payload
});

const setActiveRange = (state = INITIAL_STATE, action) => ({
  ...state,
  ...action.payload
});

const setSearchQueries = (state = INITIAL_STATE, action) => ({
  ...state,
  searchQueries: { ...action.payload }
});

const getPlanListStart = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: true
});
const getPlanListSuccess = (state = INITIAL_STATE, action) => {
  const { planList, meta } = action.payload;
  return {
    ...state,
    planList,
    planCount: meta?.count,
    planColumn: meta,
    loading: false
  };
};
const getPlanListFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  error: action.payload,
  loading: false
});

const getPlanDetailsStart = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: true
});
const getPlanDetailsSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  ...action.payload,
  loading: false
});
const getPlanDetailsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  error: action.payload,
  loading: false
});

const HANDLERS = {
  [Types.SET_ACTIVE_RANGE]: setActiveRange,
  [Types.SET_SEARCH_QUERIES]: setSearchQueries,
  [Types.GET_PLAN_LIST_START]: getPlanListStart,
  [Types.GET_PLAN_LIST_SUCCESS]: getPlanListSuccess,
  [Types.GET_PLAN_LIST_FAILURE]: getPlanListFailure,
  [Types.PLAN_VALUE_SETTER]: planValueSetter,
  [Types.GET_PLAN_DETAILS_START]: getPlanDetailsStart,
  [Types.GET_PLAN_DETAILS_SUCCESS]: getPlanDetailsSuccess,
  [Types.GET_PLAN_DETAILS_FAILURE]: getPlanDetailsFailure,
  [Types.SET_IS_LIST_VIEW]: setIsListView
};

export const plansReducer = createReducer(INITIAL_STATE, HANDLERS);
