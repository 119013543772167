/* eslint-disable camelcase */
/* eslint-disable max-len */
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { AvatarComponent } from '../..';
import { FlexContainer } from '../../../globalStyles';
import { getFormatedTimestamp } from '../../../services/utility/moment';
import { ImageContainer } from '../../Common/CommentItem/CommentItem.style';
import { device, fontFamily } from '../../constants';

export const Container = styled.div`
  padding-left: 6rem;
  width: 100%;
  position: relative;
  /* display: flex;
  align-items: center; */
   @media ${device.tablet} {
      padding-left: 3rem;
    
  }
`;

export const UsernameText = styled.div`
  color: #4a4a4a;
  font: 1.8rem ${fontFamily.circularMedium};
  &.ml {
    margin-left: 1rem;
  }
  /* background-color: red; */
  /* margin-bottom: 1.5rem; */
    @media ${device.tablet} {
     font-size: 1.4rem;
    
  }
`;

export const Content = styled.div`
  color: #404046;
  font: 1.6rem ${fontFamily.circularBook};
  padding-top: 0.2rem;
  /* background-color: red; */

  /* margin-bottom: 4rem; */
  &.ml {
    margin-left: 1rem;
  }
   @media ${device.tablet} {
     font-size: 1.2rem;
    
  }
`;

const ItemContainer = styled.div`
  /* background-color: #f4f4f8; */
  border-radius: 10px;
  box-shadow: -5px -5px 11px #fffffff5;
  margin: 2rem 2.5rem 0rem 0rem;
  padding: 2rem;
  & .mainDetails {
    font: 1.8rem ${fontFamily.circularMedium};
    color: #4a4a4a;
    margin-bottom: 1rem;
  }
  & .subDetails {
    font: 1.4rem ${fontFamily.circularMedium};
    color: #393939;
  }
`;

const TimelineItem = ({ item }) => {
  const { profilePic, from_state, to_state, timestamp, transaction_id, transition_by } = item;
  return (
    <Container>
      {!profilePic && (
        <ImageContainer style={{ height: '100%' }} className='ai_center'>
          <AvatarComponent userdata={{ firstName: transition_by }} circle={true} />
        </ImageContainer>
      )}
      <ItemContainer>
        <FlexContainer className='jc_spacebetween'>
          <FlexContainer className='ai_center'>
            <UsernameText>{`${transition_by}`}</UsernameText>
            <Content className='ml'>{`${to_state}`}</Content>
            <Content>{to_state.charAt(to_state.length - 1) === 'e' ? 'd' : 'ed'}</Content>
            <Content className='ml'>work order</Content>
            <UsernameText className='ml'>{`${transaction_id}`}</UsernameText>
          </FlexContainer>
          <Content>{timestamp ? getFormatedTimestamp(timestamp) : '-'}</Content>
        </FlexContainer>
      </ItemContainer>
    </Container>
  );
};

export default TimelineItem;
