import { apiEndPoints } from '../../services/axios/endPoints';
import { WebService } from '../../services/axios/webServices';
import config from '../../config.json';
/* eslint-disable max-len */
export const fetchUserAndLocation = async (
  pathname,
  userData,
  additionalDetails
) => {
  // extra validation for checking subMenuOptions
  // if ('tab' in additionalDetails) {
  if (config.update_page_statistics) {
    try {
      const details = {
        pagename: pathname,
        user: userData,
        additionalDetails,
        userid: userData.userId
      };
      const url = apiEndPoints.updatePageUsageStatistics;
      const response = await WebService.post(url, details);
    } catch (error) {
      console.log(error, 'in fetchUserAndLocation ');
    }
  }
  // }
};
