/* eslint-disable max-len */
import { createReducer } from 'reduxsauce';
import { sampleScheduleList } from '../sampleData';
import { ScheduleTypes } from './action';

const { data, meta } = sampleScheduleList;

const INITIAL_STATE = {
  loading: false,
  scheduleData: [],
  scheduleDataMeta: [],
  scheduleCount: null,
  searchQueries: {},
  error: '',
  details: null,
  plan: '',
  DisplayMessage: '',
};

const setSearchQueries = (state = INITIAL_STATE, action) => ({
  ...state,
  searchQueries: { ...action.payload }
});

const setNewSchedule = (state = INITIAL_STATE, action) => ({
  ...state,
  scheduleData: [...state.scheduleData, action.payload]
});

const editSchedule = (state = INITIAL_STATE, action) => {
  const { input, id: inputId } = action.payload;
  const editObj = state.scheduleData.map((trow) => {
    const { id } = trow;
    if (id === inputId) {
      return action.payload.input;
    }
    return trow;
  });
  return {
    ...state,
    scheduleData: editObj
  };
};

const getScheduleStart = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: true
});

const getScheduleSuccess = (state = INITIAL_STATE, action) => {
  const { scheduleData, meta } = action.payload;
  return {
    ...state,
    scheduleData,
    scheduleCount: meta?.count,
    scheduleDataMeta: meta,
    loading: false
  };
};

const getScheduleFailure = (state = INITIAL_STATE, action) => {
  console.log('failure', action.payload);
  return {
    ...state
  };
};

const getScheduleDetailsStart = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: true
});

const getScheduleDetailsSuccess = (state = INITIAL_STATE, action) => {
  const { schedule, plan, DisplayMessage } = action.payload;
  return ({
    ...state,
    loading: false,
    details: schedule,
    plan,
    DisplayMessage
  });
};
const getScheduleDetailsFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;

  return {
    ...state,
    error,
    loading: false
  };
};

const HANDLERS = {
  [ScheduleTypes.SET_SEARCH_QUERIES]: setSearchQueries,
  [ScheduleTypes.SET_NEW_SCHEDULE]: setNewSchedule,
  [ScheduleTypes.EDIT_SCHEDULE]: editSchedule,
  [ScheduleTypes.GET_SCHEDULE_START]: getScheduleStart,
  [ScheduleTypes.GET_SCHEDULE_SUCCESS]: getScheduleSuccess,
  [ScheduleTypes.GET_SCHEDULE_FAILURE]: getScheduleFailure,
  [ScheduleTypes.GET_SCHEDULE_DETAILS_START]: getScheduleDetailsStart,
  [ScheduleTypes.GET_SCHEDULE_DETAILS_SUCCESS]: getScheduleDetailsSuccess,
  [ScheduleTypes.GET_SCHEDULE_DETAILS_FAILURE]: getScheduleDetailsFailure
};

export const scheduleReducer = createReducer(INITIAL_STATE, HANDLERS);
