/* eslint-disable indent */
/* eslint-disable max-len */
import Card from '../component/Card';
import WorkOrders from '../views/WorkOrders';
import Spares from '../views/Stores/Spares/SparesFilter/SparesFilter.view';
import Filter from '../views/Filters/Filter.view';
import MyActionItems from '../views/MyActionItems/MyActionItems.view';
import { PAGE_TYPES } from '.';
import DirectIframe from '../views/DirectIframe/DirectIframe';
import Activity from '../views/Activity/Activity.view';
import Schedule from '../views/Schedule/Schedule.view';
import ScheduleType from '../views/ScheduleType/ScheduleType.view';
import Plan from '../views/Plan/Plan.view';
import { Users } from '../views/Users/Users.view';
import BulkList from '../views/Stores/Spares/BulkList.view';

const _ = require('lodash');

const mappingComponent = {
  Card,
  WorkOrders,
  Spares,
  Filter,
  MyActionItems,
  [PAGE_TYPES.DIRECT_IFRAME]: DirectIframe,
  Activity,
  Schedule,
  ScheduleType,
  Plan,
  Users,
  Bulk: BulkList
};

export const filteredMenu = (data) => {
  const routesInfo = {
    finalRoutes: [],
    // filterPaths: {}
    componentPaths: {}
  };
  // let finalRoutes = [];
  data.forEach((item) => {
    if (item.IsLeaf === '0') {
      const returnMenu = filteredMenu(item.SubMenu);
      if (returnMenu) {
        routesInfo.finalRoutes = [
          ...routesInfo.finalRoutes,
          ...returnMenu.finalRoutes
        ];
        // routesInfo.filterPaths = { ...routesInfo.filterPaths, ...returnMenu.filterPaths };
        routesInfo.componentPaths = _.merge(
          routesInfo.componentPaths,
          returnMenu.componentPaths
        );
      }
    } else {
      // if (item.PageType === 'Filter') {
      //   routesInfo.filterPaths = {
      //     ...routesInfo.filterPaths,
      //     [item.Id]: item.Permalink
      //   };
      // }
      const Permalink = item.Permalink || `/home/${item.Name}`;
      routesInfo.componentPaths[item.PageType] = routesInfo.componentPaths?.[
        item.PageType
      ]
        ? {
            ...routesInfo.componentPaths[item.PageType],
            [item.Id]: Permalink
          }
        : { [item.Id]: Permalink };

      routesInfo.finalRoutes = [
        ...routesInfo.finalRoutes,
        {
          exact: true,
          path: Permalink,
          name: item.Name,
          menuId: item.Id,
          subMenuOptions: item.SubMenuLabelOptions,
          description: item['[Description]'],
          Component: mappingComponent[item.PageType]
        }
      ];
    }
  });
  return routesInfo;
};
