/* eslint-disable react/button-has-type */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { isEmpty } from '../../../../shared/utility/isEmpty';
import { useActions } from '../../../../store/hooks/useActions';
import FilterContainer from '../../../Filter/FilterContainer/FilterContainer';
import { generateSelectedFilter, getBreadCrumbs } from '../../../ResultView/Iframe/generateSelectedFilter';
import ButtonComp from '../../Button/Button.view';
import Loader from '../../Loader/Loader';
import PopUpModal from '../../PopUpModal/Modal.view';

const BtnWrapper = styled.div`
  display: flex;
  margin-top: 3rem;
  align-items: center;
  justify-content: flex-end;
  &.bottom {
    position: absolute;
    bottom: 0px;
    right: 0px;
  }
`;

const FilterInputType = ({
  item,
  // equipments, setEquipments,
  setShowModal,
  saveHandler,
  loading
}) => {
  const { label, key, dataSource } = item;
  // const { params } = dataSource || {};
  // const { menuId } = params;
  // const { setTags, universalFilterSetter } = useActions();

  // const filterState = useSelector((state) => state.filterData);
  // const { cumulativeTags, filterData, tags } = filterState;

  // const check = () => {
  //   const b = getBreadCrumbs(generateSelectedFilter(cumulativeTags, filterData, false));
  //   console.log('getBreadCrumbs', b);
  //   setEquipments(b);
  // };
  // const removeMachine = (index) => {
  //   const treeKey = filterData[0].groupTitle;
  //   // get the pathNo
  //   const keyList = Object.keys(cumulativeTags[treeKey]);
  //   const pathNo = keyList[index];
  //   const lastIndex = keyList.length - 1;
  //   const isLastMachine = pathNo === keyList[lastIndex];

  //   // console.log(
  //   //   'pathNo',
  //   //   lastIndex,
  //   //   keyList[lastIndex],
  //   //   isLastMachine,
  //   //   index,
  //   //   pathNo,
  //   //   Object.keys(cumulativeTags[Object.keys(cumulativeTags)[0]])
  //   // );
  //   // dispatch(FilterCreators.setTags(tempTags));

  //   delete cumulativeTags[treeKey][pathNo];
  //   const cumulativeSelectedObj = filterState[`cumulative${treeKey}`];
  //   delete cumulativeSelectedObj[pathNo];
  //   check();

  //   // no paths
  //   if (isEmpty(cumulativeTags[treeKey])) {
  //     setTags({ ...tags, [treeKey]: {} });
  //     universalFilterSetter({
  //       key: treeKey,
  //       value: {}
  //     });
  //   }
  // };

  return (
    <PopUpModal width='60%'>
      <div style={{ position: 'relative', minHeight: '50vh' }}>
        <span style={{ fontSize: '2.2rem' }}>{label || 'Select Equipments'}</span>
        {loading ? <Loader /> : <FilterContainer />}
        <BtnWrapper className='bottom'>
          <ButtonComp onClick={() => setShowModal(false)}>cancel</ButtonComp>
          <ButtonComp style={{ marginLeft: '2rem' }} dark onClick={saveHandler}>
            Save
          </ButtonComp>
        </BtnWrapper>
      </div>
    </PopUpModal>
  );
};

export default FilterInputType;
