/* eslint-disable camelcase */
/* eslint-disable max-len */
import { createReducer } from 'reduxsauce';
import { WO_CREATION_FROM } from '../../../../constants';
import { workOrderComments } from '../../sampleData';
import { Types } from './action';

const INITIAL_STATE = {
  type: {},
  workOrderCreationFrom: WO_CREATION_FROM.WORK_ORDERS_LIST,
  parentTransactionId: '',
  comments: workOrderComments,
  status: 'To be assigned',
  assetListLoading: false,
  assetListMeta: {},
  assetListData: [],
  assetShortlist: [],
  assetListError: '',
  assetSearchQueries: {}
};

const setWorkOrderType = (state = INITIAL_STATE, action) => ({
  ...state,
  type: action.payload,
  parentTransactionId: action.payload.parentTransactionId
});

const setWorkOrderDetail = (state = INITIAL_STATE, action) => ({
  ...state,
  ...action.payload
});

const getAssetListStart = (state = INITIAL_STATE) => ({
  ...state,
  assetListLoading: true
});

const resetAssetShortlist = (state = INITIAL_STATE) => ({
  ...state,
  assetShortlist: INITIAL_STATE.assetShortlist
});

const getAssetListSuccess = (state = INITIAL_STATE, action) => {
  const { assetListMeta, assetListData, submitUrl } = action.payload;
  return {
    ...state,
    assetListMeta,
    assetListData,
    assetListLoading: false,
    submitUrl
  };
};

const setAssetShortlist = (state = INITIAL_STATE, action) => ({
  ...state,
  assetShortlist: action.payload
});

const getAssetListFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;

  return {
    ...state,
    assetListError: error,
    assetListLoading: false
  };
};

const setAssetSearchQueries = (state = INITIAL_STATE, action) => ({
  ...state,
  assetSearchQueries: { ...action.payload }
});

const resetAssetSearchQueries = (state = INITIAL_STATE, action) => ({
  ...state,
  assetSearchQueries: INITIAL_STATE.assetSearchQueries
});

const HANDLERS = {
  [Types.SET_WORK_ORDER_TYPE]: setWorkOrderType,
  [Types.SET_WORK_ORDER_DETAIL]: setWorkOrderDetail,

  [Types.RESET_ASSET_SHORTLIST]: resetAssetShortlist,
  [Types.GET_ASSET_LIST_START]: getAssetListStart,
  [Types.GET_ASSET_LIST_SUCCESS]: getAssetListSuccess,
  [Types.GET_ASSET_LIST_FAILURE]: getAssetListFailure,
  [Types.SET_ASSET_SHORTLIST]: setAssetShortlist,
  [Types.SET_ASSET_SEARCH_QUERIES]: setAssetSearchQueries,
  [Types.RESET_ASSET_SEARCH_QUERIES]: resetAssetSearchQueries
};

export const upsertWorkOrderReducer = createReducer(INITIAL_STATE, HANDLERS);
