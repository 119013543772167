/* eslint-disable max-len */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FlexContainer } from '../../../globalStyles';
import { isEmpty } from '../../../shared/utility/isEmpty';
import MonthPicker from './MonthPicker/MonthPicker';

export default function MonthRangePicker({ handleSelection, initValue }) {
  const [isAtStart, setIsAtStart] = useState(1);
  const [selectedRange, setSelectedRange] = useState({
    start: new Date(moment()),
    end: new Date(moment())
  });
  const [hoveredDate, setHoveredDate] = useState('');
  const todaysYear = new Date().getFullYear();
  const [year, setYear] = useState({ start: todaysYear - 1, end: todaysYear });

  const formatSelectionObj = (obj) => ({
    value: `${moment(obj.start).format('MMM YY')} - ${moment(obj.end).format('MMM YY')}`,
    rawValue: obj
  });

  useEffect(() => {
    if (!isEmpty(initValue)) {
      setSelectedRange({ start: new Date(initValue.start), end: new Date(initValue.end) });
    } else {
      handleSelection(formatSelectionObj(selectedRange));
    }
  }, []);

  return (
    <FlexContainer style={{ width: '100%' }}>
      <MonthPicker
        selectedRange={selectedRange}
        setSelectedRange={setSelectedRange}
        isAtStart={isAtStart}
        setIsAtStart={setIsAtStart}
        title='start'
        hoveredDate={hoveredDate}
        setHoveredDate={setHoveredDate}
        year={year}
        setYear={setYear}
        handleSelection={handleSelection}
        formatSelectionObj={formatSelectionObj}
      />
      <MonthPicker
        isAtStart={isAtStart}
        title='end'
        setIsAtStart={setIsAtStart}
        selectedRange={selectedRange}
        setSelectedRange={setSelectedRange}
        hoveredDate={hoveredDate}
        setHoveredDate={setHoveredDate}
        year={year}
        setYear={setYear}
        handleSelection={handleSelection}
        formatSelectionObj={formatSelectionObj}
      />
    </FlexContainer>
  );
}
