/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable max-len */

import { put } from 'redux-saga/effects';
import { Creators } from './action';

import { WebService } from '../../../services/axios/webServices';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { getWorkOrderDetails } from '../../../component/WorkOrder/WorkOrderDetails/getDetails';
// import { WOTypes } from '../data';
import { WO_CREATION_FROM } from '../../../constants';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';
// import  { menuData } from '../../../constants/sampleData';

export function* getWorkOrdersSaga({ payload }) {
  const { page, pageSize, type, searchParams } = payload;
  try {
    yield put(Creators.getWorkOrdersStart());

    // const fullURL = yield `${apiEndPoints.getWorkOrders}`;
    let endpoint = `${payload.endpoint}?page=${page || 2}&pagesize=${pageSize || 10}`;
    if (type) {
      endpoint += `&type=${type || ''}`;
    }
    // console.log('searchParams', searchParams);
    const response = yield WebService.post(endpoint, {
      SearchFilters: searchParams || {}
    });
    if (response.status >= 200 && response.status < 300) {
      const { data: workOrdersData, meta } = response.data;
      // const { data: workOrdersData, meta } = localWOResponse;
      // const workOrdersData = data;
      // console.log('meta', meta, workOrdersData);

      yield put(
        Creators.getWorkOrdersSuccess({ meta, workOrders: Array.isArray(workOrdersData) ? workOrdersData : [] })
      );
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message || error.response.data.Message
          };
          yield put(Creators.getWorkOrdersFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getWorkOrdersFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.getWorkOrdersFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getWorkOrdersFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        // error: error.message
        error: 'No connection try again Later.'
      };

      yield put(Creators.getWorkOrdersFailure(payload));
    }
  }
}

export function* getWorkOrderTypesSaga() {
  try {
    yield put(Creators.getWorkOrderTypesStart());

    const fullURL = yield `${apiEndPoints.getWorkflowTypes}`;
    const response = yield WebService.get(fullURL);
    // console.log('response', response);
    if (response.status >= 200 && response.status < 300) {
      const workOrderTypesData = response.data;
      // const workOrderTypesData = WOTypes;

      yield put(Creators.getWorkOrderTypesSuccess({ workOrderTypes: workOrderTypesData || [] }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message || error.response.data.Message
          };
          yield put(Creators.getWorkOrderTypesFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getWorkOrderTypesFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.getWorkOrderTypesFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getWorkOrderTypesFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: 'No connection try again Later.'
      };

      yield put(Creators.getWorkOrderTypesFailure(payload));
    }
  }
}

export function* getWorkOrderDetailsSaga({ payload }) {
  const { workOrderId, status, type } = payload;

  try {
    yield put(Creators.getWorkOrderDetailsStart());

    const fullURL = yield `${apiEndPoints.getWorkOrderDetails}${workOrderId}`;
    const response = yield WebService.get(fullURL);
    // console.log('response', response);
    if (response.status >= 200 && response.status < 300) {
      const workOrderDetailsData = response.data;

      const {
        transaction_id,
        current_state,
        childworkorders_list,
        activitylog,
        remarks,
        next_actions,
        wftemplate,
        formdefinition,
        user_data,
        sidePanel_Info,
        current_state_text
      } = workOrderDetailsData;

      // console.log(JSON.parse(sidePanel_Info));
      const userData = JSON.parse(user_data);
      const workOrderDetails = getWorkOrderDetails(JSON.parse(formdefinition), userData);
      // console.log('workOrderDetails', workOrderDetails);
      const payload = {
        userData,
        type,
        // remarks,
        workOrderDetails,

        status,
        current_state,
        workOrderId: transaction_id,
        lastCompletedState: current_state_text,
        wftemplate: JSON.parse(wftemplate),
        childworkorders_list: JSON.parse(childworkorders_list),
        activitylog: JSON.parse(activitylog),
        remarks: JSON.parse(remarks),
        nextActions: JSON.parse(next_actions),
        sidePanelInfo: JSON.parse(sidePanel_Info)
      };
      //
      yield put(Creators.getWorkOrderDetailsSuccess(payload));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message || error.response.data.Message
          };

          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };

          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };

          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
      }
    } else {
      // for custom error
      payload = {
        error: 'No connection try again Later.'
        // error: 'custom error'
      };
    }
    yield put(Creators.getWorkOrderDetailsFailure(payload));
  }
}

export function* getWorkOrdersCountByGroupSaga({ payload }) {
  const { pathName } = payload;
  try {
    yield put(Creators.getWorkOrdersCountByGroupStart());

    const response = yield WebService.get(pathName);
    if (response.status >= 200 && response.status < 300) {
      const workOrdersCountByGroup = response.data;
      // const workOrdersData = data;
      // console.log('meta', meta, workOrdersData);

      yield put(Creators.getWorkOrdersCountByGroupSuccess({ workOrdersCountByGroup: workOrdersCountByGroup || [] }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message || error.response.data.Message
          };
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
      }
    } else {
      // for custom error
      payload = {
        error: 'No connection try again Later.'
      };
    }
    yield put(Creators.getWorkOrdersCountByGroupFailure(payload));
  }
}

export function* getFormStructureSaga({ payload }) {
  const { WFTid, formName, workOrderId } = payload;

  try {
    yield put(Creators.getFormStructureStart());

    let fullURL = yield `${apiEndPoints.getFormStructure}${WFTid}`;
    if (formName) {
      fullURL += `/${formName}`;
    }
    if (workOrderId) {
      fullURL += `/${workOrderId}`;
    }
    const response = yield WebService.get(fullURL);
    // console.log('response===form=>', JSON.parse(response.data[0].formDefinition));
    if (response.status >= 200 && response.status < 300) {
      const formStructureData = response.data;
      // const formStructureData = data;
      yield put(Creators.getFormStructureSuccess({ formStructure: formStructureData[0] || [] }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message || error.response.data.Message
          };

          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };

          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };

          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };
    }
    yield put(Creators.getFormStructureFailure(payload));
  }
}

export function* getUserDataByTemplateIdSaga({ payload }) {
  const { WFTid, assetType, selections, workOrderCreationFrom, planId, onSuccessNavigation } = payload;

  try {
    yield put(Creators.getUserDataStart());

    let fullURL = `${apiEndPoints.getUserDataByTemplateId}/${WFTid}/${assetType}`;
    if (workOrderCreationFrom === WO_CREATION_FROM.PLAN_DETAILS) {
      fullURL = `${apiEndPoints.CreateScheduleJO}/${planId}`;
    }
    const response = yield WebService.post(fullURL, selections);
    if (response.status >= 200 && response.status < 300) {
      const userData = response.data;
      // const formStructureData = data;
      if (workOrderCreationFrom === WO_CREATION_FROM.PLAN_DETAILS) {
        // navigate to plan details on success
        onSuccessNavigation(userData.workflowTemplateID);
      }
      yield put(Creators.getUserDataSuccess(userData));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message || error.response.data.Message
          };

          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };

          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };

          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };
    }
    yield put(Creators.getUserDataFailure(payload));
  }
}

export function* getWOSidePanelDetailsSaga({ payload }) {
  try {
    const { refreshObj, detailsOf } = payload;
    const { url, method } = refreshObj;
    yield put(Creators.getWOSidePanelDetailsStart({ detailsOf }));

    // const fullURL = yield `${apiEndPoints.getWorkOrderDetails}${workOrderId}`;
    const response = yield WebService?.[method](url);
    if (response.status >= 200 && response.status < 300) {
      yield put(Creators.getWOSidePanelDetailsSuccess({ data: response.data, detailsOf }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    const err = sagaCatchBlockHandling(error);
    yield put(Creators.getWOSidePanelDetailsFailure(err.payload));
  }
}

export function* getRemarkSaga({ payload }) {
  try {
    yield put(Creators.getRemarkStart());

    const fullURL = yield `${apiEndPoints.getRemarks}${payload}`;
    const response = yield WebService.get(fullURL);
    if (response.status >= 200 && response.status < 300) {
      yield put(Creators.getRemarkSuccess(response.data));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });
    const err = sagaCatchBlockHandling(error);

    yield put(Creators.getRemarkFailure(err.payload));
  }
}
