/* eslint-disable indent */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PopUpModal } from '../..';
import { Div, FlexContainer } from '../../../globalStyles';
import { DropBtn, DropdownCon, HeaderTitle } from '../../../views/WorkOrders/WorkOrders.styles';
import DynamicSVGIcon from '../../Common/DynamicSVGIcon';
import { Creators as UpserWorkOrderCreators } from '../CreateWorkOrder/store';
import { CancelBlack } from '../../../assets/icons';
import { Input } from '../../Common/Input/Input';
import { STATUSES } from '../../../constants';
import { ReactComponent as Arrow } from '../../../assets/icons/layout/dropdownarrow.svg';
import { SearchResultText, SearchTextContainer } from '../../GlobalSearch/GlobalSearchContainer.style';
import { ButtonContainer } from './WorkOrderDetails.style';
import ButtonComp from '../../Common/Button/Button.view';

const AssignModal = ({ setShowAssignModal }) => {
  const dispatch = useDispatch();
  const [showDropdown, setShowDropdown] = useState(false);
  const { assignedTo } = useSelector((state) => state.upsertWorkOrder);

  const data = ['Lupe Berney', 'Rosalinda Brazil', 'Tomika Philipps', 'Tomika Philipps'];

  const handleType = (value) => {
    dispatch(UpserWorkOrderCreators.setWorkOrderDetail({ assignedTo: value }));
  };

  const handleAssign = () => {
    setShowAssignModal(false);
    dispatch(UpserWorkOrderCreators.setWorkOrderDetail({ status: STATUSES.TO_BE_ACKNOWLEGED }));
  };

  const handleClose = () => {
    setShowAssignModal(false);
    dispatch(UpserWorkOrderCreators.setWorkOrderDetail({ assignedTo: '' }));
  };

  return (
    <PopUpModal width='70%' style={{ padding: '4rem' }} closeModalHandler={handleClose}>
      <FlexContainer className='ai_center jc_spacebetween' mb='4'>
        <HeaderTitle>Assign Work Order</HeaderTitle>
        <Div className='cursor'>
          <DynamicSVGIcon iconUrl={CancelBlack} />
        </Div>
      </FlexContainer>
      <FlexContainer pr='4' style={{ position: 'relative' }} onClick={() => setShowDropdown(!showDropdown)}>
        <Input
          placeholder='Choose Techinician'
          value={assignedTo}
          style={{ zIndex: '1' }}
          disabled={true}
          width='100%'
        />
        <DropBtn className='ai_center jc_center'>
          <Arrow />
        </DropBtn>
        {showDropdown && (
          <DropdownCon>
            {data.length
              ? data.map((item, i) => (
                  <SearchTextContainer key={i} onClick={() => handleType(item)}>
                    <SearchResultText style={{ fontSize: '16px' }}>{item}</SearchResultText>
                  </SearchTextContainer>
                ))
              : 'No Results'}
          </DropdownCon>
        )}
      </FlexContainer>
      <ButtonContainer>
        <ButtonComp style={{ marginRight: '3rem' }} onClick={handleClose}>
          Cancel
        </ButtonComp>
        <ButtonComp dark={true} onClick={handleAssign}>
          Assign
        </ButtonComp>
      </ButtonContainer>
    </PopUpModal>
  );
};

export default AssignModal;
