import styled from 'styled-components';

export const BodyContainer = styled.div`
  margin: 2rem;
  background: ${({ theme }) => theme.body};
  min-height: calc(100% - 8rem - 5rem); // header margin
  /* height: 98%; */
  box-shadow: -5px -5px 11px #fffffff5;
  border-radius: 10px;
  padding: 2rem 3rem;
  overflow: auto;
  position: relative;
`;
