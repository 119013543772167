/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import DynamicSVGIcon from '../DynamicSVGIcon';
import { TabsContainer, Tab } from './Tabs.styles';

const TabsComponent = (props) => {
  const { children, getactiveTab, tab } = props;
  const [activeTab, setActiveTab] = useState();

  const handleClick = (label) => {
    setActiveTab(label);
    getactiveTab(label);
  };

  useEffect(() => {
    if (tab) {
      setActiveTab(tab);
    }
  }, [tab]);

  return (
    <>
      <div>
        <TabsContainer>
          {children.map((child, index) => {
            const { label, iconUrl, iconType = false, iconWidth, enabled = true } = child.props;

            return (
              <Tab
                className='tab '
                key={index}
                isActive={label === activeTab}
                onClick={() => enabled && handleClick(label)}
                enabled={enabled}
              >
                {iconType ? <DynamicSVGIcon iconUrl={iconUrl} width={iconWidth || '20px'} /> : label}
              </Tab>
            );
          })}
        </TabsContainer>
        <>
          {children.map((child) => {
            if (child.props.label !== activeTab) return undefined;
            return child.props.children;
          })}
        </>
      </div>
    </>
  );
};

export default TabsComponent;
