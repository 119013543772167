import styled from 'styled-components';
import { fontFamily } from '../../constants';

export const SearchContainer = styled.div`
  display: flex;
  position: relative;
  width: 58rem;
`;
export const SearchInput = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  padding: 5px;
  min-height: 45px;
  /* border: 1px solid #ccc; */
  // border-radius: 20px;
  border-radius: 1rem;
  background-color: #eeeff5;
  box-shadow: inset 2px 2px 4px #0000001a, inset -2px -2px 4px #ffffff69;
  border-radius: 1rem;
  input {
    border: none;
    height: 20px;
    padding: 5px;
    &:focus {
      outline: none;
    }
  }
`;
export const UserInput = styled.input`
  width: 100%;
  height: 100%;
  background-color: transparent;
  ::placeholder{
    font-size: 1.8rem;
    color: #9b9b9b;
    font-family: ${fontFamily.circularBook};
  }
`;
export const SuggestionsList = styled.ul`
  max-height: 300px;
  overflow-y: scroll;
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  width: 100%;
  margin-top: 15px;
  z-index: 20;
`;
export const DivContainer = styled.div`
  width: 100%;
  background-color: transparent;
`;

export const SuggestionsListItem = styled.li`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 10px;
  font-size: 2rem;
  cursor: pointer;
  /* border-bottom: 1px solid #ccc; */
  &.active {
    background-color: #ccc;
  }

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: #ccc;
  }

  img {
    height: 20px;
  }
`;
export const UserPill = styled.span`
  height: 20px;
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: black;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.6rem;
  color: #9b9b9b;
  font-family: ${fontFamily.circularBook};
`;
export const UserImage = styled.img`
  height: 100%;
`;
//
