/* eslint-disable max-len */
import axios from 'axios';

import { rootConfig } from './endPoints';
import { setAccessTokenToHeader, setCustomHeader } from './helper';

export const WebService = axios.create({
  baseURL: rootConfig.apiRoot,
  headers: {
    'Content-Type': 'application/json'
    // 'Content-Type': 'application/x-www-form-urlencoded'
  }
});

export const WebServiceMultipart = axios.create({
  baseURL: rootConfig.apiRoot,
  headers: {
    'Content-Type': 'multipart/form-data'
    // 'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
    // Accept: 'application/json'
  }
});

WebService.interceptors.response.use(
  (res) => res,
  async (err) => {
    if (err.response.status === 401 || err.response.status === 403) {
      setAccessTokenToHeader();
      setCustomHeader();
    }
    return err;
  }
);

WebServiceMultipart.interceptors.response.use(
  (res) => res,
  async (err) => {
    if (err.response.status === 401 || err.response.status === 403) {
      setAccessTokenToHeader();
      setCustomHeader();
    }
    return err;
  }
);
