import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  setWorkOrderType: ['payload'],
  setWorkOrderDetail: ['payload'],

  setAssetList: ['payload'],
  getAssetList: ['payload'],
  resetAssetShortlist: ['payload'],
  getAssetListStart: ['payload'],
  getAssetListSuccess: ['payload'],
  getAssetListFailure: ['payload'],
  setAssetShortlist: ['payload'],
  setAssetSearchQueries: ['payload'],
  resetAssetSearchQueries: ['payload']
});
