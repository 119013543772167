/* eslint-disable max-len */
import { put } from 'redux-saga/effects';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { WebService } from '../../../services/axios/webServices';
import { Creators } from './action';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';

export function* getUsersList1Saga({ payload }) {
  const { page, pagesize, searchParams } = payload;
  try {
    yield put(Creators.getUsersListStart());
    // const response = yield WebService.get(apiEndPoints.getUsersList);
    const response = yield WebService.post(`${apiEndPoints?.getUsersList}?page=${page || 1}&pagesize=${pagesize || 10}`, {
      SearchFilters: { ...searchParams }
    });
    if (response.status >= 200 && response.status < 300) {
      const { data, meta } = response.data;
      yield put(Creators.getUsersListSuccess({ data, meta }));
    } else {
      throw response;
    }
  } catch (error) {
    const { error: err } = sagaCatchBlockHandling(error);
    yield put(Creators.getUsersListFailure(err));
  }
}

export function* getUsersListSaga({ payload }) {
  const { page, pageSize, searchParams } = payload;

  try {
    yield put(Creators.getUsersListStart());
    const endpoint = `${apiEndPoints.getUsersList}/${page || 1}/${pageSize || 10}`;
    const response = yield WebService.post(`${apiEndPoints?.getUsersListNew}/${page || 1}/${pageSize || 10}`, {
      SearchFilters: { ...searchParams }
    });
    if (response.status >= 200 && response.status < 300) {
      const { data, meta, Count, CanEdit, CanChangePassword, CanAdd } = response.data;
      const userPermissions = { CanEdit, CanChangePassword, CanAdd };
      yield put(Creators.getUsersListSuccess({ data, meta, Count, userPermissions }));
    } else {
      throw response;
    }
  } catch (error) {
    const { error: err } = sagaCatchBlockHandling(error);
    yield put(Creators.getUsersListFailure(err));
  }
}
