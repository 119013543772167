import AddSchedule from '../../component/Schedule/AddSchedule/AddSchedule';
import AddScheduleDynamic from '../../component/Schedule/AddSchedule/AddScheduleDynamic';
import EditScheduleDynamic from '../../component/Schedule/AddSchedule/EditScheduleDynamic';
import EditSchedule from '../../component/Schedule/EditSchedule/EditSchedule';
import ScheduleDetails from '../../component/Schedule/ScheduleDetails/ScheduleDetails';

const ScheduleRoutes = [
  {
    path: '/home/schedule/details',
    exact: true,
    name: 'schedule-details',
    Component: ScheduleDetails
  },
  {
    path: '/home/schedule/details',
    exact: true,
    name: 'schedule-details',
    Component: ScheduleDetails
  },
  {
    path: '/home/schedule/create',
    exact: true,
    name: 'createSchedule',
    Component: AddScheduleDynamic
  },
  {
    path: '/home/schedule/create',
    exact: true,
    name: 'createSchedule',
    Component: AddScheduleDynamic
  },
  {
    path: '/home/schedule/edit',
    exact: true,
    name: 'editSchedule',
    Component: EditScheduleDynamic
  },
  {
    path: '/home/schedule/edit',
    exact: true,
    name: 'editSchedule',
    Component: EditScheduleDynamic
  }
];

export default ScheduleRoutes;
