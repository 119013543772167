/* eslint-disable no-throw-literal */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AvatarComponent } from '../../..';
import { userProfileDetails } from '../../../../constants/sampleData';
import {
  Container,
  AvatarIconContainer,
  HeadingText,
  Header,
  ContentContainer,
  UserDetailFeild,
  ButtonContainer,
  Anchor,
  FormContainer
} from './Account.style';
import editIcon from '../../../../assets/icons/Chart menu_edit pane.svg';
import DynamicSVGIcon from '../../../Common/DynamicSVGIcon';
import ItemComponent from '../../../Common/MenuItem/Item.view';
import Index from '../../../Common/Input/Input';
import ButtonComp from '../../../Common/Button/Button.view';

import { Creators as LayoutCreators } from '../../../../views/Layout/store';
import { mail, manager, phone, profession, employeeId, avatar } from '../../../../assets/icons';
import GenericForm from '../../../Common/GenericForm/GenericForm';
import { apiEndPoints } from '../../../../services/axios/endPoints';
import { WebService } from '../../../../services/axios/webServices';
import { sagaCatchBlockHandling } from '../../../../store/utility/sagaErrorHandling';
import Toast from '../../../Common/Toast';
import Loader from '../../../Common/Loader/Loader';
import { convertFormInputObjToSimple, getFieldDependents, validateFields } from '../../../Common/GenericForm/utils';
import { INPUT_TYPES } from '../../../../constants';
import { useActions } from '../../../../store/hooks/useActions';

const Item = ({ name, icon, edit }) => (
  <ItemComponent name={name} icon={icon} style={edit && { marginBottom: '0rem' }} />
);

const EditUserFeild = ({ feild }) => (
  <UserDetailFeild>
    <Item name={feild.label} edit={true} />
    <div>
      <Index
        placeholder={userProfileDetails[feild.value]}
        style={{ backgroundColor: 'transparent', width: '25rem', minWidth: '25rem' }}
      />
    </div>
  </UserDetailFeild>
);

const Accounts = () => {
  const changePwd = useSelector((state) => state.home.changePwd);
  const { userData, userMeta } = useSelector((state) => state.loginData);
  const { columnMappings } = userMeta || {};
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const userDetailsFormRef = useRef();
  const [formStructure, setFormStructure] = useState([]);
  const { updateUserDetails } = useActions();
  const formatedUserDetails = Object.entries(userData).reduce((acc, e) => {
    const [key, value] = e;
    return { ...acc, [columnMappings?.[key] || key]: value };
  }, {});
  const [formInput, setFormInput] = useState(formatedUserDetails);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const fetchProfileForm = async () => {
    try {
      setLoading(true);
      const res = await WebService.get(apiEndPoints.getProfileUserForm);
      if (res.status >= 200 && res.status < 300) {
        const { data } = res;
        setFormStructure(data);
      } else {
        throw res;
      }
    } catch (e) {
      const { error: err } = sagaCatchBlockHandling(e);
      setError(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchProfileForm();
    if (changePwd) {
      console.log('closing active settings menu', changePwd);
      dispatch(LayoutCreators.settingsActiveMenu(''));
    }
  }, [changePwd]);

  const handleChangePwd = () => {
    dispatch(LayoutCreators.toggleHeaderOption('changePwd'));
  };

  const handleSaveChanges = async () => {
    const localFormInput = userDetailsFormRef.current.getFormInput();
    try {
      const isPassed = validateFields(formStructure, localFormInput);
      if (!isPassed) {
        throw 'Validation error has occurred';
      }
      // console.log('localFormInput', localFormInput);
      const formatedFormInput = convertFormInputObjToSimple(localFormInput);
      const requiredInput = formStructure.reduce((acc, e) => {
        const { key, inputType, defaultValue } = e;
        let val = formatedFormInput[key] || defaultValue;
        if (inputType === INPUT_TYPES.TOGGLE) {
          val = val || false;
        }
        if (!key) return acc;
        return { ...acc, [key]: val };
      }, {});
      const updatedInfo = Object.entries(columnMappings).reduce((acc, e) => {
        const [key, value] = e;
        if (requiredInput[value] !== undefined) {
          return { ...acc, [key]: requiredInput[value] };
        }
        return acc;
      }, {});
      // update userData in login reducer
      updateUserDetails(updatedInfo);
      WebService.post(apiEndPoints.updateProfileDetails, requiredInput)
        .then((res) => {
          const { data, status } = res;
          if (status >= 200 && status < 300) {
            setFormInput(localFormInput);
            setEdit(false);
          } else {
            throw res;
          }
        })
        .catch((e) => {
          const { error } = sagaCatchBlockHandling(e);
          console.log('error===', error);
          setError(error);
        });
    } catch (e) {
      console.log('error--', e);
      setError(e);
    }
  };

  const cancelEdit = () => {
    setEdit(false);
  };
  return (
    <Container>
      <AvatarIconContainer>
        <AvatarComponent
          userdata={userData}
          circle='circle'
          // ml='1.5rem'
          size='12rem'
          // onClick={(e) => setModalIsVisibal(!modalIsVisibal)}
        />
      </AvatarIconContainer>
      <ContentContainer>
        {/* <Header>
          <HeadingText>{userData.profileName}</HeadingText>
          <div onClick={() => setEdit(!edit)}>
            <DynamicSVGIcon iconUrl={editIcon} width='3rem' />
          </div>
        </Header> */}
        {/* {userFeilds.map((field) => (
            <Item name={userData[field.value]} icon={field.icon} />
          ))} */}
        <>
          {loading ? (
            <Loader />
          ) : (
            <>
              <div>
                <FormContainer>
                  <GenericForm
                    hideHeader={true}
                    isFormEditable={edit}
                    formStructure={formStructure}
                    ref={userDetailsFormRef}
                    formInput={formInput}
                    setFormInput={setFormInput}
                    showHeader
                    setIsFormFilled={setIsFormFilled}
                    formStructureFieldDepenents={getFieldDependents(formStructure)}
                    allowHorizontal={true}
                  />
                </FormContainer>
                <Anchor
                  // className='disable'
                  onClick={handleChangePwd}
                >
                  change Password
                </Anchor>
                {edit && (
                  <ButtonContainer className='jc_evenly'>
                    <ButtonComp style={{ marginRight: '2rem' }} onClick={cancelEdit}>
                      Cancel
                    </ButtonComp>
                    <ButtonComp dark={true} className={isFormFilled ? '' : 'disable'} onClick={handleSaveChanges}>
                      Save Changes
                    </ButtonComp>
                  </ButtonContainer>
                )}
              </div>
              {!edit && (
                <div onClick={() => setEdit(!edit)}>
                  <DynamicSVGIcon iconUrl={editIcon} width='3rem' />
                </div>
              )}
            </>
          )}
        </>
        {error && <Toast header='Error' className='fixed right' message={error} fn={() => setError('')} />}
      </ContentContainer>
    </Container>
  );
};

export default Accounts;
