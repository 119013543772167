/* eslint-disable max-len */
import React, { useEffect, memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterSearch } from './FilterSearchComponent.style';
import { Creators as FilterCreators } from '../../../views/Filters/store';
import { loopIntoParent } from './SearchFunctionality';
// import { localString } from '../../../localization/localString';
import useDebounce from '../../../shared/hooks/useDebounce';

export const findTheValue = (filterData, val, searchBy = 'name') => {
  let searchedObjectPath = [];
  let referencePath = [];
  let pathVal = [];
  let leafNodes = [];
  if (filterData) {
    filterData.forEach((item) => {
      const value = loopIntoParent(item, val, searchBy);

      if (value.selectedPath.length > 0) {
        pathVal = [...pathVal, ...value.selectedPath];
        searchedObjectPath = [...searchedObjectPath, ...value.selectedObj];
        referencePath = [...referencePath, ...value.refrenceObj];
        leafNodes = [...leafNodes, ...value.leafNodes];
      }
    });
  }
  return { searchedObjectPath, referencePath, pathVal, leafNodes };
};

function FilterSearchInput({ searchValue, setSearchValue, placeholder }) {
  const { filterData } = useSelector((state) => state.filterData);
  const { localeStrings } = useSelector((state) => state.localeStrings);
  const dispatch = useDispatch();

  const toggleDropDown = (value) => {
    dispatch(
      FilterCreators.universalFilterSetter({
        key: 'isDropDownActive',
        value
      })
    );
  };

  const handleChange = (e) => {
    setSearchValue(e);
  };

  useEffect(() => {
    // let value;
    // if (searchValue) {
    //   value = findTheValue(filterData, searchValue);
    // } else {
    //   value = [];
    // }

    const value = findTheValue(filterData, searchValue);

    dispatch(
      FilterCreators.universalFilterSetter({
        key: 'searchResult',
        value
      })
    );
  }, [searchValue]);

  const [localValue, setLocalValue] = useState(searchValue);
  useDebounce(localValue, handleChange, 1000);

  return (
    <FilterSearch
      // localeStrings.searchFilters ||
      placeholder={placeholder || 'Search For Filters'}
      value={localValue}
      onChange={(e) => setLocalValue(e.target.value)}
      onFocus={() => toggleDropDown(true)}
    />
  );
}

export default memo(FilterSearchInput);
