import styled from 'styled-components';
import { Input } from '../Input/Input';
import { FlexContainer } from '../../../globalStyles';
import { DropBtn } from '../../../views/WorkOrders/WorkOrders.styles';

export const InputAdd = styled(Input)`
  height: 45px;
  width: 13.2rem;
`;

export const AddBtn = styled(DropBtn)`
  background: red;
`;

export const AddsBtn = styled(FlexContainer)`
  background: #fff;
  position: absolute;
  z-index: 2;
  cursor: pointer;
  width: 30px;
  height: 19px;
  top: 2px;
  border-radius: 5px;
  left: 8.5rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  justify-content: center;
  & > svg {
    height: 15px;
    width: 15px;
    margin-top: 2px;
    transform: rotate(270deg);
  }
`;

export const MinusBtn = styled(AddsBtn)`
  top: 3rem;
  & > svg {
    transform: rotate(90deg);
    margin-top: 5px;
  }
`;
