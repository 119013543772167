/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable max-len */
import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import Iframe from '../component/ResultView/Iframe/Iframe';
import { getUserDataFromLocal } from '../shared/utility/helper';
import Login from '../views/auth/login/Login.view';
import DirectIframe from '../views/DirectIframe/DirectIframe';
import Layout from '../views/Layout/Layout';

const ProtectedRoute = ({ Component, ...props }) => {
  const authenticated = useSelector((state) => state.loginData.authenticated);
  const userData = getUserDataFromLocal();

  if (Component === Login) {
    return (
      <Route
        {...props}
        render={(props) => (
          <>
            {userData?.token || authenticated ? (
              <Redirect to='/home/Dashboard/Active-WO-Summary' />
            ) : (
              <Component {...props} />
            )}
          </>
        )}
      />
    );
  }
  return (
    <Route
      {...props}
      render={(props) => <>{userData?.token || authenticated ? <Component {...props} /> : <Redirect to='/' />}</>}
    />
  );
};

const Routes = () => (
  <Switch>
    <ProtectedRoute path='/home' exact={false} name='home' Component={Layout} />
    <ProtectedRoute path='/filter-iframe' exact={false} name='iframe' Component={Iframe} />
    <ProtectedRoute path='/direct-iframe' exact={false} name='direct-iframe' Component={DirectIframe} />
    <ProtectedRoute path='/' exact={false} name='login' Component={Login} />
  </Switch>
);

export default Routes;
