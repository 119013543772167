import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Div } from '../../../../globalStyles';
import Form from './InputWorkOrderDetails';
import Header from '../Header';
import { Bttn } from '../../../../views/WorkOrders/WorkOrders.styles';

const Container = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.body};
  border-radius: 10px;
  padding: 2rem;
  box-shadow: ${({ theme }) => theme.shadowout};
`;

const Step3 = () => {
  const history = useHistory();
  return (
    <>
      {/* name='Create' onClick={null} */}
      <Header>
        <Bttn className='white' onClick={() => history.goBack()}>
          Cancel
        </Bttn>
      </Header>
      <Div px='2'>
        <Container>
          <Form />
        </Container>
      </Div>
    </>
  );
};

export default Step3;
