import React from 'react';

function CollapseIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 48 48'>
      <g>
        <path
          fill='red'
          d='M43.918 24C43.918 35 35 43.918 24 43.918S4.082 35 4.082 24 13 4.082 24 4.082 43.918 13 43.918 24zm0 0'
        />
        <path
          fill='#D2D2D2'
          d='M24 43.918C13.02 43.918 4.082 34.98 4.082 24 4.082 13.02 13.02 4.082 24 4.082c10.98 0 19.918 8.938 19.918 19.918 0 10.98-8.938 19.918-19.918 19.918zm0-38.461C13.773 5.457 5.457 13.773 5.457 24c0 10.227 8.316 18.543 18.543 18.543 10.227 0 18.543-8.316 18.543-18.543 0-10.227-8.316-18.543-18.543-18.543zm0 0'
        />
        <path fill='green' d='M32.656 25.441H15.344a1.44 1.44 0 110-2.882h17.312a1.44 1.44 0 110 2.882zm0 0' />
      </g>
    </svg>
  );
}

export default CollapseIcon;
