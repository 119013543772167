/* eslint-disable indent */
/* eslint-disable no-case-declarations */
/* eslint-disable max-len */
/* eslint-disable camelcase */

export const getValueForNestedKey = (objArg, nestedKey) => {
  const keys = nestedKey.split('.');
  let obj = { ...objArg };
  keys.every((key) => {
    if (typeof obj[key] === 'object') {
      obj = { ...obj[key] };
      return true;
    }
    obj = obj[key];
    return false;
  });
  return obj;
};

const getValue = (innerComp, user_data, computeValue) => {
  let value;
  if (computeValue) {
    value = innerComp.key.includes('.') ? getValueForNestedKey(user_data, innerComp.key) : user_data[innerComp.key];
  } else {
    value = innerComp.key;
  }
  return value;
};

const getLabels = (comp, type, req_obj, user_data, computeValue = true) => {
  // console.log('req_obj', type, [...req_obj]);
  if (!comp.hidden) {
    switch (type) {
      case 'table':
        comp?.rows?.forEach((tr) => {
          tr.forEach((td) => {
            //   console.log('table ', tr);
            td.components.forEach((innerComp) => {
              // console.log('inn', innerComp.type);
              getLabels(innerComp, innerComp.type, req_obj, user_data, computeValue);
            });
          });
        });
        break;
      case 'datagrid':
        const { key, label } = comp;
        const temp_obj = { label, value: { labels: [], values: [], defaultValues: [] } };
        comp?.components?.forEach((innerComp) => {
          const customizedValue = innerComp.customDefaultValue;
          if (!innerComp.hidden) {
            temp_obj.value.labels.push({ label: innerComp.label, value: innerComp.key, customizedValue });
          }
        });
        temp_obj.value.values = user_data[key];
        temp_obj.value.defaultValues = comp.defaultValue;
        // console.log('user_data[innerComp.key]', temp_obj);
        req_obj.push(temp_obj);
        break;

      case 'columns':
        comp?.columns?.forEach((column) => {
          column?.components?.forEach((innerComp) => {
            getLabels(innerComp, innerComp.type, req_obj, user_data, computeValue);
          });
        });
        break;

      case 'button':
        if (comp.key === 'submit') break;
        break;

      case 'htmlelement' || 'hidden':
        break;

      case 'editgrid':
        const editGridData = [];
        comp?.components?.forEach((innerComp) => {
          const { type } = innerComp;
          if (type !== 'datagrid') getLabels(innerComp, innerComp.type, editGridData, user_data, false);
        });
        // console.log('edit', [...editGridData]);
        req_obj.push({
          label: comp.label,
          value: { labels: [...editGridData], values: user_data[comp.key], defaultValues: [] }
        });
        break;

      default:
        if (comp?.components) {
          comp?.components?.forEach((innerComp) => {
            req_obj.push({
              label: innerComp.label,
              value: getValue(innerComp, user_data, computeValue),
              // value: innerComp.key.includes('.')
              //   ? getValueForNestedKey(user_data, innerComp.key)
              //   : user_data[innerComp.key],
              defaultValue: innerComp.defaultValue,
              tooltip: innerComp.tooltip,
              format: innerComp.format
            });
          });
        } else {
          req_obj.push({
            label: comp.label,
            // value: comp.key.includes('.') ? getValueForNestedKey(user_data, comp.key) : user_data[comp.key],
            value: getValue(comp, user_data, computeValue),
            defaultValue: comp.defaultValue,
            tooltip: comp.tooltip,
            format: comp.format
          });
        }
        break;
    }
  }
};

export const getWorkOrderDetails = (formdefinition, user_data) => {
  const req_obj = [];
  // console.log('aae', typeof formdefinition, formdefinition.components, user_data);
  try {
    formdefinition?.components?.forEach((comp) => {
      const { type } = comp;
      getLabels(comp, type, req_obj, user_data);
    });
  } catch (e) {
    console.log('error while fetching form details', e);
  } finally {
    // console.log('req_obj', req_obj);
  }
  return req_obj;
};
