/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { CancelBlack } from '../../../assets/icons';

import DynamicSVGIcon from '../../Common/DynamicSVGIcon';
import { Creators as UpserWorkOrderCreators } from './store';
import { HeaderTitle, Bttn } from '../../../views/WorkOrders/WorkOrders.styles';
import { Div, FlexContainer } from '../../../globalStyles';
import { PopUpModal } from '../..';
// import { STATUSES } from '../../../constants';
// import useResetDisableNext from './hooks/usetResetDisableNext';
// import Step2 from './Step2/SelectionFilter';
// import Step3 from './Step3/InputWorkOrderDetails';
// import Step1 from './Step1/step1';
import SelectWOTypeInPopup from './Step1/SelectWOTypeInPopup';

export const WOTypes = [
  { label: 'Preventive Maintenance', value: 'Preventive Maintenance' },
  { label: 'Spare Request', value: 'Spare Request' },
  { label: 'Breakdown', value: 'Breakdown' },
  { label: 'HV Parts', value: 'HV Parts' }
];

export const ApiWOTypes = [
  {
    workflowName: 'Preventive Maintenance',
    workflowTemplateId: 1,
    Type: 'Preventive Maintenance',
    version: 1
  },
  {
    workflowName: 'Break Down',
    workflowTemplateId: 2,
    Type: 'Break Down',
    version: 1
  },
  {
    workflowName: 'Spare Request Notinuse',
    workflowTemplateId: 3,
    Type: 'Spare Request Notinuse',
    version: 1
  },
  {
    workflowName: 'Vendor to Store',
    workflowTemplateId: 4,
    Type: 'Vendor to Store',
    version: 1
  },
  {
    workflowName: 'Spare Request',
    workflowTemplateId: 5,
    Type: 'Spare Request',
    version: 1
  },
  {
    workflowName: 'Spare Borrow',
    workflowTemplateId: 6,
    Type: 'Spare Borrow',
    version: 1
  }
];

const Createworkorder = (props) => {
  const { onCancel, menuId } = props;
  const { type } = useSelector((state) => state.upsertWorkOrder);
  // const [step, setStep] = useState(1);
  // const [disableNext, setDisableNext] = useState(true);
  // const history = useHistory();
  // const dispatch = useDispatch();
  // const _renderStep = (step, setDisableNext) => {
  //   switch (step) {
  //     case 1:
  //       return <Step1 useResetDisableNext={useResetDisableNext} setDisableNext={setDisableNext} />;
  //     case 2:
  //       return <Step2 menuId={menuId} setDisableNext={setDisableNext} />;
  //     case 3:
  //       return <Step3 setDisableNext={setDisableNext} disableNext={disableNext} />;
  //     default:
  //       return Step1();
  //   }
  // };

  // const nextStep = () => {
  //   setStep(step + 1);
  // };

  // const backStep = () => {
  //   setStep(step - 1);
  // };

  // const handleCreate = () => {
  //   history.push('/home/work-order-details');
  //   if (type.value === 'Spare Request') {
  //     dispatch(UpserWorkOrderCreators.setWorkOrderDetail({ status: STATUSES.SPARE_REQUEST }));
  //   }
  // };

  // console.log('disableNext--->', disableNext);
  return (
    <PopUpModal width='40%' style={{ padding: '4rem' }} closeModalHandler={() => { }}>
      <FlexContainer className='ai_center jc_spacebetween' mb='4'>
        <HeaderTitle>{`Create ${type.Type || ''} Work Order`}</HeaderTitle>
        <Div onClick={onCancel} className='pointer'>
          <DynamicSVGIcon iconUrl={CancelBlack} />
        </Div>
      </FlexContainer>
      <SelectWOTypeInPopup onCancel={onCancel} />
    </PopUpModal>
  );
};

export default Createworkorder;
