import moment from 'moment';

const eventGuid = 0;
const todayStr = new Date().toISOString().replace(/T.*$/, ''); // YYYY-MM-DD of today

export function createEventId() {
  return String(eventGuid + 1);
}

export const INITIAL_EVENTS = [
  {
    id: createEventId(),
    title: 'All-day event',
    start: todayStr,
    details: {
      activity: 'Recurring',
      asset: 'CPAO41',
      scheduleType: 'PM_Once_12days',
      woId: null,
      status: 'Recurring'
    }
  },
  {
    id: createEventId(),
    title: 'Timed event',
    start: `${todayStr}T12:00:00`,
    end: `${todayStr}T15:00:00`,
    details: {
      activity: 'Recurring',
      asset: 'CPAO41',
      scheduleType: 'PM_Once_12days',
      woId: null,
      status: 'Recurring'
    }
  },
  {
    id: createEventId(),
    title: 'PM Daily_Recurring_12Days',
    start: `${todayStr}T13:00:00`,
    end: `${todayStr}T14:00:00`,
    details: {
      activity: 'Recurring',
      asset: 'CPAO41',
      scheduleType: 'PM_Once_12days',
      woId: null,
      status: 'Recurring'
    }
  },
  {
    id: createEventId(),
    title: 'Timed event',
    start: `${todayStr}T16:00:00`,
    end: `${todayStr}T17:00:00`,
    details: {
      activity: 'Recurring',
      asset: 'CPAO41',
      scheduleType: 'PM_Once_12days',
      woId: null,
      status: 'Recurring'
    }
  },
  {
    id: createEventId(),
    title: 'Timed event',
    start: `${todayStr}T20:00:00`,
    end: `${todayStr}T22:00:00`,
    details: {
      activity: 'Recurring',
      asset: 'CPAO41',
      scheduleType: 'PM_Once_12days',
      woId: null,
      status: 'Recurring'
    }
  },
  {
    id: createEventId(),
    title: 'Timed event',
    start: moment(todayStr).subtract(2, 'days').add(3, 'hours').toISOString(),
    end: moment(todayStr).subtract(2, 'days').add(4, 'hours').toISOString(),
    details: {
      activity: 'Recurring',
      asset: 'CPAO41',
      scheduleType: 'PM_Once_12days',
      woId: null,
      status: 'Recurring'
    }
  },
  {
    id: createEventId(),
    title: 'Timed event',
    start: moment(todayStr).subtract(3, 'days').toISOString(),
    end: moment(todayStr).subtract(3, 'days').add(2, 'hours').toISOString(),
    details: {
      activity: 'Recurring',
      asset: 'CPAO41',
      scheduleType: 'PM_Once_12days',
      woId: null,
      status: 'Recurring'
    }
  },
  {
    id: createEventId(),
    title: 'Timed event',
    start: moment(todayStr).subtract(1, 'days').toISOString(),
    end: moment(todayStr).subtract(1, 'days').add(2, 'hours').toISOString(),
    details: {
      activity: 'Recurring',
      asset: 'CPAO41',
      scheduleType: 'PM_Once_12days',
      woId: null,
      status: 'Recurring'
    }
  },
  {
    id: createEventId(),
    title: 'Timed event',
    start: moment(todayStr).subtract(6, 'days').toISOString(),
    end: moment(todayStr).subtract(6, 'days').add(2, 'hours').toISOString(),
    details: {
      activity: 'Recurring',
      asset: 'CPAO41',
      scheduleType: 'PM_Once_12days',
      woId: null,
      status: 'Recurring'
    }
  },
  {
    id: createEventId(),
    title: 'Timed event',
    start: moment(todayStr).subtract(8, 'days').toISOString(),
    end: moment(todayStr).subtract(8, 'days').add(2, 'hours').toISOString(),
    details: {
      activity: 'Recurring',
      asset: 'CPAO41',
      scheduleType: 'PM_Once_12days',
      woId: null,
      status: 'Recurring'
    }
  },
  {
    id: createEventId(),
    title: 'Timed event',
    start: moment(todayStr).subtract(10, 'days').toISOString(),
    end: moment(todayStr).subtract(10, 'days').add(2, 'hours').toISOString(),
    details: {
      activity: 'Recurring',
      asset: 'CPAO41',
      scheduleType: 'PM_Once_12days',
      woId: null,
      status: 'Recurring'
    }
  },
  {
    id: createEventId(),
    title: 'Timed event',
    start: moment(todayStr).subtract(12, 'days').toISOString(),
    end: moment(todayStr).subtract(12, 'days').add(2, 'hours').toISOString(),
    details: {
      activity: 'Recurring',
      asset: 'CPAO41',
      scheduleType: 'PM_Once_12days',
      woId: null,
      status: 'Recurring'
    }
  },
  {
    id: createEventId(),
    title: 'Timed event',
    start: moment(todayStr).subtract(2, 'days').toISOString(),
    end: moment(todayStr).subtract(2, 'days').add(2, 'hours').toISOString(),
    details: {
      activity: 'Recurring',
      asset: 'CPAO41',
      scheduleType: 'PM_Once_12days',
      woId: null,
      status: 'Recurring'
    }
  },

  {
    id: createEventId(),
    title: 'Timed event',
    start: moment(todayStr).add(2, 'days').toISOString(),
    end: moment(todayStr).add(2, 'days').add(2, 'hours').toISOString(),
    details: {
      activity: 'Recurring',
      asset: 'CPAO41',
      scheduleType: 'PM_Once_12days',
      woId: null,
      status: 'Recurring'
    }
  },
  {
    id: createEventId(),
    title: 'Timed event',
    start: moment(todayStr).add(3, 'days').toISOString(),
    end: moment(todayStr).add(3, 'days').add(2, 'hours').toISOString(),
    details: {
      activity: 'Recurring',
      asset: 'CPAO41',
      scheduleType: 'PM_Once_12days',
      woId: null,
      status: 'Recurring'
    }
  },
  {
    id: createEventId(),
    title: 'Timed event',
    start: moment(todayStr).add(1, 'days').toISOString(),
    end: moment(todayStr).add(1, 'days').add(2, 'hours').toISOString(),
    details: {
      activity: 'Recurring',
      asset: 'CPAO41',
      scheduleType: 'PM_Once_12days',
      woId: null,
      status: 'Recurring'
    }
  },
  {
    id: createEventId(),
    title: 'Timed event',
    start: moment(todayStr).add(6, 'days').toISOString(),
    end: moment(todayStr).add(6, 'days').add(2, 'hours').toISOString(),
    details: {
      activity: 'Recurring',
      asset: 'CPAO41',
      scheduleType: 'PM_Once_12days',
      woId: null,
      status: 'Recurring'
    }
  },
  {
    id: createEventId(),
    title: 'Timed event',
    start: moment(todayStr).add(8, 'days').toISOString(),
    end: moment(todayStr).add(8, 'days').add(2, 'hours').toISOString(),
    details: {
      activity: 'Recurring',
      asset: 'CPAO41',
      scheduleType: 'PM_Once_12days',
      woId: null,
      status: 'Recurring'
    }
  },
  {
    id: createEventId(),
    title: 'Timed event',
    start: moment(todayStr).add(10, 'days').toISOString(),
    end: moment(todayStr).add(10, 'days').add(2, 'hours').toISOString(),
    details: {
      activity: 'Recurring',
      asset: 'CPAO41',
      scheduleType: 'PM_Once_12days',
      woId: null,
      status: 'Recurring'
    }
  },
  {
    id: createEventId(),
    title: 'Timed event',
    start: moment(todayStr).add(12, 'days').toISOString(),
    end: moment(todayStr).add(12, 'days').add(2, 'hours').toISOString(),
    details: {
      activity: 'Recurring',
      asset: 'CPAO41',
      scheduleType: 'PM_Once_12days',
      woId: null,
      status: 'Recurring'
    }
  },
  {
    id: createEventId(),
    title: 'Timed event',
    start: moment(todayStr).add(2, 'days').toISOString(),
    end: moment(todayStr).add(2, 'days').add(2, 'hours').toISOString(),
    details: {
      activity: 'Recurring',
      asset: 'CPAO41',
      scheduleType: 'PM_Once_12days',
      woId: null,
      status: 'Recurring'
    }
  },

  {
    id: createEventId(),
    title: 'Timed event',
    start: moment(todayStr).add(32, 'days').toISOString(),
    end: moment(todayStr).add(32, 'days').add(2, 'hours').toISOString(),
    details: {
      activity: 'Recurring',
      asset: 'CPAO41',
      scheduleType: 'PM_Once_12days',
      woId: null,
      status: 'Recurring'
    }
  },
  {
    id: createEventId(),
    title: 'Timed event',
    start: moment(todayStr).add(33, 'days').toISOString(),
    end: moment(todayStr).add(33, 'days').add(2, 'hours').toISOString(),
    details: {
      activity: 'Recurring',
      asset: 'CPAO41',
      scheduleType: 'PM_Once_12days',
      woId: null,
      status: 'Recurring'
    }
  },
  {
    id: createEventId(),
    title: 'Timed event',
    start: moment(todayStr).add(31, 'days').toISOString(),
    end: moment(todayStr).add(31, 'days').add(2, 'hours').toISOString(),
    details: {
      activity: 'Recurring',
      asset: 'CPAO41',
      scheduleType: 'PM_Once_12days',
      woId: null,
      status: 'Recurring'
    }
  },
  {
    id: createEventId(),
    title: 'Timed event',
    start: moment(todayStr).add(36, 'days').toISOString(),
    end: moment(todayStr).add(36, 'days').add(2, 'hours').toISOString(),
    details: {
      activity: 'Recurring',
      asset: 'CPAO41',
      scheduleType: 'PM_Once_12days',
      woId: null,
      status: 'Recurring'
    }
  },
  {
    id: createEventId(),
    title: 'Timed event',
    start: moment(todayStr).add(38, 'days').toISOString(),
    end: moment(todayStr).add(38, 'days').add(2, 'hours').toISOString(),
    details: {
      activity: 'Recurring',
      asset: 'CPAO41',
      scheduleType: 'PM_Once_12days',
      woId: null,
      status: 'Recurring'
    }
  },
  {
    id: createEventId(),
    title: 'Timed event',
    start: moment(todayStr).add(310, 'days').toISOString(),
    end: moment(todayStr).add(310, 'days').add(2, 'hours').toISOString(),
    details: {
      activity: 'Recurring',
      asset: 'CPAO41',
      scheduleType: 'PM_Once_12days',
      woId: null,
      status: 'Recurring'
    }
  },
  {
    id: createEventId(),
    title: 'Timed event',
    start: moment(todayStr).add(312, 'days').toISOString(),
    end: moment(todayStr).add(312, 'days').add(2, 'hours').toISOString(),
    details: {
      activity: 'Recurring',
      asset: 'CPAO41',
      scheduleType: 'PM_Once_12days',
      woId: null,
      status: 'Recurring'
    }
  },
  {
    id: createEventId(),
    title: 'Timed event',
    start: moment(todayStr).add(32, 'days').toISOString(),
    end: moment(todayStr).add(32, 'days').add(2, 'hours').toISOString(),
    details: {
      activity: 'Recurring',
      asset: 'CPAO41',
      scheduleType: 'PM_Once_12days',
      woId: null,
      status: 'Recurring'
    }
  }
];
