/* eslint-disable camelcase */
/* eslint-disable indent */
/* eslint-disable dot-notation */
/* eslint-disable quotes */
/* eslint-disable max-len */
/* eslint-disable react/jsx-curly-brace-presence */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { editIcon } from '../../../assets/icons';
import { FlexContainer } from '../../../globalStyles';
import ButtonComp from '../../Common/Button/Button.view';
import { Span } from '../../Common/CommonStyles';
import DynamicSVGIcon from '../../Common/DynamicSVGIcon';
import HeaderComp from '../../Common/Header/Header';
import KeyValueLayout from '../../Common/KeyValueLayout/KeyValueLayout';
import { ContentText } from '../../Common/KeyValueLayout/KeyValueLayout.style';
import { Divider } from './ScheduleTypeDetails.style';
import { BodyContainer, LoaderContainer } from '../../Activity/ActivityDetails/ActivtyDetails.style';
import { SchedultTypesCreators } from '../../../views/ScheduleType/store';
import Loader from '../../Common/Loader/Loader';
import { checkIsFrequencyBelowMonth } from '../utility';
import { scheduleRecurenceUnit } from '../../../constants';

const ScheduleTypeDetails = (props) => {
  const { loading } = useSelector((state) => state.scheduleTypesData);
  const scheduleTypedetails = useSelector((state) => state.scheduleTypesData.details);
  const { allowedBufferHour, allowedBufferTime, scheduleType, frequency, occurs, occursEvery, startTime, endTime } =
    scheduleTypedetails || {};
  const {
    scheduleFrequency,
    day,
    dayofEvery,
    firstLast,
    firstLastDay,
    firstLastDayEvery,
    day_asText,
    dayofEvery_asText,
    recurring,
    recurringDay
  } = frequency?.[0] || {};
  const { location } = props;
  const { details } = location.state;
  const { scheduleTypeId } = details;
  const history = useHistory();
  const dispatch = useDispatch();
  // console.log('got scheduleTypeId', scheduleTypeId);
  useEffect(() => {
    if (scheduleTypeId) {
      dispatch(SchedultTypesCreators.getScheduleTypeDetails({ scheduleTypeId }));
    }
  }, [scheduleTypeId]);

  const handleBack = () => {
    history.push('/home/schedule-type');
  };

  const isFrequencyBelowMonth = checkIsFrequencyBelowMonth(scheduleFrequency);
  const recurenceUnit = scheduleRecurenceUnit[scheduleFrequency];
  // console.log("frequency[0]['scheduleFrequency']", frequency?.[0]?.['scheduleFrequency']);
  return loading ? (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  ) : (
    <>
      <HeaderComp handleBack={handleBack} className='fixed-height' header={scheduleTypedetails?.scheduleName} hasBack>
        <ButtonComp
          dark
          onClick={() => {
            history.push('/home/schedule-type/edit', scheduleTypedetails);
          }}
        >
          <FlexContainer className='jc_center ai_center'>
            <DynamicSVGIcon width='2.2rem' iconUrl={editIcon} />
            <Span>Edit</Span>
          </FlexContainer>
        </ButtonComp>
      </HeaderComp>
      <BodyContainer>
        <KeyValueLayout name={'Schedule Name'} labelValue={scheduleTypedetails?.scheduleName} />
        <Divider />
        <KeyValueLayout name={'Schedule Type'} labelValue={scheduleTypedetails?.scheduleType} />
        <KeyValueLayout
          name={'Allowed Buffer Time'}
          labelValue={`${allowedBufferHour} Hour(s) ${allowedBufferTime} Minute(s)`}
        />
        {/* <Divider /> */}
        {/* <ContentText className='heading' style={{ marginBottom: '3rem' }}>
          Frequency
        </ContentText> */}
        {scheduleType !== 'One Time' && (
          <>
            {
              // frequency
              isFrequencyBelowMonth ? (
                <>
                  <KeyValueLayout
                    name={'Recurs Every'}
                    labelValue={`${recurring || '-'} ${recurenceUnit || '-'} ${
                      scheduleFrequency === 'Bi-Weekly' || scheduleFrequency === 'Weekly'
                        ? `On ${recurringDay || '-'}`
                        : ''
                    }`}
                  />
                </>
              ) : (
                <>
                  <KeyValueLayout
                    name={'Frequency'}
                    labelValue={
                      firstLastDay
                        ? //  ?`${firstLast || '-'} ${firstLastDay || '-'} of every ${
                          //   firstLastDayEvery || '-'
                          // } ${recurenceUnit}`
                          `${day_asText} ${dayofEvery_asText}`
                        : `Day ${day || '-'} of Every ${dayofEvery || '-'} ${recurenceUnit}`
                    }
                  />
                </>
              )
            }
            <Divider />
            <ContentText className='heading' style={{ marginBottom: '3rem' }}>
              Frequency within the Day
            </ContentText>
            {occurs ? (
              <>
                <KeyValueLayout name={'Occurs once At'} labelValue={`${occurs || '-'}`} />
              </>
            ) : (
              <>
                <KeyValueLayout name={'Occur Every'} labelValue={`${occursEvery} Hour(s)`} />
                <KeyValueLayout name={'Starting At'} labelValue={startTime || '-'} />
                <KeyValueLayout name={'Ending At'} labelValue={endTime || '-'} />
              </>
            )}
          </>
        )}
      </BodyContainer>
    </>
  );
};

export default ScheduleTypeDetails;
