/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */

import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators } from './store';
import { Creators as AuthCreators } from '../auth/store';
import { Creators as configCreators } from '../../config/action';
import { AvatarComponent, SideNav, IconComponent } from '../../component';
import config from '../../config.json';

// import { Icons } from '../../constants/icons';
import Home from '../../routes/HomeRoutes';

import { FlexContainer } from '../../globalStyles';
import { Body, Content, Header, Logo } from './Layout.styles';
import { useWindowSize } from '../../shared/hooks/useWindowResize';
import useClickOutside from '../../shared/hooks/useClickOutside';
import DropDownModal from '../../component/Common/DropDownModal/DropDownModal';
import { profileMenuData } from '../../constants/sampleData';
import GlobalSearchContainer from '../../component/GlobalSearch/GlobalSearchContainer';
import { THEMES } from '../../constants';
import ProfileSettingsDropdown from '../../component/HeaderOptions/ProfileSettingDropdown/ProfileSettingsDropdown';
import SettingsModal from '../../component/HeaderOptions/SettingsModal/SettingsModal.view';
import SettingsDropdown from '../../component/HeaderOptions/SettingsDropdown/SettingsDropdown.view';
import ChangePwdModal from '../../component/HeaderOptions/SettingsModal/Accounts/ChangePwdModal/ChangePwdModal.view';
import { setAccessTokenToHeader, setCustomHeader } from '../../services/axios/helper';

const Dashboard = ({ history, match }) => {
  const [width] = useWindowSize();
  const [view, setView] = useState('mobile');
  const [showSearchBarInMobile, setShowSearchBarInMobile] = useState(false);
  const [modalIsVisibal, setModalIsVisibal] = useState(false);
  const dispatch = useDispatch();
  const sideNavRef = useRef();
  const headerRef = useRef();

  const { showSideNav, profileSettingsDropdown, settingsModalActiveTab, helpDropdown, settingsDropdown, changePwd } =
    useSelector((state) => state.home);
  const { userData, keyField } = useSelector((state) => state.loginData);
  const { uName, userId, email } = userData;
  const { theme } = useSelector((state) => state.configData);

  const fetchMenu = async () => {
    await setAccessTokenToHeader(uName || userId || email);
    await setCustomHeader(userData[keyField]);
    dispatch(
      Creators.getMenu({
        // company: config.company,
        userData
      })
    );
  };

  useEffect(() => {
    if (userData && keyField) {
      fetchMenu();
    }
  }, [userData, keyField]);

  useEffect(() => {
    if (width >= 1024) {
      setView('web');
      dispatch(Creators.setShowSideNav({ showSideNav: true }));
    }

    if (width <= 768) {
      setView('mobile');
      dispatch(Creators.setShowSideNav({ showSideNav: false }));
    }
  }, [width]);

  const handleToggle = () => {
    dispatch(Creators.setShowSideNav({ showSideNav: !showSideNav }));
  };

  useClickOutside(
    sideNavRef,
    () => view === 'mobile' && showSideNav && dispatch(Creators.setShowSideNav({ showSideNav: false })),
    headerRef
  );

  const closeDropModals = (option) => {
    if (option === 'profileSettingsDropdown' && profileSettingsDropdown) {
      dispatch(Creators.toggleHeaderOption(option));
    }

    if (option === 'settingsDropdown' && settingsDropdown) {
      dispatch(Creators.toggleHeaderOption(option));
    }

    if (option === 'helpDropdown' && helpDropdown) {
      dispatch(Creators.toggleHeaderOption(option));
    }
  };

  const hanldeModalItemClick = (role) => {
    if (role === 'filter') {
      //
    } else {
      dispatch(AuthCreators.onLogout());
    }
    setModalIsVisibal(false);
  };

  const handleTheme = () => {
    const newTheme = theme === THEMES.LIGHT ? THEMES.DARK : THEMES.LIGHT;
    dispatch(configCreators.setThemeColors({ company: 'wd', theme: newTheme }));
    dispatch(configCreators.setTheme(newTheme));
  };

  const handleHeaderOptClick = (option) => {
    dispatch(Creators.toggleHeaderOption(option));
  };

  const settingsRef = useRef();
  useClickOutside(settingsRef, () => closeDropModals('settingsDropdown'));

  const avatarRef = useRef();

  return (
    <>
      <Header ref={headerRef}>
        <>{view === 'mobile' && <IconComponent name='menuWhite' onClick={handleToggle} cursor='pointer' />}</>
        {(!showSearchBarInMobile || view === 'web') && <Logo>{config.company_label || 'VistrianMMS'}</Logo>}
        <>
          {(view === 'web' || showSearchBarInMobile) && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <GlobalSearchContainer history={history} setShowSearchBarInMobile={setShowSearchBarInMobile} />
              {showSearchBarInMobile && (
                <IconComponent name='CancelWhite' ml={2} size={1.5} onClick={() => setShowSearchBarInMobile(false)} />
              )}
            </div>
          )}
        </>
        <>
          {view === 'web' ? (
            <>
              {/* settings Modal */}
              {settingsModalActiveTab && <SettingsModal />}

              <FlexContainer className='jc_center ai_center web'>
                <IconComponent name='notification' mx={1.5} />
                <IconComponent name='help' mx={1.5} />
                <IconComponent name='settings' mx={1.5} onClick={() => null} />

                {/* change password Modal */}
                {changePwd && <ChangePwdModal />}

                {/* Settings Dropdown Modal */}
                <div style={{ position: 'relative' }} ref={settingsRef}>
                  <IconComponent
                    name='settings'
                    mx={1.5}
                    cursor='pointer'
                    onClick={() => handleHeaderOptClick('settingsDropdown')}
                  />
                  {settingsDropdown && <SettingsDropdown />}
                </div>

                {/* Profile Dropdown Modal */}
                <div style={{ position: 'relative' }} ref={avatarRef}>
                  <AvatarComponent
                    userdata={userData}
                    circle='circle'
                    ml='1.5rem'
                    onClick={() => handleHeaderOptClick('profileSettingsDropdown')}
                  />
                  {profileSettingsDropdown && <ProfileSettingsDropdown />}
                </div>
              </FlexContainer>
            </>
          ) : (
            !showSearchBarInMobile && (
              <FlexContainer className='jc_center ai_center mobile'>
                {settingsModalActiveTab && <SettingsModal />}
                {changePwd && <ChangePwdModal />}

                <IconComponent name='searchW' onClick={() => setShowSearchBarInMobile(true)} />
                <div style={{ position: 'relative' }} ref={avatarRef}>
                  <AvatarComponent
                    userdata={userData}
                    circle='circle'
                    ml='1.5rem'
                    onClick={() => handleHeaderOptClick('profileSettingsDropdown')}
                  />
                  {profileSettingsDropdown && <ProfileSettingsDropdown />}
                </div>
              </FlexContainer>
            )
          )}
        </>
      </Header>
      <Body>
        <SideNav sideNavRef={sideNavRef} isExact={match.isExact} view={view} />
        <Content active={showSideNav}>
          <Home path={match.path} />
        </Content>
      </Body>
    </>
  );
};

export default Dashboard;
