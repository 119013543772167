import { put } from 'redux-saga/effects';
import { Creators as configCreators } from './action';
import { apiEndPoints } from '../services/axios/endPoints';
import { WebService } from '../services/axios/webServices';
import config from '../config.json';

export function* setThemeColorsSaga({ payload }) {
  try {
    yield put(configCreators.setThemeColorsStart());
    // endpoint
    // params for Api request
    const params = { company: payload.company, theme: payload.theme };
    const response = yield WebService.get(apiEndPoints.getTheme, { params });
    if (response.status >= 200 && response.status < 300) {
      // const { data } = response.data;
      // console.log(response?.data?.data, 'data');
      // set color to store
      const { loginprimary, primary, secondary } = response?.data?.data;
      const data = {
        primary: primary || '#4F4ED0',
        secondary: secondary || '#9493CC',
        loginprimary: loginprimary || '#4F4ED0',
      };
      yield put(configCreators.setThemeColorsSuccess(data));
    } else {
      throw response;
    }
  } catch (error) {
    if (error?.response?.status) {
      switch (error.response.status) {
        case 400:
          // eslint-disable-next-line max-len
          yield put(
            configCreators.setThemeColorsFailure({
              error: error?.response?.data?.message
            })
          );
          break;
        case 500:
          yield put(
            configCreators.setThemeColorsFailure({
              error: 'Somthing went wrong try later'
            })
          );
          break;
        default:
          yield put(
            configCreators.setThemeColorsFailure({
              error: 'Unable to update. Try again later'
            })
          );
      }
    } else {
      yield put(
        configCreators.setThemeColorsFailure({
          error: 'No connection try again Later.'
        })
      );
    }
  }
}
