import styled from 'styled-components';
import { device, fontFamily } from '../../../constants';

export const Header = styled.div`
  color: #4a4a4a;
  font: 2.2rem ${fontFamily.circularMedium};
`;

export const HeaderContainer = styled.div`
  width: calc(100% - 4rem);
  margin: 0rem 2rem;
  padding: 2.5rem 2rem 1.2rem 0rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & .dynamicSVGIcon {
    /* transform: rotate(180deg) !important; */

    & * {
      fill: ${({ theme }) => theme.contrast.primary} !important;
    }
  }
  &.fixed-height {
    height: 8rem;
  }
  @media ${device.tablet} {
    overflow-x: ${({ isActivity }) => !isActivity && 'auto'};
  }
`;

export const BodyContainer = styled.div``;

export const TextPage = styled.div`
  color: #202020;
  font: 1.8rem ${fontFamily.circularBook};
  margin-right: 2rem;
`;
