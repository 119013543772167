/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { addDays, addWeeks, addMonths, addYears, addQuarters } from 'date-fns';
import styled from 'styled-components';
import moment from 'moment';
import DateRangePickerComp from './components/DateRangePicker/index';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { createStaticRanges, defineds } from './defaultRanges';
import { thisQuarter, thisWeek } from './utils';
import { calDateFormat } from '../../../config/index';
import { isEmpty } from '../../../shared/utility/isEmpty';

const Wrapper = styled.div`
  & * {
    font-size: 1.2rem;
  }
  /* & .calender {
    background: red;
  } */
  /* & .rdrStartEdge {
    color: yellow !important;
  }
  & .rdrStartEdge + .rdrDayNumber {
    color: black;
  } */
`;
// const weekStartsOnInit = 3;
// const quarterStartsOn = 2;
// MaxAllowedRange obj {unit, value}, weekStartsOn, quarterStartsOn,
const DateRangePicker = ({
  initValue,
  handleSelection,
  MaxAllowedRange,
  isWeekSelection,
  weekStartsOn,
  quarterStartsOn = 0
  // maxRangeAllowed
}) => {
  // console.log('week', weekStartsOn, quarterStartsOn);
  const { unit, value: unitValue } = MaxAllowedRange || {};
  // || {};
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  const formatSelectionObj = (obj) => ({
    value: `${moment(obj.startDate).format(calDateFormat)} - ${moment(obj.endDate).format(calDateFormat)}`,
    rawValue: obj
  });

  useEffect(() => {
    if (!isEmpty(initValue)) {
      // console.log('initValue', initValue);
      setState([{ startDate: new Date(initValue.startDate), endDate: new Date(initValue.endDate), key: 'selection' }]);
    } else {
      handleSelection(formatSelectionObj(state[0]));
    }
  }, []);

  const handleWeekSelection = (item) => {
    // console.log('sending selection', item);
    const { startDate, endDate } = item.selection;
    let setWeek = item.selection;
    // weekstartson calc condition blocks
    if (moment(startDate).day() >= weekStartsOn) {
      setWeek = {
        ...item.selection,
        startDate: new Date(moment(startDate).startOf('week').add(weekStartsOn, 'days')),
        endDate: new Date(moment(startDate).endOf('week').add(weekStartsOn, 'days'))
      };
    } else {
      setWeek = {
        ...item.selection,
        startDate: new Date(
          moment(startDate)
            .startOf('week')
            .subtract(weekStartsOn + 1, 'days')
        ),
        endDate: new Date(
          moment(startDate)
            .endOf('week')
            .subtract(weekStartsOn + 1, 'days')
        )
      };
    }
    setState([setWeek]);
    // console.log('sending selection', item, startDate, formatSelectionObj(setWeek));
    handleSelection(formatSelectionObj(setWeek));
  };

  const handleDateSelection = (item) => {
    setState([item.selection]);
    handleSelection(formatSelectionObj(item.selection));
  };

  // console.log('unit || !unitValue', unit, unitValue);
  const restictedRange = () => {
    const date = new Date(state[0].startDate);
    if (!unit || !unitValue) {
      return { minDate: undefined, maxDate: undefined };
    }
    const obj = {};
    // set the function based on unit
    let fn = addDays;
    if (unit === 'days') {
      fn = addDays;
    } else if (unit === 'weeks') {
      fn = addWeeks;
    } else if (unit === 'months') {
      fn = addMonths;
    } else if (unit === 'years') {
      fn = addYears;
    } else if (unit === 'quarters') {
      fn = addQuarters;
    }
    obj.minDate = fn(date, -unitValue);
    obj.maxDate = fn(date, unitValue);
    // console.log('obj', obj, unit, unitValue);
    return obj;
  };
  // console.log('state', state);

  const staticRangesArr = [
    {
      label: 'Today',
      range: () => ({
        startDate: defineds.startOfToday,
        endDate: defineds.endOfToday
      })
    },
    {
      label: 'Yesterday',
      range: () => ({
        startDate: defineds.startOfYesterday,
        endDate: defineds.endOfYesterday
      })
    },
    {
      label: 'This week',
      range: () => thisWeek(weekStartsOn)
    },
    {
      label: 'Last Week',
      range: () => {
        const { startDate, endDate } = thisWeek(weekStartsOn);
        return {
          startDate: addDays(startDate, -7),
          endDate: addDays(endDate, -7)
        };
      }
    },
    {
      label: 'This Month',
      range: () => ({
        startDate: defineds.startOfMonth,
        endDate: defineds.endOfMonth
      })
    },
    {
      label: 'Last Month',
      range: () => ({
        startDate: defineds.startOfLastMonth,
        endDate: defineds.endOfLastMonth
      })
    },
    {
      label: 'This Quarter',
      range: () => thisQuarter(quarterStartsOn, true)
    },
    {
      label: 'Last Quarter',
      range: () => thisQuarter(quarterStartsOn, false)
    },
    {
      label: 'This Year',
      range: () => ({
        startDate: defineds.startOfYear,
        endDate: defineds.endOfYear
      })
    }
  ];

  const getShortcuts = () => {
    if (unit === 'days') {
      if (unitValue >= 7) {
        return staticRangesArr.slice(0, 4);
      }
      return staticRangesArr.slice(0, 2);
    }
    if (unit === 'months') {
      if (unitValue >= 3) {
        return staticRangesArr.slice(0, 8);
      }
      return staticRangesArr.slice(0, 6);
    }

    return staticRangesArr;
  };

  // console.log('getShortcuts', getShortcuts(), initValue);
  return (
    <Wrapper>
      <DateRangePickerComp
        className='calender'
        onChange={(item) => handleDateSelection(item)}
        customChange={(item) => (isWeekSelection ? handleWeekSelection(item) : handleDateSelection(item))}
        showSelectionPreview={true}
        months={isWeekSelection ? 1 : 2}
        ranges={state}
        minDate={restictedRange().minDate}
        maxDate={restictedRange().maxDate}
        direction='horizontal'
        weekStartsOn={weekStartsOn}
        isWeekSelection={isWeekSelection}
        staticRanges={createStaticRanges(getShortcuts())}
      />
    </Wrapper>
  );
};

export default DateRangePicker;
