/* eslint-disable max-len */
export const formatedFilterData = (filtData) => {
  const formatedData = [];
  const keyTitleMapping = {};
  // for (const key in data) {
  //   formatedData.push(data[key][0]);
  // }
  Object.keys(filtData).forEach((key) => {
    keyTitleMapping[key] = filtData[key][0].groupTitle;
    formatedData.push({ ...filtData[key][0], keyName: key });
  });
  return { formatedData, keyTitleMapping };
};
