/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable max-len */

import { put } from 'redux-saga/effects';
import { apiEndPoints } from '../../../../services/axios/endPoints';
import { WebService } from '../../../../services/axios/webServices';
import { Creators } from './action';

// import  { menuData } from '../../../constants/sampleData';

export function* getSpareListSaga({ payload }) {
  const { page, pagesize, searchParams, payload: filtersPayload } = payload;

  try {
    yield put(Creators.getSpareListStart());
    // console.log('filterJson', payload);
    const fullURL = yield `${apiEndPoints.getSparesList}`;
    const response = yield WebService.post(
      `${fullURL}?page=${page || 1}&pagesize=${pagesize || 10}`,
      {
        // params: {
        //   page: page || 2,
        //   pagesize: pagesize || 10
        // },
        ...filtersPayload,
        SearchFilters: { ...searchParams }
      }
    );
    if (response.status >= 200 && response.status < 300) {
      // console.log('response.data', response.data);
      const { data: SpareListData, meta } = response.data;
      // const SpareListData = data;
      // console.log('meta', meta, SpareListData);

      yield put(
        Creators.getSpareListSuccess({ meta, SpareList: SpareListData || [] })
      );
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getSpareListFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getSpareListFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.getSpareListFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getSpareListFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: 'No connection try again Later.'
      };

      yield put(Creators.getSpareListFailure(payload));
    }
  }
}

export function* getSpareListMenuSaga({ payload }) {
  try {
    yield put(Creators.getSpareListMenuStart());
    // console.log('filterJson', payload);
    const fullURL = yield `${apiEndPoints.getSparesOptions}`;
    const response = yield WebService.get(fullURL);
    if (response.status >= 200 && response.status < 300) {
      yield put(Creators.getSpareListMenuSuccess(response?.data));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getSpareListMenuFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getSpareListMenuFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.getSpareListMenuFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getSpareListMenuFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: 'No connection try again Later.'
      };

      yield put(Creators.getSpareListFailure(payload));
    }
  }
}

export function* getSpareDetailsSaga({ payload }) {
  const { id } = payload;

  try {
    yield put(Creators.setSpareDetailsStart());

    const fullURL = yield `${apiEndPoints.getSpareDetails}`;
    const response = yield WebService.get(`${fullURL}${id}`);

    if (response.status >= 200 && response.status < 300) {
      const { data, meta } = response.data;
      yield put(
        Creators.setSpareDetailsSuccess({
          spareDetailedInfo: { ...meta, data }
        })
      );
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.setSpareDetailsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.setSpareDetailsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.setSpareDetailsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.setSpareDetailsFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: 'No connection try again Later.'
      };

      yield put(Creators.setSpareDetailsFailure(payload));
    }
  }
}

export function* getBulkDocumentListSaga({ payload }) {
  const { page, pageSize, searchParams, payload: filtersPayload } = payload;

  try {
    yield put(Creators.getBulkDocumentListStart());
    // console.log('filterJson', payload);
    const fullURL1 = yield `${apiEndPoints.getBulkSpareProfileDocuments}`;
    const fullUrl =
      yield `${apiEndPoints.getBulkSpareProfileDocuments}/${page}/${pageSize}`;
    const response = yield WebService.post(fullUrl, {
      SearchFilters: { ...searchParams }
    });
    if (response.status >= 200 && response.status < 300) {
      const { data: bulkList, meta } = response.data;
      // const SpareListData = data;
      // console.log('meta', meta, SpareListData);
      yield put(
        Creators.getBulkDocumentListSuccess({
          meta,
          bulkList:
            typeof bulkList === 'string' ? JSON.parse(bulkList) : bulkList || []
        })
      );
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getBulkDocumentListFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getBulkDocumentListFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.getBulkDocumentListFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getBulkDocumentListFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: 'No connection try again Later.'
      };

      yield put(Creators.getBulkDocumentListFailure(payload));
    }
  }
}
