export const sagaCatchBlockHandling = (error) => {
  let payload;
  if (error && error.response && error.response.status) {
    // add toast notification sysytem here
    switch (error.response.status) {
      case 400:
        payload = {
          error: error.response.data.message
        };
        break;
      case 401:
        payload = {
          error: 'unAuthorized'
        };
        break;
      case 500:
        payload = {
          error: 'Somthing went wrong try later'
        };
        break;
      default:
        payload = {
          error: 'Unable to update. Try again later'
        };
    }
  } else {
    // for custom error
    payload = {
      error: error.message
    };
  }
  return payload;
};
