import styled from 'styled-components';
import { fontFamily } from '../../../constants';

export const BreadCrumbText = styled.div`
  margin-left: 2rem;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.contrast.primary};
  font-family: ${fontFamily.circularBold};
  /* margin-top: 1.5rem; */

  &.pointer {
    cursor: pointer;
  }
`;

export const DropdownIcon = styled.div`
  &.dp {
    margin-left: 1rem;
    margin-top: 1.5rem;
  }

  transform: rotate(90deg);
  &.active {
    transform: rotate(270deg);
  }
  & * {
    fill: ${({ theme }) => theme.contrast.primary} !important;
  }
`;
