/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import moment from 'moment';
// import { FlexContainer } from '../../../../globalStyles';
import { MONTHS } from '../utility/constants';
import { MonthContainer, MonthPickerContainer, MonthWrapper } from './MonthPicker.styles';
import {
  isBefore,
  isBetween,
  isSame
  // , isSameOrBefore
} from '../utility/CompareMonth';
// import { rightArrow } from '../../../../assets/icons';
// import DynamicSVGIcon from '../../../Common/DynamicSVGIcon';
import PickerHeader from '../../Common/Header/Header';
// import { isEmpty } from '../../../../shared/utility/isEmpty';

export default function MonthPicker({
  title,
  isAtStart,
  setIsAtStart,
  setSelectedRange,
  selectedRange,
  hoveredDate,
  setHoveredDate,
  year,
  setYear,
  handleSelection,
  formatSelectionObj
}) {
  const setSameStartAndEnd = (selectedDateObj) => {
    const obj = {
      ...selectedRange,
      start: selectedDateObj,
      end: selectedDateObj
    };
    setSelectedRange(obj);
    handleSelection(formatSelectionObj(obj));
  };

  const handleMouseOver = (e) => {
    const monthIndex = MONTHS.findIndex((ele) => ele === e.target.childNodes[0].data);
    setHoveredDate(moment().month(monthIndex).year(year[title]));
  };

  const onSelect = (month) => {
    const selectedDateObj = new Date(moment().date(1).month(month).year(year[title]));

    // console.log(
    //   selectedDateObj,
    //   'start and end',
    //   isSame(selectedRange.start, selectedRange.end),
    //   'isSameorBefore',
    //   isSame(selectedDateObj, selectedRange.start),
    //   isBefore(selectedDateObj, selectedRange.start),
    //   isSameOrBefore(selectedDateObj, selectedRange.start)
    // );

    if (isAtStart !== 1 && isBefore(selectedDateObj, selectedRange.start)) {
      // console.log('1. end turn, date selected is before start');
      setSameStartAndEnd(selectedDateObj);
    } else if (!isSame(selectedRange.start, selectedRange.end)) {
      // console.log('2. start and end are not equal');
      setSameStartAndEnd(selectedDateObj);
    } else if (isAtStart === 1 && isSame(selectedRange.start, selectedRange.end)) {
      // console.log('3. start and end are not equal');
      setSameStartAndEnd(selectedDateObj);
    } else {
      // console.log('4. end turn and selected date isAfter start');
      const obj = {
        ...selectedRange,
        [isAtStart === 1 ? 'start' : 'end']: selectedDateObj
      };
      setSelectedRange(obj);
      // handleSelection(obj);
      handleSelection(formatSelectionObj(obj));
    }

    // move to another position
    setIsAtStart(isAtStart === 0 ? 1 : 0);
  };

  // console.log('year', year);
  return (
    <MonthWrapper>
      {/* <TitleContainer isActive={isAtStart === 1 ? title === 'start' : title === 'end'} style={{ display: 'block' }}>
        {title}
      </TitleContainer>

      <FlexContainer style={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <span>{year[title]}</span>
        <Icons>
          <div
            style={{ transform: 'rotate(90deg)', cursor: 'pointer' }}
            onClick={() => handleYearChange(year[title] - 1)}
          >
            <DynamicSVGIcon width='2.5rem' iconUrl={rightArrow} />
          </div>
          <div
            style={{ transform: 'rotate(270deg)', cursor: 'pointer' }}
            onClick={() => handleYearChange(year[title] + 1)}
          >
            <DynamicSVGIcon width='2.5rem' iconUrl={rightArrow} />
          </div>
        </Icons>
      </FlexContainer> */}
      <PickerHeader title={title} year={year} setYear={setYear} isAtStart={isAtStart} />
      <MonthPickerContainer>
        {MONTHS.map((month, index) => {
          const currDate = moment().month(index).year(year[title]);
          //   console.log('c', currDate._d, selectedRange, isBetween(currDate, [selectedRange.start, selectedRange.end]));
          return (
            <MonthContainer
              className={`${isBetween(currDate, [selectedRange.start, selectedRange.end]) ? 'hightlight' : ''}
              ${isSame(currDate, selectedRange.start) ? 'startDate' : ''}
              ${isSame(currDate, selectedRange.end) && !isSame(currDate, selectedRange.start) ? 'endDate' : ''}
            
              ${isSame(hoveredDate, currDate) ? 'hovered' : ''}
              ${
                hoveredDate &&
                isAtStart === 0 &&
                isSame(selectedRange.start, selectedRange.end) &&
                isBetween(currDate, [selectedRange.start, hoveredDate])
                  ? 'hovered'
                  : ''
              }              
              `}
              onClick={() => onSelect(index)}
              onMouseOver={handleMouseOver}
              month={index}
            >
              {month}
            </MonthContainer>
          );
        })}
      </MonthPickerContainer>
    </MonthWrapper>
  );
}
