/* eslint-disable max-len */
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  FilterResultContentText,
  FilterResultContentTextContainer,
  FilterMultiSelectIcon
} from './FilterContentContainer.style';
import { Creators as FilterCreators } from '../../../views/Filters/store';
import DataFilterActive from '../../SvgIcons/Filter/DataFilterActive';
import { getPathNo, isChildReferencePresent, isParentSelected } from '../isItemSelected';
import RectangularAdd from '../../Common/Icons/RectangularAdd';
import { FlexContainer, Image } from '../../../globalStyles';
// import DynamicSVGIcon from '../../Common/DynamicSVGIcon';
import { rightArrow } from '../../../assets/icons';
import InputComponent from '../../Common/DynamicInput/DynamicInput';

function FilterContentContainer({
  selectedTabKey,
  innerItem,
  selectionType,
  handleClick,
  index,
  multiSelect,
  className,
  isLeaf
}) {
  const {
    [selectedTabKey]: resultVal,
    cumulativeTags,
    inputs,
    [`cumulative${selectedTabKey}`]: cummResultVal
  } = useSelector((state) => state.filterData);

  const dispatch = useDispatch();

  const handleInnerClick = () => {
    handleClick(innerItem, index);
    if (className === 'typeDate') {
      let day = moment();

      if (innerItem.name.toLowerCase() === 'yesterday') {
        day = moment().subtract(1, 'days');
      }
      dispatch(
        FilterCreators.universalFilterSetter({
          key: 'selectedDate',
          value: day
        })
      );
    }
  };
  const handleInput = (value, key) => {
    dispatch(FilterCreators.universalFilterSetter({ key: 'inputs', value: { ...inputs, [key]: value } }));
  };

  return (
    <FilterResultContentTextContainer
      className={`${className} text-overflow`}
      active={resultVal && resultVal[innerItem.id]}
      height={innerItem.type === 'input' ? '6rem' : undefined}
      cummSelect={
        cummResultVal &&
        Object.keys(cummResultVal).some((e) => Object.keys(cummResultVal[e]).includes(`${innerItem.id}`))
      }
      // onClick={handleInnerClick}
      onClick={innerItem.type !== 'input' ? handleInnerClick : undefined}
    >
      {innerItem.type === 'input' ? (
        <FlexContainer className='ai_center' style={{ width: '36rem' }}>
          <InputComponent
            style={{ height: '4rem' }}
            placeholder={innerItem.placeholder}
            setValue={handleInput}
            value={inputs && inputs[selectedTabKey]}
            inputKey={selectedTabKey}
          />
        </FlexContainer>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {selectionType !== 'singleselect' && <RectangularAdd active={resultVal && resultVal[innerItem.id]} />}
          <FilterResultContentText className='cummlativeSelect' active={resultVal && resultVal[innerItem.id]}>
            {innerItem.name}
          </FilterResultContentText>
        </div>
      )}

      {/* active tags except leaf */}
      {innerItem.values && typeof innerItem.values === 'object' && resultVal && resultVal[innerItem.id] && !isLeaf && (
        <Image src={rightArrow} width='3' />
      )}
      {/* leaf selections and multi select selections  */}
      {(multiSelect || isLeaf) && resultVal && (
        <FilterMultiSelectIcon
          active={
            (cummResultVal &&
              Object.keys(cummResultVal).some((e) => Object.keys(cummResultVal[e]).includes(`${innerItem.id}`))) ||
            (resultVal && resultVal[innerItem.id])
          }
        >
          {((cummResultVal &&
            Object.keys(cummResultVal).some((e) => Object.keys(cummResultVal[e]).includes(`${innerItem.id}`))) ||
            (resultVal && resultVal[innerItem.id])) && <DataFilterActive />}
        </FilterMultiSelectIcon>
      )}
      {multiSelect && resultVal && resultVal[innerItem.id] && (
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            height: 1,
            width: '100%',
            backgroundColor: 'rgba(255,255,255,0.4)'
          }}
        />
      )}
    </FilterResultContentTextContainer>
  );
}

export default memo(FilterContentContainer);
