import styled from 'styled-components';
import { fontFamily, fontSize, device } from '../../../constants';
import { FlexContainer } from '../../../globalStyles';
import { SearchDropDown } from '../../GlobalSearch/GlobalSearchContainer.style';
import { Radio } from '../RadioButton/RadioButton';

export const ModalContainer = styled.div`
  min-height: 20px;
  position: absolute;
  width: ${(props) => props.width};
  display: flex;
  background-color: ${({ theme }) => theme.customTheme.primary};
  z-index: 10001;
  border-radius: 6px;
  /* animation: heightAnimation 400ms 1; */
  overflow: auto;
  /* padding: 15px 20px 15px 20px; */
  /* width: 21.3rem; */
  min-width: max-content;
  max-height: 50rem;
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
  &.outside {
    background-color: ${({ theme }) => theme.core.pureSecondary};
    border: 1px solid ${({ theme }) => theme.core.secondary};

    & p {
      color: ${({ theme }) => theme.contrast.darkPrimary} !important;
    }
  }

  @keyframes heightAnimation {
    from {
      opacity: 0;
      transform: translateY(10px);
      overflow: hidden;
    }
    to {
      opacity: 1;
      transform: translateY(0px);
      overflow: hidden;
    }
  }

  /* @media ${device.tablet} {
    display: none;
  } */
`;

export const NestedModalContainer = styled.div`
  /* background: red; */
  width: 100%;
  padding: 15px 20px 15px 20px;
`;

export const ModalContentDiv = styled.div`
  min-height: 25px;
  padding: 5px 0px;
  /* border: 1px solid red; */
  &.hasRadio {
    padding: 5px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border-bottom: 1px solid black; */
  }
  &.radio {
    ${Radio} {
      margin-left: 10px;
    }
  }
  &.icon {
    & p {
      margin-left: 10px;
    }
  }
`;

export const ModalContentText = styled.p`
  font-size: 2rem;
  /* color: white; */
  color: ${({ theme }) => theme.contrast.tertiary};
  /* margin-top: 10px; */
  cursor: pointer;
  text-transform: capitalize;

  &.outside {
    color: ${({ theme }) => theme.contrast.darkPrimary};
  }
  &.disable {
    opacity: 0.2;
  }
`;
export const InputAndButtonContainer = styled.div`
  display: flex;
`;
export const DropdownLabelContainer = styled(FlexContainer)`
  width: 10rem;
  justify-content: space-between;
  position: relative;
`;

export const DropBtn = styled(FlexContainer)`
  width: 45px;
  height: 45px;
  background: #fff;
  border-radius: 10px;
  position: absolute;
  right: 0;
  z-index: 2;
  cursor: pointer;

  & > svg {
    height: 15px;
    width: 15px;
    transform: rotate(90deg);
  }
`;

export const DropdownCon = styled(SearchDropDown)`
  height: 165px;
  z-index: 0;
  @keyframes heightAnimate {
    0% {
      height: 0vh;
    }
    100% {
      height: 165px;
    }
  }
`;

export const Image = styled.div`
  /* width: ${({ width }) => `${width}rem`};
  height: ${({ width }) => `${width}rem`}; */
  padding: 2rem;
  & * {
    fill: ${({ theme }) => theme.contrast.primary} !important;
  }
`;
