import React from 'react';
import { CancelBlack } from '../../../../assets/icons';
import { CancelIcon, ItemText, ListContainer, SelectedList, Item } from '../../GenericForm/GenericForm.style';
import DynamicSVGIcon from '../../DynamicSVGIcon';
import KeyInputLayoutView from '../../KeyValueLayout/KeyInputLayout.view';
import SearchBarWithDropdown from '../../SearchBarWithDropdown';

const EquipmentSearchView = (props) => {
  const {
    item,
    value,
    itemSelectionHandler,
    removeListItem,
    formInput, // for handling dependendency
    tobeRefetched,
    resetFieldsTobeRefetched,
    fieldClassName
  } = props;
  const { key, label, placeholder, dataSource, isRequired } = item;
  const { responseKeys } = dataSource || {};
  const { labelKey, valueKey } = responseKeys || {};
  const dpItemLabel = labelKey || 'label';
  // console.log('equipment value', value, valueKey, responseKeys);
  return (
    <div>
      <KeyInputLayoutView
        label={label}
        tooltip={item.tooltip}
        className='add_equipment'
        width='50rem'
        isRequired={isRequired}
        fieldClassName={fieldClassName}
      >
        <SearchBarWithDropdown
          itemLabel={labelKey || 'label'}
          handlerFunc={itemSelectionHandler}
          dataSource={dataSource}
          placeholder={placeholder}
          width='58rem'
          itemKey={item.key}
          valueKey={valueKey}
          formInput={formInput}
          tobeRefetched={tobeRefetched}
          resetFieldsTobeRefetched={resetFieldsTobeRefetched}
        />
      </KeyInputLayoutView>
      <ListContainer>
        {(value || [])?.map((item) => (
          <SelectedList>
            <Item>{item?.[dpItemLabel]}</Item>
            <CancelIcon
              className='cancel_item'
              onClick={() => {
                removeListItem(item, key);
              }}
            >
              <DynamicSVGIcon iconUrl={CancelBlack} width='1.3rem' />
            </CancelIcon>
          </SelectedList>
        ))}
      </ListContainer>
    </div>
  );
};

export default EquipmentSearchView;
