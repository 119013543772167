/* eslint-disable max-len */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SUCCESS } from '../../../constants';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { WebService } from '../../../services/axios/webServices';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';
import Toast from '../../Common/Toast';
import AddActivityForm from './AddActivityForm';

const AddActivity = (props) => {
  const { detail } = props;
  const { activityColumn } = useSelector((state) => state.activityData);
  const history = useHistory();
  const [error, setError] = useState('');
  const createActivity = async (payload, setSubmitLoader) => {
    // eslint-disable-next-line prefer-const
    setSubmitLoader(true);
    const input = {
      data: [payload]
    };
    try {
      const fullUrl = `${apiEndPoints.createActivity}`;
      // eslint-disable-next-line prefer-const, object-shorthand
      let res = await WebService.post(fullUrl, JSON.stringify(input));
      if (res.data?.message === SUCCESS) {
        history.push({
          pathname: '/home/activity/details',
          state: { details: { activityId: res?.data?.id } }
        });
      } else {
        setError(res.data?.message);
      }
    } catch (err) {
      console.log('error facing in AddActivityForm:', err);
      const payload = sagaCatchBlockHandling(err);
      setError(payload?.error);
    } finally {
      setSubmitLoader(false);
    }
  };

  return (
    <>
      <AddActivityForm handleSave={createActivity} />
      {error && <Toast message={error} header='Error' style={{ right: 30 }} fn={() => setError('')} />}
    </>
  );
};

export default AddActivity;
