/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { INPUT_TYPES } from '../../../constants';
import { FlexContainer } from '../../../globalStyles';
import { Input } from '../../Common/Input/Input';
import { InputCounter } from '../../Common/Inputupdown/InputAddMinus';
import KeyInputLayoutView from '../../Common/KeyValueLayout/KeyInputLayout.view';
import { ContentText, Span } from '../../Common/KeyValueLayout/KeyValueLayout.style';
import Radiobutton from '../../Common/RadioButton/RadioButton';

const ScheduleTypeWithinDayFrequency = (props) => {
  const { input, setInput, handleInputChange, handleInputCounter } = props;
  const { frequencyWithDayOption } = input;
  const INIT_DATA = {
    occurs: '',
    occursEvery: 1,
    startTime: '',
    endTime: ''
  };

  const handleOptionChange = (optionName) => {
    setInput((inp) => ({
      ...inp,
      ...INIT_DATA,
      frequencyWithDayOption: optionName
    }));
  };
  const showOccursOnceAt = input?.masterScheduleTypeName?.masterScheduleTypeName === 'Recurring';
  return (
    <>
      <ContentText className='heading required' style={{ marginBottom: '3rem', fontSize: '2.2rem' }}>
        Frequency within the day
      </ContentText>
      {/* Occurs once At */}
      {/* option 1 */}
      <KeyInputLayoutView
        className={frequencyWithDayOption === 'once' ? '' : 'disable-children'}
        isLabelActive={
          <Radiobutton
            onClick={() => {
              handleOptionChange('once');
            }}
            active={frequencyWithDayOption === 'once'}
          />
        }
        label='Occurs once At'
      >
        <Input
          width='15.7rem'
          placeholder='HH:MM:SS'
          value={input.occurs}
          name='occurs'
          type='time'
          step='1'
          pl='1rem'
          pr='1rem'
          onChange={(e) => {
            handleInputChange(INPUT_TYPES.TEXT, e);
          }}
        />
      </KeyInputLayoutView>
      {/* Occurs Every */}
      {/* option 2 */}
      {showOccursOnceAt && (
        <KeyInputLayoutView
          label='Occurs Every'
          className={frequencyWithDayOption === 'repeats' ? '' : 'disable-children'}
          isLabelActive={
            <Radiobutton
              onClick={() => {
                handleOptionChange('repeats');
              }}
              active={frequencyWithDayOption === 'repeats'}
            />
          }
        >
          <FlexContainer style={{ alignItems: 'center' }}>
            <Span>HH</Span>
            <InputCounter
              inputFor='hours'
              label='occursEvery'
              value={input.occursEvery}
              valueSetter={handleInputCounter}
            />
            <Span style={{ fontSize: '3rem', marginLeft: '5rem' }}>|</Span>
            {/* starting at */}
            <Span>Starting At</Span>
            <Span>:</Span>
            <Input
              width='15.7rem'
              placeholder='HH:MM:SS'
              value={input.startTime}
              name='startTime'
              type='time'
              step='1'
              pl='1rem'
              pr='1rem'
              onChange={(e) => {
                handleInputChange(INPUT_TYPES.TEXT, e);
              }}
            />
            {/* ending at */}
            <Span style={{ marginLeft: '3rem' }}>Ending At</Span>
            <Span>:</Span>
            <Input
              width='15.7rem'
              placeholder='HH:MM:SS'
              value={input.endTime}
              name='endTime'
              type='time'
              step='1'
              pl='1rem'
              pr='1rem'
              onChange={(e) => {
                handleInputChange(INPUT_TYPES.TEXT, e);
              }}
            />
          </FlexContainer>
        </KeyInputLayoutView>
      )}
    </>
  );
};

export default ScheduleTypeWithinDayFrequency;
