/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { FlexContainer } from '../../../globalStyles';
import { Input } from '../Input/Input';
import { ReactComponent as Arrow } from '../../../assets/icons/layout/dropdownarrow.svg';
import { SearchDropDown } from '../../GlobalSearch/GlobalSearchContainer.style';
import { SearchResultText, SearchTextContainer } from '../../../views/Layout/Layout.styles';

export const Button = styled(FlexContainer)`
  width: 37px;
  height: 37px;
  background: ${({ theme }) => theme.contrast.tertiary};
  border-radius: 10px;
  position: absolute;
  right: 0;
  z-index: 2;
  cursor: pointer;

  & > svg {
    width: 10px;
    transform: rotate(90deg);
  }
`;

export const Container = styled(SearchDropDown)`
  height: 165px;
  z-index: 0;
  @keyframes heightAnimate {
    0% {
      height: 0vh;
    }
    100% {
      height: 165px;
    }
  }
`;

const DropDown = ({ value, data = [], onClick, placeholder, width, variant, margin }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const ref = useRef();
  const handleClick = (value) => {
    onClick(value);
    setShowDropdown(false);
  };

  return (
    <FlexContainer className='ai_center' style={{ position: 'relative', width: width || '100%', margin }}>
      <Input
        placeholder={placeholder}
        ref={ref}
        style={{ zIndex: '1', height: '37px' }}
        disabled={true}
        value={value}
        className={variant}
        width='100%'
      />
      <Button className='ai_center jc_center' onClick={() => setShowDropdown(false)}>
        <Arrow />
      </Button>
      {showDropdown && (
        <Container>
          {data.map((item, i) => (
            <SearchTextContainer key={i} onClick={() => handleClick(item)}>
              <SearchResultText style={{ fontSize: '16px' }}>{item.label}</SearchResultText>
            </SearchTextContainer>
          ))}
        </Container>
      )}
    </FlexContainer>
  );
};

export default DropDown;
