/* eslint-disable camelcase */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Form } from 'react-formio';
import { WO_CREATION_FROM, WO_TYPES } from '../../../../constants';
import { Div } from '../../../../globalStyles';
import { Input } from '../../../Common/Input/Input';
import { CellTemplate, RowTemplate, TableContainerTemplate } from '../../../Common/Table/Table/Table.style';
import { ContentText, DetailsContainer, DetailSection } from '../CreateWorkorder.style';
import { Creators as UpserWorkOrderCreators } from '../store';
import './inputWorkOrderDetails.css';
import Loader from '../../../Loader/Loader';
import { WebService } from '../../../../services/axios/webServices';
import { getWorkOrderDetails } from '../../WorkOrderDetails/getDetails';
import { WorkOrderCreators } from '../../../../views/WorkOrders/store';
import useGetWorkOrderTypes from '../hooks/useGetWorkOrderTypes';
// import { generateSelectedFilter } from '../../../ResultView/Iframe/generateSelectedFilter';
// import { Creators as FilterCreators } from '../../../../views/Filters/store';

const LabelItem = ({ label, value, nonEditable }) => {
  const wordOrderDetails = useSelector((state) => state.upsertWorkOrder);
  const dispatch = useDispatch();

  const onChange = (e, value) => {
    dispatch(UpserWorkOrderCreators.setWorkOrderDetail({ [value]: e.target.value }));
  };
  return (
    <DetailsContainer className='detail ai' width='100%'>
      <ContentText className='heading'>{label}</ContentText>
      <span>:</span>
      <ContentText className='label ml'>
        {nonEditable ? (
          <Input width='100%' className='read-only' value={wordOrderDetails[value]} placeholder={label} readonly />
        ) : (
          <Input
            width='100%'
            onChange={(e) => onChange(e, value)}
            value={wordOrderDetails[value]}
            placeholder={label}
          />
        )}
      </ContentText>
    </DetailsContainer>
  );
};

const LabelColumnItems = ({ properties }) => (
  // console.log('propertes', properties);
  <DetailSection>
    {[...properties].map((property) => {
      const { label, value, nonEditable } = property;
      return <LabelItem label={label} value={value} nonEditable={nonEditable} />;
    })}
  </DetailSection>
);
// const Spares = ({ handleValue, colProps, mainPartsCountDetails }) => (
//   <TableContainerTemplate>
//     <RowTemplate className='header '>
//       {colProps.map((col, index) => (
//         <CellTemplate key={index} width={col.width} className='colHeading'>
//           {col.label}
//         </CellTemplate>
//       ))}
//     </RowTemplate>
//     {mainPartsCountDetails?.map((entry, entryIndex) => (
//       <RowTemplate>
//         {colProps.map((col, index) => (
//           <>
//             {col.value === 'requestQuant' ? (
//               <CellTemplate>
//                 <Input
//                   width='80%'
//                   type='number'
//                   onChange={(e) => handleValue(e, entryIndex)}
//                   value={entry[col.value]}
//                   placeholder=''
//                 />
//               </CellTemplate>
//             ) : (
//               <CellTemplate key={index} width={col.width}>
//                 {entry[col.value]}
//               </CellTemplate>
//             )}
//           </>
//         ))}
//       </RowTemplate>
//     ))}
//   </TableContainerTemplate>
// );

const Step3 = ({ setDisableNext, commonForm, setShowModal = () => {} }) => {
  const { cumulativeTags, menuIdofFilter, selectedParent } = useSelector((state) => state.filterData);
  const machines = [];
  const wordOrderDetails = useSelector((state) => state.upsertWorkOrder);
  const { userData, workOrderTypes } = useSelector((state) => state.workOrders);
  // console.log('userData', userData);
  const dispatch = useDispatch();
  const [mainParts, setMainParts] = useState([]);

  // const createWorkOrderDetails = workOrderDetailsObj[wordOrderDetails.type.Type];
  // const resetFeilds = () => {
  //   const obj = {};
  //   createWorkOrderDetails.forEach((feild) => {
  //     obj[feild.value] = '';
  //   });
  //   dispatch(UpserWorkOrderCreators.setMultipleWorkOrderDetails(obj));
  //   console.log('obj', obj);
  // };

  useEffect(() => {
    if (!commonForm) {
      const groups = Object.keys(cumulativeTags);
      groups.forEach((group) => {
        Object.keys(cumulativeTags[group]).forEach((pathNo) => {
          const length = Object.keys(cumulativeTags[group][pathNo]).length;
          // get equipment type and equipment(last 2 levels)
          const details = Object.keys(cumulativeTags[group][pathNo]).slice(length - 2, length);
          const machineDetails = {};
          details.forEach((e) => {
            if (Array.isArray(cumulativeTags[group][pathNo][e])) {
              const leafMachines = cumulativeTags[group][pathNo][e].map((item) => {
                const { id, name } = item;
                return { id, name };
              });
              machineDetails.equipmentName = leafMachines;
            } else {
              const { id, name } = cumulativeTags[group][pathNo][e];
              machineDetails.equipmentType = { id, name };
            }
          });
          machines.push({ ...machineDetails });
        });
      });
      // resetFeilds();
      // if (wordOrderDetails.type.value === 'Preventive Maintenance') {
      //   dispatch(
      //     UpserWorkOrderCreators.setMultipleWorkOrderDetails({
      //       workOrderType: wordOrderDetails.type.label,
      //       equipmentType: machines[0]?.equipmentType?.name,
      //       equipmentName: machines[0]?.equipmentName[0]?.name
      //     })
      //   );
      // } else if (wordOrderDetails.type.value === 'Spare Request') {
      //   setMainParts(machines[0].equipmentName.map((e) => e.name));
      // }
    }
  }, []);

  useEffect(() => {
    const tempMainPartCountDetails = mainParts.map((mainPart) => ({
      mainPartNumber: mainPart,
      availableQuant: 20,
      requestQuant: ''
    }));
    dispatch(UpserWorkOrderCreators.setWorkOrderDetail({ spares: tempMainPartCountDetails }));
  }, [mainParts]);

  // useEffect(() => {
  //   const isFormFilled = createWorkOrderDetails.every((feild) => {
  //     let isObjFilled = true;
  //     if (typeof wordOrderDetails[feild.value] === 'object') {
  //       isObjFilled = wordOrderDetails[feild.value].every((ele) => Object.keys(ele).every((key) => ele[key] !== ''));
  //     }
  //     return wordOrderDetails[feild.value] !== '' && wordOrderDetails[feild.value] !== undefined && isObjFilled;
  //   });
  //   setDisableNext(!isFormFilled);
  // }, [wordOrderDetails]);

  // const handleValue = (e, entryIndex) => {
  //   const { spares: mainPartsCountDetails } = wordOrderDetails;
  //   const temp = [
  //     ...mainPartsCountDetails.slice(0, entryIndex),
  //     { ...mainPartsCountDetails[entryIndex], requestQuant: e.target.value },
  //     ...mainPartsCountDetails.slice(entryIndex + 1, mainPartsCountDetails.length)
  //   ];
  //   dispatch(UpserWorkOrderCreators.setWorkOrderDetail({ spares: temp }));
  // };
  const [submissionMessage, setSubmissionMessage] = useState('');
  const { type, parentTransactionId } = useSelector((state) => state.upsertWorkOrder);
  const {
    formLoading,
    formdefinition,
    formbuttons,
    status,
    type: workOrderType,
    workOrderId
  } = useSelector((state) => state.workOrders);
  const history = useHistory();
  const location = useLocation();
  const { createdFrom, WFTid } = location.state || {};
  // fetch wo types
  useGetWorkOrderTypes();

  const onSubmitDone = useCallback(
    (data) => {
      try {
        setSubmissionMessage('submitting...');
        const fullUrl = formbuttons[0].PostUri;
        let actUrl;
        const isCreation = !fullUrl.includes('{currentState}');
        if (!isCreation) {
          actUrl = fullUrl.replace('{{currentState}}', status);
        } else if (parentTransactionId) {
          actUrl = fullUrl.replace('{{parentTransactionId}}', parentTransactionId);
        } else {
          actUrl = fullUrl.replace('parentTransactionId={{parentTransactionId}}&', '');
        }
        // console.log('act', actUrl, parentTransactionId);
        // console.log('form data', data, formbuttons[0].PostUri, actUrl);
        // const queryString = actUrl.split('?')[1];
        // const params = {};
        // const paramsArr = queryString.split('&');
        // paramsArr.forEach((e) => {
        //   const pair = e.split('=');
        //   params[pair[0]] = pair[1];
        // });
        // console.log('params', params);
        WebService.post(actUrl, {
          transaction_id: workOrderId,
          ...data
        })
          .then((res) => {
            // console.log('success response', res);
            if (res.data.saved) {
              setSubmissionMessage('submission successful');
              if (isCreation) {
                const { transaction_id, state } = res.data;
                dispatch(
                  WorkOrderCreators.getWorkOrderDetails({
                    status: state,
                    type: type.Type,
                    workOrderId: transaction_id
                  })
                );
                let woType = type.Type;
                if (createdFrom === WO_CREATION_FROM.PLAN_DETAILS) {
                  woType = workOrderTypes?.find((wo) => wo.workflowTemplateId === WFTid)?.Type;
                }
                history.push(`/home/work_orders/${woType}/${transaction_id}`);
              } else {
                setShowModal(false);
                dispatch(WorkOrderCreators.getWorkOrderDetails({ status, type: workOrderType, workOrderId }));
              }
              Form.emit('submitDone', { transaction_id: workOrderId, ...data });
              dispatch(UpserWorkOrderCreators.resetAssetShortlist());
            } else {
              setSubmissionMessage(`submission failed, ${res.data?.error}`);
              Form.emit('submitDone', { transaction_id: workOrderId, ...data });
            }
          })
          .catch((rej) => {
            setSubmissionMessage(`submission failed ${rej}`);
            console.log('error', rej);
          });
      } catch (e) {
        console.log('error====>', e);
      }
    },
    [formbuttons]
  );
  useEffect(() => {
    // const formatedTags = generateSelectedFilter(cumulativeTags);

    // // console.log('location', location);
    // const payload = {
    //   menuid: menuIdofFilter,
    //   tab: selectedParent,
    //   data: formatedTags
    // };
    // console.log('iframe payload', payload);

    // only for create work order
    if (!commonForm) {
      dispatch(
        WorkOrderCreators.getFormStructure({
          // use from WFid from params for creation from plan details
          WFTid: createdFrom === WO_CREATION_FROM.PLAN_DETAILS ? WFTid : type.workflowTemplateId
        })
      );
    }
  }, []);

  return (
    <div className='createForm bootstrap-inside'>
      {formLoading ? (
        <Loader />
      ) : (
        <Form
          // {...props}
          src={formdefinition}
          submission={{ data: { ...userData } }}
          onSubmit={onSubmitDone}
          // options={optionsData}
          // formReady={onFormReady}
        />
      )}
      <span>{submissionMessage}</span>
    </div>
  );
};

export default memo(Step3);
