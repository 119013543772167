/* eslint-disable arrow-body-style */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../component/Loader/Loader';
import { Div, Image } from '../../../globalStyles';
import FilterView from '../../Filters/Filter.view';
import { spares } from '../../../constants/sampleData';
import partImage from './pi.jpg';
import { SpareDetailsContainer, Table, Td, Tr } from './Spare.styles';
import { Th } from '../../WorkOrders/WorkOrders.styles';
import SpareDetails from '../../../component/Stores/Spares/SpareDetails/SpareDetails.view';
import { Creators as SpareDetailsCreators } from './store';
import Toast from '../../../component/Common/Toast';
import { fontSize } from '../../../constants';
import TabsTable from '../../../component/Common/Tabs_Table';
import useGetSpares from './useGetSpares';
import { useActions } from '../../../store/hooks/useActions';
// import { sampleTableData } from '../../../component/Common/Tabs_Table/sampleTableData';

const Spares = () => {
  const dispatch = useDispatch();
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [currentId, setCurrentId] = useState('');
  // const {showLoader} = useSelector(state=>state.)
  const {
    sparesListData,
    sparesListMeta,
    isLoading,
    error,
    sparesSearchQueries
  } = useSelector((state) => state.spareDetails);
  const { filtersPayload } = useSelector((state) => state.filterData);

  // const isMounted = useRef();
  // useEffect(() => {
  //   if (isMounted.current && filtersPayload.data) {
  //     console.log('filtersPayload', filtersPayload);
  //     dispatch(SpareDetailsCreators.getSpareList({ payload: filtersPayload }));
  //   } else {
  //     isMounted.current = true;
  //   }
  // }, [filtersPayload]);

  const handleShowDetails = (spare) => {
    setCurrentId(spare.mainPartNumber);
    setShowDetailsModal(true);
  };

  const clearToastMesssage = () => {
    dispatch(SpareDetailsCreators.getSpareListFailure({ error: '' }));
  };

  const { handlePageChange, pageSize } = useGetSpares();

  const setAssetSearchQueries = (searchQueries) => {
    dispatch(SpareDetailsCreators.setSparesSearchQueries(searchQueries));
  };

  const resetSearchQueries = () => {
    dispatch(SpareDetailsCreators.resetSparesSearchQueries());
  };
  return (
    <div style={{ flex: 1, overflowX: window?.innerWidth < 768 && 'auto' }}>
      <TabsTable
        handlePageChange={handlePageChange}
        handleTabSelect={() => {}}
        pageSize={pageSize}
        metaDetails={sparesListMeta}
        dataDetails={sparesListData}
        // metaDetails={sampleTableData.meta}
        // dataDetails={sampleTableData.data}
        assetListLoading={isLoading}
        onItemSelect={handleShowDetails}
        showPagiation={false}
        showShortlistSelection={false}
        saveSearchQueries={setAssetSearchQueries}
        savedSearchQueries={sparesSearchQueries}
        resetSearchQueries={resetSearchQueries}
        error={error}
      />
      {showDetailsModal && (
        <SpareDetails onCancel={setShowDetailsModal} id={currentId} />
      )}
      {error && (
        <Toast
          message={error}
          fn={clearToastMesssage}
          header='Error'
          style={{ right: '20px' }}
        />
      )}
    </div>
  );
};

export default Spares;

// old code
// <Div
//   px='2'
//   mt='2'
//   style={{
//     width: '100%',
//     position: 'relative'
//   }}
// >
//   {/* {showLoader && <Loader />} */}
//   {isLoading ? (
//     <Loader />
//   ) : (
//     <>
//       {sparesListMeta?.columns && (
//         <Table>
//           <thead>
//             <tr>
//               {sparesListMeta?.columns?.map((col) => {
//                 // if (col.key !== 'imageUrl')
//                 return <Th>{col.title}</Th>;
//                 // return <></>;
//               })}
//               <Th />
//             </tr>
//           </thead>
//           <tbody>
//             {sparesListData?.length > 0 && (
//               <>
//                 {sparesListData?.map((spare, index) => (
//                   <Tr onClick={() => handleShowDetails(spare)} key={index}>
//                     {sparesListMeta?.columns?.map((col, i) => (
//                       <React.Fragment key={i}>
//                         <Td width={col.width} align={col.align}>
//                           {(() => {
//                             switch (col.key) {
//                               case 'mainPartNumber':
//                                 return (
//                                   <SpareDetailsContainer>
//                                     {/* <img width='60px' src={spare?.imageUrl} alt='spare' /> */}
//                                     <span>{spare[col.key]}</span>
//                                   </SpareDetailsContainer>
//                                 );
//                               default: {
//                                 if (typeof spare[col.key] === 'string' && spare[col.key]?.startsWith('http')) {
//                                   return <img width='60px' src={spare?.imageUrl} alt='spare' />;
//                                 }
//                                 return spare[col.key];
//                               }
//                             }
//                           })()}
//                         </Td>
//                       </React.Fragment>
//                     ))}
//                   </Tr>
//                 ))}
//               </>
//             )}
//           </tbody>
//         </Table>
//       )}
//       {sparesListMeta?.columns && !sparesListData?.length && (
//         <div style={{ fontSize: fontSize.text, textAlign: 'center' }}>No Spares</div>
//       )}
//     </>
//   )}
// </Div>
