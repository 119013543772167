/* eslint-disable react/jsx-curly-newline */
/* eslint-disable prefer-template */
/* eslint-disable no-lonely-if */
/* eslint-disable no-constant-condition */
/* eslint-disable function-paren-newline */
/* eslint-disable max-len */
import React, { useCallback, memo, lazy, Suspense, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FilterResultContent,
  FilterResultHeaderText,
  FilterResultHeader
} from '../FilterResultCard/FilterResultCard.style';
import FilterContentContainer from '../FilterContentContainer/FilterContentContainer';
import { Creators as FilterCreators } from '../../../views/Filters/store';
import Loader from '../../Loader/Loader';
import { getAllPathNos, getPathNo, getPathNoForMultiSelect, isChildReferencePresent } from '../isItemSelected';
import Radiobutton from '../../Common/RadioButton/RadioButton';
import MonthRangePicker from '../../RangeSelectors/MonthRangePicker/MonthRangePicker';
import QuarterRangePicker from '../../RangeSelectors/QuarterRangePicker/QuarterRangePicker';
import DateRangePicker from '../../RangeSelectors/DateRangePicker/DateRangePicker';
import { calanderInitContants } from '../../RangeSelectors/DateRangePicker/constants';

const _ = require('lodash');

const CalendarComponent = lazy(() => import('../../Calendar/index'));

function FilterChildrenContent({
  keyVal,
  groupTitle,
  index,
  attribute,
  selectionType,
  handleChildrenClick,
  handleMultiSelectClick,
  isLeaf
}) {
  const {
    tags,
    [groupTitle]: selectedObj,
    cumulativeTags,
    [`cumulative${groupTitle}`]: cummSelectedObj,
    leafLevels,
    meta
  } = useSelector((state) => state.filterData);
  const { weekStartsOn, q1StartsOn, maxRangeAllowed } = meta[groupTitle] || {};
  const dispatch = useDispatch();

  const addTabToObj = useCallback((payload) => dispatch(FilterCreators.universalFilterSetter(payload)), [dispatch]);
  const handleLeaf = () => {
    // console.log('leafLevels[item.groupTitle]', leafLevels[groupTitle], selectedObj[keyVal]?.attribute);

    dispatch(
      FilterCreators.universalFilterSetter({
        key: 'leafLevels',
        value: {
          ...leafLevels,
          [groupTitle]: leafLevels[groupTitle] === selectedObj[keyVal]?.attribute ? '' : selectedObj[keyVal]?.attribute
        }
      })
    );
  };

  // handles only new pickers(daily, weekly, monthly, quarterly)
  const handleSelection = (e) => {
    const foundPathNo = Object.keys(cummSelectedObj).find(
      (pathNo) =>
        cummSelectedObj[pathNo][cumulativeTags[groupTitle][pathNo][index].id].type === selectedObj[keyVal].type
    );
    // console.log('foundPathNo', foundPathNo);
    // console.log(
    //   'foundPathNo',
    //   Object.values(cummSelectedObj[foundPathNo])[0],
    //   Object.values(cumulativeTags[groupTitle][foundPathNo])[0]
    // );
    const name = `${selectedObj[keyVal].attribute} - ${e.value}`;
    const selObjVal = {
      ...Object.values(cummSelectedObj[foundPathNo])[0],
      name,
      rawValue: e.rawValue,
      isEnd: true
    };
    const tagVal = { ...cumulativeTags[groupTitle][foundPathNo][0], name, rawValue: e.rawValue };
    // const tagVal = { ...tags[groupTitle][0], name, rawValue: e.rawValue };

    // console.log('imp check', { ...cumulativeTags[groupTitle][foundPathNo][0] }, { ...tags[groupTitle][0] });
    const selObj = {
      [Object.keys(cummSelectedObj[foundPathNo])[0]]: selObjVal
    };
    const tagObj = { 0: tagVal };
    cummSelectedObj[foundPathNo] = selObj;
    addTabToObj({ value: selObj, key: groupTitle });
    cumulativeTags[groupTitle][foundPathNo] = tagObj;
    tags[groupTitle] = tagObj;
  };

  return (
    <React.Fragment key={index}>
      <div
        style={{
          width: 1,
          borderLeft: '0.5px dashed darkgray',
          marginLeft: '1rem'
        }}
      />
      {/* {console.log('weekStartsOn', weekStartsOn)} */}
      <div>
        <FilterResultHeader>
          <FilterResultHeaderText>{selectedObj[keyVal]?.attribute}</FilterResultHeaderText>
          {selectedObj[keyVal].lockable && (
            <Radiobutton
              onClick={handleLeaf}
              active={leafLevels && leafLevels[groupTitle] === selectedObj[keyVal]?.attribute}
            />
          )}
        </FilterResultHeader>
        {selectedObj[keyVal].type === 'multiselect' && (
          <FilterResultContent left={true}>
            {selectedObj[keyVal].values.map((innerItem, i) => (
              <FilterContentContainer
                selectedTabKey={groupTitle}
                innerItem={{ ...innerItem, id: `_${innerItem.id}` }}
                selectionType={selectedObj[keyVal].selectionType}
                handleClick={() =>
                  handleMultiSelectClick({ ...innerItem, id: `_${innerItem.id}` }, index, groupTitle, attribute)
                }
                key={innerItem.id || i}
                index={index}
                multiSelect={true}
                className='multiSelect'
              />
            ))}
          </FilterResultContent>
        )}
        {/* {console.log('aadds', selectedObj[keyVal].rawValue, selectedObj[keyVal])} */}

        {selectedObj[keyVal].type === 'date' && (
          <FilterResultContent left={true} className='flexing'>
            <div>
              {selectedObj[keyVal].values.map((innerItem, i) => (
                <FilterContentContainer
                  selectedTabKey={groupTitle}
                  // innerItem={innerItem}
                  innerItem={{ ...innerItem, id: `_${innerItem.id}` }}
                  selectionType={selectedObj[keyVal].selectionType}
                  handleClick={() =>
                    handleChildrenClick(
                      { ...innerItem, id: `_${innerItem.id}` },
                      groupTitle,
                      index,
                      attribute,
                      selectionType
                    )
                  }
                  key={innerItem.id || i}
                  index={index}
                  className='typeDate'
                  isLeaf={isLeaf}
                />
              ))}
            </div>
            <Suspense fallback={<Loader />}>
              <CalendarComponent
                groupTitle={groupTitle}
                values={selectedObj[keyVal].values}
                handleChildrenClick={() =>
                  handleChildrenClick(selectedObj[keyVal].values, groupTitle, index, attribute, selectionType)
                }
                index={index}
              />
            </Suspense>
          </FilterResultContent>
        )}
        {/* refactor date and week as single */}
        {/* {selectedObj[keyVal].type === 'dateRange' && ( */}
        {selectedObj[keyVal].type.toLowerCase() === 'daterange' && (
          //  || selectedObj[keyVal].type === 'week')
          <FilterResultContent left={true} className='flexing'>
            <Suspense fallback={<Loader />}>
              <DateRangePicker
                initValue={selectedObj[keyVal].rawValue}
                handleSelection={handleSelection}
                weekStartsOn={weekStartsOn ?? calanderInitContants.weekStartsOn}
                quarterStartsOn={q1StartsOn ?? calanderInitContants.q1StartsOn}
                MaxAllowedRange={maxRangeAllowed}
              />
            </Suspense>
          </FilterResultContent>
        )}
        {selectedObj[keyVal].type === 'week' && (
          <FilterResultContent left={true} className='flexing'>
            <Suspense fallback={<Loader />}>
              <DateRangePicker
                isWeekSelection={selectedObj[keyVal].type === 'week'}
                initValue={selectedObj[keyVal].rawValue}
                handleSelection={handleSelection}
                weekStartsOn={weekStartsOn ?? calanderInitContants.weekStartsOn}
                quarterStartsOn={q1StartsOn ?? calanderInitContants.q1StartsOn}
                MaxAllowedRange={maxRangeAllowed}
              />
            </Suspense>
          </FilterResultContent>
        )}

        {selectedObj[keyVal].type === 'month' && (
          <FilterResultContent left={true} className='flexing'>
            <Suspense fallback={<Loader />}>
              <MonthRangePicker initValue={selectedObj[keyVal].rawValue} handleSelection={handleSelection} />
            </Suspense>
          </FilterResultContent>
        )}

        {selectedObj[keyVal].type === 'quarter' && (
          <FilterResultContent left={true} className='flexing'>
            <Suspense fallback={<Loader />}>
              <QuarterRangePicker
                initValue={selectedObj[keyVal].rawValue}
                handleSelection={handleSelection}
                quarterStartsOn={q1StartsOn ?? calanderInitContants.q1StartsOn}
              />
            </Suspense>
          </FilterResultContent>
        )}

        {(selectedObj[keyVal].type === 'select' || selectedObj[keyVal].type === 'object') && (
          <FilterResultContent left={true}>
            {selectedObj[keyVal].values.map((innerItem, i) => (
              <FilterContentContainer
                selectedTabKey={groupTitle}
                // innerItem={innerItem}
                innerItem={{ ...innerItem, id: `_${innerItem.id}` }}
                handleClick={() =>
                  handleChildrenClick(
                    { ...innerItem, id: `_${innerItem.id}` },
                    groupTitle,
                    index,
                    attribute,
                    selectionType
                  )
                }
                selectionType={selectedObj[keyVal].selectionType}
                key={innerItem.id || i}
                index={index}
                isLeaf={isLeaf}
              />
            ))}
          </FilterResultContent>
        )}
      </div>
    </React.Fragment>
  );
}

export default memo(FilterChildrenContent);
