/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Header, HeaderContainer } from '../../component/Common/CommonStyles';
import { WO_TYPES, WO_TYPES_SHORTHAND } from '../../constants';
import WorkOrders from '../WorkOrders/WorkOrders';
import {
  BodyContainer,
  Container,
  CountText,
  HeaderText,
  ItemContainer
} from './MyActionItem.style';
import { Data } from '../WorkOrders/data';
import useGetWorkOrderTypes from '../../component/WorkOrder/CreateWorkOrder/hooks/useGetWorkOrderTypes';
import { Creators as WorkOrderCreators } from '../WorkOrders/store/action';
import Toast from '../../component/Common/Toast';
import Loader from '../../component/Loader/Loader';
import { apiEndPoints } from '../../services/axios/endPoints';
import { fetchUserAndLocation } from '../../component/Sidenav/fetchPageStatistics';

const MyActionItems = () => {
  const location = useLocation();
  const {
    workOrderFilter,
    workOrdersCountByGroup,
    woGroupCountLoading,
    woGroupCounterror
  } = useSelector((state) => state.workOrders);
  const { colors } = useSelector((state) => state.configData);
  const dispatch = useDispatch();
  const isDashboard = location.pathname.includes('/Dashboard');
  useGetWorkOrderTypes();
  // const [workOrdersCountByGroup, setWorkOrdersCountByGroup] = useState([]);
  const setType = (type) => {
    dispatch(WorkOrderCreators.setWorkOrderFilter({ type, isDashboard }));
  };
  useEffect(() => {
    let pathName = apiEndPoints.getActionItems;
    if (isDashboard) {
      pathName = apiEndPoints.getWorkOrdersCountByGroup;
    }
    dispatch(WorkOrderCreators.getWorkOrdersCountByGroup({ pathName }));

    if (workOrderFilter.isDashboard !== isDashboard) {
      // console.log('clearing', workOrderFilter.isDashboard, isDashboard);
      setType('');
    }
  }, []);

  const { userData } = useSelector((state) => state.loginData);
  const { finalMenu } = useSelector((state) => state.home);
  useEffect(() => {
    const name = finalMenu.find((ele) => ele.path === location.pathname);
    fetchUserAndLocation(location.pathname, userData, {
      pageType: name?.name
    });
  }, []);

  const clearToastMessage = () => {
    dispatch(WorkOrderCreators.getWorkOrdersCountByGroupFailure({ error: '' }));
  };

  const Item = ({ item }) => (
    <ItemContainer
      onClick={() => {
        setType(item.Type);
      }}
    >
      <CountText color={colors?.primary || '#4f4ed0'}>{item.count}</CountText>
      <HeaderText>{item.Type}</HeaderText>
    </ItemContainer>
  );
  // console.log('workOrderFilter', workOrderFilter);
  return (
    <Container>
      {workOrderFilter?.type === '' ? (
        <>
          <HeaderContainer>
            <Header>Activities</Header>
          </HeaderContainer>
          {woGroupCountLoading ? (
            <Loader />
          ) : (
            <>
              <BodyContainer className='wrap'>
                {workOrdersCountByGroup.map((e, key) => (
                  <Item item={e} key={key} />
                ))}
              </BodyContainer>
            </>
          )}
        </>
      ) : (
        <WorkOrders
          setType={setType}
          type={workOrderFilter?.type}
          withFilter={!isDashboard}
        />
      )}
      {woGroupCounterror && (
        <Toast
          style={{ right: 20 }}
          fn={clearToastMessage}
          header='Error'
          message={woGroupCounterror}
        />
      )}
    </Container>
  );
};

export default MyActionItems;
