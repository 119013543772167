/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import { fontFamily, fontSize } from '../../../constants';
import { device } from '../../constants';

export const Button = styled.button`
  display: inline;
  min-width: 12rem;
  padding: ${({ padding }) => padding || '0rem 2rem'};
  height: 4rem;
  font-size: ${fontSize.titleBig};
  box-shadow: -5px -5px 19px ${({ theme }) => theme.shadowContrast}, 3px 3px 10px ${({ theme }) => theme.shadow};
  border-radius: 5px;
  border: 0px;
  text-transform: capitalize;
  font-family: ${fontFamily.circularMedium};
  background-color: ${({ theme }) => theme.aux.secondary};
  color: ${({ theme }) => theme.contrast.primary};
  cursor: pointer;
  margin: ${({ margin }) => margin || '0rem'};
  &.dark {
    background-color: ${({ theme }) => theme.customTheme.primary};
    color: ${({ theme }) => theme.contrast.tertiary};
  }
  &.dark * {
    font-size: ${fontSize.titleBig};
    color: ${({ theme }) => theme.contrast.tertiary};
    fill: ${({ theme }) => theme.contrast.tertiary} !important;
  }
  & .dynamicSVGIcon {
    margin-right: 1rem;
  }
  &:disabled {
    pointer-events: none;
    opacity: 0.6;
  }

  &.sidePanelButton {
    min-width: 1rem;
    border-radius: 3px;
  }
  @media ${device.tablet} {
  font-size: 1.4rem;
    
  }
`;

const ButtonComp = ({ children, onClick, dark, style, disabled, margin, padding }) => (
  <Button
    disabled={disabled}
    style={{ ...style }}
    className={dark && 'dark'}
    onClick={onClick}
    padding={padding}
    margin={margin}
  >
    {children}
  </Button>
);

export default ButtonComp;
