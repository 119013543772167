import styled from 'styled-components';
import { ContentTextTemp, DetailsContainerTemp } from '../CommonStyles';
import { device, fontSize } from '../../constants';
import { Radio } from '../RadioButton/RadioButton';

export const LabelToolTip = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.customTheme.primary};
  width: max-content;
  max-width: 350px;
  /* height: fit-content; */

  /* display: flex; */
  display: none;
  justify-content: center;
  align-items: center;
  /* bottom: -15px; */
  left: 0;
  border-radius: 4px;
  z-index: 1;
  padding: 5px 10px;
  top: -100%

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 100%;
    width: 0;
    height: 0;
    border-bottom: 5px solid ${({ theme }) => theme.customTheme.primary};
    border-top: 5px solid transparent;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }
  &.top {
    top: -4.2rem;
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 100%;
      left: 10%;
      width: 0;
      height: 0;
      border-top: 5px solid ${({ theme }) => theme.customTheme.primary};
      border-bottom: 5px solid transparent;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }
  }
`;

export const Span = styled.p`
  font-size: ${fontSize.titleBig};
  color: #9b9b9b;
  margin-right: 2rem;
  margin-top: 0.5rem;

  &.colonMargin {
    margin: 2rem;
  }
  @media ${device.tablet} {
  font-size: ${fontSize.medium};
    
  }
`;

export const ContentText = styled(ContentTextTemp)`
  font-size: 2rem;
  position: relative;
  display: block;

  &.truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 20rem;
    overflow: hidden;
  }

  align-items: center;
  &:hover ${LabelToolTip} {
    display: flex;
  }
  &.table {
    padding: 2rem 0rem 1rem;
  }

  &.formLabel {
    width: 15%;
    font-size: 1.8rem;
  }
  ${Radio} {
    margin-right: 1rem;
  }
`;

export const DetailsContainer = styled(DetailsContainerTemp)`
  /* background-color: red; */
  flex-wrap: wrap;
  display: flex;
  & > div.detail + div.detail {
    /* margin-right: 3rem; */
    /* background-color: green; */
  }
`;
