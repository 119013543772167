import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Bttn } from '../../../../views/WorkOrders/WorkOrders.styles';

const NavigationBtns = ({ setDisableNext, onCancel }) => {
  const history = useHistory();
  const { type } = useSelector((state) => state.upsertWorkOrder);
  const handleCancel = (...params) => {
    if (onCancel) {
      onCancel(...params);
    } else {
      history.goBack();
    }
  };
  return (
    <>
      <Bttn className='white' onClick={handleCancel}>
        Cancel
      </Bttn>
      <Bttn disabled={!type.Type} onClick={() => history.push('/home/work_orders/create_WO/filter')}>
        Next
      </Bttn>
    </>
  );
};

export default NavigationBtns;
