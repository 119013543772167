import { STATUSES } from '../../constants';

export const Data = [
  {
    orderId: '05678',
    title: 'Preventive Maintenance',
    assignedTo: {
      firstName: 'Aarun A/L ',
      lastName: 'Emom',
      email: '',
      url: ''
    },
    type: 'PM',
    status: STATUSES.TO_BE_ACKNOWLEGED,
    createdDate: '24-02-2021',
    isLeaf: false,
    child: [
      {
        orderId: '056700',
        parentID: '05678',
        title: 'Engine Spare Part',
        assignedTo: {
          firstName: 'Abd Aziz  ',
          lastName: '- Azwad',
          profilePic: ''
        },
        type: 'PM',
        status: STATUSES.TO_BE_ACKNOWLEGED,
        createdDate: '24-02-2021',
        isLeaf: true,
        child: []
      }
    ]
  },
  {
    orderId: '03841',
    title: 'Preventive Maintenance',
    assignedTo: {
      firstName: 'Aarun A/L ',
      lastName: 'Emom',
      email: '',
      url: ''
    },
    type: 'PM',
    status: STATUSES.TO_BE_ACKNOWLEGED,
    createdDate: '16-02-2021',
    isLeaf: false,
    child: [
      {
        orderId: '32589',
        parentID: '03841',
        title: 'Engine Spare Part',
        assignedTo: {
          firstName: 'Abd Aziz  ',
          lastName: '- Azwad',
          profilePic: ''
        },
        type: 'Spare',
        status: STATUSES.TO_BE_ASSIGNED,
        createdDate: '10-02-2021',
        isLeaf: true,
        child: []
      }
    ]
  },
  {
    orderId: '80256',
    title: 'HV Parts',
    assignedTo: {
      firstName: 'Aarun A/L ',
      lastName: 'Emom',
      email: '',
      url: ''
    },
    type: 'HV Parts',
    status: STATUSES.TO_BE_ASSIGNED,
    createdDate: '02-02-2021',
    isLeaf: false,
    child: [
      {
        orderId: '32589',
        parentID: '03841',
        title: 'Engine Spare Part',
        assignedTo: {
          firstName: 'Abd Aziz  ',
          lastName: '- Azwad',
          profilePic: ''
        },
        type: 'Spare',
        status: STATUSES.TO_BE_ASSIGNED,
        createdDate: '10-02-2021',
        isLeaf: true,
        child: []
      }
    ]
  }
];

export const localWOResponse = {
  meta: {
    count: 29,
    page: 1,
    pagesize: 20,
    lang: 'en',
    columns: [
      {
        key: 'serialNumber',
        title: '',
        searchable: false,
        sortable: false,
        visible: true,
        type: 'string',
        options: []
      },

      {
        key: 'jobOrderId',
        title: 'Work Order #',
        searchable: true,
        sortable: false,
        visible: true,
        type: 'string',
        options: []
      },
      {
        key: 'title',
        title: 'Type',
        searchable: false,
        sortable: false,
        visible: true,
        type: 'string',
        options: [
          {
            label: 'Preventive Maintenance',
            workflowTemplateId: 1,
            Type: 'Preventive Maintenance',
            version: 1,
            onlyChild: 0
          },
          {
            label: 'Break Down',
            workflowTemplateId: 2,
            Type: 'Break Down',
            version: 1,
            onlyChild: 0
          },
          {
            label: 'Vendor to Store',
            workflowTemplateId: 4,
            Type: 'Vendor to Store',
            version: 1,
            onlyChild: 0
          },
          {
            label: 'Part Request',
            workflowTemplateId: 7,
            Type: 'Part Request',
            version: 1,
            onlyChild: 1
          },
          {
            label: 'Part Return',
            workflowTemplateId: 8,
            Type: 'Part Return',
            version: 1,
            onlyChild: 1
          }
        ]
      },
      {
        key: 'fromLocation',
        title: 'From Location',
        searchable: false,
        sortable: false,
        visible: false,
        type: 'string',
        options: []
      },
      {
        key: 'toLocation',
        title: 'To Location',
        searchable: false,
        sortable: false,
        visible: false,
        type: 'string',
        options: []
      },
      {
        key: 'assignedTo',
        title: 'Assigned To',
        searchable: false,
        sortable: false,
        visible: false,
        type: 'User',
        options: []
      },
      {
        key: 'current_state_completed_text',
        title: 'Last Completed Step',
        searchable: true,
        sortable: false,
        visible: true,
        type: 'string',
        options: []
      },
      {
        key: 'next_avaialable_steps_altText',
        title: 'Next Available Step(s)',
        searchable: true,
        sortable: false,
        visible: true,
        type: 'string',
        options: []
      },
      {
        key: 'createdDate',
        title: 'Created Date',
        searchable: true,
        sortable: false,
        visible: true,
        type: 'DateTime',
        options: []
      }
    ]
  },
  data: [
    {
      serialNumber: '-',
      jobOrderId: 'VS04000000011',
      parentjobOrderId: null,
      title: 'Vendor to Store',
      type: 'Vendor to Store',
      status: 'Create',
      next_avaialable_steps_altText: 'Receive',
      current_state_completed_text: 'Created',
      version: 1,
      createdBy: 'Chetan',
      createdDate: '12/07/2022T10:53:53.9900000Z',
      isLeaf: false,
      assignedTo: {
        firstName: null,
        lastName: null,
        email: null,
        url: null,
        profilePic: null
      },
      child: [],
      fromLocation: null,
      toLocation: null
    },
    {
      serialNumber: '-',

      jobOrderId: 'JO01000000012',
      parentjobOrderId: null,
      title: 'Preventive Maintenance',
      type: 'Preventive Maintenance',
      status: 'Start',
      next_avaialable_steps_altText: 'Reassign',
      current_state_completed_text: 'Started',
      version: 1,
      createdBy: 'Hemanth',
      createdDate: '11/07/2022T09:27:06.9700000Z',
      isLeaf: false,
      assignedTo: {
        firstName: 'Hemanth',
        lastName: null,
        email: null,
        url: null,
        profilePic: null
      },
      child: [
        {
          jobOrderId: 'PR08000000013',
          parentjobOrderId: 'JO01000000012',
          title: 'Part Return',
          type: 'Part Return',
          status: 'Close',
          next_avaialable_steps_altText: '',
          current_state_completed_text: 'Parts Return Closed',
          version: 1,
          createdBy: 'Chetan',
          createdDate: '13/07/2022T06:39:08.7070000Z',
          isLeaf: true,
          assignedTo: {
            firstName: null,
            lastName: null,
            email: null,
            url: null,
            profilePic: null
          },
          child: null,
          fromLocation: null,
          toLocation: null
        },
        {
          jobOrderId: 'PR07000000019',
          parentjobOrderId: 'JO01000000012',
          title: 'Part Request',
          type: 'Part Request',
          status: 'Close',
          next_avaialable_steps_altText: '',
          current_state_completed_text: 'Part Request Closed',
          version: 1,
          createdBy: 'Hemanth',
          createdDate: '11/07/2022T09:28:23.8870000Z',
          isLeaf: true,
          assignedTo: {
            firstName: null,
            lastName: null,
            email: null,
            url: null,
            profilePic: null
          },
          child: null,
          fromLocation: null,
          toLocation: null
        },
        {
          jobOrderId: 'PR08000000011',
          parentjobOrderId: 'JO01000000012',
          title: 'Part Return',
          type: 'Part Return',
          status: 'SubmitRequest',
          next_avaialable_steps_altText: 'Store Acknowledge',
          current_state_completed_text: 'Parts Return Request Submitted',
          version: 1,
          createdBy: 'Hemanth',
          createdDate: '11/07/2022T09:52:22.8170000Z',
          isLeaf: true,
          assignedTo: {
            firstName: null,
            lastName: null,
            email: null,
            url: null,
            profilePic: null
          },
          child: null,
          fromLocation: null,
          toLocation: null
        },
        {
          jobOrderId: 'PR08000000012',
          parentjobOrderId: 'JO01000000012',
          title: 'Part Return',
          type: 'Part Return',
          status: 'UpdateInventory',
          next_avaialable_steps_altText: 'Close Parts Return',
          current_state_completed_text: 'Inventory Updated',
          version: 1,
          createdBy: 'Hemanth',
          createdDate: '11/07/2022T09:56:38.8770000Z',
          isLeaf: true,
          assignedTo: {
            firstName: null,
            lastName: null,
            email: null,
            url: null,
            profilePic: null
          },
          child: null,
          fromLocation: null,
          toLocation: null
        }
      ],
      fromLocation: null,
      toLocation: null
    },
    {
      serialNumber: '-',

      jobOrderId: 'VS04000000010',
      parentjobOrderId: null,
      title: 'Vendor to Store',
      type: 'Vendor to Store',
      status: 'Close',
      next_avaialable_steps_altText: '',
      current_state_completed_text: 'Vendor to Store Closed',
      version: 1,
      createdBy: 'Hemanth',
      createdDate: '11/07/2022T09:12:14.5570000Z',
      isLeaf: false,
      assignedTo: {
        firstName: null,
        lastName: null,
        email: null,
        url: null,
        profilePic: null
      },
      child: [],
      fromLocation: null,
      toLocation: null
    },
    {
      serialNumber: '-',

      jobOrderId: 'JO02000000006',
      parentjobOrderId: null,
      title: 'Break Down',
      type: 'Break Down',
      status: 'Create',
      next_avaialable_steps_altText: 'Start,Reassign,Close',
      current_state_completed_text: 'Created',
      version: 1,
      createdBy: 'Chetan',
      createdDate: '08/07/2022T09:46:30.9830000Z',
      isLeaf: false,
      assignedTo: {
        firstName: 'Chetan',
        lastName: null,
        email: null,
        url: null,
        profilePic: null
      },
      child: [],
      fromLocation: null,
      toLocation: null
    },
    {
      serialNumber: '-',

      jobOrderId: 'JO02000000005',
      parentjobOrderId: null,
      title: 'Break Down',
      type: 'Break Down',
      status: 'Create',
      next_avaialable_steps_altText: 'Start,Reassign',
      current_state_completed_text: 'Created',
      version: 1,
      createdBy: 'Chukiat Srichai',
      createdDate: '08/07/2022T09:12:45.6930000Z',
      isLeaf: false,
      assignedTo: {
        firstName: 'Chukiat Srichai',
        lastName: null,
        email: null,
        url: null,
        profilePic: null
      },
      child: [
        {
          jobOrderId: 'PR08000000010',
          parentjobOrderId: 'JO02000000005',
          title: 'Part Return',
          type: 'Part Return',
          status: 'SubmitRequest',
          next_avaialable_steps_altText: 'Store Acknowledge',
          current_state_completed_text: 'Parts Return Request Submitted',
          version: 1,
          createdBy: 'Chukiat Srichai',
          createdDate: '08/07/2022T09:25:16.1500000Z',
          isLeaf: true,
          assignedTo: {
            firstName: null,
            lastName: null,
            email: null,
            url: null,
            profilePic: null
          },
          child: null,
          fromLocation: null,
          toLocation: null
        }
      ],
      fromLocation: null,
      toLocation: null
    },
    {
      serialNumber: '-',

      jobOrderId: 'JO02000000004',
      parentjobOrderId: null,
      title: 'Break Down',
      type: 'Break Down',
      status: 'Create',
      next_avaialable_steps_altText: 'Start,Reassign,Close',
      current_state_completed_text: 'Created',
      version: 1,
      createdBy: 'Chukiat Srichai',
      createdDate: '08/07/2022T07:25:42.0370000Z',
      isLeaf: false,
      assignedTo: {
        firstName: 'Chukiat Srichai',
        lastName: null,
        email: null,
        url: null,
        profilePic: null
      },
      child: [],
      fromLocation: null,
      toLocation: null
    },
    {
      serialNumber: '-',

      jobOrderId: 'JO02000000003',
      parentjobOrderId: null,
      title: 'Break Down',
      type: 'Break Down',
      status: 'Create',
      next_avaialable_steps_altText: 'Start,Reassign,Close',
      current_state_completed_text: 'Created',
      version: 1,
      createdBy: 'Chukiat Srichai',
      createdDate: '07/07/2022T07:46:04.0030000Z',
      isLeaf: false,
      assignedTo: {
        firstName: 'Chukiat Srichai',
        lastName: null,
        email: null,
        url: null,
        profilePic: null
      },
      child: [],
      fromLocation: null,
      toLocation: null
    },
    {
      serialNumber: '-',

      jobOrderId: 'JO01000000011',
      parentjobOrderId: null,
      title: 'Preventive Maintenance',
      type: 'Preventive Maintenance',
      status: 'Create',
      next_avaialable_steps_altText: 'Start,Reassign',
      current_state_completed_text: 'Created',
      version: 1,
      createdBy: 'Chetan',
      createdDate: '07/07/2022T06:17:13.4900000Z',
      isLeaf: false,
      assignedTo: {
        firstName: 'Chetan',
        lastName: null,
        email: null,
        url: null,
        profilePic: null
      },
      child: [
        {
          jobOrderId: 'PR08000000009',
          parentjobOrderId: 'JO01000000011',
          title: 'Part Return',
          type: 'Part Return',
          status: 'StoreAck',
          next_avaialable_steps_altText: 'Store Verify Parts',
          current_state_completed_text: 'Acknowledged at Store',
          version: 1,
          createdBy: 'Chetan',
          createdDate: '07/07/2022T06:17:49.1570000Z',
          isLeaf: true,
          assignedTo: {
            firstName: null,
            lastName: null,
            email: null,
            url: null,
            profilePic: null
          },
          child: null,
          fromLocation: null,
          toLocation: null
        }
      ],
      fromLocation: null,
      toLocation: null
    },
    {
      serialNumber: '-',

      jobOrderId: 'JO01000000010',
      parentjobOrderId: null,
      title: 'Preventive Maintenance',
      type: 'Preventive Maintenance',
      status: 'Create',
      next_avaialable_steps_altText: 'Start,Reassign,Close',
      current_state_completed_text: 'Created',
      version: 1,
      createdBy: 'Chetan',
      createdDate: '07/07/2022T05:34:37.2730000Z',
      isLeaf: false,
      assignedTo: {
        firstName: 'Chetan',
        lastName: null,
        email: null,
        url: null,
        profilePic: null
      },
      child: [],
      fromLocation: null,
      toLocation: null
    },
    {
      serialNumber: '-',

      jobOrderId: 'VS04000000009',
      parentjobOrderId: null,
      title: 'Vendor to Store',
      type: 'Vendor to Store',
      status: 'Create',
      next_avaialable_steps_altText: 'Receive',
      current_state_completed_text: 'Created',
      version: 1,
      createdBy: 'Chetan',
      createdDate: '04/07/2022T10:10:21.6130000Z',
      isLeaf: false,
      assignedTo: {
        firstName: null,
        lastName: null,
        email: null,
        url: null,
        profilePic: null
      },
      child: [],
      fromLocation: null,
      toLocation: null
    },
    {
      serialNumber: '-',

      jobOrderId: 'JO01000000009',
      parentjobOrderId: null,
      title: 'Preventive Maintenance',
      type: 'Preventive Maintenance',
      status: 'Create',
      next_avaialable_steps_altText: 'Start,Reassign,Close',
      current_state_completed_text: 'Created',
      version: 1,
      createdBy: 'Chetan',
      createdDate: '04/07/2022T09:43:06.9570000Z',
      isLeaf: false,
      assignedTo: {
        firstName: 'Chetan',
        lastName: null,
        email: null,
        url: null,
        profilePic: null
      },
      child: [],
      fromLocation: null,
      toLocation: null
    },
    {
      serialNumber: '-',

      jobOrderId: 'JO01000000008',
      parentjobOrderId: null,
      title: 'Preventive Maintenance',
      type: 'Preventive Maintenance',
      status: 'Reassign',
      next_avaialable_steps_altText: 'Reassign',
      current_state_completed_text: 'Reassigned',
      version: 1,
      createdBy: 'Hemanth',
      createdDate: '29/06/2022T12:53:12.0070000Z',
      isLeaf: false,
      assignedTo: {
        firstName: 'Santosh',
        lastName: null,
        email: null,
        url: null,
        profilePic: null
      },
      child: [
        {
          jobOrderId: 'PR07000000012',
          parentjobOrderId: 'JO01000000008',
          title: 'Part Request',
          type: 'Part Request',
          status: 'Close',
          next_avaialable_steps_altText: '',
          current_state_completed_text: 'Part Request Closed',
          version: 1,
          createdBy: 'Hemanth',
          createdDate: '29/06/2022T13:39:44.6670000Z',
          isLeaf: true,
          assignedTo: {
            firstName: null,
            lastName: null,
            email: null,
            url: null,
            profilePic: null
          },
          child: null,
          fromLocation: null,
          toLocation: null
        },
        {
          jobOrderId: 'PR07000000013',
          parentjobOrderId: 'JO01000000008',
          title: 'Part Request',
          type: 'Part Request',
          status: 'Issue',
          next_avaialable_steps_altText: 'Receive Parts',
          current_state_completed_text: 'Parts Issued',
          version: 1,
          createdBy: 'Hemanth',
          createdDate: '29/06/2022T13:39:54.7100000Z',
          isLeaf: true,
          assignedTo: {
            firstName: null,
            lastName: null,
            email: null,
            url: null,
            profilePic: null
          },
          child: null,
          fromLocation: null,
          toLocation: null
        },
        {
          jobOrderId: 'PR08000000007',
          parentjobOrderId: 'JO01000000008',
          title: 'Part Return',
          type: 'Part Return',
          status: 'UpdateInventory',
          next_avaialable_steps_altText: 'Close Parts Return',
          current_state_completed_text: 'Inventory Updated',
          version: 1,
          createdBy: 'Chetan',
          createdDate: '06/07/2022T07:31:15.7900000Z',
          isLeaf: true,
          assignedTo: {
            firstName: null,
            lastName: null,
            email: null,
            url: null,
            profilePic: null
          },
          child: null,
          fromLocation: null,
          toLocation: null
        },
        {
          jobOrderId: 'PR08000000008',
          parentjobOrderId: 'JO01000000008',
          title: 'Part Return',
          type: 'Part Return',
          status: 'StoreAck',
          next_avaialable_steps_altText: 'Store Verify Parts',
          current_state_completed_text: 'Acknowledged at Store',
          version: 1,
          createdBy: 'Chetan',
          createdDate: '06/07/2022T09:32:54.7330000Z',
          isLeaf: true,
          assignedTo: {
            firstName: null,
            lastName: null,
            email: null,
            url: null,
            profilePic: null
          },
          child: null,
          fromLocation: null,
          toLocation: null
        },
        {
          jobOrderId: 'PR07000000016',
          parentjobOrderId: 'JO01000000008',
          title: 'Part Request',
          type: 'Part Request',
          status: 'StoreAck',
          next_avaialable_steps_altText: 'Issue Parts',
          current_state_completed_text: 'Acknowledged at Store',
          version: 1,
          createdBy: 'Chetan',
          createdDate: '06/07/2022T09:49:26.6970000Z',
          isLeaf: true,
          assignedTo: {
            firstName: null,
            lastName: null,
            email: null,
            url: null,
            profilePic: null
          },
          child: null,
          fromLocation: null,
          toLocation: null
        },
        {
          jobOrderId: 'PR07000000017',
          parentjobOrderId: 'JO01000000008',
          title: 'Part Request',
          type: 'Part Request',
          status: 'StoreAck',
          next_avaialable_steps_altText: 'Issue Parts',
          current_state_completed_text: 'Acknowledged at Store',
          version: 1,
          createdBy: 'Chetan',
          createdDate: '06/07/2022T09:53:59.2400000Z',
          isLeaf: true,
          assignedTo: {
            firstName: null,
            lastName: null,
            email: null,
            url: null,
            profilePic: null
          },
          child: null,
          fromLocation: null,
          toLocation: null
        },
        {
          jobOrderId: 'PR07000000018',
          parentjobOrderId: 'JO01000000008',
          title: 'Part Request',
          type: 'Part Request',
          status: 'StoreAck',
          next_avaialable_steps_altText: 'Issue Parts',
          current_state_completed_text: 'Acknowledged at Store',
          version: 1,
          createdBy: 'Chetan',
          createdDate: '07/07/2022T08:59:25.1030000Z',
          isLeaf: true,
          assignedTo: {
            firstName: null,
            lastName: null,
            email: null,
            url: null,
            profilePic: null
          },
          child: null,
          fromLocation: null,
          toLocation: null
        }
      ],
      fromLocation: null,
      toLocation: null
    },
    {
      serialNumber: '-',

      jobOrderId: 'VS04000000008',
      parentjobOrderId: null,
      title: 'Vendor to Store',
      type: 'Vendor to Store',
      status: 'Update',
      next_avaialable_steps_altText: 'Close',
      current_state_completed_text: 'Inventory Update Complete',
      version: 1,
      createdBy: 'Hemanth',
      createdDate: '28/06/2022T10:29:29.7500000Z',
      isLeaf: false,
      assignedTo: {
        firstName: null,
        lastName: null,
        email: null,
        url: null,
        profilePic: null
      },
      child: [],
      fromLocation: null,
      toLocation: null
    },
    {
      serialNumber: '-',

      jobOrderId: 'JO01000000007',
      parentjobOrderId: null,
      title: 'Preventive Maintenance',
      type: 'Preventive Maintenance',
      status: 'Start',
      next_avaialable_steps_altText: 'Reassign,Close',
      current_state_completed_text: 'Started',
      version: 1,
      createdBy: 'Hemanth',
      createdDate: '27/06/2022T10:16:45.4930000Z',
      isLeaf: false,
      assignedTo: {
        firstName: 'Hemanth',
        lastName: null,
        email: null,
        url: null,
        profilePic: null
      },
      child: [
        {
          jobOrderId: 'PR08000000005',
          parentjobOrderId: 'JO01000000007',
          title: 'Part Return',
          type: 'Part Return',
          status: 'Close',
          next_avaialable_steps_altText: '',
          current_state_completed_text: 'Parts Return Closed',
          version: 1,
          createdBy: 'Hemanth',
          createdDate: '27/06/2022T16:43:23.9300000Z',
          isLeaf: true,
          assignedTo: {
            firstName: null,
            lastName: null,
            email: null,
            url: null,
            profilePic: null
          },
          child: null,
          fromLocation: null,
          toLocation: null
        },
        {
          jobOrderId: 'PR07000000011',
          parentjobOrderId: 'JO01000000007',
          title: 'Part Request',
          type: 'Part Request',
          status: 'Close',
          next_avaialable_steps_altText: '',
          current_state_completed_text: 'Part Request Closed',
          version: 1,
          createdBy: 'Hemanth',
          createdDate: '27/06/2022T16:39:03.7200000Z',
          isLeaf: true,
          assignedTo: {
            firstName: null,
            lastName: null,
            email: null,
            url: null,
            profilePic: null
          },
          child: null,
          fromLocation: null,
          toLocation: null
        }
      ],
      fromLocation: null,
      toLocation: null
    },
    {
      serialNumber: '-',

      jobOrderId: 'VS04000000007',
      parentjobOrderId: null,
      title: 'Vendor to Store',
      type: 'Vendor to Store',
      status: 'Close',
      next_avaialable_steps_altText: '',
      current_state_completed_text: 'Vendor to Store Closed',
      version: 1,
      createdBy: 'Hemanth',
      createdDate: '27/06/2022T10:08:38.2800000Z',
      isLeaf: false,
      assignedTo: {
        firstName: null,
        lastName: null,
        email: null,
        url: null,
        profilePic: null
      },
      child: [],
      fromLocation: null,
      toLocation: null
    },
    {
      serialNumber: '-',

      jobOrderId: 'JO01000000006',
      parentjobOrderId: null,
      title: 'Preventive Maintenance',
      type: 'Preventive Maintenance',
      status: 'Start',
      next_avaialable_steps_altText: 'Reassign',
      current_state_completed_text: 'Started',
      version: 1,
      createdBy: 'Hemanth',
      createdDate: '23/06/2022T09:24:57.2230000Z',
      isLeaf: false,
      assignedTo: {
        firstName: 'Hemanth',
        lastName: null,
        email: null,
        url: null,
        profilePic: null
      },
      child: [
        {
          jobOrderId: 'PR07000000009',
          parentjobOrderId: 'JO01000000006',
          title: 'Part Request',
          type: 'Part Request',
          status: 'StoreAck',
          next_avaialable_steps_altText: 'Issue Parts',
          current_state_completed_text: 'Acknowledged at Store',
          version: 1,
          createdBy: 'Hemanth',
          createdDate: '23/06/2022T09:31:35.9070000Z',
          isLeaf: true,
          assignedTo: {
            firstName: null,
            lastName: null,
            email: null,
            url: null,
            profilePic: null
          },
          child: null,
          fromLocation: null,
          toLocation: null
        },
        {
          jobOrderId: 'PR07000000010',
          parentjobOrderId: 'JO01000000006',
          title: 'Part Request',
          type: 'Part Request',
          status: 'Receive',
          next_avaialable_steps_altText: 'Close Part Request',
          current_state_completed_text: 'Parts Received By Requestor',
          version: 1,
          createdBy: 'Hemanth',
          createdDate: '23/06/2022T09:37:13.4800000Z',
          isLeaf: true,
          assignedTo: {
            firstName: null,
            lastName: null,
            email: null,
            url: null,
            profilePic: null
          },
          child: null,
          fromLocation: null,
          toLocation: null
        }
      ],
      fromLocation: null,
      toLocation: null
    },
    {
      serialNumber: '-',

      jobOrderId: 'VS04000000006',
      parentjobOrderId: null,
      title: 'Vendor to Store',
      type: 'Vendor to Store',
      status: 'Create',
      next_avaialable_steps_altText: 'Receive',
      current_state_completed_text: 'Created',
      version: 1,
      createdBy: 'Hemanth',
      createdDate: '07/06/2022T09:53:53.2300000Z',
      isLeaf: false,
      assignedTo: {
        firstName: null,
        lastName: null,
        email: null,
        url: null,
        profilePic: null
      },
      child: [],
      fromLocation: null,
      toLocation: null
    },
    {
      serialNumber: '-',

      jobOrderId: 'VS04000000005',
      parentjobOrderId: null,
      title: 'Vendor to Store',
      type: 'Vendor to Store',
      status: 'Receive',
      next_avaialable_steps_altText: 'Update',
      current_state_completed_text: 'Received',
      version: 1,
      createdBy: 'Hemanth',
      createdDate: '07/06/2022T09:53:10.7270000Z',
      isLeaf: false,
      assignedTo: {
        firstName: null,
        lastName: null,
        email: null,
        url: null,
        profilePic: null
      },
      child: [],
      fromLocation: null,
      toLocation: null
    },
    {
      serialNumber: '-',

      jobOrderId: 'VS04000000004',
      parentjobOrderId: null,
      title: 'Vendor to Store',
      type: 'Vendor to Store',
      status: 'Create',
      next_avaialable_steps_altText: 'Receive',
      current_state_completed_text: 'Created',
      version: 1,
      createdBy: 'Hemanth',
      createdDate: '07/06/2022T09:52:45.2730000Z',
      isLeaf: false,
      assignedTo: {
        firstName: null,
        lastName: null,
        email: null,
        url: null,
        profilePic: null
      },
      child: [],
      fromLocation: null,
      toLocation: null
    },
    {
      serialNumber: '-',

      jobOrderId: 'VS04000000003',
      parentjobOrderId: null,
      title: 'Vendor to Store',
      type: 'Vendor to Store',
      status: 'Receive',
      next_avaialable_steps_altText: 'Update',
      current_state_completed_text: 'Received',
      version: 1,
      createdBy: 'Hemanth',
      createdDate: '07/06/2022T09:51:17.7230000Z',
      isLeaf: false,
      assignedTo: {
        firstName: null,
        lastName: null,
        email: null,
        url: null,
        profilePic: null
      },
      child: [],
      fromLocation: 'TEST Inventory B2L2',
      toLocation: null
    },
    {
      serialNumber: '-',

      jobOrderId: 'VS04000000002',
      parentjobOrderId: null,
      title: 'Vendor to Store',
      type: 'Vendor to Store',
      status: 'Update',
      next_avaialable_steps_altText: 'Close',
      current_state_completed_text: 'Inventory Update Complete',
      version: 1,
      createdBy: 'Hemanth',
      createdDate: '07/06/2022T09:38:52.4270000Z',
      isLeaf: false,
      assignedTo: {
        firstName: null,
        lastName: null,
        email: null,
        url: null,
        profilePic: null
      },
      child: [],
      fromLocation: null,
      toLocation: null
    },
    {
      serialNumber: '-',

      jobOrderId: 'JO01000000005',
      parentjobOrderId: null,
      title: 'Preventive Maintenance',
      type: 'Preventive Maintenance',
      status: 'Start',
      next_avaialable_steps_altText: 'Reassign',
      current_state_completed_text: 'Started',
      version: 1,
      createdBy: 'Hemanth',
      createdDate: '01/06/2022T13:33:45.6730000Z',
      isLeaf: false,
      assignedTo: {
        firstName: 'Hemanth',
        lastName: null,
        email: null,
        url: null,
        profilePic: null
      },
      child: [
        {
          jobOrderId: 'PR07000000008',
          parentjobOrderId: 'JO01000000005',
          title: 'Part Request',
          type: 'Part Request',
          status: 'Close',
          next_avaialable_steps_altText: '',
          current_state_completed_text: 'Part Request Closed',
          version: 1,
          createdBy: 'Chetan',
          createdDate: '23/06/2022T07:08:58.5000000Z',
          isLeaf: true,
          assignedTo: {
            firstName: null,
            lastName: null,
            email: null,
            url: null,
            profilePic: null
          },
          child: null,
          fromLocation: null,
          toLocation: null
        },
        {
          jobOrderId: 'PR08000000003',
          parentjobOrderId: 'JO01000000005',
          title: 'Part Return',
          type: 'Part Return',
          status: 'StoreVerifyParts',
          next_avaialable_steps_altText: 'Update Inventory',
          current_state_completed_text: 'Parts Verified at Store',
          version: 1,
          createdBy: 'Chetan',
          createdDate: '23/06/2022T07:27:29.6600000Z',
          isLeaf: true,
          assignedTo: {
            firstName: null,
            lastName: null,
            email: null,
            url: null,
            profilePic: null
          },
          child: null,
          fromLocation: null,
          toLocation: null
        },
        {
          jobOrderId: 'PR08000000004',
          parentjobOrderId: 'JO01000000005',
          title: 'Part Return',
          type: 'Part Return',
          status: 'StoreVerifyParts',
          next_avaialable_steps_altText: 'Update Inventory',
          current_state_completed_text: 'Parts Verified at Store',
          version: 1,
          createdBy: 'Chetan',
          createdDate: '23/06/2022T07:33:32.9470000Z',
          isLeaf: true,
          assignedTo: {
            firstName: null,
            lastName: null,
            email: null,
            url: null,
            profilePic: null
          },
          child: null,
          fromLocation: null,
          toLocation: null
        },
        {
          jobOrderId: 'PR08000000002',
          parentjobOrderId: 'JO01000000005',
          title: 'Part Return',
          type: 'Part Return',
          status: 'Close',
          next_avaialable_steps_altText: '',
          current_state_completed_text: 'Parts Return Closed',
          version: 1,
          createdBy: 'Chetan',
          createdDate: '23/06/2022T06:21:20.1070000Z',
          isLeaf: true,
          assignedTo: {
            firstName: null,
            lastName: null,
            email: null,
            url: null,
            profilePic: null
          },
          child: null,
          fromLocation: null,
          toLocation: null
        }
      ],
      fromLocation: null,
      toLocation: null
    },
    {
      serialNumber: '-',

      jobOrderId: 'VS04000000001',
      parentjobOrderId: null,
      title: 'Vendor to Store',
      type: 'Vendor to Store',
      status: 'Update',
      next_avaialable_steps_altText: 'Close',
      current_state_completed_text: 'Inventory Update Complete',
      version: 1,
      createdBy: 'Hemanth',
      createdDate: '01/06/2022T10:07:57.7770000Z',
      isLeaf: false,
      assignedTo: {
        firstName: null,
        lastName: null,
        email: null,
        url: null,
        profilePic: null
      },
      child: [],
      fromLocation: null,
      toLocation: null
    },
    {
      serialNumber: '-',

      jobOrderId: 'JO01000000004',
      parentjobOrderId: null,
      title: 'Preventive Maintenance',
      type: 'Preventive Maintenance',
      status: 'Close',
      next_avaialable_steps_altText: '',
      current_state_completed_text: 'Closed',
      version: 1,
      createdBy: 'Hemanth',
      createdDate: '01/06/2022T07:39:55.5800000Z',
      isLeaf: false,
      assignedTo: {
        firstName: 'Hemanth',
        lastName: null,
        email: null,
        url: null,
        profilePic: null
      },
      child: [
        {
          jobOrderId: 'PR07000000007',
          parentjobOrderId: 'JO01000000004',
          title: 'Part Request',
          type: 'Part Request',
          status: 'Close',
          next_avaialable_steps_altText: '',
          current_state_completed_text: 'Part Request Closed',
          version: 1,
          createdBy: 'Hemanth',
          createdDate: '01/06/2022T09:47:32.6730000Z',
          isLeaf: true,
          assignedTo: {
            firstName: null,
            lastName: null,
            email: null,
            url: null,
            profilePic: null
          },
          child: null,
          fromLocation: null,
          toLocation: null
        }
      ],
      fromLocation: null,
      toLocation: null
    },
    {
      serialNumber: '-',

      jobOrderId: 'JO02000000002',
      parentjobOrderId: null,
      title: 'Break Down',
      type: 'Break Down',
      status: 'Start',
      next_avaialable_steps_altText: 'Reassign',
      current_state_completed_text: 'Started',
      version: 1,
      createdBy: 'Hemanth',
      createdDate: '30/05/2022T09:40:05.7930000Z',
      isLeaf: false,
      assignedTo: {
        firstName: 'Hemanth',
        lastName: null,
        email: null,
        url: null,
        profilePic: null
      },
      child: [
        {
          jobOrderId: 'PR07000000006',
          parentjobOrderId: 'JO02000000002',
          title: 'Part Request',
          type: 'Part Request',
          status: 'Create',
          next_avaialable_steps_altText: 'Submit Part Request',
          current_state_completed_text: 'Part Request Created',
          version: 1,
          createdBy: 'Hemanth',
          createdDate: '30/05/2022T10:41:56.9530000Z',
          isLeaf: true,
          assignedTo: {
            firstName: null,
            lastName: null,
            email: null,
            url: null,
            profilePic: null
          },
          child: null,
          fromLocation: null,
          toLocation: null
        },
        {
          jobOrderId: 'PR07000000014',
          parentjobOrderId: 'JO02000000002',
          title: 'Part Request',
          type: 'Part Request',
          status: 'Receive',
          next_avaialable_steps_altText: 'Close Part Request',
          current_state_completed_text: 'Parts Received By Requestor',
          version: 1,
          createdBy: 'Hemanth',
          createdDate: '05/07/2022T10:56:16.8270000Z',
          isLeaf: true,
          assignedTo: {
            firstName: null,
            lastName: null,
            email: null,
            url: null,
            profilePic: null
          },
          child: null,
          fromLocation: null,
          toLocation: null
        },
        {
          jobOrderId: 'PR08000000006',
          parentjobOrderId: 'JO02000000002',
          title: 'Part Return',
          type: 'Part Return',
          status: 'StoreVerifyParts',
          next_avaialable_steps_altText: 'Update Inventory',
          current_state_completed_text: 'Parts Verified at Store',
          version: 1,
          createdBy: 'Hemanth',
          createdDate: '05/07/2022T11:30:17.4670000Z',
          isLeaf: true,
          assignedTo: {
            firstName: null,
            lastName: null,
            email: null,
            url: null,
            profilePic: null
          },
          child: null,
          fromLocation: null,
          toLocation: null
        },
        {
          jobOrderId: 'PR07000000015',
          parentjobOrderId: 'JO02000000002',
          title: 'Part Request',
          type: 'Part Request',
          status: 'Create',
          next_avaialable_steps_altText: 'Submit Part Request',
          current_state_completed_text: 'Part Request Created',
          version: 1,
          createdBy: 'Chetan',
          createdDate: '06/07/2022T09:31:08.8330000Z',
          isLeaf: true,
          assignedTo: {
            firstName: null,
            lastName: null,
            email: null,
            url: null,
            profilePic: null
          },
          child: null,
          fromLocation: null,
          toLocation: null
        }
      ],
      fromLocation: null,
      toLocation: null
    },
    {
      serialNumber: '-',

      jobOrderId: 'JO01000000003',
      parentjobOrderId: null,
      title: 'Preventive Maintenance',
      type: 'Preventive Maintenance',
      status: 'Start',
      next_avaialable_steps_altText: 'Reassign',
      current_state_completed_text: 'Started',
      version: 1,
      createdBy: 'Hemanth',
      createdDate: '04/05/2022T06:39:57.9770000Z',
      isLeaf: false,
      assignedTo: {
        firstName: 'Hemanth',
        lastName: null,
        email: null,
        url: null,
        profilePic: null
      },
      child: [
        {
          jobOrderId: 'PR07000000004',
          parentjobOrderId: 'JO01000000003',
          title: 'Part Request',
          type: 'Part Request',
          status: 'Create',
          next_avaialable_steps_altText: 'Submit Part Request',
          current_state_completed_text: 'Part Request Created',
          version: 1,
          createdBy: 'Hemanth',
          createdDate: '04/05/2022T06:46:48.7300000Z',
          isLeaf: true,
          assignedTo: {
            firstName: null,
            lastName: null,
            email: null,
            url: null,
            profilePic: null
          },
          child: null,
          fromLocation: null,
          toLocation: null
        },
        {
          jobOrderId: 'PR07000000005',
          parentjobOrderId: 'JO01000000003',
          title: 'Part Request',
          type: 'Part Request',
          status: 'Create',
          next_avaialable_steps_altText: 'Submit Part Request',
          current_state_completed_text: 'Part Request Created',
          version: 1,
          createdBy: 'Hemanth',
          createdDate: '04/05/2022T09:22:31.1070000Z',
          isLeaf: true,
          assignedTo: {
            firstName: null,
            lastName: null,
            email: null,
            url: null,
            profilePic: null
          },
          child: null,
          fromLocation: null,
          toLocation: null
        }
      ],
      fromLocation: null,
      toLocation: null
    },
    {
      serialNumber: '-',

      jobOrderId: 'JO01000000002',
      parentjobOrderId: null,
      title: 'Preventive Maintenance',
      type: 'Preventive Maintenance',
      status: 'Start',
      next_avaialable_steps_altText: 'Reassign',
      current_state_completed_text: 'Started',
      version: 1,
      createdBy: 'Hemanth',
      createdDate: '29/04/2022T07:04:19.3900000Z',
      isLeaf: false,
      assignedTo: {
        firstName: 'Hemanth',
        lastName: null,
        email: null,
        url: null,
        profilePic: null
      },
      child: [
        {
          jobOrderId: 'PR08000000001',
          parentjobOrderId: 'JO01000000002',
          title: 'Part Return',
          type: 'Part Return',
          status: 'UpdateInventory',
          next_avaialable_steps_altText: 'Update Scrap',
          current_state_completed_text: 'Inventory Updated',
          version: 1,
          createdBy: 'Chetan',
          createdDate: '18/05/2022T13:36:57.8370000Z',
          isLeaf: true,
          assignedTo: {
            firstName: null,
            lastName: null,
            email: null,
            url: null,
            profilePic: null
          },
          child: null,
          fromLocation: null,
          toLocation: null
        }
      ],
      fromLocation: null,
      toLocation: null
    },
    {
      serialNumber: '-',

      jobOrderId: 'JO02000000001',
      parentjobOrderId: null,
      title: 'Break Down',
      type: 'Break Down',
      status: 'Create',
      next_avaialable_steps_altText: 'Start,Reassign,Close',
      current_state_completed_text: 'Created',
      version: 1,
      createdBy: 'Chetan',
      createdDate: '27/04/2022T07:39:55.9800000Z',
      isLeaf: false,
      assignedTo: {
        firstName: 'Chetan',
        lastName: null,
        email: null,
        url: null,
        profilePic: null
      },
      child: [],
      fromLocation: null,
      toLocation: null
    },
    {
      serialNumber: '-',

      jobOrderId: 'JO01000000001',
      parentjobOrderId: null,
      title: 'Preventive Maintenance',
      type: 'Preventive Maintenance',
      status: 'Create',
      next_avaialable_steps_altText: 'Start,Reassign',
      current_state_completed_text: 'Created',
      version: 1,
      createdBy: 'Chetan',
      createdDate: '21/04/2022T08:18:56.1800000Z',
      isLeaf: false,
      assignedTo: {
        firstName: 'chetannadgir',
        lastName: null,
        email: null,
        url: null,
        profilePic: null
      },
      child: [
        {
          jobOrderId: 'PR07000000001',
          parentjobOrderId: 'JO01000000001',
          title: 'Part Request',
          type: 'Part Request',
          status: 'Receive',
          next_avaialable_steps_altText: 'Close Part Request',
          current_state_completed_text: 'Parts Received By Requestor',
          version: 1,
          createdBy: 'Chetan',
          createdDate: '21/04/2022T08:20:41.1230000Z',
          isLeaf: true,
          assignedTo: {
            firstName: null,
            lastName: null,
            email: null,
            url: null,
            profilePic: null
          },
          child: null,
          fromLocation: null,
          toLocation: null
        },
        {
          jobOrderId: 'PR07000000002',
          parentjobOrderId: 'JO01000000001',
          title: 'Part Request',
          type: 'Part Request',
          status: 'Issue',
          next_avaialable_steps_altText: 'Receive Parts',
          current_state_completed_text: 'Parts Issued',
          version: 1,
          createdBy: 'Chetan',
          createdDate: '28/04/2022T07:58:19.1130000Z',
          isLeaf: true,
          assignedTo: {
            firstName: null,
            lastName: null,
            email: null,
            url: null,
            profilePic: null
          },
          child: null,
          fromLocation: null,
          toLocation: null
        },
        {
          jobOrderId: 'PR07000000003',
          parentjobOrderId: 'JO01000000001',
          title: 'Part Request',
          type: 'Part Request',
          status: 'StoreAck',
          next_avaialable_steps_altText: 'Issue Parts',
          current_state_completed_text: 'Acknowledged at Store',
          version: 1,
          createdBy: 'Chetan',
          createdDate: '01/05/2022T17:49:26.5930000Z',
          isLeaf: true,
          assignedTo: {
            firstName: null,
            lastName: null,
            email: null,
            url: null,
            profilePic: null
          },
          child: null,
          fromLocation: null,
          toLocation: null
        }
      ],
      fromLocation: null,
      toLocation: null
    }
  ]
};

export const WOTypes = [
  {
    workflowName: 'Preventive Maintenance',
    workflowTemplateId: 1,
    Type: 'Preventive Maintenance',
    version: 1,
    onlyChild: 0
  },
  {
    workflowName: 'Break Down',
    workflowTemplateId: 2,
    Type: 'Break Down',
    version: 1,
    onlyChild: 0
  },
  {
    workflowName: 'Vendor to Store',
    workflowTemplateId: 4,
    Type: 'Vendor to Store',
    version: 1,
    onlyChild: 0
  },
  {
    workflowName: 'Part Request',
    workflowTemplateId: 7,
    Type: 'Part Request',
    version: 1,
    onlyChild: 1
  },
  {
    workflowName: 'Part Return',
    workflowTemplateId: 8,
    Type: 'Part Return',
    version: 1,
    onlyChild: 1
  },
  {
    workflowName: 'Part Request',
    workflowTemplateId: 13,
    Type: 'Part Request',
    version: 1,
    onlyChild: 1
  },
  {
    workflowName: 'SET UP/Convert',
    workflowTemplateId: 14,
    Type: 'SET UP/Convert',
    version: 1,
    onlyChild: 0
  },
  {
    workflowName: 'Qualify & Testing',
    workflowTemplateId: 15,
    Type: 'Qualify & Testing',
    version: 1,
    onlyChild: 0
  }
];
export const filterData = {
  Equipment: {
    data: {
      tree: [
        {
          id: 1,
          groupTitle: 'Process',
          type: 'object',
          selectionType: 'singleselect',
          required: 'true',
          attribute: 'Process',
          values: [
            {
              id: 'P1',
              name: 'E.HGA',
              type: 'object',
              selectionType: 'singleselect',
              required: 'false',
              attribute: 'Sub Process',
              values: [
                {
                  id: 'P1_1026',
                  name: 'Assembly',
                  type: 'object',
                  selectionType: 'singleselect',
                  required: 'false',
                  attribute: 'Equipment Type',
                  values: [
                    {
                      id: 'P1_2886',
                      name: 'ADU',
                      type: 'multiselect',
                      selectionType: 'singleselect',
                      required: 'false',
                      attribute: 'Equipment',
                      values: [
                        {
                          id: 'P1_3108',
                          name: '2AVI0401'
                        },
                        {
                          id: 'P1_3109',
                          name: '2AVI0402'
                        },
                        {
                          id: 'P1_3110',
                          name: '2AVI0403'
                        },
                        {
                          id: 'P1_3112',
                          name: '2AVI0405'
                        }
                      ]
                    },
                    {
                      id: 'P1_2887',
                      name: 'Tester',
                      type: 'multiselect',
                      selectionType: 'singleselect',
                      required: 'false',
                      attribute: 'Equipment',
                      // values: [
                      //   {
                      //     id: 'P1_5321',
                      //     name: '2DAT18_F01',
                      //     type: 'multiselect',
                      //     selectionType: 'singleselect',
                      //     required: 'false',
                      //     attribute: 'Equipment',
                      values: [
                        {
                          id: 'P1_3136',
                          name: '2DAT1804'
                        }
                      ]
                      //   }
                      // ]
                    }
                  ]
                },
                {
                  id: 'P1_1045',
                  name: 'Tester',
                  type: 'object',
                  selectionType: 'singleselect',
                  required: 'false',
                  attribute: 'Equipment Type',
                  values: [
                    {
                      id: 'P1_2032',
                      name: '1PLM01_F02',
                      type: 'multiselect',
                      selectionType: 'singleselect',
                      required: 'false',
                      attribute: 'Equipment',
                      values: [
                        {
                          id: 'P1_2033',
                          name: '1PLM0112'
                        },
                        {
                          id: 'P1_2034',
                          name: '1PLM0111'
                        },
                        {
                          id: 'P1_2035',
                          name: '1PLM0110'
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              id: 'P2',
              name: 'D. SDET',
              type: 'object',
              selectionType: 'singleselect',
              required: 'false',
              attribute: 'Sub Process',
              values: [
                {
                  id: 'P1_2887',
                  name: 'Tester',
                  type: 'object',
                  selectionType: 'singleselect',
                  required: 'false',
                  attribute: 'Equipment Type',
                  values: [
                    {
                      id: 'P1_5321',
                      name: '2DAT18_F01',
                      type: 'multiselect',
                      selectionType: 'singleselect',
                      required: 'false',
                      attribute: 'Equipment',
                      values: [
                        {
                          id: 'P1_3136',
                          name: '2DAT1804'
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    resultType: 'iframe',
    message: 'Success'
  },

  'Spare parts': {
    data: {
      tree: [
        {
          id: 1,
          groupTitle: 'Location',
          type: 'object',
          selectionType: 'multiselect',
          required: 'true',
          attribute: 'Location',
          values: [
            {
              id: 'P1',
              name: 'HGA',
              type: 'object',
              selectionType: 'multiselect',
              required: 'false',
              attribute: 'Sub Location',
              values: [
                {
                  id: 'P1_1026',
                  name: 'CH_A4',
                  type: 'multiselect',
                  selectionType: 'multiselect',
                  required: 'false',
                  attribute: 'Main Part Number',
                  values: [
                    {
                      id: 'P1_3108',
                      name: 'TLES00103001'
                    },
                    {
                      id: 'P1_3109',
                      name: 'TLES00103002'
                    },
                    {
                      id: 'P1_3110',
                      name: 'TLES00103003'
                    }
                  ]
                },
                {
                  id: 'P1_2045',
                  name: 'B2L2R15',
                  type: 'multiselect',
                  selectionType: 'multiselect',
                  required: 'false',
                  attribute: 'Main Part Number',
                  values: [
                    {
                      id: 'P1_2033',
                      name: 'THAS00203001'
                    },
                    {
                      id: 'P1_2034',
                      name: 'THAS00203002'
                    },
                    {
                      id: 'P1_2035',
                      name: 'THAS00203003'
                    }
                  ]
                },
                {
                  id: 'P1_1045',
                  name: 'B2L1TE-C1B',
                  type: 'multiselect',
                  selectionType: 'multiselect',
                  required: 'false',
                  attribute: 'Main Part Number',
                  values: [
                    {
                      id: 'P1_2033',
                      name: 'EYPS00103001'
                    },
                    {
                      id: 'P1_2034',
                      name: 'EYPS00103002'
                    },
                    {
                      id: 'P1_2035',
                      name: 'EYPS00103001'
                    }
                  ]
                }
              ]
            },
            {
              id: 'P2',
              name: 'TE',
              type: 'object',
              selectionType: 'multiselect',
              required: 'false',
              attribute: 'Sub Location',
              values: [
                {
                  id: 'P1_2887',
                  name: 'B1133YE',
                  type: 'multiselect',
                  selectionType: 'multiselect',
                  required: 'false',
                  attribute: 'Main Part Number',
                  values: [
                    {
                      id: 'P1_3136',
                      name: 'THAS002020023'
                    },
                    {
                      id: 'P1_3223',
                      name: 'THAS002020025'
                    },
                    {
                      id: 'P1_3230',
                      name: 'THAS002020026'
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    resultType: 'iframe',
    message: 'Success'
  },
  2005: {
    data: {
      tree: [
        {
          id: 1,
          groupTitle: 'Location',
          type: 'object',
          selectionType: 'multiselect',
          required: 'true',
          attribute: 'Location',
          values: [
            {
              id: 'P1',
              name: 'Location 1',
              type: 'object',
              selectionType: 'multiselect',
              required: 'false',
              attribute: 'Store',
              values: [
                {
                  id: 'P1_1026',
                  name: 'Gaspar',
                  type: 'multiselect',
                  selectionType: 'multiselect',
                  required: 'false',
                  attribute: 'Category',
                  values: [
                    {
                      id: 'P1_3108',
                      name: 'Category 1'
                    },
                    {
                      id: 'P1_3109',
                      name: 'Category 2'
                    },
                    {
                      id: 'P1_3110',
                      name: 'Category 3'
                    },
                    {
                      id: 'P1_3112',
                      name: 'Category 4'
                    }
                  ]
                },
                {
                  id: 'P1_2021',
                  name: 'Tristan',
                  type: 'multiselect',
                  selectionType: 'multiselect',
                  required: 'false',
                  attribute: 'Category',
                  values: [
                    {
                      id: 'P1_3123',
                      name: 'Category 5'
                    },
                    {
                      id: 'P1_3231',
                      name: 'Category 6'
                    },
                    {
                      id: 'P1_4110',
                      name: 'Category 7'
                    },
                    {
                      id: 'P1_4312',
                      name: 'Category 8'
                    }
                  ]
                },
                {
                  id: 'P1_1526',
                  name: 'Avalen',
                  type: 'multiselect',
                  selectionType: 'multiselect',
                  required: 'false',
                  attribute: 'Category',
                  values: [
                    {
                      id: 'P1_5558',
                      name: 'Category 9'
                    },
                    {
                      id: 'P1_3109',
                      name: 'Category 10'
                    },
                    {
                      id: 'P1_3110',
                      name: 'Category 11'
                    }
                  ]
                }
              ]
            },
            {
              id: 'P2',
              name: 'Location 2',
              type: 'object',
              selectionType: 'multiselect',
              required: 'false',
              attribute: 'Store',
              values: [
                {
                  id: 'P1_2897',
                  name: 'Tester',
                  type: 'multiselect',
                  selectionType: 'multiselect',
                  required: 'false',
                  attribute: 'Category',
                  values: [
                    {
                      id: 'P1_114',
                      name: 'Category 20'
                    },
                    {
                      id: 'P1_113',
                      name: 'Category 21'
                    },
                    {
                      id: 'P1_110',
                      name: 'Category 22'
                    },
                    {
                      id: 'P1_112',
                      name: 'Category 23'
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    resultType: 'iframe',
    message: 'Success'
  }
};

export const metaColumns = [
  {
    key: 'jobOrderId',
    title: 'Work Order #',
    searchable: true,
    sortable: false,
    visible: true,
    type: 'string'
  },
  {
    key: 'title',
    title: 'Type',
    searchable: false,
    sortable: false,
    visible: true,
    type: 'string',
    options: [
      {
        label: 'Preventive Maintenance',
        workflowTemplateId: 1,
        value: 'Preventive Maintenance',
        version: 1,
        onlyChild: 0
      },
      {
        label: 'Break Down',
        workflowTemplateId: 2,
        value: 'Break Down',
        version: 1,
        onlyChild: 0
      },
      {
        label: 'Vendor to Store',
        workflowTemplateId: 4,
        value: 'Vendor to Store',
        version: 1,
        onlyChild: 0
      },
      {
        label: 'Part Request',
        workflowTemplateId: 7,
        value: 'Part Request',
        version: 1,
        onlyChild: 1
      },
      {
        label: 'Part Return',
        workflowTemplateId: 8,
        value: 'Part Return',
        version: 1,
        onlyChild: 1
      }
    ]
  },
  {
    key: 'assignedTo',
    title: 'Assigned To',
    searchable: false,
    sortable: false,
    visible: true,
    type: 'User',
    options: [
      { id: 1, label: 'vinay' },
      { id: 2, label: 'chetan' }
    ]
  },
  {
    key: 'current_state_completed_text',
    title: 'Last Completed Step',
    searchable: true,
    sortable: false,
    visible: true,
    type: 'string',
    isCustomSort: true,
    sortOrder: ['created', 'started', 'assigned', 'reassigned', 'closed']
  },
  {
    key: 'next_avaialable_steps_altText',
    title: 'Next Available Step(s)',
    searchable: true,
    sortable: false,
    visible: true,
    type: 'string'
  },
  {
    key: 'createdDate',
    title: 'Created Date',
    searchable: false,
    sortable: true,
    visible: true,
    type: 'DateTime'
  }
];

export const assetMetaCol = {
  count: 2121,
  page: 1,
  pagesize: 10,
  filteredBy: [
    {
      Site: 'THO',
      BU: null,
      Process: null,
      MC: null,
      Category: null,
      Location: null
    }
  ],
  columns: [
    {
      key: 'partName',
      title: 'Part Name',
      searchable: true,
      sortable: false,
      visible: true,
      type: 'string'
    },
    {
      key: 'mainPartNumber',
      title: 'Main Part #',
      searchable: false,
      sortable: false,
      visible: true,
      type: 'string',
      options: [
        {
          label: 'Preventive Maintenance',
          workflowTemplateId: 1,
          value: 'Preventive Maintenance',
          version: 1,
          onlyChild: 0
        },
        {
          label: 'Preventive Maintenance',
          workflowTemplateId: 1,
          value: 'Preventive Maintenance',
          version: 1,
          onlyChild: 0
        },
        {
          label: 'Preventive Maintenance',
          workflowTemplateId: 1,
          value: 'Preventive Maintenance',
          version: 1,
          onlyChild: 0
        }
      ]
    },
    {
      key: 'location',
      title: 'Location',
      searchable: true,
      sortable: false,
      visible: true,
      type: 'string'
    },
    {
      key: 'qtyAvailable',
      title: 'Qty Available',
      searchable: false,
      sortable: false,
      visible: true,
      type: 'string'
    },
    {
      key: 'imageUrl',
      title: 'Image',
      searchable: false,
      sortable: false,
      visible: true,
      type: 'Image'
    }
  ]
};
