/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
// import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import TabsTable from '../../Common/Tabs_Table';
import { PlanCreators } from '../../../views/Plan/store';
import { isEmpty } from '../../../shared/utility/isEmpty';
import { getMonthFromActiveRange } from '../../Common/Calendar/utility';

const PlanListView = (props) => {
  const { loading, error, searchQueries, planList, planColumn, activeRange } = useSelector((state) => state.plansData);
  const {
    calendarRef,
    // : rawActiveRange,
    handleDetails
  } = props;
  // const activeRange = useMemo(() => JSON.parse(rawActiveRange), [rawActiveRange]);
  const calendarApi = calendarRef.current?.getApi();
  const pageSize = 20;

  const dispatch = useDispatch();
  const saveSearchQueries = (payload) => {
    dispatch(PlanCreators.setSearchQueries(payload));
  };

  const handlePageChange = (page) => {
    const lang = 'en';
    const month = getMonthFromActiveRange(activeRange?.start);
    let year = moment(activeRange?.start)?.year();
    if (month === 1) {
      year += 1;
    }
    if (!isEmpty(calendarApi)) {
      dispatch(PlanCreators.getPlanList({ page, pageSize, lang, month, year, searchQueries }));
    }
  };
  useEffect(() => {
    handlePageChange(1);
  }, [activeRange, searchQueries]);

  return (
    <ListContainer>
      <TabsTable
        className='noBorder'
        handlePageChange={handlePageChange}
        handleTabSelect={() => {}}
        pageSize={pageSize}
        metaDetails={planColumn}
        dataDetails={planList}
        assetListLoading={loading}
        onItemSelect={handleDetails}
        showShortlistSelection={false}
        showPagiation={true}
        saveSearchQueries={saveSearchQueries}
        savedSearchQueries={searchQueries}
        resetSearchQueries={saveSearchQueries}
        error={error}
        //   rowOptions={rowOptions}
        //   handleRowOptionsItemClick={handleRowOptionsItemClick}
      />
    </ListContainer>
  );
};
export default PlanListView;

const ListContainer = styled.div`
  z-index: 100;
  position: relative;
  margin-top: -4rem;
`;
