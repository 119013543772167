import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { BodyContainer, Header, HeaderContainer } from './BulkList.styles';
import TabsTable from '../../../component/Common/Tabs_Table';
import { useActions } from '../../../store/hooks/useActions';
import { Creators as SpareDetailsCreators } from './store';
import { WebService } from '../../../services/axios/webServices';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';
import Toast from '../../../component/Common/Toast';
import { fetchUserAndLocation } from '../../../component/Sidenav/fetchPageStatistics';

const BulkList = () => {
  const { getBulkDocumentList } = useActions();
  const dispatch = useDispatch();
  const {
    sparesSearchQueries,
    bulkDocumentListMeta,
    bulkDocumentListData,
    isLoading: bulkListLoading
  } = useSelector((state) => state.spareDetails);
  const [pageSize, setPageSize] = useState(20);
  const [error, setError] = useState();
  // const pageSize = 20;

  const setAssetSearchQueries = (searchQueries) => {
    dispatch(SpareDetailsCreators.setSparesSearchQueries(searchQueries));
  };

  const resetSearchQueries = () => {
    dispatch(SpareDetailsCreators.resetSparesSearchQueries());
  };

  const handlePageChange = (page, rest = {}) => {
    const { searchParams } = rest;
    getBulkDocumentList({ page, pageSize, searchParams });
  };

  useEffect(() => {
    handlePageChange(1);
  }, [pageSize]);

  const { userData } = useSelector((state) => state.loginData);
  const { finalMenu } = useSelector((state) => state.home);
  const location = useLocation();

  useEffect(() => {
    const name = finalMenu.find((ele) => ele.path === location.pathname);
    fetchUserAndLocation(location.pathname, userData, {
      pageType: name?.name
    });
  }, []);

  /**
   *Function which receives url and makes call to api to download filename
   * @param {*} url
   * @returns blob with data to be downloaded
   */
  function toDataURL(url) {
    return WebService.get(url, { responseType: 'blob' })
      .then((response) => {
        const blob = new Blob([response.data], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            'application/vnd.ms-excel'
        });
        // response.blob();
        return blob;
      })
      .then((blob) => URL.createObjectURL(blob));
  }

  /**
   * Function Which is called on  click of Template name for downloading
   */
  const downloadFile = async (entry) => {
    try {
      const fullUrl = `${entry?.FileDownloadlink}`;
      // eslint-disable-next-line prefer-const, object-shorthand
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = await toDataURL(fullUrl);
      a.download = entry?.filename.replace(/[/?:><*|]/g, '');
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (err) {
      const payload = sagaCatchBlockHandling(err);
      setError(payload?.error);
    }
  };

  /**
   * Function which makes api call to the log file api
   * @param {*} url
   * @returns data from the api response
   */
  const readFileText = (url) =>
    WebService.get(url).then((response) => {
      if (response.status !== 200) {
        throw new Error(`Network response was not ok ${response.statusText}`);
      }
      return response.data; // Read the response as text
    });

  /**
   * Function to be called on click of log file
   * @param {*} entry
   */
  const showLogs = async (entry) => {
    try {
      const data = await readFileText(entry?.logfile);
      const win = window.open(
        '',
        '',
        'popUpWindow',
        'height=800,width=1600,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes'
      );
      // const check = document.getElementById('logs');
      const content = document.createTextNode(data);
      win.document.write(`<h>${data}</h>`);
      win.document.title = entry.filename;
      // check.appendChild(content);
    } catch (err) {
      const payload = sagaCatchBlockHandling(err);
      setError(payload?.error);
    }
  };

  return (
    <div>
      <HeaderContainer>
        <Header>List of Requests</Header>
      </HeaderContainer>
      <BodyContainer>
        <TabsTable
          metaDetails={bulkDocumentListMeta}
          dataDetails={bulkDocumentListData}
          showPagiation={true}
          showShortlistSelection={false}
          handlePageChange={handlePageChange}
          styleType='spareTable'
          noItemsFoundMessage='No Data Found'
          showRowOptionAtStart={false}
          id='UserID'
          onItemSelect={() => {}}
          onLogsClick={showLogs}
          onHyperLinkSelect={downloadFile}
          saveSearchQueries={setAssetSearchQueries}
          savedSearchQueries={sparesSearchQueries}
          resetSearchQueries={resetSearchQueries}
          pageSize={pageSize}
          setPageSize={setPageSize}
          assetListLoading={bulkListLoading}
        />
        {error && (
          <Toast
            message={error}
            header='Error'
            style={{ right: 30 }}
            fn={() => setError('')}
          />
        )}
      </BodyContainer>
    </div>
  );
};

export default BulkList;
