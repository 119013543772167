import styled from 'styled-components';
import { fontSize, fontWeight } from '../../constants';

export const TabsContainer = styled.div`
  display: flex;

  border-bottom: 1px solid Black;

  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const Tab = styled.div`
  padding: 2rem 1.5rem;
  text-transform: capitalize;
  cursor: ${({ enabled }) => (enabled ? 'pointer' : 'not-allowed')};
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  &:first-child {
    padding-left: 0.5rem !important;
  }

  & > * {
    opacity: ${({ enabled }) => (enabled ? 1 : 0.5)};
  }

  &:after {
    content: '';
    height: ${(props) => (props.isActive ? '3px' : '0')};
    width: 60%;
    position: absolute;
    bottom: 0;
    diplay: flex;
    justify-content: center;

    background: ${({ theme }) => theme.contrast.quaternary};
    border-radius: 3px;
  }
`;

export const NotificationSpan = styled.span`
  margin-left: 1rem;
  margin-right: -0.6rem !important;

  height: 16px;
  width: 18px;

  background: #434a5e;
  border-radius: 50%;
  padding: 2px 6px;
  text-align: center;
  color: #a2a7b5;
  font-size: 1rem;

  position: absolute;
  top: 10px;
  right: 0;
`;

export const P = styled.p`
  font-size: ${fontSize.mediumDefault};
  font-weight: ${fontWeight.medium};
  color: black;
`;
