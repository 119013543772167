import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  setTags: ['payload'],
  setCummulativeTags: ['payload'],
  setSingleTag: ['payload'],
  setSelectedTabs: ['payload'],
  deletingTags: ['payload'],
  setSelectedItem: ['payload'],
  universalFilterSetter: ['payload'],
  resetFilterState: ['payload'],
  clearSelectedFilter: ['payload'],

  getFilterData: ['payload', 'dependencyPayload'],
  getFilterDataStart: ['payload'],
  getFilterDataSuccess: ['payload'],
  getFilterDataFail: ['payload'],

  getFilterDependencyDataStart: ['payload'],
  getFilterDependencyDataSuccess: ['payload'],
  getFilterDependencyDataFail: ['payload'],

  getIFrameData: ['payload'],
  getIFrameDataStart: ['payload'],
  getIFrameDataSuccess: ['payload'],
  getIFrameDataFail: ['payload'],
  // setSingleSelectionGroups: ['payload'],

  populateFilterTags: ['payload'],
  populateFilterSelectedObjs: ['payload'],
  setMenuIdOfFilter: ['payload'],
  setPartNumber: ['payload'],
  handleFilterChange: ['payload'],

  populateFilterData: ['payload']

  // addTags: ['payload'],
  // addCummulativeTags: ['payload']
});

export { Types, Creators };
