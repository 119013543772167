import styled from 'styled-components';

// export const BodyContainer = styled.div`
//   margin: 2rem;
//   background: ${({ theme }) => theme.body};
//   height: calc(100% - 8rem - 4rem); // header margin
//   box-shadow: -5px -5px 11px #fffffff5;
//   border-radius: 10px;
//   padding: 5rem 4rem;
// `;

export const Divider = styled.div`
  border-bottom: 1.5px dashed #707070;
  margin-bottom: 2rem;
`;
