/* eslint-disable max-len */
// const a = { searchable: false, sortable: false, hasOptions: false, isDateTime: true };

export const OPTIONS_TYPES = {
  IS_DATE_TIME: 'isDateTime',
  HAS_OPTIONS: 'hasOptions',
  SEARCHABLE: 'searchable',
  SORTABLE: 'sortable'
};

export const isOptionVisible = (optionType, optionsProvided) => {
  const { searchable, sortable, options, type } = optionsProvided;
  const hasOptions = options.length > 0;
  const isDateTime = type === 'DateTime';
  // console.log('optionsProvided', optionType);

  // based on priority the conditions are written in order
  if (isDateTime && searchable) {
    return optionType === 'isDateTime';
  }
  if (hasOptions) {
    return optionType === 'hasOptions';
  }
  if (searchable) {
    return optionType === 'searchable';
  }
  return optionsProvided[optionType];
};

export const curryIsOptionsVisible = (optionsVisible) => (optionType) => isOptionVisible(optionType, optionsVisible);

export const isRowMatched = (item1, item2) =>
  Object.entries(item1).every((e) => {
    const [colKey, colValue] = e;
    // console.log('aa', colKey, item2[colKey], colValue);
    return item2[colKey] === colValue;
  });
