/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Creators as FilterCreators } from '../../../../views/Filters/store';
import useResetDisableNext from '../hooks/usetResetDisableNext';
import { WO_TYPES } from '../../../../constants';
import useFilteredSelectedItem from '../../../Filter/hooks/useFilteredSelectedItem';
import Loader from '../../../Loader/Loader';
import { Div, FlexContainer } from '../../../../globalStyles';
import { Search } from '../../..';
import FilterContainer from '../../../Filter/FilterContainer/FilterContainer';
import FilterTagArea from '../../../Filter/FilterTagArea/FilterTagArea.view';
import Header from '../Header';
import DynamicSVGIcon from '../../../Common/DynamicSVGIcon';
import DropArrow from '../../../../assets/icons/layout/dropdownarrow.svg';
import { HeaderText } from '../../WorkOrderDetails/WorkOrderDetails.style';
import { Bttn } from '../../../../views/WorkOrders/WorkOrders.styles';
import { apiEndPoints } from '../../../../services/axios/endPoints';
import ShowAllTheFilter from '../../../ResultView/ShowAllTheFilter';
import { generateSelectedFilter } from '../../../ResultView/Iframe/generateSelectedFilter';
import { WorkOrderCreators } from '../../../../views/WorkOrders/store';
import CartButton from '../../../Common/CartButton/CartButton';
import FilterSearchComponent from '../../../Filter/FilterSearchComponent/FilterSearchComponent';

const Step2 = ({ setDisableNext, menuId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // useResetDisableNext(setDisableNext);
  const {
    showFilterResult,
    menuIdofFilter,
    loading,
    resultType,
    cumulativeTags,
    selectedParent,
    assetType,
    filterData,
    inputs
  } = useSelector((state) => state.filterData);
  const planDetails = useSelector((state) => state.plansData.planDetails);
  const { type, workOrderCreationFrom } = useSelector((state) => state.upsertWorkOrder);
  // const [showResult, setShowResult] = useState(false);

  useEffect(() => {
    dispatch(FilterCreators.setMenuIdOfFilter(menuId));
    dispatch(
      FilterCreators.getFilterData({
        menuId,
        // templateID: type.workflowTemplateId,
        URLparam: `${apiEndPoints.getFilterByWOType}/${type.workflowTemplateId}`
      })
    );
  }, [menuIdofFilter]);

  // useFilteredSelectedItem(setDisableNext);
  const isDisabled = useFilteredSelectedItem();

  const handleNext = () => {
    if (resultType) {
      dispatch(
        FilterCreators.universalFilterSetter({
          key: 'showFilterResult',
          value: true
        })
      );
      // setShowResult(true);
    } else {
      const formatedTags = generateSelectedFilter(cumulativeTags, filterData, true);

      // console.log('location', location);
      const payload = {
        menuid: menuIdofFilter,
        tab: selectedParent,
        data: formatedTags,
        inputs: { ...inputs }
      };
      // console.log('iframe payload', payload);
      // dispatch(WorkOrderCreators.resetUserData());
      dispatch(
        WorkOrderCreators.getUserDataByTemplateId({
          WFTid: type.workflowTemplateId,
          assetType,
          workOrderCreationFrom,
          scheduleId: planDetails.scheduleId,
          selections: { data: { ...formatedTags }, inputs: { ...inputs } }
        })
      );
      history.push('/home/work_orders/create_WO/form');
    }
  };

  if (showFilterResult) return <ShowAllTheFilter isFullHeight={true} />;
  if (loading) return <Loader />;
  return (
    <>
      <Header>
        {/*  name='Next' onClick={handleNext} disableNext={isDisabled} */}
        <FlexContainer className='ai_center'>
          <Bttn className='white' onClick={() => history.goBack()}>
            Cancel
          </Bttn>
          <Bttn disabled={isDisabled} onClick={handleNext}>
            Next
          </Bttn>
        </FlexContainer>
      </Header>

      <FlexContainer
        px='4'
        className='col'
        style={{
          position: 'relative'
        }}
      >
        <FlexContainer className='ai_center'>
          <p style={{ fontSize: '2rem' }}>{`Select ${type.workflowName}`}</p>
          <Div style={{ width: '250px' }} mx='1.5'>
            <FilterSearchComponent canToggleFilter={false} />
            {/* placeholder={`Search for ${type.workflowName}`} */}
          </Div>
        </FlexContainer>
        <Div>
          <FilterTagArea showOnlyTags={true} />
          <FilterContainer />
        </Div>
      </FlexContainer>
    </>
  );
};

export default Step2;
