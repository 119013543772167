import styled from 'styled-components';
import { Input } from '../Input/Input';
import { ContentText } from './KeyValueLayout.style';
import { device } from '../../constants';

export const Div = styled.div`
  display: flex;
  /* margin-top: 2rem;
  margin-bottom: 3rem; */
  margin: 2rem 0rem;
  width: 80%;
  align-items: center;
  /* background: red; */

  // for nested key value layout
  &.nested {
    /* border: 10px solid green; */
    margin: 0rem;
    margin-left: 1rem;
    width: max(50%, 30rem);
    ${ContentText} {
      width: auto;
      /* background: yellow; */
    }
  }
  &.editable {
   width: 150%;
  }
  /* inputs should be disabled when not in active state */
  &.disable-children .can-disable-input,
  &.disable-children ${Input} {
    pointer-events: none;
    opacity: 0.4;
  }
  @media ${device.tablet} {
    width: 100%;
  }
`;
