/* eslint-disable max-len */
import { put } from 'redux-saga/effects';
import { Creators } from './action';
import { WebService } from '../../../services/axios/webServices';
import { apiEndPoints } from '../../../services/axios/endPoints';

export function* getActivitySaga({ payload }) {
  const { page, pageSize, type, lang, searchQueries } = payload;
  try {
    yield put(Creators.getActivityStart());

    const endpoint = `${payload.endpoint}/${lang || 'en'}/${page || 1}/${
      pageSize || 10
    }`;
    const response = yield WebService.post(endpoint, {
      SearchFilters: { ...searchQueries }
    });
    if (response.status >= 200 && response.status < 300) {
      // console.log(response.data);
      const { data, meta } = response.data;

      yield put(
        Creators.getActivitySuccess({ meta, activityData: data || [] })
      );
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message || error.response.data.Message
          };
          yield put(Creators.getActivityFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getActivityFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.getActivityFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getActivityFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        // error: error.message
        error: 'No connection try again Later.'
      };

      yield put(Creators.getActivityFailure(payload));
    }
  }
}

export function* getActivityDetailsSaga({ payload }) {
  const { activityId } = payload;

  try {
    yield put(Creators.getActivityDetailsStart());

    const fullURL = yield `${apiEndPoints.getActivityDetails}${activityId}`;
    const response = yield WebService.get(fullURL);
    if (response.status >= 200 && response.status < 300) {
      const activity = response.data.data;
      yield put(Creators.getActivityDetailsSuccess(activity));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message || error.response.data.Message
          };

          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };

          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };

          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
      }
    } else {
      // for custom error
      payload = {
        error: 'No connection try again Later.'
        // error: 'custom error'
      };
    }
    yield put(Creators.getActivityDetailsFailure(payload));
  }
}
