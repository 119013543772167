/* eslint-disable max-len */
import React from 'react';
import { INPUT_TYPES } from '../../../constants';
import { Span } from '../../../globalStyles';

const ToggleableFieldValue = (props) => {
  const { value: valueByDefault, isEditable = true, children, type } = props;
  let value = valueByDefault || '-';
  // console.log('key', valueByDefault, !isEditable && type === INPUT_TYPES.CHECKBOX, Array.isArray(valueByDefault));
  if (type === INPUT_TYPES.PASSWORD) {
    value = new Array(valueByDefault?.length || 0).fill('*').join('') || '-';
  }
  if (type === INPUT_TYPES.TOGGLE) {
    value = valueByDefault ? 'true' : 'false';
  } else if (type === INPUT_TYPES.DROPDOWN || (typeof valueByDefault === 'object' && !Array.isArray(valueByDefault))) {
    value = valueByDefault?.label || valueByDefault;
  } else if ((!isEditable && type === INPUT_TYPES.CHECKBOX) || Array.isArray(valueByDefault)) {
    value = (
      <ul>
        {valueByDefault?.map((e) => (
          <li>
            <Span>{e.label || valueByDefault}</Span>
          </li>
        ))}
      </ul>
    );
  }
  return <>{isEditable ? children : <Span>{value}</Span>}</>;
};

export default ToggleableFieldValue;
