/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import { ContentContainer, Image, Header, Message, ToastContainer } from './Toast.style';
import { errorToast, successToast } from '../../../assets/icons';

const Toast = ({ message, header, fn = () => {}, style = { bottom: 30 }, shouldFade = true, className }) => {
  const toastRef = useRef();

  useEffect(() => {
    if (shouldFade) {
      toastRef.current.onanimationend = () => {
        fn();
      };
    }
    return () => {
      if (message && shouldFade) {
        fn();
      }
    };
  }, []);

  return (
    <ToastContainer style={style} ref={toastRef} className={`${className} ${shouldFade ? 'fade' : 'show'}`}>
      {header === 'Error' ? (
        <Image src={errorToast} alt='error' />
      ) : (
        header === 'Success' && <Image src={successToast} alt='success' />
      )}
      <ContentContainer>
        <Header>{header}</Header>
        <Message>{message}</Message>
      </ContentContainer>
    </ToastContainer>
  );
};

Toast.propTypes = {
  message: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  fn: PropTypes.func.isRequired
};

export default memo(Toast);
