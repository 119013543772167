/* eslint-disable max-len */
import PlanDetails from '../../component/Plan/PlanDetails/PlanDetails';
// import AddScheduleType from '../../component/ScheduleType/AddScheduleType/AddScheduleTyoe';
// import EditScheduleType from '../../component/ScheduleType/EditScheduleType/EditScheduleType';

const PlanRoutes = [
  { path: '/home/plan/details/:planId', exact: true, name: 'schedule-type-details', Component: PlanDetails }
  // { path: '/home/plan/edit', exact: true, name: 'editScheduleType', Component: EditScheduleType }
];

export default PlanRoutes;
