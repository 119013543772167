import styled from 'styled-components';
import { fontSize } from '../../../../constants';
import { Button } from '../../../../globalStyles';

export const ItemsNo = styled.span`
  background-color: ${({ theme }) => theme.contrast.tertiary};
  border-radius: 8px;
  width: ${fontSize.title};
  color: black;
  font-size: ${fontSize.title};
  position: absolute;
  top: -10px;
  right: 10px;
`;

export const Bttn = styled(Button)`
  width: 20rem;
  margin: 0 1rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5rem;
`;
