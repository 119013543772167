import styled from 'styled-components';
import { fontSize } from '../../constants';
import { Table, Td as TdTemplate } from '../../component/Common/Table/SpacedRows.styles';

export const TableContainerTemplate = styled.div`
  border-radius: 1rem;
  padding-left: 4rem;
  overflow-y: auto;
`;

export const RowTemplate = styled.tr`
  display: flex;
  width: 100%;
  /* text-align: center; */

  /* &.pointer > * {
    cursor: pointer;
  } */

  /* &.header {
    border-bottom: 1px dashed ${({ theme }) => theme.contrast.lightSecondary};
    background-color: none;
  } */

  /* &.child {
    padding-left: 1.5rem;
  } */
`;

export const CellTemplate = styled.td`
  font-size: 1.8rem;
  min-width: 3rem;

  &.trunCate {
    /* max-width: calc(100% / 5) * 2; */
    max-width: 32rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    /* width: 2rem; */
  }

  /* position: relative;
  
  /* & p {
    font-size: 1.8rem;
  } */
  padding: 4px 4px;

  /* border-right: ${({ border, theme }) => (border ? `1px dashed ${theme.contrast.lightSecondary}` : '0px')}; */
  color: ${({ theme }) => theme.contrast.lightQuaternary};
  text-transform: capitalize;
  text-align: center;

  /* flex-wrap: nowrap; */
  /* max-height: 80px; */
  /* min-width: 8rem; */
  /* flex: 1; */
  /* flex-basis: ${({ width }) => `${width}%`}; */
  /* flex-shrink: 0; */
  /* flex-grow: 1; */
  /* order: ${({ order }) => order}; */
  /* overflow: hidden; */
  /* display: flex; 
  flex-direction: row;
  align-items: center; */
  */
  /* justify-content: ${({ align }) => (!align ? 'center' : '')}; */

  & div {
    font-size: 1.8rem;
    color: ${({ theme }) => theme.contrast.lightQuaternary};
    text-transform: capitalize;
  }

  &.colHeading div {
    color: ${({ theme }) => theme.contrast.darkSecondary};
  }

  &:not(:last-child) {
    border-right: 1px dashed ${({ theme }) => theme.contrast.lightSecondary};
  }

  &:last-child {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
  &:first-child {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    position: relative;
    overflow: visible;
    & > div {
    }
  }

  &.active {
    background-color: ${({ theme }) => theme.aux.darkSecondary};
  }

  &.td {
    background-color: ${({ theme }) => theme.core.pureSecondary};
    margin: 1rem 0;
    height: 50px;
  }

  &.colHeading {
    overflow: visible;
    position: relative;
    display: flex;
    justify-content: center;
  }
`;

export const Title = styled.p`
  font-size: ${fontSize.title};
  margin: 0 0.5rem;
  text-align: center;
`;
export const Btn = styled.div`
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  position: absolute;
  /* left: -91px; */
  /* top: 1.5rem; */
  top: 0;
  bottom: 0;
  margin: auto;
  left: -41px;

  &:hover {
    border-radius: 10px;
    background: white;
  }
`;

export const CellText = styled.div`
  /* display: block; */
  /* overflow: auto; */
  text-align: center;
  /* white-space: nowrap; */
`;
export const Tabel = styled(Table)``;

export const DateDropDown = styled.div`
  & > div {
    background: ${({ theme }) => theme.core.secondary};
  }
`;

export const Td = styled(TdTemplate)`
  padding: 5px;
  min-width: 4rem;
`;

export const Span = styled.span`
  text-align: center;
`;
