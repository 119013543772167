/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { help } from '../../../assets/icons';
import DynamicSVGIcon from '../DynamicSVGIcon';
import {
  ContentText,
  Span,
  DetailsContainer,
  LabelToolTip
} from './KeyValueLayout.style';
import ButtonComp from '../Button/Button.view';

const KeyValueLayout = ({
  label,
  value,
  tooltip,
  name,
  labelValue,
  isEditable = false,
  setShowEdit
  //  nonEditable
}) => (
  <DetailsContainer className='detail ai keyValuedetail' width='100%'>
    <ContentText className=' heading truncate'>
      {name}
      {label}
      {tooltip && (
        <div style={{ marginLeft: '3px' }}>
          <DynamicSVGIcon iconUrl={help} />
          <LabelToolTip className='top'>
            <ContentText className='truncate'>{tooltip}</ContentText>
          </LabelToolTip>
        </div>
      )}
    </ContentText>
    <Span>:</Span>
    <ContentText className='label ml truncate'>
      {value || ('-' && labelValue)}
      {isEditable && (
        <ButtonComp
          dark
          margin='0 0 0 20px'
          onClick={() => {
            setShowEdit(true);
          }}
        >
          Edit
        </ButtonComp>
      )}
    </ContentText>
  </DetailsContainer>
);

export default KeyValueLayout;
