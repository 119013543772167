/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import ButtonComp from '../../component/Common/Button/Button.view';
import HeaderComp from '../../component/Common/Header/Header';
import Pagination from '../../component/Common/Pagination/Pagination.view';
import TabsTable from '../../component/Common/Tabs_Table';
import { FlexContainer } from '../../globalStyles';
import { apiEndPoints } from '../../services/axios/endPoints';
import { SchedultTypesCreators } from './store/action';
import PaginationWithPage from '../../component/Common/Pagination/PaginationWithPage.view';
import { fetchUserAndLocation } from '../../component/Sidenav/fetchPageStatistics';

const rowOptions = [
  { id: 1, label: 'Edit', value: 'Edit' }
  // { id: 1, label: 'Delete', value: 'Delete' }
];
const SceduleType = () => {
  const { loading, error, searchQueries, scheduleList, scheduleListMeta } =
    useSelector((state) => state.scheduleTypesData);
  const dispatch = useDispatch();
  const pageSize = 20;
  const lang = 'en';
  const handlePageChange = (page) => {
    const endpoint = apiEndPoints.getScheduleType;
    dispatch(
      SchedultTypesCreators.getScheduleType({
        page,
        pageSize,
        endpoint,
        lang,
        searchQueries
      })
    );
  };

  useEffect(() => {
    handlePageChange(1);
  }, []);

  useEffect(() => {
    handlePageChange(1);
  }, [searchQueries]);

  const history = useHistory();
  const handleShowDetails = (rowItem) => {
    history.push({
      pathname: '/home/schedule-type/details',
      state: { details: rowItem, columns: scheduleListMeta?.columns }
    });
  };
  const saveSearchQueries = (payload) => {
    dispatch(SchedultTypesCreators.setSearchQueries(payload || {}));
  };

  const handleRowOptionsItemClick = (opt, addParams) => {
    // const { activeRowItem } = addParams;
    // console.log(opt, addParams);
    const payload = addParams.activeRowItem;
    // console.log(payload, 'sjjs');
    if (opt.value === 'Edit') {
      history.push('/home/schedule-type/edit');
      dispatch(SchedultTypesCreators.getScheduleTypeDetails(payload));
    }
  };

  const handleCreate = () => {
    history.push('/home/schedule-type/create');
  };

  const { userData } = useSelector((state) => state.loginData);
  const { finalMenu } = useSelector((state) => state.home);
  const location = useLocation();
  useEffect(() => {
    const name = finalMenu.find((ele) => ele.path === location.pathname);
    fetchUserAndLocation(location.pathname, userData, {
      pageType: name?.name
    });
  }, []);

  return (
    <>
      <HeaderComp hasBack={false} header='Schedule Type'>
        <FlexContainer>
          <ButtonComp
            style={{ width: window.innerWidth < 768 && '15rem' }}
            dark
            margin='0rem 2rem'
            onClick={handleCreate}
          >
            + Create Schedule Type
          </ButtonComp>
          <PaginationWithPage
            ItemsPerPage={pageSize}
            total={scheduleListMeta?.count}
            handlePageChange={handlePageChange}
            searchParams={searchQueries}
          />
        </FlexContainer>
      </HeaderComp>
      <TabsTable
        className='noBorder'
        handlePageChange={handlePageChange}
        handleTabSelect={() => {}}
        pageSize={pageSize}
        metaDetails={scheduleListMeta}
        dataDetails={scheduleList}
        assetListLoading={loading}
        onItemSelect={handleShowDetails}
        showShortlistSelection={false}
        showPagiation={false}
        saveSearchQueries={saveSearchQueries}
        savedSearchQueries={searchQueries}
        resetSearchQueries={saveSearchQueries}
        error={error}
        rowOptions={rowOptions}
        handleRowOptionsItemClick={handleRowOptionsItemClick}
        id='scheduleTypeId'
      />
    </>
  );
};

const CalendarContainer = styled.div`
  /* padding: 2rem 3rem; */
  & * {
    font-size: 1.6rem;
  }
`;

export default SceduleType;
