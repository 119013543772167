/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import KeyInputLayoutView from '../../KeyValueLayout/KeyInputLayout.view';
// import SearchFilterInput from '../../SearchFilterInput/SearchFilterInput';
// import DropDownModal from '../../DropDownModal/DropDownModal';
// import { DropdownContainer } from '../../../UpsertWidget/WidgetForm/AddWidgetForm.style';
import FilterInputType from '../FilterType/FilterInputType';
import FilterSearchComponent from '../../../Filter/FilterSearchComponent/FilterSearchComponent';
import Loader from '../../Loader/Loader';
import { useActions } from '../../../../store/hooks/useActions';
import ButtonComp from '../../Button/Button.view';
import { generateSelectedFilter, getBreadCrumbs } from '../../../ResultView/Iframe/generateSelectedFilter';
import { isEmpty } from '../../../../shared/utility/isEmpty';
import { CardContainer } from './FilterInput.styles';
import DynamicSVGIcon from '../../DynamicSVGIcon';
import { CancelBlack } from '../../../../assets/icons';
import { Span } from '../../KeyValueLayout/KeyValueLayout.style';
import { getFetchDetailsOfWidgetData } from '../../GenericForm/utils';
import FilterInputTypeTree from '../FilterType/FilterInputTypeTree';

const EquipmentFilterSearchTypeTree = ({ item, fieldClassName, value, formValueSetter }) => {
    const { label, key, dataSource, isRequired } = item;
    const { params, source } = dataSource || {};
    const { fullURL, method } = getFetchDetailsOfWidgetData(source);
    // const { menuId } = params;
    const [showModal, setShowModal] = useState(false);
    // const [loading, setLoading] = useState(false);
    const loading = useSelector((state) => state.filterData.loading);
    const { getFilterData } = useActions();
    useEffect(() => {
        getFilterData({ directParams: params, URLparam: fullURL, method });
    }, []);
    const { setTags, universalFilterSetter } = useActions();

    const filterState = useSelector((state) => state.filterData);
    const { cumulativeTags, filterData, tags, loading: filterLoading } = filterState;
    const check = () => {
        // const b = getBreadCrumbs(generateSelectedFilter(cumulativeTags, filterData, false));
        // console.log('getBreadCrumbs', b);
        // form value setter
        const b = generateSelectedFilter(
            cumulativeTags,
            filterData,
            true,
            item?.key
        );
        if (!isEmpty(b)) { formValueSetter([b], { key }); }
        // formValueSetter(b, { key });
        setShowModal(false);
    };
    const removeMachine = (index) => {
        const treeKey = filterData[0].groupTitle;
        // get the pathNo
        const keyList = Object.keys(cumulativeTags[treeKey]);
        const pathNo = keyList[index];
        const lastIndex = keyList.length - 1;
        const isLastMachine = pathNo === keyList[lastIndex];
        delete cumulativeTags[treeKey][pathNo];
        const cumulativeSelectedObj = filterState[`cumulative${treeKey}`];
        delete cumulativeSelectedObj[pathNo];
        check();

        // no paths
        if (isEmpty(cumulativeTags[treeKey])) {
            setTags({ ...tags, [treeKey]: {} });
            universalFilterSetter({
                key: treeKey,
                value: {}
            });
        }
    };

    return (
        <div>
            <KeyInputLayoutView
                label={label}
                fieldClassName={fieldClassName}
                tooltip={item.tooltip}
                className={isRequired ? 'add_equipment required' : 'add_equipment'}
                width='50rem'
                colonStyleNotRequired={true}
            >
                {loading ? (
                    <Span>Loading...</Span>
                ) : (
                    <div>
                        <FilterSearchComponent
                            showFilter={showModal}
                            setShowFilter={setShowModal}
                            setActiveTab={() => { }}
                        />
                        <FilterInputTypeTree
                            setShowModal={setShowModal}
                            item={item}
                            saveHandler={check}
                            loading={filterLoading}
                            hasPreSelection={false}
                            isPreSelection={false}
                            ignoreActiveOption={false}
                            value={value}
                        />
                    </div>
                )}
            </KeyInputLayoutView>
        </div>
    );
};

export default EquipmentFilterSearchTypeTree;
