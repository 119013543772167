/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DynamicSVGIcon from '../../../Common/DynamicSVGIcon';
import {
  AttachmentsContainer,
  AttachmentText,
  CancelContainer,
  HeaderText,
  ItemContainer,
  ContentText,
  Modal,
  ModalContainer,
  DetailSection,
  SpareImage,
  ZoomIconContainer,
  CanvasWrapper,
  IconZoomDiv,
  CancelContainerMobile,
  ThumbNailContainer
} from './SpareDetails.style';
import CancelIcon from '../../../../assets/icons/Filter/cancel white.svg';
// import { LabelColumnItems, LabelItem } from '../../../Common/Details/Details.view';
import { Table } from '../../..';
import useClickOutside from '../../../../shared/hooks/useClickOutside';
import { Creators } from '../../../../views/Stores/Spares/store';
// import { FlexContainer } from '../../../../globalStyles';
import Loader from '../../../Loader/Loader';
import { AddBlack, AddWhite, BackBlack, CancelBlack, Minus, MinusWhite, } from '../../../../assets/icons';
// import spareImg from '../../../../assets/Images/Slider_img_1.jpg';

function AttachmentItem({ item }) {
  const { fileLink, uploadedBy, uploadedOn } = item;
  return (
    <ItemContainer>
      <AttachmentText className='file'>{fileLink}</AttachmentText>
      <AttachmentText>{uploadedBy}</AttachmentText>
      <AttachmentText className='normal'>{`Uploaded: ${uploadedOn}`}</AttachmentText>
    </ItemContainer>
  );
}

function SpareDetails({ onCancel, id }) {
  let initialX;
  const stepSize = 0.1;
  const maxZoomLevel = 3;
  const minZoomLevel = 1;

  const [scale, setScale] = useState(1);
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageList, setImageList] = useState([]);
  const [imageIndex, setImageIndex] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [initialPosition, setInitialPosition] = useState({ x: 0, y: 0 });
  const [canvasSize, setCanvasSize] = useState({ width: 350, height: 240 });

  const canvasRef = useRef(null);
  const containerRef = useRef();
  const notificationModalRef = useRef();

  const dispatch = useDispatch();

  const { spareDetailedInfo } = useSelector((state) => state.spareDetails);

  const { data, fields, isLoading, error } = spareDetailedInfo;

  const issueColProps = [
    { label: 'Work Order', name: 'work_order' },
    { label: 'Quantity', name: 'quantity' },
    { label: 'Issued On', name: 'issued_date' },
    { label: 'Issued by', name: 'issued_by' }
  ];

  const closeModal = () => {
    onCancel();
  };

  useClickOutside(notificationModalRef, closeModal);

  useEffect(() => {
    if (id) {
      dispatch(Creators.getSpareDetails({ id }));
    }
  }, [id]);

  useEffect(() => {
    if (data?.multiImages) {
      const imageList = data?.multiImages;
      const sample = typeof imageList === 'string' ? JSON.parse(data?.multiImages) : data?.multiImages;
      setImageList(sample);
    }
  }, [data]);

  useEffect(() => {
    const container = containerRef.current;
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');

    const containerRect = container?.getBoundingClientRect();
    const canvasRect = canvas?.getBoundingClientRect();
    initialX = (containerRect?.width - canvasRect?.width) / 2;
    const initialY = (containerRect?.height - canvasRect?.height) / 2;
    setPosition({ x: 0, y: 0 });

    const img = new Image();
    const imageWidth = canvasSize?.width * scale;
    const imageHeight = canvasSize?.height * scale;

    img.onload = () => {
      setImage(img);
      ctx?.drawImage(img, 0, 0, imageWidth, imageHeight);
    };
    img.src = imageUrl;
  }, [imageList, imageUrl, canvasRef]);

  useEffect(() => {
    setImageUrl(imageList[imageIndex]);
  }, [imageList, imageIndex]);

  const getFiled = (field) => {
    const skipField = ['imageUrl', 'issueHistory', 'partName', 'attachments'];

    if (skipField.indexOf(field.key) === -1) {
      return (
        <DetailSection>
          <ContentText className='label'>{field.title}</ContentText>
          <span>:</span>
          <ContentText>{data[field.key]}</ContentText>
        </DetailSection>
      );
    }
    return null;
  };

  const redrawImage = () => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    ctx?.clearRect(0, 0, canvas.width, canvas.height);

    const imageWidth = canvasSize?.width * scale;
    const imageHeight = canvasSize?.height * scale;

    const container = containerRef.current;
    const containerRect = container?.getBoundingClientRect();
    const canvasRect = canvas?.getBoundingClientRect();
    initialX = (containerRect?.width - canvasRect?.width) / 2;
    const initialY = (containerRect?.height - canvasRect?.height) / 2;
    setPosition({ x: initialX, y: initialY });

    // Draw the image
    if (image) {
      ctx?.drawImage(image, 0, 0, imageWidth, imageHeight);
    } else {
      setImageUrl(null);
    }
  };

  const handleZoom = (factor) => {
    if (factor === stepSize) {
      if (scale < maxZoomLevel) {
        const newScale = Math.max(stepSize, scale + factor);
        setScale(newScale);
      }
    }
    if (factor === -stepSize) {
      if (scale > minZoomLevel) {
        const newScale = Math.max(stepSize, scale + factor);
        setScale(newScale);
      }
    }
  };

  useEffect(() => {
    redrawImage();
  }, [scale]);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setInitialPosition({
      x: e.clientX - position.x,
      y: e.clientY - position.y
    });
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      e.preventDefault();
      setPosition({
        x: e.clientX - initialPosition.x,
        y: e.clientY - initialPosition.y
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <Modal>
      <CancelContainer onClick={closeModal}>
        <DynamicSVGIcon iconUrl={CancelIcon} />
      </CancelContainer>
      <ModalContainer ref={notificationModalRef}>
        <CancelContainerMobile onClick={closeModal}>
          <DynamicSVGIcon iconUrl={CancelBlack} />
        </CancelContainerMobile>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <HeaderText>{data?.partName || ''}</HeaderText>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <CanvasWrapper
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                ref={containerRef}
              >
                <canvas
                  ref={canvasRef}
                  width={image ? canvasSize.width * scale : 0}
                  height={image ? canvasSize.height * scale : 0}
                  onMouseDown={handleMouseDown}
                  // onWheel={handleWheel}
                  style={{ left: position.x, top: position.y, position: 'absolute' }}
                />
              </CanvasWrapper>
              {image && (
                <ZoomIconContainer left={initialX}>
                  <IconZoomDiv disabled={scale >= maxZoomLevel} onClick={() => { handleZoom(stepSize); }}>
                    <DynamicSVGIcon iconUrl={AddWhite} width='2rem' />
                  </IconZoomDiv>
                  <IconZoomDiv disabled={scale <= minZoomLevel} onClick={() => { handleZoom(-stepSize); }}>
                    <DynamicSVGIcon iconUrl={MinusWhite} width='2rem' />
                  </IconZoomDiv>
                </ZoomIconContainer>
              )}
            </div>
            <ThumbNailContainer overflow={imageList?.length > 7}>
              {imageList?.map((img, index) => (
                <SpareImage
                  className={index === imageIndex ? 'active' : ''}
                  src={img}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setScale(1);
                    setCanvasSize({ width: 350, height: 240 });
                    setImageUrl(img);
                    setImageIndex(index);
                  }}
                />
              ))}

            </ThumbNailContainer>
            {fields?.map((field) => {
              const { key, visible } = field;
              return <React.Fragment key={key}>{visible ? getFiled(field) : null}</React.Fragment>;
            })}

            <ContentText style={{ margin: '2rem 0rem' }} className='label'>
              Attachments
            </ContentText>
            <AttachmentsContainer>
              {data?.attachments !== '[]' ? (
                data?.attachments?.map((attachment, i) => <AttachmentItem key={i} item={attachment} />)
              ) : (
                <ContentText style={{ margin: '0 auto' }}>No Attacments</ContentText>
              )}
            </AttachmentsContainer>

            <ContentText style={{ margin: '2rem 0rem' }} className='label'>
              Issue History
            </ContentText>
            {data?.issueHistory !== '[]' ? (
              <Table colProps={issueColProps} data={data?.issueHistory} />
            ) : (
              <ContentText style={{ margin: '0 auto' }}>No Issue History</ContentText>
            )}
          </>
        )}
        {/* {expandedView && (
          <ModalExpandedBackground>
            <CloseIconContainer onClick={() => { setExpandedView(false); }}>
              <DynamicSVGIcon iconUrl={CancelWhite} width='2.4rem' />
            </CloseIconContainer>
            <ZoomIconContainer left={initialX}>
              <IconZoomDiv disabled={scale >= maxZoomLevel} onClick={() => { handleZoom(stepSize); }}>
                <DynamicSVGIcon iconUrl={AddBlack} width='2rem' />
              </IconZoomDiv>
              <IconZoomDiv disabled={scale <= minZoomLevel} onClick={() => { handleZoom(-stepSize); }}>
                <DynamicSVGIcon iconUrl={Minus} width='2rem' />
              </IconZoomDiv>
            </ZoomIconContainer>
            <CanvasWrapper
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              ref={containerRef}
            >
              <canvas
                ref={canvasRef}
                width={image ? canvasSize.width * scale : 0}
                height={image ? canvasSize.height * scale : 0}
                onMouseDown={handleMouseDown}
                // onWheel={handleWheel}
                style={{ border: '1px solid black', left: position.x, top: position.y, position: 'absolute' }}
              />
            </CanvasWrapper>
          </ModalExpandedBackground>
        )} */}

      </ModalContainer>

    </Modal>
  );
}

export default SpareDetails;
