import styled from 'styled-components';
import { fontFamily } from '../../../constants';
import { FlexContainer } from '../../../globalStyles';
import { device } from '../../constants';

export const ImageContainer = styled(FlexContainer)`
  position: absolute;
  left: 0px;
  top: 0rem;
`;

export const UsernameText = styled.div`
  color: #404046;
  font: 1.8rem ${fontFamily.circularMedium};
  margin-bottom: 1.5rem;
`;

export const Content = styled.div`
  color: #404046;
  font: 1.6rem ${fontFamily.circularBook};
  margin-bottom: 4rem;
`;

export const Container = styled.div`
  padding-left: 6rem;
  width: 100%;
  position: relative;
  /* display: flex;
  align-items: center; */

`;
