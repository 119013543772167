/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import { createReducer } from 'reduxsauce';
import { WO_PANEL_DETAILS } from '../../../constants';
import { Types } from './action';

const INITIAL_STATE = {
  // main loader
  loading: false,
  error: null,
  workOrders: [],
  workOrderFilter: { type: '', isDashboard: false },
  woCount: null,
  workOrderTypes: [],
  woTypesLoading: false,
  woHeaders: [],
  woSearchQueries: {},

  workOrderId: '',
  status: '',
  type: '',
  wftemplate: {},
  workOrderDetails: [],
  userData: {},
  userDataLoading: false,
  userDataError: '',

  woGroupCountLoading: false,
  woGroupCounterror: null,
  workOrdersCountByGroup: [],
  childworkorders_list: [],
  nextActions: [],
  sidePanelInfo: {},

  formLoading: false,
  formdefinition: '',
  formbuttons: [],
  formError: '',
  remarkList: [],

  // sidepanel details loader
  sidePanelDetailsLoader: false
};

const setWorkOrderFilter = (state = INITIAL_STATE, action) => ({
  ...state,
  workOrderFilter: { ...state.workOrderFilter, ...action.payload }
});

const resetUserData = (state = INITIAL_STATE) => ({
  ...state,
  workOrderDetails: [],
  userData: {}
});

const resetWorkOrderDetails = (state = INITIAL_STATE) => ({
  ...state,
  workOrderId: '',
  status: '',
  type: '',
  wftemplate: {},
  workOrderDetails: [],
  userData: {}
});

const getWorkOrdersStart = (state = INITIAL_STATE) => ({
  ...state,
  loading: true
});

const getWorkOrdersSuccess = (state = INITIAL_STATE, action) => {
  const { workOrders, meta } = action.payload;

  return {
    ...state,
    workOrders,
    woCount: meta?.count,
    woHeaders: meta?.columns,
    loading: false
  };
};

const getWorkOrdersFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;

  return {
    ...state,
    error,
    loading: false
  };
};

const setWorkOrderSearchQueries = (state = INITIAL_STATE, action) => ({
  ...state,
  woSearchQueries: { ...action.payload }
});

const resetWorkOrderSearchQueries = (state = INITIAL_STATE, action) => ({
  ...state,
  woSearchQueries: INITIAL_STATE.woSearchQueries
});

const getWorkOrderTypesStart = (state = INITIAL_STATE) => ({
  ...state,
  woTypesLoading: true
});

const getWorkOrderTypesSuccess = (state = INITIAL_STATE, action) => {
  const { workOrderTypes } = action.payload;

  return {
    ...state,
    workOrderTypes,
    woTypesLoading: false
  };
};

const getWorkOrderTypesFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;

  return {
    ...state,
    WOTypeserror: error,
    woTypesLoading: false
  };
};

const getWorkOrderDetailsStart = (state = INITIAL_STATE) => ({
  ...state,
  loading: true
});

const getWorkOrderDetailsSuccess = (state = INITIAL_STATE, action) => {
  const { current_state, ...rest } = action.payload;
  return {
    ...state,
    loading: false,
    ...rest,
    status: current_state
  };
};

const getWorkOrderDetailsFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;

  return {
    ...state,
    error,
    loading: false
  };
};

const getWorkOrdersCountByGroupStart = (state = INITIAL_STATE) => ({
  ...state,
  woGroupCountLoading: true
});

const getWorkOrdersCountByGroupSuccess = (state = INITIAL_STATE, action) => {
  const { workOrdersCountByGroup } = action.payload;

  return {
    ...state,
    workOrdersCountByGroup,
    woGroupCountLoading: false
  };
};

const getWorkOrdersCountByGroupFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;

  return {
    ...state,
    woGroupCounterror: error,
    woGroupCountLoading: false
  };
};

const getFormStructureStart = (state = INITIAL_STATE) => ({
  ...state,
  formLoading: true
});

const getFormStructureSuccess = (state = INITIAL_STATE, action) => {
  const { formDefinition, formbuttons } = action.payload.formStructure;
  // eslint-disable-next-line prefer-destructuring
  let formUserData = action.payload.formStructure.formUserData;
  if (!formUserData) {
    formUserData = '{}';
  }
  return {
    ...state,
    formdefinition: JSON.parse(formDefinition),
    formbuttons: JSON.parse(formbuttons),
    formLoading: false,
    userData: { ...state.userData, ...JSON.parse(formUserData) }
  };
};

const getFormStructureFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;

  return {
    ...state,
    formError: error,
    formLoading: false,
    formdefinition: INITIAL_STATE.formdefinition,
    formbuttons: INITIAL_STATE.formbuttons
  };
};

const getUserDataStart = (state = INITIAL_STATE) => ({
  ...state,
  userDataLoading: true
});

const getUserDataSuccess = (state = INITIAL_STATE, action) => {
  // eslint-disable-next-line prefer-destructuring
  return {
    ...state,
    userDataLoading: false,
    userData: { ...action.payload }
  };
};

const getUserDataFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;

  return {
    ...state,
    userDataError: error,
    userDataLoading: false
  };
};

const getWOSidePanelDetailsStart = (state = INITIAL_STATE) => ({
  ...state,
  sidePanelDetailsLoader: true
});

const getWOSidePanelDetailsSuccess = (state = INITIAL_STATE, action) => {
  const { detailsOf, data } = action.payload;
  let payload = {};
  if (detailsOf === WO_PANEL_DETAILS.ATTACHMENT) {
    const { Attachments } = state.sidePanelInfo;
    payload = {
      ...state.sidePanelInfo,
      Attachments: {
        ...Attachments,
        data
      }
    };
  } else if (detailsOf === WO_PANEL_DETAILS.CHECKLIST) {
    const { CheckLists } = state.sidePanelInfo;

    payload = {
      ...state.sidePanelInfo,
      CheckLists: {
        ...CheckLists,
        data
      }
    };
  }

  return {
    ...state,
    sidePanelDetailsLoader: false,
    sidePanelInfo: { ...payload }
  };
};

const getWOSidePanelDetailsFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    error,
    sidePanelDetailsLoader: false
  };
};

const getRemarkStart = (state = INITIAL_STATE) => ({
  ...state
  // loading: true
});

const getRemarkSuccess = (state = INITIAL_STATE, action) => {
  // console.log(action.payload, '909090990909');
  return {
    ...state,
    loading: false,
    remarkList: action.payload
  };
};

const getRemarkFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    error
    // loading: false
  };
};

const HANDLERS = {
  [Types.GET_WORK_ORDERS_START]: getWorkOrdersStart,
  [Types.GET_WORK_ORDERS_SUCCESS]: getWorkOrdersSuccess,
  [Types.GET_WORK_ORDERS_FAILURE]: getWorkOrdersFailure,
  [Types.SET_WORK_ORDER_SEARCH_QUERIES]: setWorkOrderSearchQueries,
  [Types.RESET_WORK_ORDER_SEARCH_QUERIES]: resetWorkOrderSearchQueries,

  [Types.GET_WORK_ORDER_TYPES_START]: getWorkOrderTypesStart,
  [Types.GET_WORK_ORDER_TYPES_SUCCESS]: getWorkOrderTypesSuccess,
  [Types.GET_WORK_ORDER_TYPES_FAILURE]: getWorkOrderTypesFailure,

  [Types.GET_WORK_ORDER_DETAILS_START]: getWorkOrderDetailsStart,
  [Types.GET_WORK_ORDER_DETAILS_SUCCESS]: getWorkOrderDetailsSuccess,
  [Types.GET_WORK_ORDER_DETAILS_FAILURE]: getWorkOrderDetailsFailure,
  [Types.RESET_WORK_ORDER_DETAILS]: resetWorkOrderDetails,

  [Types.GET_WORK_ORDERS_COUNT_BY_GROUP_START]: getWorkOrdersCountByGroupStart,
  [Types.GET_WORK_ORDERS_COUNT_BY_GROUP_SUCCESS]: getWorkOrdersCountByGroupSuccess,
  [Types.GET_WORK_ORDERS_COUNT_BY_GROUP_FAILURE]: getWorkOrdersCountByGroupFailure,

  [Types.GET_FORM_STRUCTURE_START]: getFormStructureStart,
  [Types.GET_FORM_STRUCTURE_SUCCESS]: getFormStructureSuccess,
  [Types.GET_FORM_STRUCTURE_FAILURE]: getFormStructureFailure,

  [Types.GET_USER_DATA_START]: getUserDataStart,
  [Types.GET_USER_DATA_SUCCESS]: getUserDataSuccess,
  [Types.GET_USER_DATA_FAILURE]: getUserDataFailure,
  [Types.RESET_USER_DATA]: resetUserData,

  [Types.SET_WORK_ORDER_FILTER]: setWorkOrderFilter,

  [Types.GET_WO_SIDE_PANEL_DETAILS_START]: getWOSidePanelDetailsStart,
  [Types.GET_WO_SIDE_PANEL_DETAILS_SUCCESS]: getWOSidePanelDetailsSuccess,
  [Types.GET_WO_SIDE_PANEL_DETAILS_FAILURE]: getWOSidePanelDetailsFailure,

  [Types.GET_REMARK_START]: getRemarkStart,
  [Types.GET_REMARK_SUCCESS]: getRemarkSuccess,
  [Types.GET_REMARK_FAILURE]: getRemarkFailure
};

export const workOrdersReducer = createReducer(INITIAL_STATE, HANDLERS);
