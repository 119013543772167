/* eslint-disable max-len */
export const rootConfig = {
  apiRoot: 'http://35.161.23.251/MMSAPISegate/api/User',
  // apiRoot1: 'http://35.161.23.251/MMSAPI/api',
  apiRoot1: 'http://35.161.23.251/MMSAPISegate/api',
  // apiRoot2: 'http://35.161.23.251/CMMS/api', // MMS
  apiRoot2: 'http://35.161.23.251/CMMSSegate/api',
  apiRoot3: 'http://35.161.23.251/MMSAPISegate/api/User',
  apiRoot4: 'http://35.161.23.251/MMSAPI/api',
  // apiRoot2: 'http://35.161.23.251/CMMSDev/api', // MMS
  baseUrl: 'http://vistrian2.panorbitprojects.com/api'
};

export const apiEndPoints = {
  login: `${rootConfig.apiRoot2}/login/`,
  authenticate: `${rootConfig.apiRoot3}/Authenticate`,
  forgotPassword: `${rootConfig.apiRoot2}/forgetPassword/`,
  ValidateCode: `${rootConfig.baseUrl}/ValidateCode/`,
  ResetPassword: `${rootConfig.baseUrl}/ResetPassword/`,
  ChangePassword: `${rootConfig.apiRoot3}/ChangePassword`,
  getMenu: `${rootConfig.apiRoot4}/User/GetUserMenus/`,
  CarouselData: `${rootConfig.apiRoot2}/getCarouselData`,
  notifcationData: `${rootConfig.apiRoot2}/getBannerNotifications`,
  localStringsData: `${rootConfig.apiRoot2}/getLocaleString`,
  getTheme: `${rootConfig.apiRoot2}/getTheme/`,
  updatePageUsageStatistics: `${rootConfig.apiRoot3}/updatePageUsageStatistics`,

  getActionItems: `${rootConfig.apiRoot2}/WF/GetActionItems`,
  getWorkOrders: `${rootConfig.apiRoot2}/WF/listWorkOrders`,

  getWorkOrdersCountByGroup: `${rootConfig.apiRoot2}/WF/GetWorkOrdersCountByGroup`,
  getWorkOrdersWithoutUserFilter: `${rootConfig.apiRoot2}/wf/listWorkOrdersWithoutUserFilter`,

  getWorkflowTypes: `${rootConfig.apiRoot2}/WF/getWorkflowTypes`,
  getFormStructure: `${rootConfig.apiRoot2}/WF/getFormStructure/`,
  getWorkOrderDetails: `${rootConfig.apiRoot2}/WF/getFormDetails/`,
  getSparesFilters: `${rootConfig.apiRoot2}/WF/GetSparesFilters`,
  getFilterCategory: `${rootConfig.apiRoot}/getFilterCategory/`,
  getFilterCategory2: `${rootConfig.apiRoot3}/getFilterCategory/`,
  // getFilterCategory: `${rootConfig.apiRoot1}/User/getFilterCategory/`
  getSparesList: `${rootConfig.apiRoot2}/WF/getSparesList`,
  getSpareDetails: `${rootConfig.apiRoot2}/WF/getSpareDetails/`,
  getFilterByWOType: `${rootConfig.apiRoot1}/User/GetFiltersByTemplateID`,
  getAssetsList: `${rootConfig.apiRoot1}/User/getAssetsList/`,
  // getAssetsList: `${rootConfig.apiRoot2}/WF/getAssetsList/`,
  getUserDataByTemplateId: `${rootConfig.apiRoot2}/WF/GetUserDataByTemplateID`,

  // getGraphURI: `${rootConfig.baseUrl}/getGraphURI/`
  getGraphURI: `${rootConfig.apiRoot1}/User/getGraphURI/`,

  getSchedule: `${rootConfig.apiRoot1}/Schedule/GetSchedules`,
  getScheduleDetails: `${rootConfig.apiRoot1}/Schedule/GetAnySchedulenew`,
  createSchedule: `${rootConfig.apiRoot1}/Schedule/CreateSchedule`,
  scheduleFormStructure: `${rootConfig.apiRoot1}/Schedule/GetSchedulesFormStructure`,
  updateSchedule: `${rootConfig.apiRoot1}/Schedule/UpdateSchedule`,
  updatePlan: `${rootConfig.apiRoot1}/Schedule/UpdatePlan`,

  // Spares BUlk Update
  getSparesOptions: `${rootConfig.apiRoot4}/user/GetBulkSpareProfiles`,
  getSparesOptionsIndividual: `${rootConfig.apiRoot4}/user/GetBulkAnySpareProfile`,
  getBulkSpareProfileDocuments: `${rootConfig.apiRoot4}/user/GetBulkSpareProfileDocuments`,

  // Activity
  getActivity: `${rootConfig.apiRoot1}/Schedule/GetActivities`,
  getActivityDetails: `${rootConfig.apiRoot1}/Schedule/GetAnyActivity/`,
  createActivity: `${rootConfig.apiRoot1}/Schedule/CreateActivity`,
  updateActivity: `${rootConfig.apiRoot1}/Schedule/UpdateActivity`,
  getWorkflowTypesForActivity: `${rootConfig.apiRoot1}/Schedule/GetWorkflowTypes`,

  // scheduleType
  getScheduleType: `${rootConfig.apiRoot1}/Schedule/GetScheduleTypes`,
  CreateScheduleType: `${rootConfig.apiRoot1}/Schedule/CreateScheduleType`,
  UpdateScheduleType: `${rootConfig.apiRoot1}/Schedule/UpdateScheduleType`,
  getScheduleTypeDetails: `${rootConfig.apiRoot1}/Schedule/GetAnyScheduleType`,

  // plan
  getScheduleList: `${rootConfig.apiRoot1}/Schedule/GetScheduleList`,
  getSchedulePlan: `${rootConfig.apiRoot1}/Schedule/GetScheduleList`,
  getSchedulePlanDetails: `${rootConfig.apiRoot1}/Schedule/GetAnyPlan`,

  // dropdowns
  getEquipments: `${rootConfig.apiRoot1}/Schedule/AllEquipments`,
  getEquipmentsAsset: `${rootConfig.apiRoot1}/Schedule/GetEquipmentTree`,
  allScheduleTypes: `${rootConfig.apiRoot1}/Schedule/AllScheduleTypes`,
  allActivityTypes: `${rootConfig.apiRoot1}/Schedule/AllActivities`,
  MDScheduleType: `${rootConfig.apiRoot1}/Schedule/MDScheduleType`,
  frequencyType: `${rootConfig.apiRoot1}/Schedule/ScheduleTypeFrequency`,
  CreateScheduleJO: `${rootConfig.apiRoot2}/WF/CreateScheduleJO`,

  // Remarks api
  updateRemarks: `${rootConfig.apiRoot1}/User/UpdateRemarks`,
  getRemarks: `${rootConfig.apiRoot1}/User/GetRemarks/`,

  // post from modal
  addPartAdd: `${rootConfig.apiRoot2}/WF/AddPartAdd/`,
  ExchangePartAdd: `${rootConfig.apiRoot2}/WF/ExchangePartAdd`,
  // getAsset
  GetJobSpares: `${rootConfig.apiRoot1}/User/GetJobSpares/`,
  // exchange parts
  exchangeParts: `${rootConfig.apiRoot1}/User/GetJobSpares/`,
  // delete attachment in wosidepanel -post
  deleteDocument: `${rootConfig.apiRoot2}/WF/DeleteDocument`,

  // user management
  getUsersList: `${rootConfig.apiRoot3}/getUsersList`,
  getUsersListNew: `${rootConfig.apiRoot3}/getUsers`,
  // user forms
  getUserForm: `${rootConfig.apiRoot3}/getFormStructure/UserForm`,
  getUserUpdateForm: `${rootConfig.apiRoot3}/getFormStructure/UpdateUserForm`,
  getChangePasswordForm: `${rootConfig.apiRoot3}/getFormStructure/ChangePasswordUserForm`,
  getProfileUserForm: `${rootConfig.apiRoot3}/getFormStructure/ProfileUserForm`,
  // save user details
  updateProfileDetails: `${rootConfig.apiRoot3}/updateUserDetails/profile`,
  createUserDetails: `${rootConfig.apiRoot3}/updateUserDetails/new`,
  updateUserDetails: `${rootConfig.apiRoot3}/updateUserDetails/update`,
  passwordUser: `${rootConfig.apiRoot3}/updateUserDetails/password`
};
