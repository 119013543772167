/* eslint-disable function-paren-newline */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
import { put } from 'redux-saga/effects';
import { formatedFilterData } from '../../../component/Filter/utility/formatFilterData';
// import { sampleFilterData } from '../../../../constants/sampleData';
// import { dummyFilterData } from '../../../../constants/sampleData';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { WebService } from '../../../services/axios/webServices';
import { isEmpty } from '../../../shared/utility/isEmpty';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';
import { Creators } from './action';
// import { sampleFilterData } from './sampleFilter';
// import { sampleFilterData2 } from './sampleFilter2';
// import { shortFilterData } from './filterData';
// import { dummyFilterDatastore } from './filterData';
// import { dummyFilterData } from './filterData';

export function* getFilterDataSaga({ payload, dependencyPayload = {} }) {
  // console.log('comming from saga');
  const { tab, menuId, URLparam, setResultTypeAs } = payload;
  const {
    updatedGroup,
    dependentOn,
    dependents,
    metaDependency,
    updateGroupPayload,
    resetDependents,
    keyTitleMapping
  } = dependencyPayload;
  // console.log('payload', payload);
  // console.log(tab);
  // console.log('updatedGroup', updatedGroup, dependencyPayload);
  try {
    // will load entire filter data
    if (!updatedGroup) {
      yield put(Creators.getFilterDataStart());
      let URL = `${apiEndPoints.getFilterCategory}`;

      if (URLparam) {
        URL = URLparam;
      }
      // if (type === 'page') {
      //   URL = `${apiEndPoints.getDataView}`;
      // }

      const fullURL = yield URL;

      const response = yield WebService.get(fullURL, { params: { lang: 'eng', tab, menuId } });
      if (response.status >= 200 && response.status < 300) {
        const { data, meta: metaRaw, resultType, assetType } = response.data;
        // const { data, meta: metaRaw, resultType } = sampleFilterData;
        const meta = !isEmpty(metaRaw) ? metaRaw[0] : {};
        // const { data, meta, resultType } = sampleFilterData;
        // console.log('data', data, response.data);
        let dependencies = [];
        let dependents = {};
        if (!isEmpty(meta)) {
          // compute final dependencies combing all the dependencies
          dependencies = Object.keys(meta.dependency).reduce((final, dependent) => {
            const currEle = meta.dependency[dependent];
            const newDependencies = currEle.dependencies.reduce((prev, curr) => {
              if (!prev.includes(curr)) {
                return [...prev, curr];
              }
              return prev;
            }, final);
            return newDependencies;
          }, []);

          // compute  dependents for each group
          dependents = dependencies.reduce((finalDependents, dependency) => {
            const eleDependents = Object.keys(meta.dependency).reduce((prev, curr) => {
              const currEle = meta.dependency[curr];
              if (currEle.dependencies.includes(dependency)) {
                return [...prev, curr];
              }
              return prev;
            }, []);
            return { ...finalDependents, [dependency]: eleDependents };
          }, {});
        }
        // old
        // const formatedData = [];
        // for (const key in data) {
        //   formatedData.push(data[key][0]);
        // }
        // new
        const { formatedData, keyTitleMapping } = formatedFilterData(data);
        // console.log('formatedData', data, formatedData);
        // const { filterData, resultType, dataView } = dummyFilterData;
        // console.log('formatedData', formatedData);

        // meta of groups
        const groupLevelMeta = formatedData.reduce((meta, groupDetails) => {
          const { groupTitle, meta: groupMeta } = groupDetails;
          if (!isEmpty(groupMeta)) {
            return { ...meta, [groupTitle]: groupMeta };
          }
          return meta;
        }, {});

        // console.log('complete-metta', {
        //   meta: { ...meta, ...groupLevelMeta }
        // });

        yield put(
          Creators.getFilterDataSuccess({
            data: formatedData,
            meta: { ...meta, ...groupLevelMeta },
            resultType: setResultTypeAs || resultType,
            parent: tab || '',
            assetType,
            dependencies,
            dependents,
            keyTitleMapping,
            menuId
          })
        );
      } else {
        throw response;
      }
    } else {
      yield put(Creators.getFilterDependencyDataStart({ dependentOn }));
      // console.log('loading only this...', updatedGroup, dependentOn, dependents);
      const rawResponses = yield Promise.all(
        dependents.map((dependent) => {
          const filteredObj = Object.keys(updateGroupPayload.data).reduce((prev, group) => {
            if (
              metaDependency[dependent].dependencies
                .map((e) => (e === 'tree' ? 'tree' : keyTitleMapping[e]))
                .includes(group)
            ) {
              return { ...prev, [group]: updateGroupPayload.data[group] };
            }
            return prev;
          }, {});
          // console.log('filteredObj', filteredObj);
          return WebService.post(metaDependency[dependent].api, { ...updateGroupPayload, data: filteredObj });
        })
      );
      const responses = rawResponses.map((e, index) => ({ ...e.data[dependents[index]], keyName: dependents[index] }));
      // console.log('responses', responses);

      // local responses
      // const localResponses = dependents.map((dependent) => sampleFilterData2.data[dependent]);
      // const formatedData = localResponses.reduce(
      //   (final, curr, index) => [...final, { ...curr[0], keyName: dependents[index] }],
      //   // (final, curr, index) => ({ ...final, [dependents[index]]: curr[0] }),
      //   []
      // );
      // const initSelections = dependents.reduce(
      //   (final, dependent) => ({
      //     [dependent]: {},
      //     [`cumulative${dependent}`]: {}
      //   }),
      //   {}
      // );
      // console.log('formatedData', formatedData);

      yield put(
        Creators.getFilterDependencyDataSuccess({
          filterData: responses,
          resetDependents
        })
      );
    }
  } catch (error) {
    console.log(error);
    // let payload;
    // if (error && error.response && error.response.status) {
    //   switch (error.response.status) {
    //     case 400:
    //       payload = {
    //         error: error.response.data.message
    //       };
    //       break;
    //     case 500:
    //       payload = {
    //         error: 'Somthing went wrong try later'
    //       };
    //       break;
    //     default:
    //       payload = {
    //         error: 'Unable to update. Try again later'
    //       };
    //   }
    // } else {
    //   payload = {
    //     error: 'No connection try again Later.'
    //   };
    // }
    const payload = sagaCatchBlockHandling(error);
    if (!updatedGroup) {
      yield put(Creators.getFilterDataFail(payload));
    } else {
      yield put(Creators.getFilterDependencyDataFail(dependents));
    }
  }
}

export function* getIFrameDataSaga(action) {
  try {
    yield put(Creators.getIFrameDataStart());

    const url = `${apiEndPoints.getGraphURI}`;

    // console.log(action.payload);

    const response = yield WebService.post(url, JSON.stringify(action.payload));
    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      // console.log('iframe-data', data);
      yield put(Creators.getIFrameDataSuccess(data));
    } else {
      throw response;
    }
  } catch (error) {
    let payload;
    if (error && error.response && error.response.status) {
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
      }
    } else {
      payload = {
        error: 'No connection try again Later.'
      };
    }

    yield put(Creators.getIFrameDataFail(payload));
  }
}
