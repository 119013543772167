/* eslint-disable max-len */
import React from 'react';
import { useSelector } from 'react-redux';
import { PopUpModal } from '../../..';
import { Div, FlexContainer } from '../../../../globalStyles';
import { HeaderTitle } from '../../../../views/WorkOrders/WorkOrders.styles';
import DynamicSVGIcon from '../../../Common/DynamicSVGIcon';
import { CancelBlack } from '../../../../assets/icons';
import InputWorkOrderDetails from '../Step3/InputWorkOrderDetails';

const CommonForm = ({ formForStatus, setShowModal }) => {
  const { type } = useSelector((state) => state.workOrders);

  return (
    <PopUpModal width='1107px' style={{ padding: '4rem' }} closeModalHandler={() => {}}>
      <FlexContainer className='ai_center jc_spacebetween' mb='4'>
        <HeaderTitle>{`${formForStatus} - ${type || ''} Work Order`}</HeaderTitle>
        <Div className='cursor' onClick={() => setShowModal(false)}>
          <DynamicSVGIcon iconUrl={CancelBlack} />
        </Div>
      </FlexContainer>
      <InputWorkOrderDetails commonForm={true} setShowModal={setShowModal} />
    </PopUpModal>
  );
};

export default CommonForm;
