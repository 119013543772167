import React from 'react';
import DateTimePickerComp from 'react-datetime-picker';
import styled from 'styled-components';
import './DateTimePicker.css';

const Wrapper = styled.div`
  width: ${({ width }) => width || '18%'};
  /* display: flex; */
  /* align-items: center; */
  & * {
    font-size: 1.6rem;
  }
`;

export const DateTimePicker = (props) => {
  const { value, setValue, disabled, ...rest } = props;
  // const onCalendarClose = () => {
  //   console.log('calander closed');
  // };
  // const onClockClose = () => {
  //   console.log('clock closed');
  // };

  return (
    <Wrapper className={disabled ? 'disable' : ''} {...props}>
      <DateTimePickerComp
        className='input'
        // onCalendarClose={onCalendarClose}
        // onClockClose={onClockClose}
        format='dd-MM-yyyy HH:mm:ss'
        // placeholder that needs to be shown in the ui
        dayPlaceholder='DD'
        monthPlaceholder='MM'
        yearPlaceholder='YYYY'
        hourPlaceholder='hh'
        minutePlaceholder='mm'
        secondPlaceholder='ss'
        value={value && new Date(value)}
        onChange={(value) => setValue(value, rest)}
      />
    </Wrapper>
  );
};
