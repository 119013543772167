/* eslint-disable max-len */
import { createReducer } from 'reduxsauce';
import { sampleScheduleTypesList } from '../sampleData';
import { Types } from './action';

const { data, meta } = sampleScheduleTypesList;

const INITIAL_STATE = {
  loading: false,
  scheduleList: [],
  scheduleListMeta: [],
  searchQueries: {},
  error: '',
  details: null
};

const setSearchQueries = (state = INITIAL_STATE, action) => ({
  ...state,
  searchQueries: { ...action.payload }
});

const setScheduleType = (state = INITIAL_STATE, action) => ({
  ...state,
  scheduleList: [...state.scheduleList, action.payload]
});

const editScheduleType = (state = INITIAL_STATE, action) => {
  const { input, id: inputId } = action.payload;
  const editedData = state.scheduleList.map((row) => {
    const { id } = row;
    if (id === inputId) {
      return action.payload.input;
    }
    return row;
  });
  return {
    ...state,
    scheduleList: editedData
  };
};

const getScheduleTypeStart = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: true
});

const getScheduleTypeFailure = (state = INITIAL_STATE, action) => ({
  ...state
});
const getScheduleTypeSuccess = (state = INITIAL_STATE, action) => {
  // console.log('success', action.payload);
  const { scheduleType, meta } = action.payload;
  return {
    ...state,
    scheduleList: scheduleType,
    scheduleListMeta: meta,
    loading: false
  };
};

const getScheduleTypeDetailsStart = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: true
});

const getScheduleTypeDetailsSuccess = (state = INITIAL_STATE, action) => {
  const { data } = action.payload;
  // console.log(action.payload, 'af6723489239');
  return {
    ...state,
    loading: false,
    details: action.payload[0]
  };
};

const getScheduleTypeDetailsFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;

  return {
    ...state,
    error,
    loading: false
  };
};

const HANDLERS = {
  [Types.SET_SEARCH_QUERIES]: setSearchQueries,
  [Types.SET_SCHEDULE_TYPE]: setScheduleType,
  [Types.EDIT_SCHEDULE_TYPE]: editScheduleType,
  [Types.GET_SCHEDULE_TYPE_START]: getScheduleTypeStart,
  [Types.GET_SCHEDULE_TYPE_SUCCESS]: getScheduleTypeSuccess,
  [Types.GET_SCHEDULE_TYPE_FAILURE]: getScheduleTypeFailure,
  [Types.GET_SCHEDULE_TYPE_DETAILS_START]: getScheduleTypeDetailsStart,
  [Types.GET_SCHEDULE_TYPE_DETAILS_SUCCESS]: getScheduleTypeDetailsSuccess,
  [Types.GET_SCHEDULE_TYPE_DETAILS_FAILURE]: getScheduleTypeDetailsFailure
};

export const scheduleTypesReducer = createReducer(INITIAL_STATE, HANDLERS);
