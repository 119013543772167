import styled from 'styled-components';
import { FlexContainer } from '../../../../globalStyles';

export const QuarterPickerContainer = styled(FlexContainer)`
  width: 100%;
  flex-wrap: wrap;
  /* height: 70%; */
  flex: 1;
  & + & {
    margin-left: 1rem;
  }
`;

export const QuarterContainer = styled.div`
  padding: 0rem 0rem;

  display: flex;
  flex-basis: calc(100% / 2);
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &.hovered {
    background: ${({ theme }) => theme.filter.hover};
  }
  &.hightlight {
    color: ${({ theme }) => theme.core.secondary};
    background: ${({ theme }) => theme.contrast.darkSecondary};
    /* border-radius: 1rem 0rem 0rem 1rem; */
  }

  /* &.hightlight + &.hightlight {
    border-radius: 0rem;
  }

  &.startDate {
    border-radius: 3rem 0rem 0rem 3rem;
  }
  &.hightlight.endDate {
    border-radius: 0rem 3rem 3rem 0rem !important;
  } */
`;
