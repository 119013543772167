import UserDetailsForm from '../../component/Users/UserDetailsForm';

const UserRoute = [
  {
    path: '/home/user/details',
    exact: true,
    name: 'user-details',
    Component: UserDetailsForm
  },
  {
    path: '/home/user/add',
    exact: true,
    name: 'user-details',
    Component: UserDetailsForm
  },
  {
    path: '/home/user/edit',
    exact: true,
    name: 'user-details',
    Component: UserDetailsForm
  }
];

export default UserRoute;
