/* eslint-disable prefer-template */
/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useFilteredSelectedItem = (setIsDisabled) => {
  const { filterData, tags, selectedItem, inputs } = useSelector((state) => state.filterData);

  // count of no of required groups to enable the gateway
  const totalLength = filterData?.reduce((count, grp) => {
    if (grp.type !== 'input' && (grp.required === 'true' || grp.required === true)) {
      return count + 1;
    }
    return count;
  }, 0);
  //   const [isDisabled, setIsDisabled] = useState(true);

  // completely selected groups
  const [filteredSelectedItem, setFilteredSelectedItem] = useState([]);
  useEffect(() => {
    const tempSelectedItem = [];
    // filteredSelectedItem is filter of selectedItem and will have only the groups which are present in filterData
    if (filterData) {
      filterData.forEach((ele) => {
        if (selectedItem.includes(ele.groupTitle) && ele.type !== 'input') tempSelectedItem.push(ele.groupTitle);
      });
    }
    setFilteredSelectedItem(tempSelectedItem);
  }, [selectedItem]);

  // extra layer of validation
  // cross validating the selections with tags
  const test =
    filteredSelectedItem.filter((item) => {
      const resultGroup = filterData?.find(
        (q) => q.groupTitle === item && (q.required === 'true' || q.required === true)
      );
      return resultGroup?.values.find((qa) => '_' + qa.id === (tags[item] && tags[item]['0']?.id));
    }) || [];
  // console.log('tempSelectedItem', filteredSelectedItem);

  // either input should be filled or all the levels need to be selected
  const enableCondition = totalLength === test.length;
  // || (inputs && Object.keys(inputs).some((field) => inputs[field]));

  // console.log(
  //   'totalLength === test.length || (inputs && Object.keys(inputs).some((field) => inputs[field]))',
  //   filteredSelectedItem,
  //   totalLength,
  //   test,
  //   test.length,
  //   selectedItem,
  //   filteredSelectedItem,
  //   totalLength === test.length,
  //   inputs && Object.keys(inputs).some((field) => inputs[field]),
  //   totalLength === test.length || (inputs && Object.keys(inputs).some((field) => inputs[field]))
  // );
  return !enableCondition;
  // console.log('isDisabled--hook', totalLength !== filteredSelectedItems?.length);
  //   return isDisabled;
};

export default useFilteredSelectedItem;
