import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  setSearchQueries: ['payload'],
  setWorkFlowType: ['payload'],
  editActivity: ['payload'],
  getActivity: ['payload'],
  getActivityStart: ['payload'],
  getActivitySuccess: ['payload'],
  getActivityFailure: ['payload'],
  getActivityDetails: ['payload'],
  getActivityDetailsStart: ['payload'],
  getActivityDetailsSuccess: ['payload'],
  getActivityDetailsFailure: ['payload'],
  createActivity: ['payload'],
  setActivity: ['payload']
});
