/* eslint-disable no-undef */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TimelineItem from './TimelineItem';
import { SUCCESS } from '../../../constants';
import {
  ActiveDivider,
  // BodyContainer,
  HeaderContainer,
  HeaderText,
  // NormalContent,
  CommentInputContainer,
  InputCommentContainer
  // Container,
  // StatusContainer,
  // UpdatedStatusText,
  // SidePanelContainer,
  // ItemContainer
} from './WorkOrderDetails.style';
// import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';
import { WorkOrderCreators } from '../../../views/WorkOrders/store';
import RemarksList from './RemarksList';
import CreateRemark from './CreateRemark';
import Loader from '../../Loader/Loader';
import { FlexContainer } from '../../../globalStyles';

const tabs = ['Activity Log', 'Remarks'];

const RenderLogs = ({ workOrderId }) => {
  const { profileName } = useSelector((state) => state.loginData.userData);
  const [loader, setLoader] = useState(false);

  const [activeTab, setActiveTab] = useState(tabs[0]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(WorkOrderCreators.getRemark(workOrderId));
  }, []);

  // const { comments } = useSelector((state) => state.upsertWorkOrder);
  const {
    activitylog: wo_activitylog,
    remarks /* remarks: wo_remarks */,
    remarkList
  } = useSelector((state) => state.workOrders);
  const Logs = () => {
    switch (activeTab) {
      case 'Remarks':
        return (
          <>
            <CreateRemark workOrderId={workOrderId} setLoader={setLoader} />
            {loader ? (
              <FlexContainer className='jc_center'>
                <Loader />
              </FlexContainer>
            ) : (
              <div>
                {remarkList.length > 0
                  ? remarkList.map((ele, i) => <RemarksList ele={ele} key={i} />)
                  : remarks.map((ele, i) => <RemarksList ele={ele} key={i} />)}
              </div>
            )}
          </>
        );
      case 'Activity Log':
        return (
          <div>
            {[...wo_activitylog]?.reverse().map((item, i) => (
              <TimelineItem item={item} key={i} />
            ))}
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <div>
      <HeaderContainer className='inner'>
        <div>
          {tabs.map((tab, i) => (
            <React.Fragment key={i}>
              <HeaderText
                className={`${tab === activeTab ? 'active inner' : 'inner'}`}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
                {tab === activeTab && <ActiveDivider />}
              </HeaderText>
            </React.Fragment>
          ))}
        </div>
      </HeaderContainer>
      <div style={{ padding: '2rem' }}>
        <Logs />
      </div>
    </div>
  );
};

export default RenderLogs;
