import { createActions } from 'reduxsauce';

export const { Creators, Types } = createActions({
  userReducerValueSetter: ['payload'],
  updateUsersData: ['payload'],
  resetUserDataFlags: ['payload'],
  getUserTableData: ['payload'],
  setUserTableData: ['payload'],
  updateUserTableData: ['payload'],
  setSearchQueries: ['payload'],
  latestSavedValue: ['payload'],
  getUsersList: ['payload'],
  getUsersListStart: ['payload'],
  getUsersListSuccess: ['payload'],
  getUsersListFailure: ['payload']
});
