import ActivityDetails from '../../component/Activity/ActivityDetails/ActivityDetails';
import AddActivity from '../../component/Activity/AddActivity/AddActivity';
import EditActivity from '../../component/Activity/EditActivity/EditActivity';

const ActivityRoutes = [
  { path: '/home/activity/details', exact: true, name: 'activity-details', Component: ActivityDetails },
  { path: '/home/activity/create', exact: true, name: 'createActivity', Component: AddActivity },
  { path: '/home/activity/edit', exact: true, name: 'editActivity', Component: EditActivity }
];

export default ActivityRoutes;
