import styled from 'styled-components';
import { fontSize } from '../../../constants';
import { ContentTextTemp, DetailsContainerTemp } from '../CommonStyles';

export const LabelToolTip = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.customTheme.primary};
  width: max-content;
  max-width: 350px;
  /* height: fit-content; */

  /* display: flex; */
  display: none;
  justify-content: center;
  align-items: center;
  /* bottom: -15px; */
  left: 0;
  border-radius: 4px;
  z-index: 1;
  padding: 5px 10px;
  top: -100%;

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 100%;
    width: 0;
    height: 0;
    border-bottom: 5px solid ${({ theme }) => theme.customTheme.primary};
    border-top: 5px solid transparent;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }
  &.top {
    top: -4.2rem;
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 100%;
      left: 10%;
      width: 0;
      height: 0;
      border-top: 5px solid ${({ theme }) => theme.customTheme.primary};
      border-bottom: 5px solid transparent;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }
  }
`;

export const Span = styled.p`
  font-size: ${fontSize.titleBig};
  color: #9b9b9b;
  margin-right: 4rem;
  margin-top: 0.5rem;
  padding-right: 2rem;
  margin-bottom: 1rem;

  & form label {
    margin-left: 1rem;
    font-size: ${fontSize.titleBig};
    text-decoration: underline;
  }
  &.fileUploadBox {
    margin-right: 0;
    margin-top: 0;
    padding-right: 0;
  }

  &.colonMargin {
    margin-left: 2rem;
  }
  &.add_equipment {
    /* width: 15rem; */
    font-size: 1.8rem;
    text-align: left;
    font: normal normal normal 1.8rem Circular Std Book;
    letter-spacing: 0px;
    color: #717171;
    opacity: 1;
  }
  &.show_stats {
    font-size: 1.8rem;
    text-align: left;
    font: normal normal normal 1.8rem Circular Std Book;
    letter-spacing: 0px;
    color: #717171;
    opacity: 1;
  }
  &:hover {
    & + .tooltip {
      display: flex;
    }
  }
  &.minWidth {
    width: 15rem;
  }
`;

export const Tooltip = styled.div`
  display: none;
  font-size: ${fontSize.medium};
  color: ${({ theme }) => theme.contrast.tertiary};
  position: absolute;
  top: -40px;
  width: max-content;
  max-width: 350px;
  height: fit-content;
  justify-content: center;
  align-items: center;
  bottom: -15px;
  left: 0;
  border-radius: 4px;
  z-index: 1;
  padding: 8px;
  word-wrap: break-word;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    left: 10%;
    width: 0;
    height: 0;
    border-bottom: 5px solid transparent;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }
`;

export const ContentText = styled(ContentTextTemp)`
  font-size: 2rem;
  position: relative;
  display: flex;
  /* background: red; */

  align-items: center;
  &:hover ${LabelToolTip} {
    display: flex;
  }
  &.table {
    padding: 2rem 0rem 1rem;
  }

  &.formLabel {
    font-size: 1.8rem;
  }
`;

export const DetailsContainer = styled(DetailsContainerTemp)`
  /* background-color: red; */
  flex-wrap: wrap;
  display: flex;
  & > div.detail + div.detail {
    /* margin-right: 3rem; */
    /* background-color: green; */
  }
`;

export const Image = styled.img`
  widht: 7rem;
  height: 7rem;
`;
