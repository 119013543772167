/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable dot-notation */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { editIcon } from '../../../assets/icons';
import { FlexContainer } from '../../../globalStyles';
import { ActivityCreators } from '../../../views/Activity/store';
import ButtonComp from '../../Common/Button/Button.view';
import { Span } from '../../Common/CommonStyles';
import DynamicSVGIcon from '../../Common/DynamicSVGIcon';
import HeaderComp from '../../Common/Header/Header';
import KeyValueLayout from '../../Common/KeyValueLayout/KeyValueLayout';
import Loader from '../../Common/Loader/Loader';
import { BodyContainer, LoaderContainer } from './ActivtyDetails.style';
import { format } from '../../../config/index';

const ActivityDetails = (props) => {
  const activityDetails = useSelector((state) => state.activityData.details);
  const { loading } = useSelector((state) => state.activityData);
  const { location } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { details } = location.state;
  const { activityId } = details;
  useEffect(() => {
    if (activityId) {
      dispatch(ActivityCreators.getActivityDetails({ activityId }));
    }
  }, [activityId]);

  const handleEdit = () => {
    history.push('/home/activity/edit', { detail: activityDetails });
  };
  const handleBack = () => {
    history.push('/home/activity');
  };
  return loading ? (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  ) : (
    <>
      <HeaderComp
        isActivity={true}
        handleBack={handleBack}
        className='fixed-height'
        header={activityDetails?.title}
        hasBack
      >
        <ButtonComp dark onClick={() => {}}>
          <FlexContainer className='jc_center ai_center' onClick={handleEdit}>
            <DynamicSVGIcon width='2.2rem' iconUrl={editIcon} />
            <Span>Edit</Span>
          </FlexContainer>
        </ButtonComp>
      </HeaderComp>
      <BodyContainer>
        <KeyValueLayout
          name='Title'
          labelValue={activityDetails?.title || '-'}
        />
        <KeyValueLayout name='Type' labelValue={activityDetails?.type || '-'} />
        <KeyValueLayout
          name='Description'
          labelValue={
            <p
              dangerouslySetInnerHTML={{
                __html: activityDetails?.description || '-'
              }}
            />
          }
        />
        <KeyValueLayout
          name='Created On'
          labelValue={
            moment(activityDetails?.createdDate).format(format) || '-'
          }
        />
      </BodyContainer>
    </>
  );
};

export default ActivityDetails;
