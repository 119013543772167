import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Div, FlexContainer } from '../../../globalStyles';
import DynamicSVGIcon from '../../Common/DynamicSVGIcon';
import DropArrow from '../../../assets/icons/layout/dropdownarrow.svg';
import { Header } from './CreateWorkorder.style';
import { HeaderText } from '../WorkOrderDetails/WorkOrderDetails.style';
import { Bttn } from '../../../views/WorkOrders/WorkOrders.styles';

const Index = ({ children, handleBack }) => {
  const history = useHistory();
  const onClickCancel = () => {
    history.goBack();
  };
  const onBack = () => history.goBack();
  const { type } = useSelector((state) => state.upsertWorkOrder);

  return (
    // header component having only header buttons passed as props
    <Header className='jc_spacebetween'>
      <FlexContainer className='ai_center'>
        <Div className='pointer' onClick={handleBack || onBack} style={{ paddingRight: '1rem' }}>
          <DynamicSVGIcon iconUrl={DropArrow} width='2.2rem' rotate='180' />
        </Div>
        <HeaderText>{`Create ${type.Type || ''} Work Order`}</HeaderText>
      </FlexContainer>
      <Div>
        {/* <Bttn className='white' onClick={onClickCancel}>
          Cancel
        </Bttn>
        <Bttn disabled={disableNext} onClick={onClick}>
          {name}
        </Bttn> */}
        {children}
      </Div>
    </Header>
  );
};

export default Index;
