import styled from 'styled-components';
import { device } from '../../constants';

export const BodyContainer = styled.div`
  margin: 2rem;
  background: ${({ theme }) => theme.body};
  min-height: calc(100% - 8rem - 4rem); // header margin
  /* height: 98%; */
  box-shadow: -5px -5px 11px #fffffff5;
  border-radius: 10px;
  padding: 5rem 4rem;
  @media ${device.tablet} {
    min-width: 64rem;
  }
`;
export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  /* align-items: center; */
`;
