/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable import/no-named-as-default */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Creators as UpserWorkOrderCreators, Creators as UpsertWorkOrderCreators } from '../CreateWorkOrder/store';
import { StatusBtnContainer } from './WorkOrderDetails.style';
import Button from '../../Common/Button/Button.view';
import CommonForm from '../CreateWorkOrder/CommonForm/CommonForm.view';
import useGetWorkOrderTypes from '../CreateWorkOrder/hooks/useGetWorkOrderTypes';
import WoListModal from './WoListModal';
import { WebService } from '../../../services/axios/webServices';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { WorkOrderCreators } from '../../../views/WorkOrders/store';
import { WO_BUTTON_LABLE } from '../../../constants';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';
import Toast from '../../Common/Toast';
import { useActions } from '../../../store/hooks/useActions';

const RenderButtons = () => {
  const dispatch = useDispatch();
  const { type, workOrderTypes, nextActions, workOrderId, formError } = useSelector((state) => state.workOrders);
  const { getFormStructureFailure } = useActions();
  const [formForStatus, setFormForStatus] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [assetListModal, setAssetListModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  useGetWorkOrderTypes();
  const params = useParams();
  const err = error || formError;
  const handleStatusClick = async (btn, label) => {
    try {
      setShowModal(true);
      if (workOrderTypes) {
        const WOType = workOrderTypes.find((wo) => wo.Type === type);
        // TODO: WOType undefined
        dispatch(
          WorkOrderCreators.getFormStructure({
            WFTid: WOType?.workflowTemplateId,
            formName: btn,
            workOrderId: params.workOrderId
          })
        );
        setFormForStatus(label);
      }
    } catch (e) {
      setError(e);
    }
  };

  const handleAssetListModal = async (ele) => {
    console.log(ele, 'ele');
    setAssetListModal(true);
    let fullUrl;
    let submitUrl;
    let searchFilterType;
    if (ele === 'Add Parts') {
      fullUrl = `${apiEndPoints.GetJobSpares}`;
      submitUrl = apiEndPoints.addPartAdd;
      searchFilterType = {
        SearchFilters: {
          type: ' AddParts '
        }
      };
    }
    if (ele === 'Exchange Parts') {
      fullUrl = `${apiEndPoints.exchangeParts}`;
      submitUrl = apiEndPoints.ExchangePartAdd;
      searchFilterType = {
        SearchFilters: {
          type: ' ExchangeParts '
        }
      };
    }
    // const fullUrl = `${apiEndPoints.GetJobSpares}`;
    const page = 1;
    const pageSize = 20;
    try {
      const res = await WebService.post(`${fullUrl}${workOrderId}/${page}/${pageSize}`, searchFilterType);
      if (res.status === 200) {
        const { meta, data } = res.data;
        dispatch(UpsertWorkOrderCreators.getAssetListSuccess({ assetListMeta: meta, assetListData: data, submitUrl }));
        setLoading(false);
      } else {
        throw res;
      }
    } catch (err) {
      console.log(err, 'error');
      const payload = sagaCatchBlockHandling(err);
      setError(payload?.error);
    } finally {
      setLoading(false);
    }
  };
  const handleClearToast = () => {
    getFormStructureFailure({ error: '' });
    setError('');
  };

  return (
    <StatusBtnContainer>
      {nextActions?.map((btn, i) => (
        <Button
          disabled={!btn.enabled}
          onClick={() =>
            btn.label === WO_BUTTON_LABLE.ADD_PARTS || btn.label === WO_BUTTON_LABLE.EXCHANGE_PARTS
              ? handleAssetListModal(btn.label)
              : handleStatusClick(btn.value, btn.label)
          }
          dark={true}
          key={i}
          margin='0rem 0rem 1rem'
        >
          {btn.label}
        </Button>
      ))}
      {assetListModal && <WoListModal setAssetListModal={setAssetListModal} loading={loading} />}
      {showModal && <CommonForm formForStatus={formForStatus} setShowModal={setShowModal} />}
      {err && <Toast message={err} header='Error' style={{ right: 30, zIndex: '310' }} fn={handleClearToast} />}
    </StatusBtnContainer>
  );

  // const btns = {
  //   [STATUSES.TO_BE_ASSIGNED]: [
  //     { value: 'Assign', label: 'Assign' },
  //     { value: 'CloseWO', label: 'Close Work Order' }
  //   ],
  //   [STATUSES.TO_BE_ACKNOWLEGED]: [
  //     { value: 'Acknowledge', label: 'Acknowledge' },
  //     { value: 'Reject', label: 'Reject' }
  //   ],
  //   [STATUSES.TO_BE_STARTED]: [
  //     { value: 'AttachChecklist', label: 'Attach Checklist' },
  //     { value: 'Start', label: 'Start' }
  //   ],
  //   [STATUSES.SPARE_REQUEST]: [
  //     { value: 'issueParts', label: 'Issue Parts' },
  //     { value: 'reqClosed', label: 'Request Closed' }
  //   ]
  // };
  // const handleStatusClick = (key) => {
  //   switch (key) {
  //     case 'Assign':
  //       setShowAssignModal(true);
  //       break;
  //     case 'Reject':
  //       dispatch(UpserWorkOrderCreators.setWorkOrderDetail({ status: STATUSES.TO_BE_ASSIGNED }));
  //       break;
  //     case 'Acknowledge':
  //       dispatch(UpserWorkOrderCreators.setWorkOrderDetail({ status: STATUSES.TO_BE_STARTED }));
  //       break;

  //     default:
  //       break;
  //   }
  // };
};

export default RenderButtons;
