/* eslint-disable max-len */
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';
import { Div, FlexContainer } from '../../../globalStyles';
import DropArrow from '../../../assets/icons/layout/dropdownarrow.svg';
import { Header, HeaderContainer } from '../CommonStyles';
import DynamicSVGIcon from '../DynamicSVGIcon';
import { device, fontFamily } from '../../constants';

const HeaderComp = ({ header, hasBack, children, handleBack, className, isActivity }) => {
  const history = useHistory();
  const onClickCancel = () => {
    history.goBack();
  };
  const onBack = () => history.goBack();

  return (
    // header component having only header buttons passed as props
    <HeaderContainer isActivity={isActivity} className={`jc_spacebetween ${className}`}>
      <FlexContainer className='ai_center'>
        {hasBack && (
          <Div className='pointer' onClick={handleBack || onBack} style={{ paddingRight: '1rem' }}>
            <DynamicSVGIcon iconUrl={DropArrow} width='2.2rem' rotate='180' />
          </Div>
        )}
        <HeaderText>{header}</HeaderText>
      </FlexContainer>
      <Div style={{ zIndex: 10000 }}>
        {/* <Bttn className='white' onClick={onClickCancel}>
          Cancel
        </Bttn>
        <Bttn disabled={disableNext} onClick={onClick}>
          {name}
        </Bttn> */}
        {children}
      </Div>
    </HeaderContainer>
  );
};

export const HeaderText = styled.div`
  color: '#4A4A4A';
  font: 2.2rem ${fontFamily.circularMedium};
  position: relative;
  display: flex;
  justify-content: center;
  &.inner {
    cursor: pointer;
  }
  @media ${device.tablet} {
  font: 1.2rem ${fontFamily.circularMedium};
    
  }
  /* &.active {
    background-color: red;
  } */
`;

export default HeaderComp;
