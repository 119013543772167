/* eslint-disable max-len */
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { combineReducers } from 'redux';

import { configReducer } from '../config/reducer';
import { loginReducer } from '../views/auth/store/reducer';
import { homeReducer } from '../views/Layout/store';
import { localeStringsReducer } from '../localeStrings';
import { globalReducer } from '../component/GlobalSearch/store';
import { FilterReducer } from '../views/Filters/store';
import { upsertWorkOrderReducer } from '../component/WorkOrder/CreateWorkOrder/store';
import { spareDetailsReducer } from '../views/Stores/Spares/store';
import { workOrdersReducer } from '../views/WorkOrders/store';
import { activityReducer } from '../views/Activity/store';
import { scheduleTypesReducer } from '../views/ScheduleType/store/reducer';
import { scheduleReducer } from '../views/Schedule/store/reducer';
import { plansReducer } from '../views/Plan/store';
import { UsersReducer } from '../views/Users/store';
//

const configDataPersistConfig = {
  key: 'configData',
  storage
};

const loginDataPersistConfig = {
  key: 'loginData',
  storage,
  blacklist: ['token']
};

const filtersDataPersistConfig = {
  key: 'filtersData',
  storage,
  blacklist: ['filterData', 'menuIdofFilter', 'selectedParent', 'searchResult', 'iframeRes', 'showFilterResult']
};

const rootReducer = combineReducers({
  configData: persistReducer(configDataPersistConfig, configReducer),
  loginData: persistReducer(loginDataPersistConfig, loginReducer),
  filterData: persistReducer(filtersDataPersistConfig, FilterReducer),
  home: homeReducer,
  upsertWorkOrder: upsertWorkOrderReducer,
  localeStrings: localeStringsReducer,
  globalSearchData: globalReducer,
  spareDetails: spareDetailsReducer,
  workOrders: workOrdersReducer,
  activityData: activityReducer,
  scheduleData: scheduleReducer,
  scheduleTypesData: scheduleTypesReducer,
  plansData: plansReducer,
  usersDetails: UsersReducer
});

export default rootReducer;
