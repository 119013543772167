import styled from 'styled-components';
import { fontFamily, fontSize } from '../../../../constants';
import { FlexContainer } from '../../../../globalStyles';
import { ContentTextTemp } from '../../CommonStyles';

export const EventsSidePanelContainer = styled.div`
  width: 25%;
  background: #ffffff;
  margin-left: 1rem;
  /* padding: 1rem; */
  position: relative;
`;

export const HeaderContainer = styled(FlexContainer)`
  position: absolute;
  width: 100%;
  padding: 1rem;
  background: #f6f7f9 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  justify-content: space-between;
  align-items: center;
`;

export const Label = styled(ContentTextTemp)`
  padding-right: 1rem;
  color: #717171;
  font-size: 2rem;
  font-style: ${fontFamily.circularMedium};
`;
export const Value = styled(ContentTextTemp)`
  color: #4a4a4a;
  font-size: 2rem;
  font-family: ${fontFamily.circularMedium};
`;

export const MainContainer = styled.div`
  padding: 1rem 1rem;
  margin-top: 4rem;
  height: calc(100% - 3.6rem);
  overflow-y: auto;
`;

export const EventContainer = styled.div`
  display: flex;
  background: #eeeff5;
  border-left: 1.5rem solid #4f4ed0;
  /* background: #4f4ed0 0% 0% no-repeat padding-box; */
  box-shadow: -5px -5px 11px #fffffff5;
  border-radius: 10px 0px 0px 10px;
  margin-top: 5px;
  align-items: center;
  justify-content: space-between;
  height: 10rem;
  max-height: 10rem;
`;

export const EventTitleContainer = styled.div`
  padding: 2rem 0rem 2rem 1.5rem;
  display: block;
  max-width: 80%;
  height: 100%;
`;

export const EventContent = styled.div`
  font-size: 2rem;
  color: #4a4a4a;
  font-family: ${fontFamily.circularBold};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const TimingContainer = styled.div`
  background: #4f4ed0;
  color: #ffffff;
  /* width: 9rem; */
  max-width: 9rem;
  border-radius: 0px 10px 10px 0px;
  padding: 2rem 5px;
  height: 100%;
`;
