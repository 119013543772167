/* eslint-disable max-len */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FlexContainer } from '../../../../globalStyles';
import { Creators as UpsertWorkOrderCreators } from '../store';
import TabsTable from '../../../Common/Tabs_Table';
import Header from '../Header';
import { Bttn } from './AssetSelection.style';
import { WorkOrderCreators } from '../../../../views/WorkOrders/store';

export default function AssetShortlist() {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    type,
    assetShortlist,
    assetListMeta,
    workOrderCreationFrom
    // , assetSearchQueries
  } = useSelector((state) => state.upsertWorkOrder);
  const { assetType } = useSelector((state) => state.filterData);
  const planDetails = useSelector((state) => state.plansData.planDetails);
  // duplicate
  const handleShortList = (entry) => {
    const check = assetShortlist.some(
      (ele) => ele.mainPartNumber === entry.mainPartNumber && ele.inventPartnumber === entry.inventPartnumber
    );
    if (!check) {
      dispatch(UpsertWorkOrderCreators.setAssetShortlist([...assetShortlist, entry]));
    } else {
      dispatch(
        UpsertWorkOrderCreators.setAssetShortlist(
          assetShortlist.filter(
            (ele) => !(ele.mainPartNumber === entry.mainPartNumber && ele.inventPartnumber === entry.inventPartnumber)
          )
        )
      );
    }
  };
  const handleNext = () => {
    dispatch(
      WorkOrderCreators.getUserDataByTemplateId({
        WFTid: type.workflowTemplateId,
        assetType,
        workOrderCreationFrom,
        scheduleId: planDetails.scheduleId,
        selections: assetShortlist
      })
    );
    history.push('/home/work_orders/create_WO/form');
  };

  // const setAssetSearchQueries = (searchQueries) => {
  //   dispatch(UpsertWorkOrderCreators.setAssetSearchQueries(searchQueries));
  // };
  // console.log('assetSearchQueries', assetSearchQueries);
  return (
    <div style={{ height: '100%' }}>
      <Header>
        {/*  name='Next' onClick={handleNext} disableNext={isDisabled} */}
        <FlexContainer className='ai_center'>
          <Bttn disabled={!assetShortlist.length} onClick={handleNext}>
            Next
          </Bttn>
        </FlexContainer>
        {/* <Bttn onClick={handleNext}>
          Next
          {assetShortlist.length > 0 && <ItemsNo>{assetShortlist.length}</ItemsNo>}
        </Bttn> */}
      </Header>
      <TabsTable
        handleShortList={handleShortList}
        //   handlePageChange={handlePageChange}
        showPagiation={false}
        assetShortlist={assetShortlist}
        handleTabSelect={() => {}}
        // pageSize={() => {}}
        metaDetails={assetListMeta}
        dataDetails={assetShortlist}
        assetListLoading={false}
        onItemSelect={() => {}}
        // saveSearchQueries={setAssetSearchQueries}
        // savedSearchQueries={assetSearchQueries}
      />
    </div>
  );
}
