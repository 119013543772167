/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { SUCCESS } from '../../../constants';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { WebService } from '../../../services/axios/webServices';
import { isEmpty } from '../../../shared/utility/isEmpty';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';
import Toast from '../../Common/Toast';
import ScheduleTypeForm from '../ScheduleTypeForm/ScheduleTypeForm';
import { formatPayload, formatToScheduleTypeForm } from '../utility';

const EditScheduleType = () => {
  // dispatch(SchedultTypesCreators.getScheduleTypeDetails(location.state.detail.scheduleTypeId));
  const scheduleTypesData = useSelector((state) => state.scheduleTypesData.details);
  const { scheduleListMeta } = useSelector((state) => state.scheduleTypesData);
  const [formData, setFormData] = useState({});
  const [error, setError] = useState('');

  useEffect(() => {
    if (!isEmpty(scheduleTypesData)) {
      setFormData(formatToScheduleTypeForm(scheduleTypesData));
    }
  }, [scheduleTypesData]);

  const history = useHistory();
  const handleSave = async (payload, dropdownData, setSubmitLoader) => {
    setSubmitLoader(true);

    try {
      const p = formatPayload(payload, dropdownData);
      const res = await WebService.post(apiEndPoints.UpdateScheduleType, {
        data: [p]
      });
      if (res.data?.message === SUCCESS) {
        // console.log('pushing scheduleTypeId', res.data?.id);
        history.push({
          pathname: '/home/schedule-type/details',
          state: {
            details: {
              ...payload,
              // new id of the updated schedule type
              scheduleTypeId: res.data?.id
            }
          }
        });
      } else {
        setError(res.data?.message);
      }
    } catch (err) {
      console.log('error facing location:', err);
      const payload = sagaCatchBlockHandling(err);
      setError(payload?.error);
    } finally {
      setSubmitLoader(false);
    }
  };
  return (
    <>
      <ScheduleTypeForm isEdit={true} data={formData} handleSave={handleSave} />
      {error && <Toast message={error} header='Error' style={{ right: 30, zIndex: 20 }} fn={() => setError('')} />}
    </>
  );
};

export default EditScheduleType;
