import styled from 'styled-components';
import { fontFamily } from '../../../constants';
import { Button } from '../../Common/Button/Button.view';
import {
  BodyContainer as BodyContainerTemp,
  HeaderContainer as HeaderContainerTemp,
  Header as HeaderTemp,
  NormalContent as NormalContentTemp,
  ContentTextTemp,
  DetailsContainerTemp,
  DetailSectionTemp,
  CommentInputContainerTemp
} from '../../Common/CommonStyles';
import { device } from '../../constants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const HeaderContainer = styled(HeaderContainerTemp)`
  & * {
    fill: ${({ theme }) => theme.contrast.primary} !important;
  }

  &.inner {
    margin: 0rem;
    border-bottom: 1px solid #707070;
    & > div {
      display: flex;
      & > div {
        margin-right: 2rem;
      }
    }
  }
`;

export const BodyContainer = styled(BodyContainerTemp)`
  background-color: #ffffff;
  flex: 1;
  margin: 1.5rem 1%;
  box-shadow: -5px -5px 11px #fffffff5;
  border-radius: 10px;
  overflow: auto;
  @media ${device.tablet} {
  flex-direction: column;
  }
`;

export const NormalContent = styled.span`
  color: #8e8e8e;
  font: 2.4rem ${fontFamily.circularMedium};

  &.imp {
    margin-left: 2rem;
    color: #202020;
    /* font-family: ${fontFamily.circularBook}; */
  }
  @media ${device.tablet} {
    font: 1.4rem ${fontFamily.circularMedium};

  }
`;

export const SpacedDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StatusContainer = styled(SpacedDiv)`
  margin: 4rem 0rem;
`;

export const UpdatedStatusText = styled.div`
  color: #393939;
  font: 1.4rem ${fontFamily.circularBook};
`;

export const HeaderText = styled(HeaderTemp)`
  position: relative;
  display: flex;
  justify-content: center;
  &.inner {
    cursor: pointer;
  }
   @media ${device.tablet} {
    font: 1.2rem ${fontFamily.circularMedium};

  }
  /* &.active {
    background-color: red;
  } */
`;

export const DetailsContainer = styled(DetailsContainerTemp)`
  /* background-color: red; */
  flex-wrap: wrap;
  display: flex;
  & > div.detail + div.detail {
    /* margin-right: 3rem; */
    /* background-color: green; */
  }
`;

export const LabelContainer = styled.div``;

export const DetailSection = styled(DetailSectionTemp)``;

export const ActiveDivider = styled.div`
  position: absolute;
  bottom: calc(-1.2rem - 1.5px);
  width: 60%;
  height: 3px;
  border-radius: 3px;
  background-color: #000000;
`;

export const CommentInputContainer = styled(CommentInputContainerTemp)``;

export const InputCommentContainer = styled.div`
  padding-left: 6rem;
  margin-bottom: 1rem;
`;

export const SidePanelContainer = styled.div`
  flex: 1;
  border: 1px solid #707070;
  border-radius: 10px;
  /* height: 100%; */
  margin: 1rem 0rem 1rem 1rem;
  overflow-y: auto;
`;

export const ItemContainer = styled.div`
  background-color: #f4f4f8;
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.shadowout};
  margin: 2rem 2.5rem 0rem 2.5rem;

  padding: 2rem;
  & .mainDetails {
    font: 1.8rem ${fontFamily.circularMedium};
    color: #4a4a4a;
    margin-bottom: 0.5rem;
  }

  & .subDetails {
    font: 1.4rem ${fontFamily.circularMedium};
    color: #393939;
  }

  &.selectedFile {
    color: #393939;
    background-color: #afafbb;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 6rem;
`;

export const StatusBtnContainer = styled.div`
  margin-bottom: 5rem;
  & > button {
    margin-right: 3rem;
  }
`;

export const NormalText = styled.div`
  color: #717171;
  font: 2rem ${fontFamily.circularMedium};
  &.hightlight {
    color: #000000;
  }

`;
export const Main = styled.div`
  width: 75%;
  height: 100%;
  overflow-y: auto;
  padding-right: 5px;
  @media ${device.tablet} {
  width: 100%;
  height: 75%;
  }
`;

export const AddButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.customTheme.primary};
  font-size: 16px;
  padding: 1rem;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
  }
`;

export const LabelToolTip = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.customTheme.primary};
  width: max-content;
  max-width: 350px;
  /* height: fit-content; */

  /* display: flex; */
  display: none;
  justify-content: center;
  align-items: center;
  /* bottom: -15px; */
  left: 0;
  border-radius: 4px;
  z-index: 1;
  padding: 5px 10px;
  top: -100%;

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 100%;
    width: 0;
    height: 0;
    border-bottom: 5px solid ${({ theme }) => theme.customTheme.primary};
    border-top: 5px solid transparent;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }
  &.top {
    top: -4.2rem;
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 100%;
      left: 10%;
      width: 0;
      height: 0;
      border-top: 5px solid ${({ theme }) => theme.customTheme.primary};
      border-bottom: 5px solid transparent;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }
  }
`;

export const ContentText = styled(ContentTextTemp)`
  font-size: 2rem;
  position: relative;
  display: flex;
  align-items: center;
  &:hover ${LabelToolTip} {
    display: flex;
  }
  &.table {
    padding: 2rem 0rem 1rem;
  }
  @media ${device.tablet} {
      font-size: 1.5rem;
    
  }
`;

export const ButtonComponent = styled(Button)`
  /* background: red; */
`;

export const CountSpan = styled.div`
  position: absolute;
  bottom: 3rem;
  right: 2rem;
  background: white;
  color: black;
  border-radius: 8px;
  width: 12px;
`;

export const ModalListHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  width: 97%;
  z-index: 2;
  height: 8rem;
  padding: 3rem;
  background: #eeeff5;
`;

// export const LogsContainer = styled.div`
//   padding: 2px;
//   max-height: 28rem;
//   overflow-y: auto;
// `;

export const RemarkContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  width: 100%;
`;

export const EditorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const EditorBtn = styled.div`
  padding-top: 2rem;
  display: flex;
  gap: 1rem;
   @media ${device.tablet} {
    padding-top: 4rem;

    
  }
`;
