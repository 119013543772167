/* eslint-disable max-len */
/* eslint-disable indent */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { SUCCESS } from '../../../constants';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { WebService } from '../../../services/axios/webServices';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';
import Toast from '../../Common/Toast';
import AddActivityForm from '../AddActivity/AddActivityForm';

const EditActivity = () => {
  const history = useHistory();
  const location = useLocation();
  const [error, setError] = useState('');
  const updateActivity = async (ele, setSubmitLoader) => {
    setSubmitLoader(true);

    // eslint-disable-next-line prefer-const
    let newinput = {
      data: [ele]
    };

    try {
      const fullUrl = `${apiEndPoints.updateActivity}`;
      // eslint-disable-next-line prefer-const, object-shorthand
      let res = await WebService.post(fullUrl, JSON.stringify(newinput));
      if (res.data?.message === SUCCESS) {
        history.push({
          pathname: '/home/activity/details',
          state: { details: { ...ele, activityId: res.data?.id } }
        });
      } else {
        setError(res.data?.message);
      }
    } catch (err) {
      console.log('error facing location:', err);
      const payload = sagaCatchBlockHandling(err);
      setError(payload?.error);
    } finally {
      setSubmitLoader(false);
    }
  };
  return (
    <>
      <AddActivityForm detail={location.state.detail} handleSave={updateActivity} />
      {error && <Toast message={error} header='Error' style={{ right: 30, zIndex: 20 }} fn={() => setError('')} />}
    </>
  );
};

export default EditActivity;
