/* eslint-disable max-len */
import { isEmpty } from '../../../shared/utility/isEmpty';

// drawbacks space complexity is high
// const getCumTagsOfGroup = (group) => {
//   // while (!isEmpty(group.values)) {
//   //   finalObj = group.values.map((e) => e);
//   // }

//   const finalObj = [];
//   const stack = [];
//   stack.push(...group.values);
//   let pointer = stack.pop();

//   const transformedObj = {};
//   while (!isEmpty(pointer)) {
//     const { groupTitle, attribute, id, name, values, prevId } = pointer;
//     console.log('pointer', pointer, stack);
//     const checkParentObj = transformedObj[prevId];
//     let nodeDetails = [{ id, attribute, name, groupTitle }];

//     if (!isEmpty(checkParentObj)) {
//       nodeDetails = [...checkParentObj, ...nodeDetails];
//     }
//     transformedObj[id] = nodeDetails;
//     console.log('transformedObj', transformedObj);
//     if (!isEmpty(values)) {
//       stack.push(...values.map((e) => ({ ...e, prevId: id })));
//     } else {
//       finalObj.push(nodeDetails);
//     }
//     pointer = stack.pop();
//   }
//   return finalObj;
// };

// const getCumTagsOfGroup = (obj, curObj, finalObj) = {
//   // const {id, name, attribute} = obj;
//   // if(id){
//   //   curObj.push({id, name, attribute})
//   // }
// }

// recursive solution
const getCumTagsOfGroup = (obj, curObj, finalObj, completeObj, curCompleteObj, finalCompleteObj) => {
  const { id, name, attribute, ...rest } = obj;
  let findCompleteObj = completeObj;
  if (id) {
    let curObjPayload = { id: `_${id}`, name, attribute };
    findCompleteObj = findCompleteObj?.values?.find((e) => e.id === id);
    if (obj.rawValue) {
      curObjPayload = { ...curObjPayload, ...rest };
      findCompleteObj = { ...findCompleteObj, ...rest, isEnd: true };
    }
    curObj.push(curObjPayload);
    // eslint-disable-next-line no-param-reassign
    curCompleteObj[`_${findCompleteObj?.id}`] = findCompleteObj;
  }
  if (isEmpty(obj.values)) {
    finalObj.push([...curObj]);
    finalCompleteObj.push({ ...curCompleteObj });
    return;
  }
  obj.values.forEach((e) => {
    getCumTagsOfGroup(e, [...curObj], finalObj, findCompleteObj, { ...curCompleteObj }, finalCompleteObj);
  });
};

export const populateFilterDashboard = (payload, filterData) => {
  const { menuid, tab, data } = payload;
  const cumTags = {};
  const cumSelectedObj = {};
  const groupMapping = {};
  Object.keys(filterData).forEach((key) => {
    if (filterData[key][0].groupTitle) {
      groupMapping[filterData[key][0].groupTitle] = key;
    }
  });
  Object.keys(data).forEach((groupKey) => {
    const finalObj = [];
    const finalCompleteObj = [];
    // console.log(
    //   'sa',
    //   filterData,
    //   (filterData[groupKey.toLowerCase()] && filterData[groupKey.toLowerCase()][0]) ||
    //     filterData[groupMapping[groupKey]][0],
    //   groupMapping,
    //   groupKey,
    //   groupMapping[groupKey]
    // );
    if (!isEmpty(data[groupKey])) {
      const { ...restOfObj } = data[groupKey][0] || data[groupKey];
      getCumTagsOfGroup(
        restOfObj,
        [],
        finalObj,
        (filterData[groupKey.toLowerCase()] && filterData[groupKey.toLowerCase()][0]) ||
        filterData[groupMapping[groupKey]],
        {},
        finalCompleteObj
      );
      if (data[groupKey][0]?.groupTitle) {
        cumTags[data[groupKey][0].groupTitle] = finalObj;
        cumSelectedObj[data[groupKey][0].groupTitle] = finalCompleteObj;
      }
    }
    // console.log('groupKey.', data[groupKey].groupTitle, cumTags, cumTags[data[groupKey].groupTitle]);
  });
  // console.log('final....', cumTags, 'cumSelectedObj', cumSelectedObj);
  return { cumTags, cumSelectedObj };
};

// this will return payload with all selections and tags
export const getFilterSelectionInp = (cumTags, cumSelectedObj) => {
  const payload = Object.keys(cumSelectedObj).reduce(
    (prev, group) => ({
      ...prev,
      [`cumulative${group}`]: { ...cumSelectedObj[group].map((e) => ({ ...e })) },
      [group]: { ...cumSelectedObj[group][0] }
    }),
    {}
  );
  const tempCumTags = Object.keys(cumTags).reduce(
    (prev, e) => ({ ...prev, [e]: { ...cumTags[e].map((e1) => ({ ...e1 })) } }),
    {}
  );
  // console.log('tempCumTags', tempCumTags, cumTags);
  payload.cumulativeTags = tempCumTags;
  payload.tags = Object.keys(cumTags).reduce((prev, curr) => ({ ...prev, [curr]: { ...cumTags[curr][0] } }), {});
  payload.showFilter = true;
  return payload;
};
