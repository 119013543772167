import styled from 'styled-components';
import { device, fontFamily } from '../../../constants';
import {
  Modal as ModalTemplate,
  RightAlignedModalContainer
} from '../../../Common/PopUpModal/Modal.style';
import { fontSize } from '../../../../constants';
import { Div } from '../../../../globalStyles';

export const Modal = styled(ModalTemplate)`
  overflow: hidden;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & .dynamicSVGIcon {
    margin-right: 2rem;
  }
`;

export const ModalContainer = styled(RightAlignedModalContainer)`
  width: 40%;
  padding: 3.5rem;
  animation: lefttoright 300ms 1;

  @keyframes lefttoright {
    from {
      right: -100%;
    }
    to {
      right: 0px;
    }
  }
  @media ${device.tablet} {
    width: 100%;
  }
`;

export const CancelContainer = styled.div`
  position: absolute;
  right: 41%;
  top: 3rem;
  cursor: pointer;
`;

export const CancelContainerMobile = styled.div`
  position: absolute;
  right: 2%;
  top: 2%;
  cursor: pointer;
  display: none;
  @media ${device.tablet} {
    display: block;
  }
`;

export const AttachmentsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ItemContainer = styled.div`
  min-width: 45%;
  margin: 0rem 2rem 2rem 0rem;
  background-color: #eaebf2;
  padding: 2rem;
  border-radius: 1.5rem;
`;

export const AttachmentText = styled.div`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.contrast.lightQuaternary};
  &.normal {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.contrast.darkSecondary};
  }
  &.file {
    margin-bottom: 1rem;
  }
`;

export const HeaderText = styled.div`
  font: 2.6rem ${fontFamily.circularMedium};
  color: #202020;
  margin-bottom: 1rem;
`;

export const DetailSection = styled(Div)`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;

  & > span {
    font-size: 18px;
    margin: 0 15px 0 10px;
  }
`;

export const ContentText = styled(Div)`
  color: #000000;
  font: ${fontSize.titleBig} ${fontFamily.circularMedium};
  max-width: 65%;

  &.label {
    color: #717171;
    width: 25%;
    word-break: break-word;
    @media ${device.tablet} {
      width: 50%;
      font-size: ${fontSize.text};
    }
  }
`;

export const SpareImage = styled.img`
  height: 50px;
  min-width: 60px;
  width: 60px;
  margin: 2rem 1rem;
  transition: transform 0.2s;
  &.active {
    transform: scale(1.4);
  }
`;

export const ThumbNailContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ overflow }) => !overflow && 'center'};

  /* justify-content: center; */
  width: 76%;
  margin: auto;
  overflow-x: auto;
`;

export const ModalExpandedBackground = styled.div`
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
`;

export const CloseIconContainer = styled.div`
  margin-top: 20px;
  margin-left: 96%;
  cursor: pointer;
`;

export const ZoomIconContainer = styled.div`
  display: flex;
  background-color: #000;
  /* width: 30px; */
  justify-content: space-between;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 1rem;
  margin-left: 1rem;
  /* margin: auto; */
  /* position: absolute; */
  bottom: 0;
  z-index: 99;
  /* right: 41%;
  top: 38%; */
  height: 60px;
  opacity: 0.7;
  flex-direction: column;

  @media ${device.tablet} {
    left: 40%;
  }
`;

export const CanvasWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 350px;
  height: 240px;
  /* background-color: white; */
  overflow: hidden;
  position: relative;
  /* margin: auto; */
`;

export const IconZoomDiv = styled.div`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;
