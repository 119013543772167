/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { FlexContainer } from '../../../../globalStyles';
import { Bttn } from '../../../../views/WorkOrders/WorkOrders.styles';
import KeyInputLayoutView from '../../../Common/KeyValueLayout/KeyInputLayout.view';
import InputDropdown from '../../../Common/DropDownModal/InputDropdown';
import { Creators as UserWorkOrderCreators } from '../store';
import useGetWorkOrderTypes from '../hooks/useGetWorkOrderTypes';
import { Creators as FilterCreators } from '../../../../views/Filters/store';
import DropDownModal from '../../../Common/DropDownModal/DropDownModal';

const SelectWOInputType = ({ setDisableNext, onCancel }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { type } = useSelector((state) => state.upsertWorkOrder);
  const { workOrderTypes } = useSelector((state) => state.workOrders);
  const [showDropdown, setShowDropdown] = useState(false);

  // useResetDisableNext(setDisableNext);

  useGetWorkOrderTypes();
  const handleType = (value) => {
    setShowDropdown(false);
    dispatch(UserWorkOrderCreators.setWorkOrderType(value));
  };
  useEffect(() => {
    // reset workorder type
    dispatch(UserWorkOrderCreators.setWorkOrderType({}));
    dispatch(
      FilterCreators.universalFilterSetter({
        key: 'showFilterResult',
        value: false
      })
    );
    dispatch(FilterCreators.resetFilterState());
    dispatch(UserWorkOrderCreators.resetAssetShortlist());
  }, []);

  // useEffect(() => {
  //   if (type.Type) setDisableNext(false);
  // });

  // const location = useLocation();

  // filtering workorders having onlyChild=0
  const workOrderTypesAvailable = workOrderTypes?.length && workOrderTypes.filter((e) => e.onlyChild === 0);
  // const isRenderedAsPage = location.pathname.includes('select_WO_type');

  // const handleCancel = (...params) => {
  //   if (onCancel) {
  //     onCancel(...params);
  //   } else {
  //     history.goBack();
  //   }
  // };

  // return (
  //   <>
  //     {isRenderedAsPage && (
  //       <Header>
  //         {/*  name='Next' onClick={handleNext} disableNext={isDisabled} */}
  //         <FlexContainer className='ai_center'>{NavigationalButtons}</FlexContainer>
  //       </Header>
  //     )}
  //     <div>
  //       <DropDownModal
  //         style={{ position: 'absolute' }}
  //         data={workOrderTypesAvailable}
  //         isActive={showDropdown}
  //         handleClick={handleType}
  //         setModalIsVisibal={() => setShowDropdown(!showDropdown)}
  //         label='workflowName'
  //         width='68rem'
  //       >
  //         <InputDropdown
  //           value={type?.Type || ''}
  //           handleDropdownVisibility={() => setShowDropdown(!showDropdown)}
  //           placeholder='Select Type of Work Order'
  //         />
  //       </DropDownModal>
  //     </div>
  //     {/* if this componnet is rendered as a seperate page
  //       then don't dispay the below btns
  //     */}
  //     {!isRenderedAsPage && (
  // <FlexContainer mt='6' mb='3.5' className='ai_center jc_end'>
  //         {NavigationalButtons}
  //       </FlexContainer>
  //     )}
  //   </>
  // );

  // refactored into component retrurning on WO selection input
  return (
    <div>
      <DropDownModal
        style={{ position: 'absolute' }}
        data={workOrderTypesAvailable}
        isActive={showDropdown}
        handleClick={handleType}
        setModalIsVisibal={() => setShowDropdown(!showDropdown)}
        label='workflowName'
        width={window.innerWidth > 769 && '68rem'}
      >
        <InputDropdown
          value={type?.Type || ''}
          handleDropdownVisibility={() => setShowDropdown(!showDropdown)}
          placeholder='Select Type of Work Order'
        />
      </DropDownModal>
    </div>
  );
};

export default SelectWOInputType;
