/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Step1 from '../../component/WorkOrder/CreateWorkOrder/Step1/SelectWOType.view';
import SelectWOTypeInPage from '../../component/WorkOrder/CreateWorkOrder/Step1/SelectWOTypeInPage';
import CreateWOStep2 from '../../component/WorkOrder/CreateWorkOrder/Step2/SelectionFilter';
import CreateWOStep3 from '../../component/WorkOrder/CreateWorkOrder/Step3/Step3';
import { useActions } from '../../store/hooks/useActions';

const CreateWORoutes = ({ createdFrom }) => {
  const { path } = useRouteMatch();
  const { setWorkOrderDetail } = useActions();

  useEffect(() => {
    if (createdFrom) {
      setWorkOrderDetail({
        workOrderCreationFrom: createdFrom
      });
    }
  }, [createdFrom]);

  return (
    <Switch>
      <Route
        path={`${path}/select_WO_type`}
        exact
        name='select_WO_type'
        render={(props) => <SelectWOTypeInPage {...props} />}
      />
      <Route path={`${path}/filter`} exact name='create_WO_filter' render={(props) => <CreateWOStep2 />} />
      {/* <Route path={`${path}/spares`} exact name='create_WO_spares' render={(props) => <>Select spares</>} /> */}
      <Route path={`${path}/form`} exact name='create_WO_form' render={(props) => <CreateWOStep3 />} />
    </Switch>
  );
};

export default CreateWORoutes;
