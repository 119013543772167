/* eslint-disable no-useless-escape */
/* eslint-disable max-len */
export const VALIDATION_TYPES = {
  FLOAT: 'float',
  DATE_WITH_TIME: 'dateWithTime',
  DATE_WITH_TIME_WITH_SECONDS: 'dateWithTimeSeconds',
  ISO_DATE_STRING: 'isoDateString',
  TIME: 'time',
  URL: 'url',
  EMAIL: 'email'
};

const regex = {
  [VALIDATION_TYPES.FLOAT]: /^[+-]?\d+(.\d+)?$/,
  [VALIDATION_TYPES.DATE_WITH_TIME]:
    /^([1-9]|([012][0-9])|(3[01]))-([0]{0,1}[1-9]|1[012])-\d\d\d\d[" "]+[012]{0,1}[0-9]:[0-6][0-9]$/,
  [VALIDATION_TYPES.DATE_WITH_TIME_WITH_SECONDS]:
    /^([1-9]|([012][0-9])|(3[01]))-([0]{0,1}[1-9]|1[012])-\d\d\d\d[" "]+[012]{0,1}[0-9]:[0-6][0-9]:[0-6][0-9]$/,
  [VALIDATION_TYPES.ISO_DATE_STRING]:
    /^([0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9]).*/,
  [VALIDATION_TYPES.TIME]: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?$/,
  [VALIDATION_TYPES.URL]: /http/i,
  // eslint-disable-next-line no-useless-escape
  [VALIDATION_TYPES.EMAIL]: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
};

export const validate = (string, type) => {
  const regEx = regex[type];
  return regEx.test(string);
};

export const validateNonEmptyStrings = (string, type) => {
  const regEx = regex[type];
  return string ? regEx.test(string) : true;
};
