/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-use-before-define */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DateRange } from 'react-date-range';
import { useSelector } from 'react-redux';
import DynamicSVGIcon from '../../DynamicSVGIcon';
import { DropDownArrow } from '../../../../assets/icons/common';
import { Div, FlexContainer } from '../../../../globalStyles';
import './DatePicker.styles.css';
// import DropDownModal from '../../DropDownModal/DropDownModal';

const DatePicker = (props) => {
  const { calendarApi: calendarApiObjProp, additionalProps } = props;
  const { dateOfWeekStart, weekStartsOn } = additionalProps || {};
  const activeRange = useSelector((state) => state.plansData.activeRange);
  const [showDropdown, setShowDropdown] = useState(false);
  const [state, setState] = useState({
    datePicker: {
      selection: {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
        isInit: true
      }
    }
  });
  const calendarApiObj = calendarApiObjProp?.currentDataManager;
  const updateSelection = (obj) => {
    // local update
    setState({
      datePicker: {
        ...state.datePicker,
        selection: {
          ...obj,
          key: 'selection'
        }
      }
    });
  };
  const handleSelection = (selectionObj) => {
    const obj = {
      ...onPreviewChange(selectionObj?.selection?.startDate)
    };
    updateSelection(obj);
    // calendar api update
    calendarApiObj.dispatch({
      type: 'CHANGE_DATE',
      dateMarker: obj?.endDate
    });
    setShowDropdown(false);
  };

  // const updatePreview = (val) => {
  //   console.log("preview vla", val);
  //   if (!val) {
  //     setState({
  //       ...state,
  //       datePicker: { ...state.datePicker, preview: null },
  //     });
  //     return;
  //   } else {
  //     setState({
  //       ...state,
  //       datePicker: {
  //         ...state.datePicker,
  //         preview: { ...val, color: "green" },
  //       },
  //     });
  //   }
  // };

  const onPreviewChange = (arg) => {
    try {
      const unit = calendarApiObj.data.viewSpec.durationUnit;
      const obj = {
        startDate: moment(arg).startOf(unit).toDate(),
        endDate: moment(arg).endOf(unit).toDate()
      };
      return obj;
    } catch (e) {
      return {};
    }
  };
  // update selection when change in viewType
  useEffect(() => {
    const currentRange = calendarApiObj.state?.dateProfile?.currentRange;
    const { start, end } = currentRange || {};
    updateSelection({
      startDate: new Date(start),
      endDate: new Date(moment(end).subtract(1, 'day').endOf('day'))
    });
  }, [activeRange]);

  return (
    <div className='date-picker-container'>
      <FlexContainer className='ai_center' onClick={() => setShowDropdown((prev) => !prev)}>
        <h2 className='inline-block'>{calendarApiObj?.data?.viewTitle}</h2>
        <Div ml={1} className='inline-block'>
          <DynamicSVGIcon rotate={showDropdown ? 270 : 90} width='1rem' iconUrl={DropDownArrow} />
        </Div>
      </FlexContainer>

      <div>
        {showDropdown && (
          <div
            style={{
              position: 'absolute'
            }}
          >
            <DateRange
              ranges={[state?.datePicker?.selection]}
              onChange={handleSelection}
              // focusedRange={state.datePicker.focusedRange}
              // onRangeFocusChange={handleRangeFocusChange}
              // preview={state.datePicker?.preview}
              // onPreviewChange={(value) => {
              //   updatePreview(value ? onPreviewChange(value) : null);
              // }}
              focusedRange={[0, 0]}
              displayMode='dateRange'
              weekStartsOn={weekStartsOn}
            />
          </div>
        )}
      </div>
      {/* <FontAwesomeIcon icon="fa-sharp fa-solid fa-chevron-down" /> */}
    </div>
  );
};

export default DatePicker;
