/* eslint-disable max-len */
import { takeLatest, takeEvery } from 'redux-saga/effects';
import { Types } from './actions';

// imports
import {
  loginSaga,
  carouselDataSaga,
  notificationDataSaga,
  onLogoutSaga
} from '../views/auth/store';
import { getMenuSaga } from '../views/Layout/store';
import {
  getFilterDataSaga,
  getIFrameDataSaga
} from '../views/Filters/store/saga';
import { getLocaleStringsSaga } from '../localeStrings';
import { setThemeColorsSaga } from '../config/saga';
import {
  getWorkOrdersSaga,
  getWorkOrderDetailsSaga,
  getWorkOrderTypesSaga,
  getWorkOrdersCountByGroupSaga,
  getFormStructureSaga,
  getUserDataByTemplateIdSaga,
  getRemarkSaga,
  getWOSidePanelDetailsSaga
} from '../views/WorkOrders/store';
import { getAssetListSaga } from '../component/WorkOrder/CreateWorkOrder/store/saga';
import {
  getSpareListSaga,
  getSpareDetailsSaga,
  getSpareListMenuSaga,
  getBulkDocumentListSaga
} from '../views/Stores/Spares/store/saga';
import {
  getActivitySaga,
  getActivityDetailsSaga
} from '../views/Activity/store';
import {
  getScheduleTypeSaga,
  getScheduleTypeDetailsSaga
} from '../views/ScheduleType/store';
import {
  getScheduleSaga,
  getScheduleDetailsSaga
} from '../views/Schedule/store';
import { getPlanDetailsSaga, getPlanListSaga } from '../views/Plan/store';
import { getUsersListSaga } from '../views/Users/store';

export function* watchAuth() {
  yield takeLatest(Types.LOGIN, loginSaga);
  yield takeLatest(Types.ON_LOGOUT, onLogoutSaga);
  yield takeLatest(Types.GET_MENU, getMenuSaga);
  yield takeLatest(Types.CAROUSEL_DATA, carouselDataSaga);
  yield takeLatest(Types.NOTIFICATION_DATA, notificationDataSaga);
  yield takeLatest(Types.GET_LOCALE_STRINGS, getLocaleStringsSaga);
  yield takeLatest(Types.SET_THEME_COLORS, setThemeColorsSaga);
  yield takeLatest(Types.GET_FILTER_DATA, getFilterDataSaga);
  yield takeLatest(Types.GET_I_FRAME_DATA, getIFrameDataSaga);
  yield takeLatest(Types.GET_WORK_ORDERS, getWorkOrdersSaga);
  yield takeLatest(Types.GET_WORK_ORDER_TYPES, getWorkOrderTypesSaga);
  yield takeLatest(Types.GET_WORK_ORDER_DETAILS, getWorkOrderDetailsSaga);
  yield takeLatest(
    Types.GET_WORK_ORDERS_COUNT_BY_GROUP,
    getWorkOrdersCountByGroupSaga
  );
  yield takeLatest(Types.GET_FORM_STRUCTURE, getFormStructureSaga);
  yield takeLatest(Types.GET_SPARE_LIST, getSpareListSaga);
  yield takeLatest(Types.GET_SPARE_LIST_MENU, getSpareListMenuSaga);
  yield takeLatest(Types.GET_BULK_DOCUMENT_LIST, getBulkDocumentListSaga);
  yield takeLatest(Types.GET_SPARE_DETAILS, getSpareDetailsSaga);
  yield takeLatest(Types.GET_ASSET_LIST, getAssetListSaga);
  yield takeLatest(
    Types.GET_USER_DATA_BY_TEMPLATE_ID,
    getUserDataByTemplateIdSaga
  );
  yield takeLatest(Types.GET_ACTIVITY, getActivitySaga);
  yield takeLatest(Types.GET_ACTIVITY_DETAILS, getActivityDetailsSaga);
  yield takeLatest(Types.GET_SCHEDULE, getScheduleSaga);
  yield takeLatest(Types.GET_SCHEDULE_DETAILS, getScheduleDetailsSaga);
  yield takeLatest(Types.GET_SCHEDULE_TYPE, getScheduleTypeSaga);
  yield takeLatest(Types.GET_SCHEDULE_TYPE_DETAILS, getScheduleTypeDetailsSaga);
  yield takeLatest(Types.GET_PLAN_LIST, getPlanListSaga);
  yield takeLatest(Types.GET_PLAN_DETAILS, getPlanDetailsSaga);
  yield takeLatest(Types.GET_WO_SIDE_PANEL_DETAILS, getWOSidePanelDetailsSaga);
  yield takeLatest(Types.GET_REMARK, getRemarkSaga);
  yield takeLatest(Types.GET_USERS_LIST, getUsersListSaga);
}
