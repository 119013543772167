import styled from 'styled-components';
import { device, fontFamily } from '../../../constants';

export const BulkUpdateHeader = styled.div`
  color: ${({ theme }) => theme.text.secondary};
  font-size: 16px;
  font-family: ${fontFamily.circularBold};
  @media ${device.tablet} {
    font-size: 16px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const UpdateBody = styled.div`
  margin-top: 2rem;
`;

export const UpdateInstruction = styled.p`
  color: ${({ theme }) => theme.text.gray_2};
  font-size: 14px;
  font-family: ${fontFamily.circularBold};
  margin-bottom: 2rem;
  text-transform: capitalize;
  @media ${device.tablet} {
    font-size: 12px;
  }
`;

export const DownloadSpan = styled.span`
  display: none;
`;
export const Instructions = styled.div`
  color: ${({ theme }) => theme.text.gray_2};
  font-size: 1.6rem;
  font-family: ${fontFamily.circularBook};
  div {
    font-size: 1.6rem;
  }
`;

export const BoldSpan = styled.span`
  color: ${({ theme }) => theme.core.loginPrimary};
  font-size: 1.6rem !important;
  font-family: ${fontFamily.circularBold};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  pointer-events: ${({ disabled }) => disabled && ''};
  text-decoration: underline;
  cursor: pointer;
`;

export const HighlightedText = styled.span`
  color: ${({ theme }) => theme.errorText};
  font-size: 1.6rem;
  font-family: ${fontFamily.circularBold};
`;

export const MaximumRestiriction = styled.div`
  color: ${({ theme }) => theme.text.gray_2};
  font-size: 12px;
  font-family: ${fontFamily.circularBook};
  margin-top: 2rem;
`;

export const UploadButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
`;

export const ProgressBarContainer = styled.div`
  width: 100%;
  /* height: 5rem; */
  box-shadow: ${({ theme }) => theme.shadowout};
  padding: 1rem;
  /* color: ${({ theme }) => theme.text.gray_2}; */
  font-size: 12px;
  font-family: ${fontFamily.circularBook};
`;

export const UploadingText = styled.p`
  margin-bottom: 1rem;
  font-family: ${fontFamily.circularBold};
  font-size: 1.7rem;
  color: black;
`;

export const ProgressText = styled.p`
  color: ${({ theme }) => theme.contrast.primary};
  font-family: ${fontFamily.circularBook};
  font-size: 1.5rem;
`;
export const FileControl = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.contrast.primary};
`;

export const CloseContainer = styled.div`
  background-color: ${({ theme }) => theme.contrast.tertiary};
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
`;
