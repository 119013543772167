/* eslint-disable no-throw-literal */
import { FILTER_TERMINOLOGY, INPUT_TYPES } from '../../../constants';
import { VALIDATION_TYPES, validate } from '../../../constants/regex';
import { apiEndPoints, rootConfig } from '../../../services/axios/endPoints';
import { isEmpty } from '../../../shared/utility/isEmpty';

/* eslint-disable max-len */
export const convertFormInputObjToSimple = (formInput) =>
  Object.entries(formInput).reduce((acc, entry) => {
    const [key, value] = entry;
    let resolvedVal = value;
    if (typeof value === 'object') {
      resolvedVal = value?.value;
    }
    return {
      ...acc,
      [key]: resolvedVal
    };
  }, {});

export const validateFields = (userformStructure, formInput) =>
  userformStructure.every((field) => {
    const { inputType, key, validation, label, defaultValue } = field;
    if (validation) {
      const { format, type, value, key: comparisionKey } = validation;
      if (type === 'regex') {
        const res = new RegExp(value).test(formInput?.[key] || '');
        if (res) {
          return true;
        }
        throw `Please enter ${label} in ${format} format`;
      }
      // if value is equals
      if (value.trim()?.toLowerCase() === 'equals') {
        if (
          (formInput[key] || defaultValue)?.localeCompare(
            formInput[comparisionKey] || defaultValue
          ) === 0
        ) {
          return true;
        }
        const toBeMatchedLabel = userformStructure.find(
          (e) => e.key === comparisionKey
        )?.label;
        throw `${toBeMatchedLabel} and ${label} should match`;
      }
    }
    if (inputType === INPUT_TYPES.EMAIL) {
      const res = validate(formInput?.[key], VALIDATION_TYPES.EMAIL);
      if (!res) {
        throw 'Please Enter valid Email';
      }
      return res;
    }
    return true;
  });

export const genericFormValidation = (formStructure, formInput) =>
  formStructure.every((e) => {
    const { isRequired, key } = e;
    if (isRequired) {
      return !isEmpty(formInput?.[key]);
    }
    return true;
  });

export const getResetPayloadForDependentFields = (dependents, formInput) =>
  dependents?.reduce((params, field) => {
    let value = '';
    if (typeof formInput?.[field] === 'object') {
      if (Array.isArray(formInput?.[field])) {
        value = [];
      } else {
        value = {};
      }
    }
    return { ...params, [field]: value };
  }, {});

export const getFieldDependents = (formStructure) =>
  formStructure?.reduce((acc, field) => {
    const { key } = field;
    const dependents = formStructure
      .filter((e) => {
        const dependencies = e.dataSource?.dependencies;
        return dependencies?.includes(key);
      })
      ?.map((e) => e.key);
    return {
      ...acc,
      [key]: dependents
    };
  }, {});

/* eslint-disable max-len */
export const getReqPayloadForDependentField = (
  formInput,
  dependencies,
  formStructure
) => {
  const formStructureFormat = formStructure.reduce((acc, field) => {
    const { key, ...rest } = field;
    return {
      ...acc,
      [key]: { ...rest }
    };
  }, {});
  return dependencies?.reduce((params, field) => {
    let value = formInput?.[field];
    const { inputType, dataSource } = formStructureFormat?.[field];
    const { dependentParamDetails } = dataSource || {};
    const { type, paramMapping } = dependentParamDetails || {};
    if (typeof formInput?.[field] === 'object') {
      if (Array.isArray(formInput?.[field])) {
        value = [...formInput?.[field]];
        let attributeName;
        // convert JSON to leaf value
        if (
          inputType === INPUT_TYPES.FILTER_SEARCH &&
          type === FILTER_TERMINOLOGY.LEAF_NODE
        ) {
          let filterTree = formInput?.[field]?.[0]?.value?.tree;
          while (filterTree?.values) {
            attributeName = filterTree.attribute;
            // eslint-disable-next-line prefer-destructuring
            filterTree = filterTree.values[0];
            value = filterTree;
          }
          // formating the payload required for that field from paramMapping
          const fieldPayload = Object.entries(paramMapping || {}).reduce(
            (acc, detail) => {
              const [requiredDetailKey, existingDetailKey] = detail;
              return {
                ...acc,
                [requiredDetailKey]:
                  existingDetailKey === 'attribute'
                    ? attributeName
                    : value?.[existingDetailKey]
              };
            },
            {}
          );
          // exception RETURN
          return { ...params, ...(fieldPayload || {}) };
        }
      } else {
        value = { ...formInput?.[field] };
      }
    }
    return { ...params, [field]: value };
  }, {});
};

export const getFetchDetailsOfWidgetData = (fetchUrl, isActual = false) => {
  if (fetchUrl) {
    // method 1 approch
    // const { method: definedMethod, baseUrl, absoluteUrl } = fetchUrl;
    // // console.log('fetch widget detail from', definedMethod, baseUrl, absoluteUrl);
    // return {
    //   fullURL: baseUrl ? `${baseUrl}${absoluteUrl}` : `${primaryRootConfig}${absoluteUrl}`,
    //   method: definedMethod
    // };

    // method 2 approch
    const { method: definedMethod, UrlType, Url } = fetchUrl;
    // console.log('fetch widget detail from', definedMethod, baseUrl, absoluteUrl);
    return {
      fullURL: UrlType === 'absolute' ? Url : `${rootConfig.apiRoot3}${Url}`,
      method: definedMethod
    };
  }
  // console.log('Using default details to fetch widget Data');
  if (isActual) {
    return {
      fullURL: apiEndPoints.getWidgetDataActual,
      method: 'post'
    };
  }
  return {
    fullURL: apiEndPoints.getWidgetData,
    method: 'post'
  };
};

/**
 * Function to convert Iso date string into format required for input type 'date-time-local'
 * @param {*} isoString
 * @returns
 */
export const convertISOTime = (isoString) => {
  // Create a new Date object from the ISO string.
  const date = new Date(isoString);

  // Get the year, month, day, hour, and minute from the Date object.
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  });
  const day = date
    .getDate()
    .toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
  const currentHour = date.getHours();
  const hour = `0${currentHour}`.slice(-2);
  const currentminute = date.getMinutes();
  const minute = `0${currentminute}`.slice(-2);

  // Create a new string in the format "2018-06-12T19:30".
  const formattedTime = `${year}-${month}-${day}T${hour}:${minute}`;

  // Return the formatted time string.
  return formattedTime;
};

/**
 * Function to check if string received from Backend is valid json string
 * @param {*} str
 * @returns
 */
export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
