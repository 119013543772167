/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable max-len */
import React, { memo, useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddWhite, filterIcon, toggleOff, toggleOn } from '../../assets/icons';
import Table from './Table';
import DynamicSVGIcon from '../../component/Common/DynamicSVGIcon';
import { Data } from './data';

import { Container, CreateBttn, Filetrcont, HeaderText, HeadingBox, HeadingContainer } from './WorkOrders.styles';
import Createworkorder from '../../component/WorkOrder/CreateWorkOrder/CreateWorkorder';
import { Div, FlexContainer } from '../../globalStyles';
import { DropdownComponent, InputComponent } from '../../component';
import { AlignedDiv } from '../../component/Common/CommonStyles';
import DropArrow from '../../assets/icons/layout/dropdownarrow.svg';
import { WorkOrderCreators } from './store';
import { WO_CREATION_FROM, WO_TYPES } from '../../constants';
import useGetWorkOrderTypes from '../../component/WorkOrder/CreateWorkOrder/hooks/useGetWorkOrderTypes';
import Loader from '../../component/Loader/Loader';
import Pagination from '../../component/Common/Pagination/Pagination.view';
import Toast from '../../component/Common/Toast';
import { apiEndPoints } from '../../services/axios/endPoints';
import { isEmpty } from '../../shared/utility/isEmpty';
import { useActions } from '../../store/hooks/useActions';
import PaginationWithPage from '../../component/Common/Pagination/PaginationWithPage.view';

const WorkOrdersList = ({ menuId, type, setType, withFilter = true, filteredWorkOrders }) => {
  const { localeStrings } = useSelector((state) => state.localeStrings);
  const { workOrders, woCount, loading, error, woHeaders, woSearchQueries } = useSelector((state) => state.workOrders);
  const { setWorkOrderDetail } = useActions();
  const [showCreate, setShowCreate] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const pageSize = 20;

  const dispatch = useDispatch();
  useGetWorkOrderTypes();

  const handlePageChange = (page, rest = {}) => {
    const { searchParams } = rest;
    let endpoint = apiEndPoints.getWorkOrders;
    if (!withFilter) {
      endpoint = apiEndPoints.getWorkOrdersWithoutUserFilter;
    }
    dispatch(WorkOrderCreators.getWorkOrders({ page, pageSize, type, endpoint, searchParams }));
  };

  const clearToastMessage = () => {
    if (error) dispatch(WorkOrderCreators.getWorkOrdersFailure({ error: '' }));
  };

  useEffect(() => {
    dispatch(WorkOrderCreators.resetWorkOrderDetails());
    handlePageChange(1);

    return () => {
      dispatch(WorkOrderCreators.resetWorkOrderSearchQueries());
    };
  }, []);

  const handleCreateWO = () => {
    // dispatch(
    //   UpsertWOCreators.setWorkOrderDetail({
    //     key: 'workOrderCreationFrom',
    //     value: WO_CREATION_FROM.WORK_ORDERS_LIST
    //   })
    // );
    setWorkOrderDetail({
      workOrderCreationFrom: WO_CREATION_FROM.WORK_ORDERS_LIST
    });
    setShowCreate(true);
  };
  const showCollapse = !isEmpty(workOrders) ? workOrders.some((woItem) => woItem.child?.length > 1) : false;

  return (
    <>
      {showCreate && <Createworkorder menuId={menuId} onCancel={() => setShowCreate(false)} />}
      <Container style={{ position: 'relative' }}>
        <HeadingContainer>
          <HeadingBox>
            {type ? (
              <>
                <AlignedDiv>
                  <div onClick={() => setType('')} style={{ paddingRight: '1rem' }}>
                    <DynamicSVGIcon iconUrl={DropArrow} width='2.2rem' />
                  </div>
                  <HeaderText>{type}</HeaderText>
                </AlignedDiv>
              </>
            ) : (
              <HeaderText>{localeStrings['Work Orders'] || 'Work Orders'}</HeaderText>
            )}
          </HeadingBox>
          <FlexContainer className='ai_center'>
            {!type && (
              <CreateBttn className='flex ai_center' onClick={handleCreateWO}>
                <DynamicSVGIcon iconUrl={AddWhite} width='10px' />
                Create Work Order
              </CreateBttn>
            )}
            {showCollapse && (
              <FlexContainer
                style={{ marginLeft: '2rem' }}
                className='ai_center pointer'
                onClick={() => setCollapse(!collapse)}
              >
                <p style={{ fontSize: '12px', marginRight: '5px' }}>Collapse</p>
                {collapse ? (
                  <DynamicSVGIcon iconUrl={toggleOn} width='42px' />
                ) : (
                  <DynamicSVGIcon iconUrl={toggleOff} width='42px' />
                )}
              </FlexContainer>
            )}
            <PaginationWithPage
              ItemsPerPage={pageSize}
              total={woCount}
              handlePageChange={handlePageChange}
              searchParams={woSearchQueries}
            />
          </FlexContainer>
        </HeadingContainer>
        {/* <Filetrcont className='ai_center'>
          <DynamicSVGIcon iconUrl={filterIcon} width='28px' />
          <InputComponent
            width='25%'
            margin='0 2rem'
            placeholder='Filter'
            disabled={true}
            variant='gray'
            style={{ height: '37px' }}
          />
          <DropdownComponent placeholder='Type' width='15%' variant='gray' margin='0 1rem' />
          <DropdownComponent placeholder='Assigned To' width='15%' variant='gray' margin='0 1rem' />
          <DropdownComponent placeholder='State' width='15%' variant='gray' margin='0 1rem' />
          <DropdownComponent placeholder='Duration' width='15%' variant='gray' margin='0 1rem' />
          <FlexContainer className='ai_center pointer' onClick={() => setCollapse(!collapse)}>
            <p style={{ fontSize: '12px', marginRight: '5px' }}>Collapse</p>
            {collapse ? (
              <DynamicSVGIcon iconUrl={toggleOn} width='42px' />
            ) : (
              <DynamicSVGIcon iconUrl={toggleOff} width='42px' />
            )}
          </FlexContainer>
        </Filetrcont> */}
        {loading ? (
          <Loader className='position' />
        ) : (
          <Div style={{ width: '100%', position: 'relative' }}>
            <Table
              colProps={woHeaders}
              data={workOrders}
              collapse={collapse}
              fetchFilteredWorkOrders={handlePageChange}
            />
          </Div>
        )}
        {error && <Toast style={{ right: 20 }} fn={clearToastMessage} header='Error' message={error} />}
      </Container>
    </>
  );
};

export default memo(WorkOrdersList);
