/* eslint-disable max-len */
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  DropdownImage,
  ItemsNo,
  PlotBtn,
  PlotContainer,
  PlotDropDownContainer,
  PlotOptions,
  PlotOptionText
} from './CartButton.style';
import { DropDownArrowWhite } from '../../../assets/icons';

const CartButton = ({ showShortlistHandler, shortlistCount, onNextHandler, nextLabel }) => {
  const plotRef = useRef();
  const [showPlotDropdown, setShowPlotDropDown] = useState(false);
  const { localeStrings } = useSelector((state) => state.localeStrings);
  //   const history = useHistory();

  return (
    <PlotContainer
      disabled={!shortlistCount}
      className={!shortlistCount ? 'disable' : ''}
      // className={`content ${KPVShortlist.length < 1 ? 'disable' : ''}`}
      ref={plotRef}
    >
      <PlotBtn onClick={onNextHandler}>
        {localeStrings[nextLabel] || nextLabel}
        {/* {KPVShortlist.length > 0 &&  */}
        <ItemsNo>{shortlistCount}</ItemsNo>
        {/* } */}
      </PlotBtn>
      <PlotOptions
        onClick={() => {
          setShowPlotDropDown(!showPlotDropdown);
        }}
      >
        <DropdownImage className={showPlotDropdown ? 'active' : ''} src={DropDownArrowWhite} />
      </PlotOptions>
      {showPlotDropdown && (
        <PlotDropDownContainer>
          <PlotOptionText onClick={onNextHandler}>{localeStrings[nextLabel] || nextLabel}</PlotOptionText>
          <PlotOptionText onClick={showShortlistHandler}>
            {localeStrings.viewShortlist || 'View Shortlist'}
          </PlotOptionText>
        </PlotDropDownContainer>
      )}
    </PlotContainer>
  );
};

export default CartButton;
