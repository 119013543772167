/* eslint-disable max-len */
import moment from 'moment';

export const isSame = (date1, date2) =>
  moment(date1).year() === moment(date2).year() && moment(date1).month() === moment(date2).month();

export const isAfter = (date1, date2) =>
  moment(date1).year() > moment(date2).year() ||
  (moment(date1).year() === moment(date2).year() && moment(date1).month() > moment(date2).month());

export const isBefore = (date1, date2) =>
  moment(date1).year() < moment(date2).year() ||
  (moment(date1).year() === moment(date2).year() && moment(date1).month() < moment(date2).month());

export const isSameOrAfter = (date1, date2) => isSame(date1, date2) || isAfter(date1, date2);

export const isSameOrBefore = (date1, date2) => isSame(date1, date2) || isBefore(date1, date2);

export const isBetween = (date, [start, end]) => isSameOrAfter(date, start) && isSameOrBefore(date, end);
