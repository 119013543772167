/* eslint-disable max-len */
import React from 'react';
import { scheduleRecurenceUnit } from '../../../constants';
import { Div, FlexContainer } from '../../../globalStyles';
import DropDownModal from '../../Common/DropDownModal/DropDownModal';
import InputDropdown from '../../Common/DropDownModal/InputDropdown';
import { InputCounter } from '../../Common/Inputupdown/InputAddMinus';
import KeyInputLayoutView from '../../Common/KeyValueLayout/KeyInputLayout.view';
import { Span } from '../../Common/KeyValueLayout/KeyValueLayout.style';
import Radiobutton from '../../Common/RadioButton/RadioButton';
import { checkIsFrequencyBelowMonth, getFirstLastDayLabel, getFirstLastLabel } from '../utility';

const ScheduleTypeFrequency = (props) => {
  const {
    input,
    setInput,
    dropdownData,
    showDropdownOf,
    handleDropdownSelection,
    handleInputCounter,
    handleDropdownVisibility, frequencyDropdownData
  } = props;

  const INIT_DATA = {
    recurring: 1,
    // firstLastDayName: '', // don't require
    day: 1,
    dayofEvery: 1
    // firstLastName: '', // don't require
    // firstLastDayName: '', // repeat
    // firstLastDayEvery: '' // don't require
  };
  const masterScheduleFrequencyName =
    input.masterScheduleFrequencyName?.masterScheduleFrequencyName || input.masterScheduleFrequencyName?.Name ||
    frequencyDropdownData[0]?.Name;

  const isFrequencyBelowMonth = checkIsFrequencyBelowMonth(masterScheduleFrequencyName);

  // getting label if id is present
  let firstLastDayName = getFirstLastDayLabel(dropdownData, input.firstLastDayName?.firstLastDayName);
  if (!isFrequencyBelowMonth && !firstLastDayName) {
    firstLastDayName = input.firstLastDayName?.firstLastDayName;
  }
  firstLastDayName = firstLastDayName || dropdownData?.Frequency_FirstLastDays?.[0]?.[0]?.firstLastDayName;

  const firstLastName =
    getFirstLastLabel(dropdownData, input.firstLastName?.firstLastName) ||
    input.firstLastName?.firstLastName ||
    dropdownData?.Frequency_FirstLast?.[0]?.[0]?.firstLastName;

  const recurenceUnit = scheduleRecurenceUnit[masterScheduleFrequencyName];
  const handleOptionChange = (optionType) => {
    setInput((inp) => ({
      ...inp,
      ...INIT_DATA,
      frequencyOption: optionType
    }));
  };
  //    1. for day and week
  const ContentBelowMonth = (
    <>
      <KeyInputLayoutView label='Recurs Every'>
        <InputCounter inputFor='recurs' label='recurring' value={input.recurring} valueSetter={handleInputCounter} />
        <Span style={{ marginLeft: '2rem' }}>{recurenceUnit}</Span>
        {(masterScheduleFrequencyName === 'Bi-Weekly' || masterScheduleFrequencyName === 'Weekly') && (
          <DropDownModal
            // data={dropDownOptions?.frequencyType}
            data={dropdownData?.Frequency_FirstLastDays?.[0]}
            isActive={showDropdownOf === 'firstLastDayName'}
            handleClick={handleDropdownSelection}
            setModalIsVisibal={() => handleDropdownVisibility('firstLastDayName')}
            label='firstLastDayName'
            singleSelection
            width='22.7rem'
          >
            <KeyInputLayoutView className='nested' label='on'>
              <InputDropdown
                keyName='firstLastDayName'
                value={firstLastDayName}
                handleDropdownVisibility={handleDropdownVisibility}
              />
            </KeyInputLayoutView>
          </DropDownModal>
        )}
      </KeyInputLayoutView>
    </>
  );

  // 2. more than week
  const ContentMonthAndAbove = (
    <>
      {/* option 1 */}
      <KeyInputLayoutView
        label='Day'
        className={input.frequencyOption === 'date' ? '' : 'disable-children'}
        isLabelActive={
          <Radiobutton active={input.frequencyOption === 'date'} onClick={() => handleOptionChange('date')} />
        }
      >
        <InputCounter inputFor='days' label='day' value={input.day} valueSetter={handleInputCounter} />
        <Span style={{ marginLeft: '5rem' }}>of every</Span>
        <InputCounter inputFor='months' label='dayofEvery' value={input.dayofEvery} valueSetter={handleInputCounter} />
        <Span style={{ marginLeft: '5rem' }}>{recurenceUnit}</Span>
      </KeyInputLayoutView>
      {/* option 2 */}
      <KeyInputLayoutView
        label='The'
        className={input.frequencyOption === 'day' ? '' : 'disable-children'}
        isLabelActive={
          <Radiobutton active={input.frequencyOption === 'day'} onClick={() => handleOptionChange('day')} />
        }
      >
        <DropDownModal
          // data={dropDownOptions?.frequencyType}
          data={dropdownData?.Frequency_FirstLast?.[0]}
          isActive={showDropdownOf === 'firstLastName'}
          handleClick={handleDropdownSelection}
          setModalIsVisibal={() => handleDropdownVisibility('firstLastName')}
          label='firstLastName'
          singleSelection
          width='22.7rem'
        >
          <InputDropdown
            keyName='firstLastName'
            value={firstLastName}
            handleDropdownVisibility={handleDropdownVisibility}
          />
        </DropDownModal>
        <div style={{ marginLeft: '2rem' }}>
          <DropDownModal
            // data={dropDownOptions?.frequencyType}
            isActive={showDropdownOf === 'firstLastDayName'}
            data={dropdownData?.Frequency_FirstLastDays?.[0]}
            handleClick={handleDropdownSelection}
            setModalIsVisibal={() => handleDropdownVisibility('firstLastDayName')}
            label='firstLastDayName'
            singleSelection
            width='22.7rem'
          >
            <InputDropdown
              keyName='firstLastDayName'
              value={firstLastDayName}
              handleDropdownVisibility={handleDropdownVisibility}
            />
          </DropDownModal>
        </div>

        <Span style={{ marginLeft: '3rem' }}>of every</Span>
        <InputCounter
          inputFor='months'
          label='firstLastDayEvery'
          value={input.firstLastDayEvery}
          valueSetter={handleInputCounter}
        />
        <Span style={{ marginLeft: '5rem' }}>{recurenceUnit}</Span>
      </KeyInputLayoutView>
    </>
  );

  return (
    <>
      <KeyInputLayoutView className='required' label='Frequency'>
        <DropDownModal
          // data={dropDownOptions?.frequencyType}
          data={frequencyDropdownData}
          isActive={showDropdownOf === 'masterScheduleFrequencyName'}
          handleClick={handleDropdownSelection}
          setModalIsVisibal={() => handleDropdownVisibility('masterScheduleFrequencyName')}
          label='Name'
          singleSelection
          width='22.7rem'
        >
          <InputDropdown
            keyName='masterScheduleFrequencyName'
            value={masterScheduleFrequencyName}
            handleDropdownVisibility={handleDropdownVisibility}
          />
        </DropDownModal>
      </KeyInputLayoutView>

      <Div>{isFrequencyBelowMonth ? ContentBelowMonth : ContentMonthAndAbove}</Div>
    </>
  );
};

export default ScheduleTypeFrequency;
