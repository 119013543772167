/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-use-before-define */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
/* eslint-disable max-len */
/* eslint-disable react/sort-comp */
import React, { useState, memo, useRef, useCallback } from 'react';
import FullCalendar, { formatDate } from '@fullcalendar/react';
import rrulePlugin from '@fullcalendar/rrule';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { INITIAL_EVENTS, createEventId } from './event-utils';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DemoAppContainer, DemoAppMain, EventItemContainer, LoadingContainer } from './Calendar.styles';
import SearchBar from '../SearchBar';
// import ViewTypeSelection from './ViewTypeSelection/ViewTypeSelection';
import { weekNumberCalculation as weekNoCalc, getMonthFromActiveRange, viewTypeMapping } from './utility';
import EventsSidePanel from './EventsSidePanel/EventsSidePanel';
import OcurranceSelection from './OcurranceSelection/OcurranceSelection';
import { WebService } from '../../../services/axios/webServices';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { formatEvents } from '../../../views/Plan/utility';
import { PlanCreators } from '../../../views/Plan/store';
import Loader from '../../Loader/Loader';
import DatePicker from './DatePicker/DatePicker';
import { useActions } from '../../../store/hooks/useActions';
import ScheduleSearchBar from './SearchBar';

const EventsCalendar = (props) => {
  // calendarRef = React.createRef();
  const { calendarRef, handleDetails } = props;

  const dispatch = useDispatch();
  const { planValueSetter } = useActions();
  const occurrenceType = useSelector((state) => state.plansData.occurrenceType);
  const calendarConfig = useSelector((state) => state.plansData.calendarConfig);
  const isListView = useSelector((state) => state.plansData.isListView);

  const [state, setState] = useState({
    weekendsVisible: true,
    currentEvents: [],
    selectedDateEvents: [],
    occurrenceType: {
      label: 'All',
      value: 'all',
      id: 1
    }
  });

  const setError = (error) => {
    dispatch(
      PlanCreators.planValueSetter({
        key: 'error',
        value: error
      })
    );
  };

  // console.log('calendarRef', calendarRef.current?.getApi()?.currentDataManager?.state?.currentViewType);
  // no dispatches possible
  const getCalendarEvents = (info, successCallback, failureCallback) => {
    const { start, end } = info;
    const month = getMonthFromActiveRange(start);
    let year = moment(start).year();
    if (month === 1) {
      year += 1;
    }
    const endpoint = `${apiEndPoints.getScheduleList}/${month}/${year}`;
    const currentViewType = calendarRef.current?.getApi()?.currentDataManager?.state?.currentViewType;
    let SearchFilters = {};
    if (occurrenceType?.label?.toLowerCase() !== 'all') {
      SearchFilters = {
        ...SearchFilters,
        scheduleType: occurrenceType.label
      };
    }
    if (!isListView) {
      WebService.post(endpoint, {
        dateRange: {
          startDate: moment(start).format('YYYY-MM-DD'),
          endDate: moment(end).format('YYYY-MM-DD')
        },
        viewType: viewTypeMapping[currentViewType],
        SearchFilters
      })
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            const { data, Calendar } = res.data;
            // setting the calendar configs
            planValueSetter({ key: 'calendarConfig', value: Calendar });
            // setting events from response
            planValueSetter({ key: 'rawCalendarEvents', value: data });
            const formatedData = formatEvents(data) || [];
            // console.log('formatedData', formatedData, data);
            successCallback(formatedData);
          } else {
            failureCallback(new Error('something went wrong'));
          }
        })
        .catch((e) => {
          // console.log('e', e);
          failureCallback(new Error('something went wrong'));
        });
    } else {
      successCallback([]);
    }
  };
  const getMemoizedGetCalendarEvents = useCallback(
    (...args) => getCalendarEvents(...args),
    [isListView, occurrenceType]
  );
  const [loading, setLoading] = useState(false);
  const toggleSpinner = useCallback((state) => {
    setLoading(state);
  }, []);
  const { weekStartsOn, dateOfWeekStart } = calendarConfig || {};
  let calendarMenuOptions = 'searchBar prev,next localDatePicker ocurranceSelection';
  if (isListView) {
    // remove occurace selection as this is already covered in table header
    calendarMenuOptions = calendarMenuOptions.replace(' ocurranceSelection', '');
  }
  return (
    <DemoAppContainer className={isListView ? 'isListView' : ''}>
      <LoadingContainer>{loading && <Loader />}</LoadingContainer>
      <DemoAppMain>
        <FullCalendar
          loading={(s) => toggleSpinner(s)}
          ref={calendarRef}
          height='100%'
          plugins={[rrulePlugin, dayGridPlugin, timeGridPlugin, interactionPlugin]}
          // headerToolbar={{
          //   // prevYear,nextYear
          //   left: "datePicker",
          //   // center: "title",
          //   // center: "datePicker",
          //   right: "today prev,next,viewTypeSelection",
          //   //dayGridMonth,timeGridWeek,timeGridDay",
          // }}
          headerToolbar={{
            // prevYear,nextYear
            left: calendarMenuOptions,
            // center: "title",
            // center: "datePicker",
            right: 'today'
            // dayGridMonth,timeGridWeek,timeGridDay",
          }}
          initialView='dayGridMonth'
          editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          weekends={state.weekendsVisible}
          events={getMemoizedGetCalendarEvents}
          select={handleDateSelect}
          eventContent={renderEventContent} // custom render function
          eventClick={handleEventClick}
          eventsSet={handleEvents} // called after events are initialized/added/changed/removed
          weekNumbers={true}
          firstDay={weekStartsOn} // week starts on day
          // weekNumberCalculation={(date) => weekNoCalc(date, { dateOfWeekStart: '02-07', weekStartsOn: 2 })}
          weekNumberCalculation={(date) => weekNoCalc(date, { dateOfWeekStart, weekStartsOn })}
          moreLinkClick={moreLinkClickHandler}
          // dateClick={handleDataClick}
          /* you can update a remote database when these fire:
            eventAdd={function(){}}
            eventChange={function(){}}
            eventRemove={function(){}}
            */
          customButtons={{
            searchBar: {
              text: 'searchBar',
              hint: 'Search bar',
              // click: this.handleDatePicker,
              component: ScheduleSearchBar
            },
            // viewTypeSelection: {
            //   text: 'viewTypeSelection',
            //   hint: 'View Type Selection',
            //   // click: this.handleDatePicker,
            //   component: ViewTypeSelection
            // },
            ocurranceSelection: {
              text: 'ocurranceSelection',
              hint: 'OcurranceSelection',
              // click: this.handleDatePicker,
              additionalProps: {
                handleOcurranceSelection
              },
              component: OcurranceSelection
            },
            localDatePicker: {
              text: 'Date Picker',
              hint: 'Date Picker',
              // click: this.handleDatePicker,
              additionalProps: {
                calendarApi: calendarRef.current?.getApi(),
                dateOfWeekStart,
                weekStartsOn
              },
              component: DatePicker
            }
          }}
        />
      </DemoAppMain>
      {/* {calendarApi?.data?.viewSpec?.type === 'dayGridMonth' */}
      {!isListView && (
        <EventsSidePanel
          eventSidePanelHandler={eventSidePanelHandler}
          listDetails={state.selectedDateEvents}
          handleEventClick={handleEventClick}
        />
      )}
    </DemoAppContainer>
  );

  function moreLinkClickHandler(params) {
    // console.log('params', params);
    const { allSegs, date } = params;
    eventSidePanelHandler({ allSegs, date });
  }
  function eventChange() {
    INITIAL_EVENTS.slice(3);
  }

  function handleDataClick(e) {
    // console.log('ro0wwwwwwwwwwwww', e);
  }

  function eventSidePanelHandler(obj) {
    setState({
      ...state,
      selectedDateEvents: obj
    });
  }

  function handleOcurranceSelection(item) {
    // if (this.state.occurrenceType.includes(item)) {
    //   console.log('inclides', item);
    // }
    dispatch(
      PlanCreators.planValueSetter({
        key: 'occurrenceType',
        value: item
      })
    );
    setState({
      ...state
    });
  }

  function handleDateSelect(selectInfo) {
    // console.log('yessssssss selectInfo', selectInfo, selectInfo?.additionalDetails?.allSegs, selectInfo.view.calendar);
    // const title = prompt('Please enter a new title for your event');
    // eventSidePanelHandler({
    //   date: selectInfo?.start,
    //   events: selectInfo?.additionalDetails?.allSegs
    // });
    // const calendarApi = selectInfo.view.calendar;
    // calendarApi.unselect(); // clear date selection
    // if (title) {
    //   calendarApi.addEvent({
    //     id: createEventId(),
    //     title,
    //     start: selectInfo.startStr,
    //     end: selectInfo.endStr,
    //     allDay: selectInfo.allDay
    //   });
    // }
  }

  function handleEventClick(clickInfo) {
    // if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
    //   clickInfo.event.remove();
    // }
    // const p = JSON.stringify(clickInfo.event || clickInfo);
    try {
      // let p = clickInfo;
      // console.log('clickInfo.event', p);
      if (clickInfo.event) {
        const {
          // title,
          // start,
          // end,
          // allDay,
          // id,
          extendedProps: { details }
        } = clickInfo.event;
        // p = { title, start, end, allDay, id, details };
        // console.log('inside', p, clickInfo.event);
        handleDetails(details);
      } else {
        throw Error('error in selecting the details');
      }
    } catch (e) {
      // console.log('error in selecting the details');
      setError('error in selecting the details');
    }
  }

  // function handleSelection(details) {
  //   console.log('details');
  // }

  // called after events are initialized/added/changed/removed
  function handleEvents(events) {
    // console.log('called');
    // set the active duration
    const calendarApi = calendarRef.current?.getApi();
    const activeR = calendarApi?.currentDataManager?.state?.dateProfile?.activeRange;

    planValueSetter({
      key: 'activeRange',
      value: activeR
    });

    // setTest((a) => !a);
    // setState({
    //   ...state,
    //   currentEvents: events
    // });
  }
};

function renderEventContent(eventInfo) {
  const {
    event: { _def }
  } = eventInfo;
  const {
    extendedProps: { details }
  } = _def || {};
  const {
    asset
    // activity,
    // starttime, endtime
  } = details || {};
  return (
    <EventItemContainer className='text-overflow'>
      <b>{eventInfo.timeText}</b>
      <i>{asset}</i>
    </EventItemContainer>
  );
}

function renderSidebarEvent(event) {
  return (
    <li key={event.id}>
      <b>
        {formatDate(event.start, {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        })}
      </b>
      <i>{event.title}</i>
    </li>
  );
}

export default memo(EventsCalendar);

// renderSidebar() {
//   return (
//     <div className="demo-app-sidebar">
//       <div className="demo-app-sidebar-section">
//         <h2>Instructions</h2>
//         <ul>
//           <li>Select dates and you will be prompted to create a new event</li>
//           <li>Drag, drop, and resize events</li>
//           <li>Click an event to delete it</li>
//         </ul>
//       </div>
//       <div className="demo-app-sidebar-section">
//         <label>
//           <input
//             type="checkbox"
//             checked={this.state.weekendsVisible}
//             onChange={this.handleWeekendsToggle}
//           ></input>
//           toggle weekends
//         </label>
//       </div>
//       <div className="demo-app-sidebar-section">
//         <h2>All Events ({this.state.currentEvents.length})</h2>
//         <ul>{this.state.currentEvents.map(renderSidebarEvent)}</ul>
//       </div>
//     </div>
//   );
// }

// handleWeekendsToggle = () => {
//   this.setState({
//     weekendsVisible: !this.state.weekendsVisible,
//   });
// };
