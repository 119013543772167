/* eslint-disable max-len */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PointedDropdownModal from '../../Common/DropDownModal/PointedDropdownModal';
import { Creators as LayoutCreators } from '../../../views/Layout/store';
import { Creators as AuthCreators } from '../../../views/auth/store';
import { AvatarIconContainer, UserDetails, UserName } from './ProfileSettingDropdown.style';
import { AvatarComponent } from '../..';
import { AlignedDiv } from '../../Common/CommonStyles';
import DynamicSVGIcon from '../../Common/DynamicSVGIcon';
import { logOut, mail, phone, profileSettings } from '../../../assets/icons';

const ProfileSettingsDropdown = () => {
  const { profileSettingsDropdown } = useSelector((state) => state.home);
  const { userData } = useSelector((state) => state.loginData);
  console.log(userData);
  const ProfileSettingsData = [
    { label: 'Profile settings', value: 'profileSettings', labelIcon: profileSettings },
    { label: 'Logout', value: 'logout', labelIcon: logOut }
  ];

  const dispatch = useDispatch();

  const closeModal = () => {
    if (profileSettingsDropdown) {
      dispatch(LayoutCreators.toggleHeaderOption('profileSettingsDropdown'));
    }
  };

  const handleClick = (value) => {
    switch (value) {
      case 'logout':
        dispatch(AuthCreators.onLogout());
        dispatch(LayoutCreators.toggleHeaderOption('profileSettingsDropdown'));
        break;
      case 'profileSettings':
        dispatch(LayoutCreators.toggleHeaderOption('profileSettingsDropdown'));
        dispatch(LayoutCreators.settingsActiveMenu('Accounts'));
        break;
      default:
        break;
    }
  };

  return (
    <PointedDropdownModal
      style={{ top: window.innerWidth > 769 ? '150%' : '109%', zIndex: '10001' }}
      data={ProfileSettingsData}
      closeModal={() => null}
      handleClick={handleClick}
    >
      <AlignedDiv>
        <AvatarIconContainer>
          <AvatarComponent
            userdata={userData}
            circle='circle'
            // ml='1.5rem'
            size='7rem'
          // onClick={(e) => setModalIsVisibal(!modalIsVisibal)}
          />
        </AvatarIconContainer>
        <div>
          <UserName>{userData.profileName || '-'}</UserName>
          <UserDetails>
            <DynamicSVGIcon iconUrl={mail} />
            {userData.email || '-'}
          </UserDetails>
          <UserDetails>
            <DynamicSVGIcon iconUrl={phone} />
            {userData.phone || '-'}
          </UserDetails>
        </div>
      </AlignedDiv>
    </PointedDropdownModal>
  );
};

export default ProfileSettingsDropdown;
