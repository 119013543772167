/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import KeyInputLayoutView from '../../KeyValueLayout/KeyInputLayout.view';

import { ReactComponent as CheckMark } from '../../../../assets/icons/checkmark.svg';
import { useActions } from '../../../../store/hooks/useActions';
import { WebService } from '../../../../services/axios/webServices';
import { sagaCatchBlockHandling } from '../../../../store/utility/sagaErrorHandling';

import { Span } from '../../KeyValueLayout/KeyValueLayout.style';
import { isEmpty } from '../../../../shared/utility/isEmpty';
import ToggleableFieldValue from '../../GenericForm/ToggleableFieldValue';
import { INPUT_TYPES } from '../../../../constants';
import { CheckBox, ItemContainer, Label } from './CheckboxType.styles';
import { getFetchDetailsOfWidgetData, getReqPayloadForDependentField } from '../../GenericForm/utils';

const CheckboxTypeComponent = (props) => {
  const {
    item,
    value,
    handleChange,
    formStructure, // formstructure
    formInput, // for handling dependency
    tobeRefetched = false, // check for refetching the data
    resetFieldsTobeRefetched = () => {}, // reset the FieldsTobeRefetched
    isEditable,
    fieldClassName
  } = props;
  const {
    // setToastError,
    setFormStructureFailure
  } = useActions();
  const { label, key, dataSource, isRequired } = item;
  // TODO: handle api response
  const { data, dataKey, source, responseKeys, dependencies, selectAll, params } = dataSource;
  const { showOption, defaultValue } = selectAll || {};
  const [options, setOptions] = useState(data);
  const { labelKey, valueKey } = responseKeys || {};
  const labelKeyFinal = labelKey || 'label';
  const valueKeyFinal = valueKey || 'value';
  const [loading, setLoading] = useState(false);

  const handleSelectAll = (items, selectAll) => {
    try {
      // parameters can be left undefined so or statements are used inside
      let payload = [];
      if (selectAll || (options || [])?.length !== (value || [])?.length) {
        payload = items || [...options];
      }
      handleChange(
        payload,
        {
          key,
          itemValueKey: valueKey
        },
        { isDirectUpdate: true }
      );
    } catch (e) {
      console.log('e', e);
    }
  };

  const fetchDetails = async (args) => {
    try {
      // console.log('need to fetch', key);
      // if (isFormIntialized) {
      setLoading(true);
      const { additionalPayload } = args || {};
      if (dataKey === 'source' && source) {
        // console.log(`fetching dp details of ${key}`);
        const fetchUrl = dataSource.source; // {method, UrlType, Url}
        const { fullURL, method } = getFetchDetailsOfWidgetData(fetchUrl);
        // test
        // const method = 'post';
        let payload = { ...params, ...additionalPayload };
        if (method === 'get') {
          payload = { params: { ...params, ...additionalPayload } };
        }
        // console.log('fetching....', key, payload);
        const resp = await WebService?.[method](fullURL, payload);
        if (resp.status >= 200 && resp.status < 300) {
          setOptions(resp.data?.data);
          if (defaultValue && isEmpty(value)) {
            // console.log('during fetch selectAll?', value, label, key, formInput, isEmpty(value));
            // enable select all only for fresh form
            handleSelectAll(resp.data?.data, true);
          }
        } else {
          throw resp;
        }
        resetFieldsTobeRefetched();
        // }
      }
    } catch (e) {
      const error = sagaCatchBlockHandling(e);
      setOptions([]);
      console.log('e', e, error.error);
      // setToastError(error.error);
      setFormStructureFailure(error.error);
    } finally {
      setLoading(false);
    }
  };

  // normal fetch
  // useEffect(() => {
  //   // fetchDetails();
  // }, []);

  useEffect(() => {
    // console.log('toberef..', key, tobeRefetched);
    if (tobeRefetched) {
      // console.log('refetching fetch2....', key);
      const newPayload = getReqPayloadForDependentField(formInput, dependencies, formStructure);
      fetchDetails({ additionalPayload: newPayload });
    }
  }, [tobeRefetched]);
  // console.log('options', label, options, value);

  if (loading) {
    return (
      <KeyInputLayoutView fieldClassName={fieldClassName} label={label} className='show_stats'>
        {loading && <Span>Loading...</Span>}
      </KeyInputLayoutView>
    );
  }
  if (isEmpty(options) || !Array.isArray(options)) {
    return (
      <KeyInputLayoutView fieldClassName={fieldClassName} label={label} className='show_stats' isRequired={isRequired}>
        <Span>NA</Span>
      </KeyInputLayoutView>
    );
  }
  // console.log('valuee', valueKeyFinal, key, value);
  return (
    <div>
      <KeyInputLayoutView
        fieldClassName={fieldClassName}
        label={label}
        tooltip={item.tooltip}
        className='show_stats'
        isRequired={isRequired}
      >
        <ToggleableFieldValue type={INPUT_TYPES.CHECKBOX} isEditable={isEditable} value={formInput?.[key]}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              height: 'auto',
              maxHeight: '20rem',
              // flexWrap: 'wrap',
              padding: '1rem',
              overflowY: 'auto',
              minWidth: '35rem'
            }}
          >
            {showOption && (
              <ItemContainer className='checkbox-container'>
                <CheckBox
                  className={(options || [])?.length === (value || [])?.length ? 'active' : 'unactive'}
                  onClick={() => handleSelectAll()}
                >
                  <CheckMark />
                </CheckBox>
                <Label>Select All</Label>
                {/* not yet used till now */}
                {/* {item.hasLink && (
            <Label>
              <a style={{ fontSize: '1.5rem' }} href='http://mms.panorbitprojects.com/'>
                Add
              </a>
            </Label>
          )} */}
              </ItemContainer>
            )}
            {(options || [])?.map((item, index) => (
              <ItemContainer key={index.toString()} className='checkbox-container'>
                <CheckBox
                  className={
                    (Array.isArray(value) ? value : [])?.some((e) => e?.[valueKeyFinal] === item?.[valueKeyFinal])
                      ? 'active'
                      : 'unactive'
                  }
                  onClick={() => {
                    handleChange(item, { key, itemValueKey: valueKey });
                  }}
                >
                  <CheckMark />
                </CheckBox>
                <Label>{item?.[labelKeyFinal]}</Label>
                {/* not yet used till now */}
                {/* {item.hasLink && (
                <Label>
                  <a style={{ fontSize: '1.5rem' }} href='http://mms.panorbitprojects.com/'>
                    Add
                  </a>
                </Label>
              )} */}
              </ItemContainer>
            ))}
          </div>
        </ToggleableFieldValue>
      </KeyInputLayoutView>
    </div>
  );
};
export default CheckboxTypeComponent;
