/* eslint-disable prefer-template */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { createPopper } from '@popperjs/core';
import backArrow from '../../../assets/icons/layout/BackArrow.svg';
import useClickOutside from '../../../shared/hooks/useClickOutside';
import DynamicSVGIcon from '../DynamicSVGIcon';
import Radiobutton from '../RadioButton/RadioButton';
import NoResultSvg from '../../../assets/icons/layout/No_results_on_Dashboard.svg';
import { FlexContainer } from '../../../globalStyles';
import {
  DropdownLabelContainer,
  Image,
  ModalContainer,
  ModalContentDiv,
  ModalContentText,
  NestedModalContainer
} from './DropDownModal.style';
import Loader from '../Loader/Loader';

function DropDownModal(props) {
  const {
    style = {},
    ContainerStyle = {},
    TextStyle = {},
    data = [],
    itemContainerStyle = {},
    setModalIsVisibal,
    kababIconRef = false,
    handleClick,
    version = 1,
    isActive,
    field = '',
    extraParms, // additional params required to pass during click
    headerLabel, // dropdown header label
    activeItem, // active selected item
    showSelection, // show radio icon indication
    isActiveChecker = (activeItem, item) => activeItem.value === item.value,
    label = 'label', // label key which needs to used
    iconUrl = 'iconUrl', // icon for each item ? optional
    width,
    singleSelection, // closes the dropdown on selection if true
    itemKey,
    noContainerPadding = false, // main container padding
    loader,
    multiRole,
  } = props;
  const modalReff = useRef();
  const secondaryRef = useRef();
  const [modalRef, setModalRef] = useState(null);
  const [boxRef, setBoxRef] = useState(null); // default reference if refernce prop is defined
  createPopper(boxRef, modalRef, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 0]
        }
      }
    ]
  });
  const { localeStrings } = useSelector((state) => state.localeStrings);
  useClickOutside(
    modalReff,
    () => {
      if (!field) {
        return setModalIsVisibal(false);
      }
      return setModalIsVisibal(field);
    },
    secondaryRef
  );
  const handleSelection = (item) => {
    if (singleSelection) {
      setModalIsVisibal(false);
    }
    if (multiRole && multiRole === 0) {
      return handleClick(item, { itemKey: 'Roles', itemKey2: itemKey, ele2: item.value.toString() });
    }
    if (!field) {
      return handleClick(item, { label, activeRowItem: extraParms?.activeRowItem, itemKey });
    }
    return handleClick(field, item);
  };

  const header = (
    <>
      {headerLabel && (
        <DropdownLabelContainer ref={kababIconRef} onClick={() => setModalIsVisibal((prev) => !prev)}>
          <span style={{ textTransform: 'capitalize' }}>{headerLabel}</span>
          <DynamicSVGIcon rotate={isActive ? 90 : 270} width='2.3rem' iconUrl={backArrow} />
        </DropdownLabelContainer>
      )}
    </>
  );
  // undefined to handle prev developed code
  // if (!isActive && isActive !== undefined) {
  //   return <>{header}</>;
  // }

  return (
    <>
      <div style={{ width }} ref={secondaryRef}>
        <div ref={setBoxRef}>{props.children}</div>
        {header}
        {(isActive || isActive === undefined) && true && (
          <ModalContainer
            // isActive
            isActive={isActive}
            className={version === '1' ? '' : 'outside'}
            ref={setModalRef}
            style={ContainerStyle || style}
            width={width}
            noContainerPadding={noContainerPadding}
          >
            <NestedModalContainer ref={modalReff}>
              {/* {loader && <Loader />} */}
              {data.length > 0 ? (
                data?.map((item, i) => (
                  <ModalContentDiv
                    // TODO: not accepting string interpolation
                    className={
                      (item.class || '') +
                      ' ' +
                      (activeItem ? 'hasRadio' : '') +
                      ' ' +
                      (showSelection ? 'radio' : '') +
                      ' ' +
                      (item[iconUrl] ? 'icon' : '') +
                      'list'
                    }
                    style={itemContainerStyle}
                    key={i}
                    onClick={() => handleSelection(item)}
                  >
                    {item[iconUrl] ? <DynamicSVGIcon width='2.3rem' iconUrl={item[iconUrl]} /> : null}
                    <ModalContentText
                      className={(version === '1' ? '' : 'outside') + ' ' + (item.disable ? 'disable' : '')}
                      // {`${version === '1' ? '' : 'outside'} ${item.disable ? 'disable' : ''}`}
                      style={TextStyle}
                    >
                      {localeStrings[item[label]] || item.title || item[label]}
                    </ModalContentText>
                    {showSelection && (
                      <Radiobutton
                        // margin='10px 0px 0px 10px'
                        onClick={() => handleSelection(item)}
                        active={isActiveChecker(activeItem, item)}
                      />
                    )}
                  </ModalContentDiv>
                ))
              ) : (
                <FlexContainer className='ai_center'>
                  <Image src={NoResultSvg}>
                    <DynamicSVGIcon iconUrl={NoResultSvg} width='3rem' />
                  </Image>
                  No items found
                </FlexContainer>
              )}
            </NestedModalContainer>
          </ModalContainer>
        )}
      </div>
    </>
  );
}

export default DropDownModal;
