/* eslint-disable max-len */
import moment from 'moment';
import { startOfWeek } from 'date-fns';
import { DailyIcon, ListIcon, MonthlyIcon, WeeklyIcon, WorkWeekIcon } from '../../../assets/icons/calendar';

export const getMonthFromActiveRange = (start) => {
  const day = moment(start).date();
  let month = moment(start).month() + 1;
  // console.log('info', start, day);
  if (day > 20) {
    if (month === 12) {
      // only dec ===> jan case
      month = 1;
    } else {
      month += 1;
    }
  }
  return month;
};

export const getWeekNoInit = (dateOfWeekStart, dateOptions) => {
  if (dateOfWeekStart) {
    const dateObjOfWeekStart = moment(dateOfWeekStart, 'DD-MM');
    // console.log('dateObjOfWeekStart', dateObjOfWeekStart);
    return moment(startOfWeek(new Date(dateObjOfWeekStart), dateOptions)).week();
  }
  return 1;
};

// weekNoInit is revised week (which is used as week1)
export const getWeekno = (day, weekNoInit) => moment(day).week() - weekNoInit + 1;

export const absoluteWeekNo = (weekNo) => ({
  isNextYear: weekNo > 0,
  weekNo: weekNo <= 0 ? 52 + weekNo : weekNo
});

export const weekNumberCalculation = (date, { dateOfWeekStart = '01-01', weekStartsOn = 1 }) => {
  const weekNoInit = getWeekNoInit(dateOfWeekStart, { weekStartsOn });
  const weekNo = getWeekno(date, weekNoInit);
  const { weekNo: aWeekNo } = absoluteWeekNo(weekNo);
  // console.log('date', date, weekNoInit, weekNo, aWeekNo);
  return aWeekNo;
};

export const viewTypeMapping = {
  dayGridMonth: 'month',
  timeGridWeek: 'week',
  timeGridDay: 'day'
};

export const options = [
  {
    label: 'month',
    key: 'dayGridMonth',
    id: 1,
    iconUrl: MonthlyIcon
  },
  {
    label: 'week',
    key: 'timeGridWeek',
    id: 2,
    iconUrl: WeeklyIcon
  },
  {
    label: 'day',
    key: 'timeGridDay',
    id: 3,
    iconUrl: DailyIcon
  },
  {
    label: 'List',
    key: 'list',
    id: 4,
    isCustomHandling: true,
    iconUrl: ListIcon
  }
];

export const occuranceTypes = [
  {
    label: 'all',
    value: 'all',
    id: 1
  },
  {
    label: 'Relative Recurring',
    value: 'relativeRecurring',
    id: 1
  },
  {
    label: 'One Time',
    value: 'oneTime',
    id: 2
  },
  {
    label: 'Recurring',
    value: 'recurring',
    id: 3
  }
];

// export const isRangeEqual = (obj1, obj2) => {
//   const { startDate: start, endDate: end } = obj1;
//   const { startDate, endDate } = obj2;
//   return (
//     new Date(start).getTime() === new Date(startDate).getTime() &&
//     new Date(end).getTime() === new Date(endDate).getTime()
//   );
// };

export const getDateObj = (date, isMoment) => {
  if (date) {
    if (isMoment) {
      return moment(date);
    }
    return new Date(date);
  }
  return null;
};
