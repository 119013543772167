import styled from 'styled-components';
import { device, fontFamily } from '../../constants';
import { BodyContainer as BodyContainerTemp } from '../../component/Common/CommonStyles';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  height: 100%;
  display: flex;
  overflow-y: scroll;
  /* padding: 0 2.2rem; */
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media ${device.tablet} {
    grid-template-columns: auto auto;
  }

  /* mobileL */
  @media ${device.mobileL} {
    grid-template-columns: auto;
  }
`;

export const ItemContainer = styled.div`
  background: #ffffff;
  min-width: 30%;
  height: 25rem;
  margin: 2rem;
  padding: 2rem;
  box-shadow: -5px -5px 11px #fffffff5;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  flex: 1;
  max-width: 20%;

  @media ${device.mobileL} {
    height: auto;
    max-width: 40%;
  }
`;

export const HeaderText = styled.div`
  font-size: 3rem;
  font-family: ${fontFamily.circularMedium};
  color: #4a4a4a;
  @media ${device.mobileL} {
    font-size: 2rem;
  }
  ${'' /* width: 25rem; */}
  width: 100%;
`;

export const CountText = styled.div`
  font-size: 8rem;
  font-family: ${fontFamily.circularMedium};
  color:  ${({ color }) => (color || '#4f4ed0')};

  margin-bottom: 2rem;

  @media ${device.mobileL} {
    font-size: 6rem;
    margin-bottom: 1.5rem;
  }
`;
