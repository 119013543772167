/* eslint-disable max-len */
import React from 'react';
import { Div } from './KeyInput.style';
import { ContentText, Span, Tooltip } from './KeyValueLayout.style';

const KeyInputLayoutView = (props) => {
  const {
    isLabelActive,
    children,
    label,
    tooltip,
    className,
    fieldClassName, // field layout
    width,
    isRequired // is field required
  } = props;
  return (
    <div className={`field-col ${fieldClassName || 'col_md'}`}>
      <Div className={`${className}  field-layout`} width={width}>
        <ContentText className='formLabel'>
          {/* radio button indication if any */}
          {isLabelActive}
          <div style={{ position: 'relative' }}>
            <Span className={`${className} ${isRequired ? 'required' : ''} text-label text-overflow`}>{label}</Span>
            <Tooltip className='tooltip'>{tooltip || label}</Tooltip>
          </div>
        </ContentText>

        <Span className='colonMargin field-colon'>{label ? ':' : null}</Span>
        {/* input of any type inputfield/dropdown/comment-editor */}
        {children}
      </Div>
    </div>
  );
};

export default KeyInputLayoutView;
