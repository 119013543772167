/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */

import { SCHEDULE } from '../../constants';
import { validate, VALIDATION_TYPES } from '../../constants/regex';

export const checkIsFrequencyBelowMonth = (masterScheduleFrequencyName) =>
  masterScheduleFrequencyName === 'Daily' ||
  masterScheduleFrequencyName === 'Weekly' ||
  masterScheduleFrequencyName === 'Bi-Weekly';

export const shouldSubmissionEnable = (payload, dropdownData) => {
  let isEnabled = true;
  // checkpoint 1 start
  const fixedFields = ['scheduleName', 'masterScheduleTypeName'];
  for (const field of fixedFields) {
    if (payload[field] === '') {
      isEnabled = false;
      break;
    }
  }
  // console.log('Checkpoint 1', isEnabled);
  if (!isEnabled) return false;

  // frequency input checks
  // checkpoint 2
  const {
    masterScheduleFrequencyName,
    frequencyOption,
    day,
    dayofEvery,
    firstLastName,
    firstLastDayName,
    firstLastDayEvery,
    recurring,
    occurs,
    occursEvery,
    startTime,
    endTime
  } = payload;
  // Change in json naming when schedule type frequency api is integrated
  const scheduleFrequency = masterScheduleFrequencyName?.Name;
  const masterScheduleTypeName =
    payload?.masterScheduleTypeName?.masterScheduleTypeName ||
    dropdownData?.masterScheduleTypes?.[0]?.[0]?.masterScheduleTypeName;
  if (masterScheduleTypeName !== 'One Time') {
    if (checkIsFrequencyBelowMonth(scheduleFrequency)) {
      if (recurring < 0) {
        // console.log('recurring', recurring);
        return false;
      }
      if (scheduleFrequency !== 'Daily') {
        // changed this condition as save was not being enabled when editing if daily changed to weekly or monthly changed to weekly
        if (!firstLastDayName?.firstLastDayId && !firstLastDayName?.firstLastDayName) return false;
      }
    } else if (frequencyOption === 'date') {
      if (day < 0 || dayofEvery < 0) {
        // console.log('date');
        return false;
      }
    } else if (frequencyOption === 'day') {
      if (firstLastDayEvery === '' || firstLastDayEvery < 0) {
        // console.log('day', firstLastName?.firstLastId, firstLastDayName?.firstLastDayId, firstLastDayEvery);
        return false;
      }
    }
    // console.log('Checkpoint 2', payload, isEnabled, 'endTime', endTime, !endTime);

    // checkpoint 3 within day frequency
    if (!occurs && (occursEvery < 0 || !startTime || !endTime)) return false;
  }

  // console.log('isenabled', isEnabled);
  return isEnabled;
};

// value to id utils
export const getMasterScheduleTypeId = (dropdownData, scheduleType) =>
  dropdownData?.masterScheduleTypes[0]?.find((e) => e.masterScheduleTypeName === scheduleType)?.masterScheduleTypeId;
export const getMasterScheduleFrequencyId = (dropdownData, scheduleFrequency) =>
  dropdownData?.masterScheduleFrequency?.[0]?.find((e) => e.masterScheduleFrequencyName === scheduleFrequency)
    ?.masterScheduleFrequencyId;
export const getFirstLastDayId = (dropdownData, firstLastDayName) =>
  dropdownData?.Frequency_FirstLastDays?.[0]?.find((e) => e.firstLastDayName === firstLastDayName?.firstLastDayName)
    ?.firstLastDayId;
export const getFirstLastId = (dropdownData, firstLastName) =>
  dropdownData?.Frequency_FirstLast?.[0]?.find((e) => e.firstLastName === firstLastName.firstLastName)?.firstLastId;

//  id to label utils ====== 2
export const getMasterScheduleTypeLabel = (dropdownData, scheduleType) =>
  dropdownData?.masterScheduleTypes[0]?.find((e) => e.masterScheduleTypeName === scheduleType)?.masterScheduleTypeId;
export const getMasterScheduleFrequencyLabel = (dropdownData, scheduleFrequency) =>
  dropdownData?.masterScheduleFrequency?.[0]?.find((e) => e.masterScheduleFrequencyName === scheduleFrequency)
    ?.masterScheduleFrequencyId;
export const getFirstLastDayLabel = (
  dropdownData,
  firstLastDayName // id
  // changed this condition as on dropdown change of values it was not getting reflected on UI. Always showing monday
) => dropdownData?.Frequency_FirstLastDays?.[0]?.find((e) => e.firstLastDayName === firstLastDayName)?.firstLastDayName;
export const getFirstLastLabel = (
  dropdownData,
  firstLastName // id
) => dropdownData?.Frequency_FirstLast?.[0]?.find((e) => e.firstLastId === firstLastName)?.firstLastName;

export const formatPayload = (payload, dropdownData) => {
  const {
    scheduleTypeId,
    scheduleName,
    masterScheduleTypeName,
    allowBufferHours,
    allowBufferMinutes,
    masterScheduleFrequencyName,
    frequencyOption,
    day,
    dayofEvery,
    firstLastName,
    firstLastDayName,
    firstLastDayEvery,
    recurring,
    occurs,
    occursEvery,
    startTime,
    endTime
  } = payload;
  const { masterScheduleTypeName: scheduleType } = masterScheduleTypeName || {};
  const scheduleFrequency = masterScheduleFrequencyName?.masterScheduleFrequencyName || masterScheduleFrequencyName?.Name;
  // masterScheduleTypeId if not get
  const masterScheduleTypeId =
    masterScheduleTypeName?.masterScheduleTypeId || getMasterScheduleTypeId(dropdownData, scheduleType);
  // dropdownData?.masterScheduleTypes[0]?.find((e) => e.masterScheduleTypeName === scheduleType)?.masterScheduleTypeId;

  // masterScheduleFrequencyId if not get
  const masterScheduleFrequencyId =
    masterScheduleFrequencyName?.masterScheduleFrequencyId || masterScheduleFrequencyName?.FrequencyId ||
    getMasterScheduleFrequencyId(dropdownData, scheduleFrequency);

  const frequencyId =
    masterScheduleFrequencyName?.masterScheduleFrequencyId || masterScheduleFrequencyName?.FrequencyId ||
    getMasterScheduleFrequencyId(dropdownData, scheduleFrequency);
  // dropdownData?.masterScheduleFrequency?.[0]?.find((e) => e.masterScheduleFrequencyName === scheduleFrequency)?.masterScheduleFrequencyId;
  let frequency = {
    scheduleFrequency,
    masterScheduleFrequencyId,
    frequencyId,
    day: '',
    dayofEvery: '',
    recurring: '',
    recurringDay: '',
    firstLast: '',
    firstLastDay: '',
    firstLastDayEvery: ''
  };
  // if option id not available
  // sending id instead of label change
  const firstLastDayId = firstLastDayName?.firstLastDayId || firstLastDayName?.firstLastDayName;
  // const firstLastDayId = firstLastDayName?.firstLastDayId || getFirstLastDayId(dropdownData, firstLastDayName); // || firstLastDayName
  // dropdownData?.Frequency_FirstLastDays?.[0]?.find((e) => e.firstLastDayName === firstLastDayName?.firstLastDayName)?.firstLastDayId;

  if (checkIsFrequencyBelowMonth(scheduleFrequency)) {
    const payload = {
      recurring
    };
    if (scheduleFrequency !== 'Daily') {
      payload.recurringDay = firstLastDayId;
    }
    frequency = { ...frequency, ...payload };
  } else if (frequencyOption === 'date') {
    // above month and option type date
    frequency = {
      ...frequency,
      day,
      dayofEvery
    };
  } else if (frequencyOption === 'day') {
    // above month and option type day
    // sending id instead of label change
    const firstLastId = firstLastName?.firstLastId || firstLastName?.firstLastName;
    // const firstLastId = firstLastName?.firstLastId || getFirstLastId(dropdownData, firstLastName); // ||  firstLastName
    // dropdownData?.Frequency_FirstLast?.[0]?.find((e) => e.firstLastName === firstLastName.firstLastName)?.firstLastId;
    frequency = {
      ...frequency,
      firstLast: firstLastId,
      firstLastDay: firstLastDayId,
      firstLastDayEvery
    };
  }

  if (!occurs && scheduleType?.toLowerCase() !== SCHEDULE.ONE_TIME.toLowerCase()) {
    if (!validate(startTime, VALIDATION_TYPES.TIME)) {
      throw new Error('Please Enter "Starting At" at in HH:MM:SS format');
    } else if (!validate(endTime, VALIDATION_TYPES.TIME)) {
      throw new Error('Please Enter "Ending At" in HH:MM:SS format');
    }

    if (startTime > endTime) {
      throw new Error('Start Time should be Greater than End Time');
    }
  }

  return {
    scheduleTypeId,
    scheduleName,
    scheduleType,
    masterScheduleTypeId,
    allowedBufferHour: allowBufferHours,
    allowedBufferTime: allowBufferMinutes,
    frequency: [frequency],
    occurs,
    occursEvery: occurs ? '' : occursEvery, // handling  occursEvery default value 1
    startTime,
    endTime
  };
};

export const formatToScheduleTypeForm = (payload) => {
  const {
    scheduleTypeId,
    scheduleName,
    scheduleType,
    allowedBufferHour,
    allowedBufferTime,
    occurs,
    occursEvery,
    startTime,
    endTime,
    // createdBy,
    // createdDate,
    // updatedBy,
    // updatedOn,
    frequency
  } = payload;
  const { scheduleFrequency, day, dayofEvery, firstLast, firstLastDay, firstLastDayEvery, recurring, recurringDay } =
    frequency[0] || {};
  // undefined ids are resolved during submit of edit form
  return {
    scheduleTypeId,
    scheduleName,
    masterScheduleTypeName: {
      masterScheduleTypeName: scheduleType
    },
    allowBufferHours: allowedBufferHour,
    allowBufferMinutes: allowedBufferTime,
    // frequency
    masterScheduleFrequencyName: {
      // masterScheduleFrequencyId:
      masterScheduleFrequencyName: scheduleFrequency
    },
    day,
    dayofEvery,
    firstLastName: {
      firstLastName: firstLast
      // firstLastId:
    },
    firstLastDayName: {
      // firstLastDayId,
      firstLastDayName: firstLastDay || recurringDay
    },
    firstLastDayEvery,
    recurring,
    // freq within a day
    occurs,
    occursEvery,
    startTime,
    endTime
  };
};
