/* eslint-disable function-paren-newline */
/* eslint-disable max-len */
import React, { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  SearchContainer,
  CommonButtonContainer,
  SearchButton,
  FilterButtom,
  SearchDropDown,
  SearchResultText,
  SearchTextContainer,
  Image,
  SearchMainContainer
} from './FilterSearchComponent.style';
import { Creators as FilterCreators } from '../../../views/Filters/store';
import FilterSearchInput from './FilterSearchInput';
import useClickOutside from '../../../shared/hooks/useClickOutside';
import { ReactComponent as FilterIcon } from '../../../assets/icons/Filter/Filters.svg';
import { ReactComponent as SearchIconSvg } from '../../../assets/icons/Filter/Search Black.svg';
import NoResultSvg from '../../../assets/icons/Filter/No results on filter.svg';
import { localString } from '../../../localization/localString';
import DynamicSVGIcon from '../../Common/DynamicSVGIcon';
import { NoResultText } from '../../GlobalSearch/GlobalSearchContainer.style';
import useSelectionTypesDefined from '../hooks/useSelectionTypesDefined';
import { FILTER_TERMS } from '../../../constants';
import { isEmpty } from '../../../shared/utility/isEmpty';

function FilterSearchComponent({
  showFilter = true,
  setShowFilter = () => {},
  setActiveTab = () => {},
  canToggleFilter = true,
  placeholder = ''
}) {
  const [searchValue, setSearchValue] = useState('');
  const {
    isDropDownActive,
    searchResult,
    tags,
    selectedItem,
    cumulativeTags,
    showFilterResult,
    filterData: fd
  } = useSelector((state) => state.filterData);
  const filterData = useSelector((state) => state.filterData);
  const { localeStrings } = useSelector((state) => state.localeStrings);

  const dispatch = useDispatch();

  const addTabToObj = useCallback((payload) => dispatch(FilterCreators.universalFilterSetter(payload)), [dispatch]);
  const addTags = useCallback((payload) => dispatch(FilterCreators.setTags(payload)), [dispatch]);
  const addCummulativeTags = useCallback((payload) => dispatch(FilterCreators.setCummulativeTags(payload)), [dispatch]);

  const closeSearchDropDown = () => {
    if (isDropDownActive) {
      dispatch(
        FilterCreators.universalFilterSetter({
          key: 'isDropDownActive',
          value: false
        })
      );
    }
  };

  const handleClick = (i, parent) => {
    const cummSelectedObj = filterData[`cumulative${parent}`];
    // show filter on click if it is in resultview
    if (showFilterResult) {
      dispatch(
        FilterCreators.universalFilterSetter({
          key: 'showFilterResult',
          value: false
        })
      );
    }
    let pathInsertIndex = 0;
    let check;
    let pathNoFound;
    // console.log('cummSelectedObj', cummSelectedObj);
    // checking whether the full or partial selection is already present.
    if (cummSelectedObj) {
      check = Object.keys(cummSelectedObj).findIndex((pathNum) =>
        Object.keys(searchResult.searchedObjectPath[i]).every((key) => {
          pathNoFound = pathNum;
          return Object.keys(cummSelectedObj[pathNum]).includes(key);
        })
      );
      // console.log('pathInsertIndex', check, searchResult.searchedObjectPath[i]);
    }

    if (!isEmpty(cummSelectedObj)) {
      const noOfPaths = Object.keys(cummSelectedObj).length;
      pathInsertIndex = parseInt(Object.keys(cummSelectedObj)[noOfPaths - 1], 10) + 1;
    }
    // selection is already present(exactly)
    if (check > -1) {
      // console.log('already present', pathNoFound);
      addTabToObj({
        key: parent,
        value: cummSelectedObj[pathNoFound]
      });
      addTags({
        ...tags,
        [parent]: cumulativeTags[parent][pathNoFound]
      });
    } else {
      const selectionTypesDefined = useSelectionTypesDefined(fd, parent);
      // console.log(
      //   'aaaa',
      //   Object.keys(searchResult.searchedObjectPath[i]),
      //   searchResult.searchedObjectPath[i],
      //   selectionTypesDefined
      // );

      // checking whether an item is already selected
      // for a single selection attrubute
      let pathNoTobeReplaced = -1;
      Object.keys(searchResult.searchedObjectPath[i]).some((key) => {
        const item = searchResult.searchedObjectPath[i][key];
        if (selectionTypesDefined[item.attribute] === FILTER_TERMS.SINGLE_SELECT) {
          console.log('found', key);
          if (!isEmpty(cummSelectedObj)) {
            Object.keys(cummSelectedObj).some((pathNo) => {
              if (Object.keys(cummSelectedObj[pathNo]).includes(key)) {
                pathNoTobeReplaced = pathNo;
                return true;
              }
              return false;
            });
          }
          // console.log('pathNoTobeReplaced', pathNoTobeReplaced);
          return true;
        }
        return false;
      });
      if (pathNoTobeReplaced > -1) {
        pathInsertIndex = pathNoTobeReplaced;
      }
      console.log('adding/replacing the path', pathInsertIndex);
      // adding the path
      addTabToObj({
        key: parent,
        value: searchResult.searchedObjectPath[i]
      });
      addTags({
        ...tags,
        [parent]: searchResult.referencePath[i]
      });
      addTabToObj({
        key: `cumulative${parent}`,
        value: {
          ...cummSelectedObj,
          [pathInsertIndex]: searchResult.searchedObjectPath[i]
        }
      });
      addCummulativeTags({
        ...cumulativeTags,
        [parent]: {
          ...cumulativeTags[parent],
          [pathInsertIndex]: searchResult.referencePath[i]
        }
      });
    }
    // }

    closeSearchDropDown();
    if (!showFilter) {
      setShowFilter(true);
    }
    if (window.innerWidth < 767) {
      const index = filterData.findIndex((item) => item.groupTitle === parent);
      setActiveTab(index);
    }

    setSearchValue('');
    addTabToObj({
      key: 'searchResult',
      value: null
    });

    // if (searchResult.searchedObjectPath[i]) {
    //   const lastIndex = Object.keys(searchResult.searchedObjectPath[i])[
    //     Object.keys(searchResult.searchedObjectPath[i]).length - 1
    //   ];
    //   if (!searchResult.searchedObjectPath[i][lastIndex].values) {
    //     const index = selectedItem.findIndex((item) => item === parent);
    //     if (index === -1) {
    //       addTabToObj({
    //         key: 'selectedItem',
    //         value: [...selectedItem, parent]
    //       });
    //     }
    //   }
    // }
  };

  const handleFilterClick = () => {
    setShowFilter(true);
    addTabToObj({
      key: 'showFilterResult',
      value: false
    });
  };

  const ref = useRef();

  useClickOutside(ref, closeSearchDropDown);

  return (
    (showFilter || window.innerWidth > 768) && (
      <SearchMainContainer>
        <SearchContainer ref={ref}>
          <FilterSearchInput searchValue={searchValue} setSearchValue={setSearchValue} placeholder={placeholder} />
          <CommonButtonContainer>
            <SearchButton>
              <SearchIconSvg />
            </SearchButton>
            {canToggleFilter && (
              <FilterButtom onClick={handleFilterClick}>
                <FilterIcon />
              </FilterButtom>
            )}
          </CommonButtonContainer>
          {isDropDownActive && searchValue !== '' && (
            <SearchDropDown>
              <div style={{ marginTop: 5 }}>
                {searchResult &&
                  (searchResult?.pathVal?.length > 0 ? (
                    searchResult.pathVal.map((path, i) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <SearchTextContainer key={i} onClick={() => handleClick(i, path.parent)}>
                        <SearchResultText>{`${path.parent} / ${path.path}`}</SearchResultText>
                      </SearchTextContainer>
                    ))
                  ) : (
                    <div
                      style={{
                        width: '100%',
                        height: 100,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      {/* <Image src={NoResultSvg} width={3} height={3} /> */}
                      <Image src={NoResultSvg}>
                        <DynamicSVGIcon iconUrl={NoResultSvg} width='3rem' />
                      </Image>
                      <NoResultText>{localeStrings.noResult || 'No Result'}</NoResultText>
                    </div>
                  ))}
              </div>
            </SearchDropDown>
          )}
        </SearchContainer>
      </SearchMainContainer>
    )
  );
}

export default FilterSearchComponent;
