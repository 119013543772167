import styled from 'styled-components';
import { device } from '../../constants';

export const EditorContainer = styled.div`
  & * {
    font-size: 1.5rem;
  }
  & .ql-editor {
    height: 120px;
  }
  & .quill {
    /* margin: 10px 0px; */
    width: ${(props) => props.wd || '62rem'};
    justify-content: center;
    border: none;
    position: relative;
    margin-top: 12rem;
    margin-bottom: 1.6rem;
    // height: 90px;
    // background-color: red;
    @media ${device.tablet} {
      width: 31rem;
    margin-bottom: 3.6rem;

    }
  }
  & .ql-toolbar {
    border: none;
    border-radius: 5px 5px 0px 0px;
    box-shadow: 0px 0px 5px 1px rgb(222, 217, 217) inset;
    position: relative;
    /* box-shadow: ${({ theme }) => theme.shadowin}; */
    background-color: ${({ theme }) => theme.cardBg};
    margin-bottom: -17rem;
    margin-top: 4rem;
    z-index: 1;
    border-radius: 7px;
  }
  & .ql-container {
    border: none;
    /* border-radius: 0px 0px 5px 5px; */
    border-radius: 5px;

    background-color: ${({ theme }) => theme.table.hightlight};
    box-shadow: ${({ theme }) => theme.shadowin};
  }
  & span.ql-formats {
    // background-color: red;
    border-right: 1px solid rgb(222, 217, 217);
    weight: 500;
  }
`;

export const Div = styled.div`
  & {
    font-size: 5.5rem;
    // margi: 20px 0px;
  }
`;

export const Buttons = styled.div`
  & {
    font-size: 4.1rem;
  }
`;
