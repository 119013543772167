/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import backArrow from '../../../../assets/icons/layout/BackArrow.svg';
import { FlexContainer } from '../../../../globalStyles';
import { useActions } from '../../../../store/hooks/useActions';
import DropDownModal from '../../DropDownModal/DropDownModal';
import DynamicSVGIcon from '../../DynamicSVGIcon';
import { options } from '../utility';

const ViewTypeSelection = (props) => {
  const { calendarRef } = props;
  const calendarApiObjProp = calendarRef.current?.getApi();
  const isListView = useSelector((state) => state.plansData.isListView);
  const { setIsListView } = useActions();
  const [showModal, setShowModal] = useState(false);
  const calendarApiObj = calendarApiObjProp?.currentDataManager;

  const batchRendering = (callback) => {
    // subclasses should implement
    callback();
  };
  const getCurrentData = () => calendarApiObj?.getCurrentData();
  // util
  function triggerDateUnselect(pev, context) {
    context.emitter.trigger('unselect', {
      jsEvent: pev ? pev.origEvent : null,
      view: context.viewApi || context.calendarApi.view
    });
  }
  function unselect(pev) {
    const state = getCurrentData();

    if (state.dateSelection) {
      calendarApiObj.dispatch({ type: 'UNSELECT_DATES' });
      triggerDateUnselect(pev, state);
    }
  }

  const changeView = (viewTypeRaw, dateOrRange) => {
    const { key: viewType, isCustomHandling } = viewTypeRaw;
    if (isCustomHandling) {
      if (viewType === 'list') {
        setIsListView(true);
      }
    } else {
      setIsListView(false);
      batchRendering(() => {
        unselect();
        if (dateOrRange) {
          if (dateOrRange.start && dateOrRange.end) {
            // a range
            calendarApiObj.dispatch({
              type: 'CHANGE_VIEW_TYPE',
              viewType
            });
            calendarApiObj.dispatch({
              // not very efficient to do two dispatches
              type: 'SET_OPTION',
              optionName: 'visibleRange',
              rawOptionValue: dateOrRange
            });
          } else {
            const { dateEnv } = getCurrentData();

            calendarApiObj.dispatch({
              type: 'CHANGE_VIEW_TYPE',
              viewType,
              dateMarker: dateEnv.createMarker(dateOrRange)
            });
          }
        } else {
          calendarApiObj.dispatch({
            type: 'CHANGE_VIEW_TYPE',
            viewType
          });
        }
      });
    }
    setShowModal(false);
  };

  const activeItem = calendarApiObj?.data?.viewSpec?.type;
  const isActiveChecker = (activeItem, item) => activeItem === item.key;
  const activeSelectionType = options.find((e) => e.key === activeItem) || options[0];
  return (
    <>
      <DropDownModal
        setModalIsVisibal={setShowModal}
        data={options}
        handleClick={changeView}
        ContainerStyle={{ width: '120px', right: '0px' }}
        activeItem={isListView ? 'list' : activeItem}
        showSelection
        isActive={showModal}
        isActiveChecker={isActiveChecker}
      >
        <FlexContainer className='ai_center' onClick={() => setShowModal((prev) => !prev)}>
          {activeSelectionType?.iconUrl && <DynamicSVGIcon width='2.3rem' iconUrl={activeSelectionType?.iconUrl} />}
          <span className='capitalize' style={{ marginLeft: '5px' }}>
            {(isListView && 'List') || activeSelectionType?.label || activeItem}
          </span>
          <DynamicSVGIcon rotate={showModal ? 90 : 270} width='2.3rem' iconUrl={backArrow} />
        </FlexContainer>
      </DropDownModal>
    </>
  );
};

export default ViewTypeSelection;
