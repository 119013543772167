import {
  actions,
  actionsActive,
  boards,
  boardsActive,
  dashBoard,
  dashBoardActive,
  reports,
  reportsActive,
  schedules,
  storesActive,
  schedulesActive,
  stores,
  workorders,
  workordersActive
} from '../../assets/icons/layout/menuIcons';

export const data = [
  {
    Id: 1000,
    PId: 0,
    Name: 'Dashboard',
    '[Description]': 'Dashboard Page',
    Icon: dashBoard,
    IconActive: dashBoardActive,
    Permalink: '/home/Dashboard',
    PageType: 'MyActionItems',
    FilterType: 'None',
    FilterLevel: '-1',
    IsLeaf: '0',
    SubMenu: [
      {
        Id: 2006,
        PId: 1000,
        Name: 'Active WO Summary',
        '[Description]': 'Active WO Summary',
        Permalink: '/home/Dashboard/Active-WO-Summary',
        PageType: 'MyActionItems',
        FilterType: 'None',
        FilterLevel: '-1',
        IsLeaf: '1',
        SubMenu: [{}]
      }
      // {
      //   Id: 2002,
      //   PId: 1000,
      //   Name: 'Test Iframe',
      //   '[Description]': 'for iframe testing',
      //   Permalink: '/home/Dashboard/test-iframe',
      //   PageType: 'Filter',
      //   FilterType: 'None',
      //   FilterLevel: '-1',
      //   IsLeaf: '1',
      //   SubMenu: [{}]
      //   // SubMenuLabelOptions: 'Trend,Comparison'
      // }
    ]
  },
  {
    Id: 1001,
    PId: 0,
    Name: 'Work Orders',
    '[Description]': 'Work Orders Items',
    Icon: workorders,
    IconActive: workordersActive,
    Permalink: '/home/work_orders',
    PageType: 'WorkOrders',
    IsLeaf: '1',
    SubMenu: [
      {
        SubMenu: [{}]
      }
    ]
  },
  {
    Id: 1002,
    PId: 0,
    Name: 'My Action Items',
    '[Description]': 'My Action Items',
    Icon: actionsActive,
    IconActive: actionsActive,
    Permalink: '/home/My_Action',
    PageType: 'MyActionItems',
    IsLeaf: '1',
    SubMenu: []
  },
  // {
  //   Id: 1003,
  //   PId: 0,
  //   Name: 'Boards',
  //   '[Description]': 'Boards',
  //   Icon: boards,
  //   IconActive: boardsActive,
  //   Permalink: '/home/boards',
  //   PageType: 'Card',
  //   FilterType: 'None',
  //   FilterLevel: '-1',
  //   IsLeaf: '1',
  //   SubMenu: [
  //     {
  //       SubMenu: [{}]
  //     }
  //   ]
  // },
  // {
  //   Id: 1004,
  //   PId: 0,
  //   Name: 'Reports',
  //   '[Description]': 'Reports',
  //   Icon: reports,
  //   IconActive: reportsActive,
  //   Permalink: '/home/reports',
  //   PageType: 'Card',
  //   FilterType: 'None',
  //   FilterLevel: '-1',
  //   IsLeaf: '1',
  //   SubMenu: [
  //     {
  //       SubMenu: [{}]
  //     }
  //   ]
  // },
  {
    Id: 1005,
    PId: 0,
    Name: 'Stores',
    '[Description]': 'Stores',
    Icon: stores,
    IconActive: storesActive,
    Permalink: '/home/Stores',
    PageType: 'Card',
    FilterType: 'None',
    FilterLevel: '-1',
    IsLeaf: '0',
    SubMenu: [
      // {
      //   Id: 2004,
      //   PId: 1005,
      //   Name: 'Location',
      //   '[Description]': 'Location',
      //   Permalink: '/home/Stores/Location',
      //   PageType: 'Filter',
      //   FilterType: 'Single',
      //   FilterLevel: '4',
      //   IsLeaf: '1',
      //   SubMenu: [{}]
      // },

      {
        Id: 2005,
        PId: 1005,
        Name: 'Spares',
        '[Description]': 'Spares',
        Permalink: '/home/Stores/Spares',
        PageType: 'Spares',
        FilterType: 'Single',
        FilterLevel: '4',
        IsLeaf: '1',
        SubMenu: [{}]
      }
      // {
      //   Id: 2005,
      //   PId: 1005,
      //   Name: 'Reports',
      //   '[Description]': 'Reports',
      //   Permalink: '/home/Stores/Reports',
      //   PageType: 'Card',
      //   FilterType: 'Single',
      //   FilterLevel: '4',
      //   IsLeaf: '1',
      //   SubMenu: [{}]
      // }
    ]
  },
  {
    Id: 1006,
    PId: 0,
    Name: 'Reports',
    '[Description]': 'Reports',
    Icon: reports,
    IconActive: reportsActive,
    Permalink: '/home/Dashboard/charts-tabular',
    PageType: 'Filter',
    FilterType: 'singleselect',
    FilterLevel: '2',
    SubMenuLabelOptions: 'Reports',
    IsLeaf: '1',
    SubMenu: []
  },
  // {
  //   Id: 1003,
  //   PId: 0,
  //   Name: 'Direct Iframe Demo-1',
  //   '[Description]': 'Data Verifier',
  //   Icon: reports,
  //   IconActive: reportsActive,
  //   Permalink: '/home/dataverifier',
  //   PageType: 'directIframe',
  //   FilterType: 'None',
  //   FilterLevel: '-1',
  //   IsLeaf: '1',
  //   SubMenuLabelOptions: 'Summary2,Trend2,Comparison2,Details2',
  //   SubMenu: [
  //     {
  //       SubMenu: [{}]
  //     }
  //   ]
  // },
  // {
  //   Id: 1004,
  //   PId: 0,
  //   Name: 'Direct Iframe Demo-2',
  //   '[Description]': 'Review',
  //   Icon: reports,
  //   IconActive: reportsActive,
  //   Permalink: '/home/review',
  //   PageType: 'directIframe',
  //   FilterType: 'None',
  //   FilterLevel: '-1',
  //   IsLeaf: '1',
  //   SubMenu: [
  //     {
  //       SubMenu: [{}]
  //     }
  //   ]
  // },
  {
    Id: 3000,
    PId: 0,
    Name: 'Admin',
    '[Description]': 'Admin',
    Icon: reports,
    IconActive: reportsActive,
    // Permalink: '/home/activity',
    // PageType: 'Activity',
    FilterType: 'None',
    FilterLevel: '-1',
    IsLeaf: '0',
    SubMenu: [
      {
        Id: 3001,
        PId: 3000,
        Name: 'Activity',
        '[Description]': 'Activity',
        Icon: reports,
        IconActive: reportsActive,
        Permalink: '/home/activity',
        PageType: 'Activity',
        FilterType: 'None',
        FilterLevel: '-1',
        IsLeaf: '1',
        SubMenu: [
          {
            SubMenu: [{}]
          }
        ]
      },
      {
        Id: 3002,
        PId: 3000,
        Name: 'Schedule Type',
        '[Description]': 'Schedule Type',
        Icon: reports,
        IconActive: reportsActive,
        Permalink: '/home/schedule-type',
        PageType: 'ScheduleType',
        FilterType: 'None',
        FilterLevel: '-1',
        IsLeaf: '1',
        SubMenu: [
          {
            SubMenu: [{}]
          }
        ]
      },
      {
        Id: 3003,
        PId: 3000,
        Name: 'Schedule',
        '[Description]': 'Schedule',
        Icon: reports,
        IconActive: reportsActive,
        Permalink: '/home/schedule',
        PageType: 'Schedule',
        FilterType: 'None',
        FilterLevel: '-1',
        IsLeaf: '1',
        SubMenu: [
          {
            SubMenu: [{}]
          }
        ]
      },
      {
        Id: 3004,
        PId: 3000,
        Name: 'Plan',
        '[Description]': 'Plan',
        Icon: reports,
        IconActive: reportsActive,
        Permalink: '/home/plan',
        PageType: 'Plan',
        FilterType: 'None',
        FilterLevel: '-1',
        IsLeaf: '1',
        SubMenu: [
          {
            SubMenu: [{}]
          }
        ]
      }
    ]
  },
  {
    Id: 1007,
    PId: 0,
    Name: 'Users',
    '[Description]': 'Users',
    Icon: reports,
    IconActive: reportsActive,
    Permalink: '/home/Users',
    PageType: 'Users',
    FilterType: 'None',
    FilterLevel: '-1',
    IsLeaf: '1',
    SubMenu: [
      {
        SubMenu: [{}]
      }
    ]
  }
];
