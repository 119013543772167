/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DropBtn, DropdownCon } from '../../../views/WorkOrders/WorkOrders.styles';
import { InputAndButtonContainer } from '../../Common/DropDownModal/DropDownModal.style';
import { ReactComponent as Arrow } from '../../../assets/icons/layout/dropdownarrow.svg';
import { FlexContainer } from '../../../globalStyles';
import { Div } from '../../ScheduleType/ScheduleTypeForm/ScheduleTypeForm.style';
import InputDropdown from '../../Common/DropDownModal/InputDropdown';
import { BtnAddActivity } from './AddActivity.style';
import Editor from '../../../assets/Editor/Editor';
import { Input } from '../../Common/Input/Input';
import { BodyContainer } from '../ActivityDetails/ActivtyDetails.style';
import { ContentText, Span } from '../../Common/KeyValueLayout/KeyValueLayout.style';
import { SearchResultText, SearchTextContainer } from '../../GlobalSearch/GlobalSearchContainer.style';
import useClickOutside from '../../../shared/hooks/useClickOutside';
import HeaderComp from '../../Common/Header/Header';
import { Creators as ActivityCreators } from '../../../views/Activity/store/action';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { WebService } from '../../../services/axios/webServices';
import { actions } from '../../../assets/icons/layout/menuIcons';
import DropDownModal from '../../Common/DropDownModal/DropDownModal';
import KeyInputLayoutView from '../../Common/KeyValueLayout/KeyInputLayout.view';
// import Loader from '../../Common/Loader/Loader';
import Loader from '../../Loader/Loader';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';

const AddActivityForm = (props) => {
  const { detail, handleSave } = props;
  const [typeDropDown, setTypeDropdown] = useState([]);
  const [input, setInput] = useState(
    detail || {
      title: '',
      description: '',
      type: ''
      // createdOn: `${new Date()
      //   .toJSON()
      //   .slice(0, 10)
      //   .replace(/-/g, '-')}T${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`
    }
  );
  const [showDropdown, setShowDropdown] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [notEmpty, setNotEmpty] = useState(false);
  const [activeRef, setActiveRef] = useState('');
  const ref = useRef();
  const typeRef = useRef();
  const buttonReff = useRef();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    WebService.get(apiEndPoints.getWorkflowTypesForActivity)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          setTypeDropdown(res.data);
        } else {
          throw res;
        }
      })
      .catch((e) => {
        const err = sagaCatchBlockHandling(e);
        console.log('err', err);
      });
  }, [input]);

  const closeDropDown = () => {
    if (showDropdown) {
      setShowDropdown(false);
    }
  };

  const backButton = () => {
    history.push('/home/activity');
  };

  const handleType = (ele) => {
    setInput({ ...input, type: ele.workFlowTypeName });
    setShowDropdown(false);
  };
  const handleTypedropDown = () => {
    setShowDropdown(!showDropdown);
    setActiveRef(typeRef);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };

  const onSubmit = () => {
    handleSave({ ...input }, setSubmitLoader);
  };

  const validating = () => {
    let isFilled = true;
    for (const key of Object.keys(input)) {
      if (input[key] === '') {
        isFilled = false;
      }
      setNotEmpty(isFilled);
    }
  };

  useEffect(() => {
    validating();
  }, [handleChange]);

  // useClickOutside(ref, closeDropDown);
  return (
    <>
      <HeaderComp isActivity={true} className='fixed-height' header={detail ? 'Edit Ativity' : 'Create Acitvity'} hasBack>
        <FlexContainer className='jc_center'>
          <BtnAddActivity className='cancel_btn' onClick={backButton}>
            Cancel
          </BtnAddActivity>
          <BtnAddActivity onClick={onSubmit} disabled={!notEmpty || submitLoader}>
            {submitLoader ? <Loader className='submitBtn' /> : 'Save'}
          </BtnAddActivity>
        </FlexContainer>
      </HeaderComp>

      <BodyContainer style={{ zIndex: '1', position: 'relative' }}>
        <KeyInputLayoutView className='required' label='Title'>
          <Input
            width={window.innerWidth < 768 ? '100%' : '62rem'}
            placeholder='Title'
            value={input.title}
            name='title'
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </KeyInputLayoutView>
        <KeyInputLayoutView label='Description'>
          <Editor
            value={input.description}
            name='description'
            onChange={(e) => {
              handleChange({ target: { name: 'description', value: e } });
            }}
          />
        </KeyInputLayoutView>
        <KeyInputLayoutView className='required' label='Workflow'>
          <DropDownModal
            data={typeDropDown}
            isActive={showDropdown}
            handleClick={handleType}
            setModalIsVisibal={closeDropDown}
            label='workFlowTypeName'
            width={window.innerWidth < 768 ? '31rem' : '62rem'}

          >
            <InputDropdown
              value={input?.type}
              handleDropdownVisibility={handleTypedropDown}
              placeholder='Description workflow'
            />
          </DropDownModal>
        </KeyInputLayoutView>
      </BodyContainer>
    </>
  );
};

export default AddActivityForm;
