/* eslint-disable max-len */
import React from 'react';
import { Div } from './KeyInput.style';
import { ContentText, Span } from './KeyValueLayout.style';

const KeyInputLayoutView = (props) => {
  const { isLabelActive, label, children, className: rawClassName, colonStyleNotRequired = false } = props;
  const className = rawClassName?.replace('required', '')?.trim();
  return (
    <Div className={className}>
      <ContentText className='formLabel'>
        {/* radio button indication if any */}
        {isLabelActive}
        <Span className={rawClassName?.includes('required') ? 'required' : ''}>{label}</Span>
      </ContentText>
      <Span className='colonMargin'>{!(colonStyleNotRequired) && ':'}</Span>
      {/* input of any type inputfield/dropdown/comment-editor */}
      {children}
    </Div>
  );
};

export default KeyInputLayoutView;
