/* eslint-disable max-len */
import { put } from 'redux-saga/effects';
import { PlanCreators } from './action';
import { WebService } from '../../../services/axios/webServices';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';
// import { formatEvents } from '../utility';
// import { samplePlanList } from './sampleData';

export function* getPlanListSaga({ payload }) {
  try {
    const { page, pageSize, lang, searchQueries, month, year } = payload;
    yield put(PlanCreators.getPlanListStart());

    const endpoint = `${apiEndPoints.getSchedulePlan}/${lang || 'en'}/${page || 1}/${pageSize || 10}/${month || 0}/${
      year || 0
    }`;
    const response = yield WebService.post(endpoint, { SearchFilters: { ...searchQueries } });
    if (response.status >= 200 && response.status < 300) {
      // console.log(response.data);
      const { data, meta } = response.data;

      yield put(PlanCreators.getPlanListSuccess({ meta, planList: data || [] }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });
    const payload = sagaCatchBlockHandling(error);
    yield put(PlanCreators.getPlanListFailure(payload?.error));
  }
}

export function* getPlanDetailsSaga({ payload }) {
  try {
    const { id } = payload;
    yield put(PlanCreators.getPlanDetailsStart());
    const endPoint = `${apiEndPoints.getSchedulePlanDetails}/${id}`;
    const response = yield WebService.get(endPoint);
    if (response.status >= 200 && response.status < 300) {
      // console.log(response.data);
      const { data } = response.data;

      yield put(PlanCreators.getPlanDetailsSuccess({ planDetails: data?.[0]?.[0] || {} }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log('error', error);
    const payload = sagaCatchBlockHandling(error);
    yield put(PlanCreators.getPlanDetailsFailure(payload?.error));
  }
}
