/* eslint-disable function-paren-newline */
/* eslint-disable max-len */

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { isEmpty } from '../../shared/utility/isEmpty';
import { Creators as FilterCreators } from '../../views/Filters/store';
import Spares from '../../views/Stores/Spares/Spares.view';
import Loader from '../Common/Loader/Loader';
import AssetSelection from '../WorkOrder/CreateWorkOrder/AssetSelection';
import { generateSelectedFilter, getBreadCrumbs } from './Iframe/generateSelectedFilter';
// import { generateSelectedFilter, linkNodes } from './Iframe/generateSelectedFilter';

function ShowAllTheFilter({ type, location, isFullHeight }) {
  const {
    resultType,
    tags,
    menuIdofFilter,
    cumulativeTags,
    selectedParent,
    filterData,
    iframeRes,
    iframeLoading,
    inputs
  } = useSelector((state) => state.filterData);
  const dispatch = useDispatch();

  useEffect(() => {
    const formatedTags = generateSelectedFilter(cumulativeTags, filterData, true);
    // console.log('location', location);
    const payload = {
      menuid: menuIdofFilter,
      tab: selectedParent,
      data: formatedTags,
      inputs: { ...inputs }
    };

    // dispatch(FilterCreators.getIFrameData(payload));
    dispatch(
      FilterCreators.universalFilterSetter({
        key: 'filtersPayload',
        value: payload
      })
    );

    dispatch(
      FilterCreators.universalFilterSetter({
        key: 'activeBreadCrumb',
        value: 0
      })
    );
    dispatch(
      FilterCreators.universalFilterSetter({
        key: 'breadCrumbs',
        value: [...getBreadCrumbs(generateSelectedFilter(cumulativeTags, filterData, false))]
      })
    );
  }, []);

  const switchComp = () => {
    switch (resultType) {
      case 'spares':
        return <Spares />;
      default:
      case 'drilldown':
        return <AssetSelection />;
      case 'iframe':
        return <Iframe location={location} />;
      // default:
      //   return <div style={{ fontSize: '30px', textAlign: 'center' }}>Invalid Result Type</div>;
    }
  };
  return <MainContainer isFullHeight>{switchComp()}</MainContainer>;
}

const Iframe = ({ location }) => {
  const { iframeRes, filtersPayload, iframeLoading } = useSelector((state) => state.filterData);
  // const [formatTags, setFormatTags] = useState('');

  const dispatch = useDispatch();

  // useEffect(() => {
  //   setFormatTags(JSON.stringify(tags));
  // }, [tags]);

  useEffect(() => {
    if (!isEmpty(filtersPayload)) dispatch(FilterCreators.getIFrameData(filtersPayload));
  }, [filtersPayload]);

  if (iframeRes) {
    return (
      <>
        {iframeLoading ? (
          <Loader />
        ) : (
          <IframeContainer src={iframeRes.uri} name='iframe' title='filter-iframe' style={{ border: '0px' }} />
        )}
      </>
    );
    // <div>{JSON.stringify(tags, false, 10)}</div>;
  }
  return null;
};

export const IframeContainer = styled.iframe`
  width: 100%;
  margin: 2rem;
  flex: 1;
  /* background: green; */
  /* margin-top: 2rem; */
`;

const MainContainer = styled.div`
  height: ${({ isFullHeight }) => (isFullHeight ? '100%' : 'calc(100% - 6.5rem)')};
  width: 100%;
  display: flex;
  /* background: green; */
`;

export default ShowAllTheFilter;
