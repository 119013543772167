/* eslint-disable indent */
/* eslint-disable no-useless-escape */
/* eslint-disable no-throw-literal */
/* eslint-disable max-len */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from '../../shared/utility/isEmpty';
import {
  HeaderText
  // ButtonContainer,
  // Button
} from '../../views/Users/Users.style';
import UsersHeader from '../../views/Users/UsersHeader';
import { INPUT_TYPES, fontSize } from '../../constants';
import { userformStructure as localForm } from './data';
import { BodyContainer } from './UserDetails.style';
import GenericForm from '../Common/GenericForm/GenericForm';
import { WebService } from '../../services/axios/webServices';
import { apiEndPoints } from '../../services/axios/endPoints';
import { sagaCatchBlockHandling } from '../../store/utility/sagaErrorHandling';
import Loader from '../Common/Loader/Loader';
import { useActions } from '../../store/hooks/useActions';
import {
  convertFormInputObjToSimple,
  getFieldDependents,
  validateFields
} from '../Common/GenericForm/utils';
import Toast from '../Common/Toast';
// import { VALIDATION_TYPES, validate } from '../../shared/utility/regex';

// import { FlexContainer } from '../../globalStyles';
// import { UsersCreators } from '../../views/Users/store';
// import { employeeId, CancelBlack } from '../../assets/icons';
// import PageHeader from '../Common/PageHeader/PageHeader';
// import PopUpModal from '../Common/PopUpModal/Modal.view';
// import { Text } from '../../views/Widgets/Widget.style';
// import { ModalContentText } from '../Common/DropDownModal/DropDownModal.style';
// import ButtonComp from '../Common/Button/Button.view';

const UserDetailsForm = (props) => {
  const locations = useLocation();
  const history = useHistory();
  const { state } = locations;
  const [formInput, setFormInput] = useState({});
  const [userformStructure, setUserformStructure] = useState([]);
  const { userPermissions } = useSelector((state) => state.usersDetails);
  // const userformStructure = [
  //   {
  //     header: 'Update User Information'
  //   },
  //   {
  //     label: 'Full Name:',
  //     tooltip: 'Full Name',
  //     inputType: 'text',
  //     isReadOnly: true,
  //     allowEmpty: false,
  //     key: 'UserFullName'
  //   },
  //   {
  //     label: 'UserID:',
  //     inputType: 'text',
  //     key: 'UserID',
  //     isRequired: true,
  //     isReadOnly: true,
  //     allowEmpty: false
  //   },
  //   {
  //     label: 'EmployeeID',
  //     inputType: 'text',
  //     key: 'EmployeeID',
  //     placeholder: 'Enter EmployeeID'
  //   },
  //   {
  //     label: 'Full Name:',
  //     inputType: 'text',
  //     key: 'UserFullName'
  //   },
  //   {
  //     label: 'Mobile Number:',
  //     inputType: 'text',
  //     key: 'MobileNumber',
  //     placeholder: '',
  //     validation: {
  //       value: '^$|^(\\+\\d{1,2}\\s?)?1?\\-?\\.?\\s?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$',
  //       type: 'regex',
  //       format: '(000) 000-0000'
  //     }
  //   },
  //   {
  //     label: 'Email Address:',
  //     inputType: 'text',
  //     key: 'EmailAddress',
  //     placeholder: '',
  //     validation: {
  //       value: '^$|^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?w+)*(\\.\\w{2,3})+$',
  //       type: 'regex',
  //       format: 'xxx@xxx.xx'
  //     }
  //   },
  //   {
  //     label: 'Profile Image:',
  //     inputType: 'imageUpload',
  //     placeholder: ''
  //   },
  //   {
  //     label: 'Manager:',
  //     inputType: 'text',
  //     key: 'Manager',
  //     placeholder: ''
  //   },
  //   {
  //     label: 'Active Directory User:',
  //     inputType: 'toggle',
  //     key: 'IsActiveDirectoryUser',
  //     placeholder: ''
  //   },
  //   {
  //     label: 'IsEnabled:',
  //     inputType: 'toggle',
  //     key: 'IsEnabled',
  //     placeholder: ''
  //   },
  //   {
  //     label: 'IsChecklistUser:',
  //     inputType: 'toggle',
  //     key: 'IsChecklistUser',
  //     placeholder: ''
  //   },
  //   {
  //     label: 'Operations Group:',
  //     inputType: 'dropdown',
  //     key: 'OperationsGroup',
  //     placeholder: '',
  //     fieldWidth: 'MEDIUM',
  //     dataSource: {
  //       dataKey: 'source',
  //       source: {
  //         method: 'get',
  //         UrlType: 'relative',
  //         Url: '/getDepartmentMaster'
  //       }
  //     }
  //   },
  //   {
  //     header: 'Assign Roles'
  //   },
  //   {
  //     label: 'Assign Role',
  //     inputType: 'dropdown',
  //     key: 'RoleId',
  //     placeholder: '',
  //     dataSource: {
  //       dataKey: 'source',
  //       source: {
  //         method: 'get',
  //         UrlType: 'relative',
  //         Url: '/getUserRolesMaster'
  //       }
  //     }
  //   }
  // ];
  const { setToastError } = useActions();
  const {
    isFormEditable,
    isFormChangePass = false,
    formInput: formInputProp,
    areFormInputsChanged,
    formApi,
    saveForm
  } = state;
  const isEdit = saveForm === apiEndPoints.updateUserDetails;
  const userDetailsFormRef = useRef();
  // console.log('state', state, formInputProp, isFormEditable);
  // const [isModalVisible, setIsModalVisible] = useState(false);
  // const [details, setDetails] = useState();
  // const [notEmpty, setNotEmpty] = useState(false);
  // const imgeinputRef = useRef();
  // const dispatch = useDispatch();

  // setting up the form details from props
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (isFormChangePass) {
      setLoading(true);
      WebService.get(`${formApi}/${formInputProp?.UserID}`)
        .then((res) => {
          const { status, data } = res;
          if (status >= 200 && status < 300) {
            setUserformStructure(data);
          } else {
            throw res.data;
          }
        })
        .catch((e) => {
          const { error } = sagaCatchBlockHandling(e);
          setToastError(error);
        })
        .finally(() => {
          setLoading(false);
        });
      // if (!isEmpty(formInputProp)) {
      // console.log('setting details to form', formInputProp);
      setFormInput(formInputProp);
    } else {
      setLoading(true);
      WebService.get(formApi)
        .then((res) => {
          const { status, data } = res;
          if (status >= 200 && status < 300) {
            setUserformStructure(data);
            // setUserformStructure([
            //   {
            //     header: 'Update User Information'
            //   },
            //   {
            //     label: 'UserID:',
            //     inputType: 'text',
            //     key: 'UserID',
            //     isRequired: true,
            //     isReadOnly: true,
            //     allowEmpty: false,
            //     fieldWidth: 'MEDIUM'
            //   },
            //   {
            //     label: 'EmployeeID',
            //     inputType: 'text',
            //     key: 'EmployeeID',
            //     placeholder: '',
            //     fieldWidth: 'MEDIUM'
            //   },
            //   {
            //     label: 'Full Name:',
            //     inputType: 'text',
            //     key: 'UserName',
            //     fieldWidth: 'MEDIUM'
            //   },
            //   {
            //     label: 'Mobile Number:',
            //     inputType: 'text',
            //     key: 'MobileNumber',
            //     placeholder: '',
            //     fieldWidth: 'MEDIUM',
            //     validation: {
            //       value: '^$|^(\\+\\d{1,2}\\s?)?1?\\-?\\.?\\s?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$',
            //       type: 'regex',
            //       format: '(000) 000-0000'
            //     }
            //   },
            //   {
            //     label: 'Email Address:',
            //     inputType: 'email',
            //     key: 'EmailID',
            //     placeholder: '',
            //     fieldWidth: 'MEDIUM'
            //   },
            //   {
            //     label: 'Profile Image:',
            //     inputType: 'imageUpload',
            //     placeholder: ''
            //   },
            //   {
            //     label: 'Manager:',
            //     inputType: 'text',
            //     key: 'Manager',
            //     placeholder: ''
            //   },
            //   {
            //     label: 'Active Directory User:',
            //     inputType: 'toggle',
            //     key: 'IsADUser',
            //     placeholder: ''
            //   },
            //   {
            //     label: 'IsEnabled:',
            //     inputType: 'toggle',
            //     key: 'Enable',
            //     placeholder: ''
            //   },
            //   {
            //     label: 'IsChecklistUser:',
            //     inputType: 'toggle',
            //     key: 'IsChecklistUser',
            //     placeholder: ''
            //   },
            //   {
            //     label: 'Operations Group:',
            //     inputType: 'dropdown',
            //     key: 'OperationsGroup',
            //     placeholder: '',
            //     fieldWidth: 'MEDIUM',
            //     dataSource: {
            //       dataKey: 'source',
            //       source: {
            //         method: 'get',
            //         UrlType: 'relative',
            //         Url: '/getDepartmentMaster'
            //       }
            //     }
            //   },
            //   {
            //     header: 'Assign Roles'
            //   },
            //   {
            //     label: 'Assign Role',
            //     inputType: 'dropdown',
            //     key: 'RoleId',
            //     placeholder: '',
            //     dataSource: {
            //       dataKey: 'source',
            //       source: {
            //         method: 'get',
            //         UrlType: 'relative',
            //         Url: '/getUserRolesMaster'
            //       }
            //     }
            //   },
            //   {
            //     label: 'Assign Role',
            //     key: 'Roles',
            //     placeholder: ''
            //   }
            // ]);
          } else {
            throw res.data;
          }
        })
        .catch((e) => {
          const { error } = sagaCatchBlockHandling(e);
          setToastError(error);
        })
        .finally(() => {
          setLoading(false);
        });
      // if (!isEmpty(formInputProp)) {
      // console.log('setting details to form', formInputProp);
      setFormInput(formInputProp);
    }
    // }
  }, [formInputProp]);

  const [userSavePermissionInfo, setUserSavePermissionInfo] = useState({});
  useEffect(() => {
    userformStructure?.forEach((user) => {
      if (Object.keys(user)?.includes('isSave')) {
        if (user?.isSave) {
          setUserSavePermissionInfo({ ...user });
        }
      }
    });
  }, [userformStructure]);

  // header handlers
  const handleUserEdit = () => {
    history.push({
      pathname: '/home/user/edit',
      state: {
        isFormEditable: true,
        formInput,
        formApi: apiEndPoints.getUserUpdateForm,
        saveForm: apiEndPoints.updateUserDetails
      }
    });
  };
  const handleDisableUser = () => {
    // console.log('handleDisableUser');
  };

  const handleSave = () => {
    const localFormInput = userDetailsFormRef.current.getFormInput();
    try {
      const isPassed = validateFields(userformStructure, localFormInput);
      if (!isPassed) {
        throw 'Validation error has occurred';
      }
      // console.log('localFormInput', localFormInput);
      const formatedFormInput = convertFormInputObjToSimple(localFormInput);
      const requiredInput = userformStructure.reduce((acc, e) => {
        const { key, inputType, defaultValue } = e;
        let val = formatedFormInput[key] || defaultValue;
        if (inputType === INPUT_TYPES.TOGGLE) {
          val = val || false;
        }
        if (!key) return acc;
        // if (key === 'RoleId') return { ...acc, [key]: val, Roles: formatedFormInput.Roles };
        return { ...acc, [key]: val };
      }, {});
      WebService.post(saveForm, requiredInput)
        .then((res) => {
          const { data, status } = res;
          if (status >= 200 && status < 300) {
            setFormInput(localFormInput);
            history.push({
              pathname: '/home/Users',
              state: {
                isFormEditable: false,
                formInput: localFormInput,
                areFormInputsChanged: true,
                formApi,
                saveForm
              }
            });
          } else {
            throw res;
          }
        })
        .catch((e) => {
          const { error } = sagaCatchBlockHandling(e);
          console.log('error===', error);
          setToastError(error);
        });
    } catch (e) {
      console.log('error--', e);
      setToastError(e);
    }
  };
  const handleCancel = () => {
    if (isEdit) {
      history.push({
        pathname: '/home/user/details',
        state: {
          isFormEditable: false,
          formInput: formInputProp,
          formApi,
          saveForm
        }
      });
    } else {
      history.push({
        pathname: '/home/users',
        state: { record: areFormInputsChanged ? formInput : {} }
      });
    }
  };
  const handleBack = () => {
    // if in details page go back to users
    if (!isFormEditable) {
      history.push({
        pathname: '/home/users',
        state: { record: areFormInputsChanged ? formInput : {} }
      });
    } else {
      history.goBack();
    }
  };
  const [isFormFilled, setIsFormFilled] = useState(false);
  return (
    <>
      <UsersHeader
        title={
          !isFormEditable
            ? 'User details'
            : `${isEdit ? 'Edit' : 'Add'} User Details`
        }
        isFormEditable={isFormEditable}
        handleUserEdit={handleUserEdit}
        handleDisableUser={handleDisableUser}
        handleSave={handleSave}
        isSaveEnabled={
          !isEmpty(userSavePermissionInfo)
            ? userSavePermissionInfo?.isSave === 'Y' && isFormFilled
            : isFormFilled
        }
        handleCancel={handleCancel}
        canEdit={userPermissions?.CanEdit}
        handleBack={handleBack}
      />
      <BodyContainer>
        {loading ? (
          <Loader />
        ) : (
          <GenericForm
            isFormEditable={isFormEditable}
            formStructure={userformStructure}
            ref={userDetailsFormRef}
            formInput={formInput}
            setFormInput={setFormInput}
            showHeader
            setIsFormFilled={setIsFormFilled}
            formStructureFieldDepenents={getFieldDependents(userformStructure)}
            allowHorizontal={false}
            isUser={true}
          />
        )}
        {!isEmpty(userSavePermissionInfo) &&
          userSavePermissionInfo?.isSave !== 'Y' && (
            <Toast
              message={userSavePermissionInfo?.displayMsg || ''}
              header='Error'
              style={{ right: 30, bottom: 30 }}
            />
          )}
      </BodyContainer>
      {/* {isModalVisible && (
        <PopUpModal closeModalHandler={cancelRejection}>
          <FlexContainer className='jc_spacearound' style={{ minHeight: '15rem', flexDirection: 'column' }}>
            <ModalContentText style={{ color: 'black', marginBottom: '2rem', textAlign: 'center' }}>
              You may have changes. Do you want cancel ?
            </ModalContentText>
            <ButtonContainer>
              <FlexContainer className='jc_spacearound' style={{ width: '100%' }}>
                <ButtonComp onClick={cancelRejection}>No</ButtonComp>
                <ButtonComp dark onClick={cancelCormation}>
                  Yes
                </ButtonComp>
              </FlexContainer>
            </ButtonContainer>
          </FlexContainer>
        </PopUpModal>
      )} */}
    </>
  );
};

export default UserDetailsForm;

export const RequiredHeaderContainet = styled.div`
  position: relative;
  bottom: 2rem;
  font-size: ${fontSize.large};
`;
